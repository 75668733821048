/* eslint-disable react-native/no-inline-styles */
import React, { useState } from 'react';

import moment, { Moment } from 'moment';

import { useSelector } from 'react-redux';

import { Button, Card, Checkbox, useToast } from '@chakra-ui/react';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { BankAccount, StaticDataStore } from '../commonTypes';
import CDropdown from '../components/CDropdown';
import { DateInput } from '../components/DateInput';
import { MultiSelectInput } from '../components/MultiSelectInput';
import { Text, View } from '../components/Themed';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import BankAccountInput from '../components/BankAccountInput';

export default function ApplyBankTransactionRulesScreen() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [valueDate, setValueDate] = useState<Moment>();
  const [unmappedTransactionsOnly, setUnmappedTransactionsOnly] = useState(true);
  const [isIntraday, setIsIntraday] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const toast = useToast();

  return (
    <View style={AppStyles.container}>
      <Card mt={2} style={{ width: Layout.window.width / 3, padding: 10 }}>
        <Text style={[AppStyles.textRowTitle, AppStyles.marginBottom]}>Selection Criteria</Text>
        <DateInput title={'Value Date'} value={valueDate} onSelect={setValueDate} isNotOnModal />
        <View style={AppStyles.marginTop}>
          <BankAccountInput
            onSelect={(values) => {
              setSelectedAccounts(values);
            }}
            values={selectedAccounts}
            isMultiSelect
            includeAll={false}
          />
        </View>

        <View style={AppStyles.marginTop}>
          <CDropdown
            title={'Statement Type'}
            options={[
              { value: 'Intraday', label: 'Intraday' },
              { value: 'EndOfDay', label: 'End of Day / Prior Day' },
            ]}
            onChange={(val) => {
              setIsIntraday(val === 'Intraday');
            }}
            value={isIntraday ? 'Intraday' : 'EndOfDay'}
            placeholder="Select Statement Type"
            name="StatementType"
          />
        </View>
        <Checkbox
          style={{ marginTop: 20 }}
          isChecked={unmappedTransactionsOnly}
          value={'unmappedTransactionsOnly'}
          onChange={(e) => setUnmappedTransactionsOnly(e.target.checked)}
        >
          <Text style={{ marginTop: 10 }}>Unmapped transctions only</Text>
        </Checkbox>
        <Button
          mt={5}
          w={200}
          alignSelf={'flex-end'}
          onClick={() => {
            if (!valueDate || !selectedAccounts || selectedAccounts?.length === 0) {
              return toast({
                title: 'Enter all selection criteria',
                description: 'Please choose your selection criteria to apply the rules to',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
            setIsProcessing(true);
            const functions = getFunctions();
            if (__DEV__) {
              connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            const applyRules = httpsCallable(functions, 'applyBankTransactionRules');
            applyRules({
              org: profileData.orgId,
              selectedAccounts: selectedAccounts,
              valueDate: moment(valueDate?.toDate()).format('YYYYMMDD'),
              isIntraday,
              category: unmappedTransactionsOnly ? 'Unmapped' : undefined,
            })
              .then((response: any) => {
                setIsProcessing(false);
                if (response.data.error) {
                  toast({
                    title: 'Someting went wrong',
                    description: response.data.error,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                  });
                }
                if (response.data.warning) {
                  toast({
                    title: 'Completed with errors',
                    description: response.data.warning,
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                  });
                }
                if (response.data.success) {
                  toast({
                    title: 'Rules applied successfully',
                    description: response.data.success,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  });
                }
              })
              .catch((error) => {
                setIsProcessing(false);
                toast({
                  title: 'Someting went wrong',
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                });
              });
          }}
          isLoading={isProcessing}
          colorScheme="blue"
        >
          {'Apply Rules'}
        </Button>
      </Card>
    </View>
  );
}
