import { Avatar, Badge, Box, HStack, Text, VStack } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { Comment, StaticDataStore, User } from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import { _getFormattedDateTime } from '../../utils/helper';


const CommentCard = ({
  comment,
  onReply,
  onResolve,
  onDelete,
}: {
  comment: Comment;
  onReply: () => void;
  onResolve: () => void;
  onDelete: () => void;
}) => {
  const users = useSelector((store: StaticDataStore) => store.staticData?.users);
  const { uid } = useSelector((store: StaticDataStore) => store.profileData);

  const user = users?.find((u: User) => u.uid === comment.user);
  const isMe = useMemo(() => comment.user === uid, [uid]);

  /**
   * Parse mentions in the comment text and render with styles.
   * Example: "@[JohnDoe](uid123)" -> "@JohnDoe" (with style)
   */
  const renderCommentText = (text: string) => {
    const mentionRegex = /@\[(.+?)\]\((.+?)\)/g; // Matches "@[DisplayName](UID)"
    const parts: React.ReactNode[] = [];
    let lastIndex = 0;

    // Process text to identify mentions
    text.replace(mentionRegex, (match, displayName, uid, offset) => {
      // Push text before the mention
      if (offset > lastIndex) {
        parts.push(
          <Text key={`text-${lastIndex}`} as="span">
            {text.substring(lastIndex, offset)}
          </Text>,
        );
      }

      // Push the mention
      parts.push(
        <Text key={`mention-${uid}`} as="span" color="blue.500" fontWeight="bold" cursor="pointer">
          @{displayName}
        </Text>,
      );

      lastIndex = offset + match.length;
      return match; // Return the match to avoid issues
    });

    // Push remaining text after the last mention
    if (lastIndex < text.length) {
      parts.push(
        <Text key={`text-${lastIndex}`} as="span">
          {text.substring(lastIndex)}
        </Text>,
      );
    }

    return parts;
  };

  return (
    <Box  p={2} rounded="md" mb={2} mt={1}>
      <HStack  justifyContent={'flex-start'} mb={-1}>
        <Avatar src={user?.displayImage} name={user?.displayName || user?.firstName} size="xs" />
        <VStack align="start" spacing={1} w="full">
          <HStack>
            <Text style={{fontWeight: 'bold', fontSize: 12}}>
              {isMe ? 'You' : user?.displayName || user?.firstName || 'Unknown User'}
            </Text>
            <Text style={{ fontSize: 10 }}>
              {_getFormattedDateTime(comment.createdAt.isoString, comment.createdAt.timezone)}
            </Text>
            {comment.resolved && <Badge colorScheme="green">Resolved</Badge>}
          </HStack>
        </VStack>
      </HStack>
      <Text  style={{fontSize: 12, marginLeft: 35}}>{renderCommentText(comment.text)}</Text>
      {isMe && (
        <HStack mt={1} ml={9}>
          <TouchableOpacity onPress={onDelete}>
            <Text style={{ ...AppStyles.textButton, color: Colors.red }}>Delete</Text>
          </TouchableOpacity>
        </HStack>
      )}
    </Box>
  );
};

export default CommentCard;
