import { Card, Center } from '@chakra-ui/react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { AppContext } from '../../App';
import { CashPositionSummary, StaticDataStore } from '../../commonTypes';
import Colors from '../../constants/Colors';
import useCategory from '../../hooks/useCategories';
import useCurrencies from '../../hooks/useCurrencies';
import { Text } from '../Themed';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

// Register Chart.js plugins
Chart.register(...registerables, ChartDataLabels);

const CashTrendWidget: FC = () => {
  const { profileData } = useSelector((store: StaticDataStore) => store);

  const [data, setData] = useState<any[]>();
  const [labels, setLabels] = useState<string[]>();

  const [isEmpty, setIsEmpty] = useState(false);

  const { _convertToReportingCurrency } = useCurrencies();
  const reportingCurrency = 'USD';
  const { db } = useContext(AppContext);
  const { _getCategoryName } = useCategory();
  const navigation = useNavigation();

  useEffect(() => {
    if (!profileData.orgId) {
      return;
    }
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'CashPositionSummary'),
      where('date', '>', moment().subtract(8, 'days').toDate()),
      where('date', '<', moment().add(1, 'days').toDate()),
      orderBy('date', 'asc'),
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (snapshot.size === 0) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
      const data = snapshot.docs.map((doc) => doc.data()) as CashPositionSummary[];
      const _labels = Array.from(new Set(data.map((e) => moment(e.date.toDate()).format('DD/MM'))));
      setLabels(_labels);

      const categoryGroup = groupBy(data, (position) => position.category || 'Unmapped');
      const formattedData = Object.entries(categoryGroup).map(([categoryId, positions], index) => {
        const positionMap = positions.reduce((acc, position) => {
          const date = moment(position.date.toDate()).format('DD/MM');
          acc[date] =
            Math.abs(_convertToReportingCurrency(position.statementAmount, position.currency)) /
            1000; // Convert to thousands and ensure positive values
          return acc;
        }, {} as Record<string, number>);

        const orderedData = _labels.map((label) => positionMap[label] || 0);

        return {
          data: orderedData,
          label: _getCategoryName(categoryId),
          borderColor: Colors.pieChartColors[(index * 2) % Colors.pieChartColors.length],
          backgroundColor: Colors.pieChartColors[(index * 2) % Colors.pieChartColors.length],
        };
      });
      setData(formattedData);
    });

    return () => {
      unsubscribe();
    };
  }, [profileData?.orgId, db, _convertToReportingCurrency]);

  return (
    <Card p={5} style={{ alignItems: 'center', flex: 1 }}>
      <TouchableOpacity onPress={() => navigation.navigate('CashPositionScreen')}>
        <Text style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }}>
          Cash flow Trends
        </Text>
      </TouchableOpacity>
      {isEmpty ? (
        <Center flex={1}>
          <Text style={{ fontSize: 14, color: '#718096', textAlign: 'center', marginTop: 20 }}>
            No data yet
          </Text>
        </Center>
      ) : (
        labels &&
        data && (
          <Bar
            options={{
              plugins: {
                legend: { display: true },
                subtitle: {
                  display: true,
                  text: `K ${reportingCurrency}`,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const label = context.label || '';
                      const value = (context.raw || 0).toFixed(2);
                      return `${context.dataset.label}: ${value}k ${reportingCurrency}`;
                    },
                  },
                },
                datalabels: {
                  color: Colors.transBlack, // White text for contrast
                  anchor: 'end',
                  align: 'top',
                  offset: 0,
                  padding: 0,

                  formatter: (value, context) => {
                    if (value === 0) return ''; // Hide zero values
                    return value > 1 ? Math.round(value) : value.toFixed(2); // Display percentage correctly
                  },

                  font: {
                    weight: 'bold',
                    size: 12,
                  },
                },
              },
              maintainAspectRatio: false,
              responsive: true,
            }}
            data={{
              labels: labels,
              datasets: data,
            }}
          />
        )
      )}
    </Card>
  );
};

export default CashTrendWidget;
