import React from 'react';

import { Text, TextProps } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import { _getCrDbIndicator, _getFormattedCurrency } from '../utils/helper';

export function MonoText(props: TextProps) {
  return <Text {...props} style={[props.style, { fontFamily: 'space-mono' }]} />;
}

export function AmountText(
  props: TextProps & {
    amount: number;
    currency: string;
    code?: string;
    size?: number;
    color?: string;
    currencyColor?: string;
    shorten?: 'B' | 'M';
    hideZeroAmount?: boolean;
  },
) {
  let color = props.color;
  if (!color) {
    if (props.amount < 0) {
      color = Colors.red;
    } else if (_getCrDbIndicator(props.code) === 'CR') {
      color = Colors.green;
    } else if (_getCrDbIndicator(props.code) === 'DB') {
      color = Colors.red;
    }
  }
  color = color || Colors.black;
  return (
    <Text
      style={[
        {
          color: color,
          fontSize: props.size || Constants.TextSize.xxl,
          fontWeight: 'bold',
        },
      ]}
    >
      <Text
        style={[
          {
            color: props.currencyColor || color,
            fontSize: props.size || Constants.TextSize.xxl,
            fontWeight: 'bold',
          },
        ]}
      >
        {props.currency + ' '}
      </Text>

      {props.hideZeroAmount && (Number(props.amount) === 0 || !props.amount)
        ? ''
        : _getFormattedCurrency(props.amount, props.currency, true, props.shorten)}
    </Text>
  );
}
