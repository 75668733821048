/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { ScrollView, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import { CReport, StaticDataStore, Visibility } from '../commonTypes';
import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';

import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import * as WebDataRocksReact from '@webdatarocks/react-webdatarocks';
import { Pivot, Report } from '@webdatarocks/webdatarocks/webdatarocks';

import { FontAwesome } from '@expo/vector-icons';
import { omit, set } from 'lodash';
import { ActivityIndicator } from 'react-native-paper';
import { AppContext } from '../App';
import AccessType from '../components/AccessType';
import Button from '../components/Button';
import CCheckBox from '../components/CCheckBox';
import CDropdown from '../components/CDropdown';
import CInput from '../components/CInput';
import { FilterBar } from '../components/FilterBar';
import useReporting from '../hooks/useReporting';
import { _textFieldIsInvalid } from '../utils/helper';

export default function ReportingDetailsScreen({
  navigation,
  route,
}: RootTabScreenProps<'ReportingDetailsScreen'>) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [reportDetails, setReportDetails] = useState<CReport>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const reportRef = useRef<any>();
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const [dataLimit, setDataLimit] = useState<number | undefined>(10);

  const { DataSources, fetchData, formatFilterValues } = useReporting();

  useEffect(() => {
    if (route.params?.report) {
      const _filters = formatFilterValues(
        route.params?.report?.filters,
        route.params?.report?.dataSource,
      );
      setReportDetails({ ...(route.params?.report || {}), filters: _filters } as CReport);
    }
  }, [route.params?.report]);

  const canEdit = staticData.accessibleScreens?.ReportingDetailsScreen?.edit || false;

  const { db } = useContext(AppContext);

  useEffect(() => {
    if (reportDetails?.dataSource) {
      fetchData(reportDetails, dataLimit, setIsLoading, reportRef);
    }
  }, [reportDetails, dataLimit]);

  const onTextChange = useCallback((field: keyof CReport, value: string) => {
    setReportDetails((existingValue) => {
      return { ...existingValue, [field]: value } as CReport;
    });
  }, []);

  const saveReport = useCallback(() => {
    // reportRef.current?.webdatarocks?.save();
    setIsSaving(true);
    if (_textFieldIsInvalid(reportDetails?.name)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid name',
        description: 'Please enter a valid Report name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (_textFieldIsInvalid(reportDetails?.dataSource)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid name',
        description: 'Please enter a valid DataSource',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    const reportConfig: Report = (reportRef.current?.webdatarocks as Pivot)?.getReport() as Report;
    if (!reportDetails) {
      setIsSaving(false);
      return;
    }
    if (reportConfig?.dataSource) {
      reportConfig.dataSource.data = [];
    }

    reportDetails.reportConfig = reportConfig;
    if (!reportDetails?.visibility) {
      reportDetails.visibility = Visibility.Private;
    }
    if (reportDetails?.visibility === Visibility.UserGroup && !reportDetails?.userGroups?.length) {
      setIsSaving(false);
      return toast({
        title: 'Invalid User Group',
        description: 'Please select a user group',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (reportDetails?.id) {
      //Update
      updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'Reports', reportDetails?.id),
        omit(
          {
            ...reportDetails,
            lastUpdatedBy: profileData?.uid,
            lastUpdatedDttm: serverTimestamp(),
          },
          ['lastRun', 'createdBy', 'createdDtTm'],
        ),
      )
        .then(() => {
          setIsSaving(false);
          return toast({
            title: 'Report updated successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          setIsSaving(false);
          console.error('Something went wrong');
        });
    } else {
      //Create
      getDocs(
        query(
          collection(db, 'Organizations', profileData.orgId, 'Reports'),
          where('name', '==', reportDetails.name),
        ),
      ).then((querySnapshot) => {
        if (querySnapshot.docs.length) {
          setIsSaving(false);
          return toast({
            title: 'Report already exists',
            description: 'A report with the same name already exists. Please use a different name',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      });

      addDoc(collection(db, 'Organizations', profileData.orgId, 'Reports'), {
        ...reportDetails,
        createdBy: profileData?.uid,
        createdDtTm: serverTimestamp(),
        lastRun: serverTimestamp(),
        filterParams: reportDetails?.filterParams || [],
      })
        .then((documentRef) => {
          setIsSaving(false);
          setReportDetails((currentValue) => ({ ...currentValue, id: documentRef.id }));
          return toast({
            title: 'Report created successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          setIsSaving(false);
          console.error('Something went wrong');
        });
    }
  }, [reportDetails]);

  function customizeToolbar(toolbar) {
    // Get all tabs from the toolbar
    const tabs = toolbar.getTabs();
    toolbar.getTabs = function () {
      // Delete the first tab
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      delete tabs[7];
      tabs[3].rightGroup = true;
      return tabs;
    };
  }

  return (
    <ScrollView style={[AppStyles.container, AppStyles.padding, { backgroundColor: Colors.white }]}>
      <Stack direction="row" justifyContent={'space-between'}>
        <Stack direction="row" spacing={4} alignItems={'center'} mb={2}>
          <CInput
            placeholder="Name"
            fieldKey="name"
            fieldValue={reportDetails?.name || ''}
            title="Name"
            onTextChange={onTextChange}
            isRequired
            label="Report Name"
            tooltip={
              reportDetails?.isLocked
                ? 'Default Report - Cannot be edited. You can create your own copy to edit as required'
                : undefined
            }
            disabled={reportDetails?.isLocked}
            isLockIcon
          />
          <CDropdown
            isRequired
            placeholder="Datasource"
            name="datasource"
            value={reportDetails?.dataSource}
            title="Select Datasource"
            disabled={reportDetails?.isLocked}
            options={DataSources}
            onChange={(value) => {
              const _filterParams = DataSources.find((_ds) => _ds.value === value)?.filterParams;
              setDataLimit(10); //Reset data limit
              const _filter = {};
              _filterParams?.forEach((filterParam) => {
                if (filterParam.selectedValue !== undefined) {
                  set(_filter, filterParam.field, filterParam.selectedValue);
                }
              });
              setReportDetails(
                (currentValue) =>
                  ({
                    ...currentValue,
                    dataSource: value,
                    filterParams: _filterParams,
                    reportConfig: undefined,
                    filters: _filter,
                  } as CReport),
              );
            }}
          />
          {DataSources.find((_ds) => _ds.value === reportDetails?.dataSource)?.type === 'db' && (
            <View style={AppStyles.marginLeft}>
              <CCheckBox
                label="Show all rows"
                value={!dataLimit}
                onChange={(value) => setDataLimit(value ? undefined : 10)}
              />
              {dataLimit && (
                <Text style={[AppStyles.textTiny, { textAlign: 'right' }]}>Limited to 10 rows</Text>
              )}
            </View>
          )}
        </Stack>
        <Stack direction="row" spacing={4} alignItems={'center'}>
          {reportDetails?.dataSource && !isLoading && (
            <TouchableOpacity
              style={[AppStyles.marginRight, AppStyles.padding]}
              onPress={() => {
                fetchData(reportDetails, dataLimit, setIsLoading, reportRef);
              }}
            >
              <FontAwesome name="refresh" size={24} color={Colors.primary} />
            </TouchableOpacity>
          )}
          {isLoading && <ActivityIndicator size="small" color={Colors.primary} />}
          <Button
            w={100}
            mt={2}
            variant={'Cancel'}
            isLoading={isSaving}
            onPress={() => {
              navigation.goBack();
            }}
            label="Cancel"
          />
          {reportDetails?.id && (
            <Button
              w={100}
              mt={2}
              isLoading={isSaving}
              onPress={() => {
                setReportDetails(
                  (currentValue) =>
                    ({
                      ...currentValue,
                      isLocked: !currentValue.isLocked,
                      name: `${currentValue.name} - Copy`,
                      id: undefined,
                      visibility: Visibility.Private,
                    } as CReport),
                );
              }}
              label="Copy"
            />
          )}
          {!reportDetails?.isLocked && (
            <Button
              w={100}
              mt={2}
              isLoading={isSaving}
              onPress={() => {
                saveReport();
              }}
              label="Save"
            />
          )}
        </Stack>
      </Stack>
      {(reportDetails?.filterParams?.length || 0) > 0 && (
        <FilterBar
          hideSearch
          filterParams={reportDetails?.filterParams}
          onSearch={() => null}
          filter={reportDetails?.filters}
          updateFilter={(value) => {
            console.warn('Filter updated', value);
            setReportDetails((currentValue) => ({ ...currentValue, filters: value }));
          }}
        />
      )}
      <Tabs
        variant="enclosed"
        mt={0}
        index={tabIndex}
        onChange={setTabIndex}
        backgroundColor={Colors.white}
      >
        <TabList>
          <Tab>Report</Tab>
          {!reportDetails?.isLocked && <Tab>Visibility</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            {reportDetails?.dataSource && (
              <WebDataRocksReact.Pivot
                key={reportDetails?.dataSource}
                ref={reportRef}
                beforetoolbarcreated={customizeToolbar}
                toolbar={true}
                width="100%"
                report={{
                  dataSource: {
                    dataSourceType: 'json',
                    data: [],
                  },
                }}
              />
            )}
          </TabPanel>
          <TabPanel>
            <AccessType
              label="Visibility"
              options={[Visibility.Private, Visibility.Public, Visibility.UserGroup]}
              value={reportDetails?.visibility || Visibility.Private}
              onChange={(v) => onTextChange('visibility', v)}
              userGroups={reportDetails?.userGroups}
              onUserGroupChange={(v) => onTextChange('userGroups', v)}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ScrollView>
  );
}
