import React, { FC, useCallback, useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import debounce from 'lodash/debounce';
import { TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { Searchbar } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import { CFlatList } from './CFlatList';
import { Card, Text, View } from './Themed';
import { SHOW_AI_SCREEN, SHOW_SMART_MENU } from '../actions/ActionConstatnts';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { AccessibleScreen, StaticDataStore } from '../commonTypes';
import { omit } from 'lodash';

const SmartMenu: FC = () => {
  const dispatch = useDispatch();
  const { userInterface, staticData } = useSelector((store: StaticDataStore) => store);

  const [searchQuery, setSearchQuery] = React.useState('');

  const [searchText, setSearchText] = React.useState('');

  const [menuResults, setMenuResults] = React.useState<AccessibleScreen[]>([]);

  const navigation = useNavigation();

  useEffect(() => {
    if (searchQuery && staticData.accessibleScreens) {
      console.warn('searchQuery', staticData.accessibleScreens);
      setMenuResults(
        Object.values(staticData.accessibleScreens)?.filter((item) =>
          JSON.stringify(omit(item, 'menuItem')).toLowerCase().includes(searchQuery.toLowerCase()),
        ) || [],
      );
    } else {
      setMenuResults([]);
    }
  }, [searchQuery, staticData.accessibleScreens]);

  const delayedQuery = useCallback(
    debounce((q) => setSearchQuery(q), 500),
    [],
  );
  const onChange = (value: string) => {
    setSearchText(value);
    delayedQuery(value);
  };

  if (!userInterface.smartMenu) {
    return null;
  }
  return (
    <BlurView
      intensity={50}
      tint="dark"
      style={{
        flex: 1,
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        zIndex: 999,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          dispatch({
            payload: false,
            type: SHOW_SMART_MENU,
          });
        }}
      >
        <View style={{ width: Layout.window.width, height: Layout.window.height, padding: 100 }}>
          <Searchbar
            value={searchText}
            placeholder="Search Anything"
            placeholderTextColor={Colors.transBlack}
            onChangeText={onChange}
            autoFocus
          />
          {menuResults.length > 0 && (
            <>
              <Text
                style={[
                  AppStyles.textRowTitle,
                  AppStyles.marginTop25,
                  { color: Colors.black, fontWeight: 'bold' },
                ]}
              >
                Navigation
              </Text>
              <View style={{ height: 120, marginTop: 10 }}>
                <CFlatList
                  data={menuResults}
                  keyExtractor={(item) => item.target}
                  horizontal={true}
                  renderItem={({ item, index }: { item: AccessibleScreen; index: number }) => (
                    <TouchableOpacity
                      onPress={() => {
                        dispatch({
                          payload: false,
                          type: SHOW_SMART_MENU,
                        });
                        dispatch({
                          payload: false,
                          type: SHOW_AI_SCREEN,
                        });
                        setSearchText('');
                        navigation.navigate(item.target);
                      }}
                    >
                      <Card
                        style={{
                          width: 150,
                          height: 100,
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            justifyContent: 'space-between',
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 14,
                              color: '#718096',
                              textAlign: 'center',
                              marginBottom: 10,
                            }}
                          >
                            {item.menuItem.title}
                          </Text>
                          <Text style={[AppStyles.textRowTitle, { textAlign: 'center' }]}>
                            {item.title}
                          </Text>
                        </View>
                      </Card>
                    </TouchableOpacity>
                  )}
                />
              </View>
            </>
          )}
        </View>
      </TouchableWithoutFeedback>
    </BlurView>
  );
};

export default SmartMenu;
