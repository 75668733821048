import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import { StaticDataStore } from '../../commonTypes';
import { View } from '../Themed';
import { Avatar, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import { FontAwesome } from '@expo/vector-icons';
import { Mention, MentionsInput } from 'react-mentions';
import { ActivityIndicator, ViewStyle } from 'react-native';
import Colors from '../../constants/Colors';

const mentionStyle = {
  backgroundColor: '#e3f2fd',
  borderRadius: '4px',
};

const CommentInput = ({
  onSubmit,
  isLoading,
  style,
}: {
  onSubmit: (comment: string, mentions: string[]) => void;
  isLoading?: boolean;
  style?: ViewStyle;
}) => {
  const [text, setText] = useState('');
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const users = useSelector((store: StaticDataStore) => store.staticData?.users);

  const userMentions =
    users?.map((user) => ({
      id: user.uid,
      display: user.displayName || user.firstName || user.lastName,
      avatar: user.displayImage || 'default-avatar-url',
    })) || [];

  const extractMentions = (text: string) => {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
    const mentions: string[] = [];
    let match;

    while ((match = mentionRegex.exec(text)) !== null) {
      mentions.push(match[2]);
    }

    return mentions;
  };

  const handleSubmit = () => {
    if (text.trim()) {
      const mentions = extractMentions(text);

      onSubmit(text, mentions);
      setText('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <View spacing={2} style={style}>
      <MentionsInput
        value={text}
        onChange={(e) => setText(e.target.value)}
        onFocus={() => setIsFocused(true)} // Update focus state on focus
        onBlur={() => !text && setIsFocused(false)} // Reset focus state on blur if text is empty
        onKeyDown={handleKeyDown} // Handle Enter key press
        placeholder="Add a comment... Use @ to tag users"
        style={{
          input: {
            padding: 5,
            fontSize: '12px',
            borderRadius: '8px',
            border: '1px solid #ccc',
          },
          highlighter: {
            padding: 5,
            fontSize: '12px',
          },
          minHeight: isFocused ? '80px' : '40px', // Multi-line when focused
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          lineHeight: '1.5',
        }}
      >
        <Mention
          trigger="@"
          data={userMentions}
          renderSuggestion={(
            suggestion: any,
            search: any,
            highlightedDisplay: string,
            index: any,
            focused: boolean,
          ) => (
            <HStack
              key={suggestion.id}
              spacing={2}
              padding="8px"
              backgroundColor={focused ? '#e3f2fd' : Colors.lightest_grey}
              borderRadius="4px"
            >
              <Avatar size="xs" name={suggestion.display} src={suggestion.avatar} />
              <Text>{highlightedDisplay}</Text>
            </HStack>
          )}
          style={mentionStyle}
          appendSpaceOnAdd
        />
      </MentionsInput>

      {isFocused && (
        <IconButton
          aria-label="Submit"
          icon={
            isLoading ? (
              <ActivityIndicator color={Colors.white} />
            ) : (
              <FontAwesome name="send" color={Colors.white} />
            )
          }
          colorScheme="blue"
          onClick={handleSubmit}
          bottom="8px"
          right="8px"
          position={'absolute'}
          isDisabled={!text.trim() && !isLoading}
        />
      )}
    </View>
  );
};

export default CommentInput;
