import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { UPDATE_BANK_ACCOUNTS } from '../../actions/ActionConstatnts';
import AppStyles from '../../constants/Styles';

import { Badge, Code, useToast } from '@chakra-ui/react';
import { TouchableOpacity } from 'react-native';
import { AppContext } from '../../App';
import {
  ApprovalWorkflowSource,
  Bank,
  BankAccount,
  Entity,
  InputMode,
  Ledger,
  LedgerSegment,
  LedgerSegmentType,
  LogType,
  StaticDataStore,
  Status,
} from '../../commonTypes';
import { _getChangedFields, _getFormattedCurrency, _textFieldIsInvalid } from '../../utils/helper';
import ActivityHistory from '../ActivityHistory';
import ReviewAndApproveModal from '../ApprovalWorkflow/ReviewAndApproveModal';
import BankInput from '../BankInput';
import ButtonGroup from '../ButtonGroup';
import CInput from '../CInput';
import CurrencyInput from '../CurrencyInput';
import EntityInput from '../EntityInput';
import FieldDisplay from '../FieldDisplay';
import LedgerInput from '../GL/LedgerInput';
import PaymentMethodInput from '../Payments/PaymentMethodInput';
import StatusTag from '../StatusTag';
import { Button, Text, View } from '../Themed';
import AccountGroupInput from './AccountGroupInput';

export type BankAccountDetailsProps = {
  bankAccount: BankAccount;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onAccountUpdated?: (updatedAccount: BankAccount) => any;
  mode?: InputMode;
  onNext?: () => void;
  onItemActioned?: () => void;
};

const BankAccountDetails: FC<BankAccountDetailsProps> = ({
  bankAccount,
  setIsVisible,
  onAccountUpdated,
  mode = InputMode.CREATE,
  onNext,
  onItemActioned,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>(bankAccount);
  const [canApprove, setCanApprove] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const isReadOnly = [InputMode.VIEW, InputMode.APPROVE].includes(mode);
  useEffect(() => {
    setSelectedBankAccount(bankAccount);
    console.warn(bankAccount);
  }, [bankAccount]);

  const { db } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [entityGlField, setEntityGlField] = useState<{ field: keyof Ledger; value: string }>();

  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.BANK_ACCOUNTS]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;

  const ledgerDetails = useMemo(() => {
    return staticData?.orgInfo?.Ledgers?.[selectedBankAccount.ledger] as Ledger;
  }, [staticData?.orgInfo?.Ledgers, selectedBankAccount.ledger]);

  const glAccount = useMemo(() => {
    if (ledgerDetails) {
      let _glAccount = '';
      for (let i = 1; i <= 10; i++) {
        const fieldName = ('segment' + i) as keyof Ledger;
        if (ledgerDetails[fieldName]?.type) {
          switch (ledgerDetails[fieldName]?.type) {
            case LedgerSegmentType.Constant:
              _glAccount += ledgerDetails[fieldName]?.value;
              break;
            case LedgerSegmentType.Wildcard:
            case LedgerSegmentType['Bank Account']:
              _glAccount +=
                selectedBankAccount?.glCodes?.[fieldName] || ledgerDetails[fieldName]?.name;
              break;
            case LedgerSegmentType.Entity:
              const entityCode =
                staticData?.entities?.find((entity) => entity.name === selectedBankAccount.entity)
                  ?.glCode || ledgerDetails[fieldName]?.name;
              _glAccount += entityCode;
              setEntityGlField({ field: fieldName, value: entityCode });

            // case LedgerSegmentType['Custom List']:
            //   _glAccount += ledgerDetails[fieldName]?.value;
            //   break;
          }
          if (ledgerDetails[fieldName]?.suffix) {
            _glAccount += ledgerDetails[fieldName]?.suffix;
          }
        }
      }
      setSelectedBankAccount((existingValue) => {
        return { ...existingValue, glAccount: _glAccount } as BankAccount;
      });
      return _glAccount;
    } else {
      return '';
    }
  }, [selectedBankAccount.glCodes, ledgerDetails, selectedBankAccount.entity]);

  const onSave = useCallback(async () => {
    if (isSaving || !profileData?.orgId) {
      return;
    }
    setIsSaving(true);
    if (_textFieldIsInvalid(selectedBankAccount?.accountNumber)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid account number',
        description: 'Please enter a valid account number',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (_textFieldIsInvalid(selectedBankAccount?.accountName)) {
      setIsSaving(false);
      return toast({
        title: 'Invalid account name',
        description: 'Please enter a account name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (_textFieldIsInvalid(selectedBankAccount?.currency)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid currency',
        description: 'Please enter a valid currency',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (_textFieldIsInvalid(selectedBankAccount?.entity)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid entity',
        description: 'Please select a valid entity',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (_textFieldIsInvalid(selectedBankAccount?.bank)) {
      setIsSaving(false);

      return toast({
        title: 'Invalid bank',
        description: 'Please select a valid bank',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (selectedBankAccount?.ledger) {
      let errorExists = false;
      [...Array(10)].map((_, i) => {
        const fieldName = `segment${i + 1}`;
        const segment = ledgerDetails[fieldName] as LedgerSegment;
        if (
          segment?.type === LedgerSegmentType['Bank Account'] ||
          segment?.type === LedgerSegmentType.Wildcard ||
          segment?.type === LedgerSegmentType['Custom List']
        ) {
          if (_textFieldIsInvalid(selectedBankAccount?.glCodes?.[fieldName])) {
            errorExists = true;
            return toast({
              title: 'Invalid ' + segment?.name,
              description: 'Since you have selected a Ledger, this is a mandatory field',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
          if (
            segment.type === LedgerSegmentType.Wildcard &&
            ((segment.maxLength &&
              (selectedBankAccount?.glCodes?.[fieldName]?.length || 0) > segment.maxLength) ||
              (segment.minLength &&
                (selectedBankAccount?.glCodes?.[fieldName]?.length || 0) < segment.minLength))
          ) {
            errorExists = true;
            let toastMessage = 'The value should be ';
            if ((segment.minLength === segment.maxLength) !== undefined) {
              toastMessage += 'exactly ' + segment.minLength;
            } else if (segment.minLength !== undefined && segment.maxLength !== undefined) {
              toastMessage += 'between ' + segment.minLength + ' and ' + segment.maxLength;
            } else if (segment.minLength) {
              toastMessage += 'more than ' + segment.minLength;
            } else if (segment.maxLength) {
              toastMessage += 'less than ' + segment.maxLength;
            }
            toastMessage += ' characters';
            return toast({
              title: 'Invalid ' + segment?.name,
              description: toastMessage,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        }
      });
      if (errorExists) {
        setIsSaving(false);
        return;
      }
    }

    const updateRequest = {
      ..._getChangedFields(bankAccount, selectedBankAccount),
      requestedBy: profileData.uid,
      requestId: uuidv4(),
      lastUpdatedAt: serverTimestamp(),
    };

    let bankAccountDoc = {
      ...selectedBankAccount,
      glAccount,
      status:
        !selectedBankAccount.status ||
        selectedBankAccount.status === Status.NEW ||
        (!isWorkflowEnabled && selectedBankAccount.status === Status.PENDING_APPROVAL)
          ? Status.ACTIVE
          : selectedBankAccount.status,
      lastUpdatedBy: profileData.uid,
    } as BankAccount;

    console.warn('entityGlField', entityGlField);

    if (entityGlField?.field && entityGlField?.value) {
      if (bankAccountDoc.glCodes) {
        console.warn('asd', bankAccountDoc.glCodes);
        bankAccountDoc.glCodes[entityGlField?.field] = entityGlField?.value;
      } else {
        bankAccountDoc.glCodes = { [entityGlField?.field]: entityGlField?.value };
      }
    }

    if (bankAccountDoc?.id) {
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'BankAccounts', bankAccountDoc.id),
        isWorkflowEnabled ? { updateRequest: updateRequest } : bankAccountDoc,
      )
        .then(() => {
          if (isWorkflowEnabled) {
            bankAccountDoc = { ...bankAccount, updateRequest };
          }
          onAccountUpdated?.(bankAccountDoc);
          const _bankAccounts = staticData.bankAccounts || [];
          _bankAccounts[
            _bankAccounts.findIndex((eBankAccount) => eBankAccount.id === bankAccountDoc.id)
          ] = bankAccountDoc;
          dispatch({
            payload: _bankAccounts,
            type: UPDATE_BANK_ACCOUNTS,
          });

          toast({
            title: isWorkflowEnabled
              ? 'Account update requested. Pending approval'
              : 'Account Updated',
            status: isWorkflowEnabled ? 'warning' : 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((e) => {
          console.warn(e);
          return toast({
            title: 'Something went wrong',
            description: 'You may not have permission to perform this action',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });

      setIsSaving(false);
      setIsVisible?.(false);
    } else if (bankAccountDoc?.accountNumber && bankAccountDoc?.currency) {
      //Create new account
      const docSnap = await getDoc(
        doc(
          db,
          'Organizations',
          profileData?.orgId,
          'BankAccounts',
          bankAccountDoc?.accountNumber + bankAccountDoc?.currency || '',
        ),
      );
      if (docSnap.exists()) {
        setIsSaving(false);
        return toast({
          title: 'Account already exists',
          description: 'An account with the same number and currency already exists',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      await setDoc(
        doc(
          db,
          'Organizations',
          profileData?.orgId,
          'BankAccounts',
          bankAccountDoc?.accountNumber + bankAccountDoc?.currency || '',
        ),
        {
          ...selectedBankAccount,
          createdBy: profileData?.uid,
          createdDtTm: serverTimestamp(),
          status: isWorkflowEnabled ? Status.PENDING_APPROVAL : Status.ACTIVE,
        },
      );
      onAccountUpdated?.({
        ...bankAccountDoc,
        createdBy: profileData?.uid,
        createdDtTm: Date.now(),
      });
      setIsSaving(false);
      setIsVisible?.(false);
      const _bankAccounts = staticData.bankAccounts || [];
      _bankAccounts.push(bankAccountDoc);
      dispatch({
        payload: _bankAccounts,
        type: UPDATE_BANK_ACCOUNTS,
      });

      return toast({
        title: 'Account Created',
        description: isWorkflowEnabled
          ? 'Account creation request has been submitted and is pending approval'
          : 'Account Created Successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setIsSaving(false);
      return toast({
        title: 'Something went wrong',
        description: 'Please check the details and try again',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    bankAccount,
    db,
    dispatch,
    isSaving,
    onAccountUpdated,
    profileData.orgId,
    profileData.uid,
    selectedBankAccount,
    setIsVisible,
    staticData.bankAccounts,
    staticData?.orgInfo?.Workflow?.BankAccounts?.status,
    toast,
    entityGlField,
  ]);

  const onTextChange = useCallback((field: keyof BankAccount, value: string) => {
    setSelectedBankAccount((existingValue) => {
      return { ...existingValue, [field]: value } as BankAccount;
    });
  }, []);

  const BackButton = useCallback(
    (isGoBack?: boolean) => {
      return (
        <Button
          label={isGoBack ? 'Go Back' : 'Cancel'}
          variant="outline"
          disabled={isSaving}
          onPress={() => setIsVisible?.(false)}
          mr={2}
        />
      );
    },
    [isSaving],
  );

  if (!selectedBankAccount) {
    return null;
  }

  return (
    <>
      <View>
        {isWorkflowEnabled && (
          <ReviewAndApproveModal
            onClose={() => {
              setShowApprovalModal(false);
            }}
            pendingApprovalItem={selectedBankAccount}
            source={ApprovalWorkflowSource.BANK_ACCOUNTS}
            id={bankAccount?.id}
            onApprovalDataLoaded={(approvalData) => {
              setCanApprove(approvalData.canApprove);
              console.warn('approvalData2', approvalData);
            }}
            onApprovalOrRejectonCompleted={() => {
              setIsVisible?.(false);
            }}
            onSomeActionDone={onItemActioned}
            show={showApprovalModal}
          />
        )}
        <View style={AppStyles.flexRowCenter}>
          <View style={AppStyles.flex1} pointerEvents={isReadOnly ? 'none' : 'auto'}>
            <CInput
              label="Account Number"
              fieldKey="accountNumber"
              fieldValue={selectedBankAccount.accountNumber}
              onTextChange={onTextChange}
              isRequired
              disabled={mode === InputMode.EDIT && !!selectedBankAccount?.id}
            />
          </View>
          <View style={AppStyles.flex1} pointerEvents={isReadOnly ? 'none' : 'auto'}>
            <CInput
              label="Account Name"
              fieldKey="accountName"
              fieldValue={selectedBankAccount.accountName}
              onTextChange={onTextChange}
              isRequired
            />
          </View>
          <View style={[AppStyles.flex1, AppStyles.alignFlexEnd]}>
            <StatusTag
              label={selectedBankAccount.status}
              onPendingApprovalPress={() => setShowApprovalModal(true)}
            />
            {selectedBankAccount.updateRequest && (
              <TouchableOpacity
                onPress={() => {
                  setShowApprovalModal(true);
                }}
              >
                <Badge variant="solid" colorScheme="orange">
                  Pending Updates
                </Badge>
              </TouchableOpacity>
            )}
            <FieldDisplay
              label="System Balance"
              value={_getFormattedCurrency(
                selectedBankAccount.balance,
                selectedBankAccount.currency,
              )}
            />
          </View>
        </View>
        <View style={AppStyles.flexRowCenter} pointerEvents={isReadOnly ? 'none' : 'auto'}>
          <View style={[AppStyles.flex1, AppStyles.marginTop]}>
            <EntityInput
              onSelect={(values: Entity[]) => onTextChange('entity', values[0].name)}
              value={selectedBankAccount.entity}
              isRequired
            />
          </View>
          <View style={AppStyles.flex1}>
            <CurrencyInput
              onSelect={(values: string[]) => onTextChange('currency', values[0])}
              value={selectedBankAccount.currency}
              isReadOnly={!!selectedBankAccount?.id}
              isRequired
            />
          </View>
          <View style={AppStyles.flex1} />
        </View>
        <View style={AppStyles.flexRowCenter} pointerEvents={isReadOnly ? 'none' : 'auto'}>
          <View style={AppStyles.flex1}>
            <PaymentMethodInput
              onSelect={(values: string[]) => onTextChange('paymentMethods', values)}
              values={selectedBankAccount.paymentMethods}
              isMultiSelect
            />
          </View>
          <View style={AppStyles.flex1}>
            <AccountGroupInput
              onSelect={(values: string[]) => onTextChange('accountGroups', values)}
              values={selectedBankAccount.accountGroups}
              isMultiSelect
              includeAll={false}
            />
          </View>
          <View style={AppStyles.flex1} />
        </View>
        <View style={[AppStyles.flexRow]} pointerEvents={isReadOnly ? 'none' : 'auto'}>
          <View style={AppStyles.flex1}>
            <Text style={[AppStyles.textFormSectionHeader, AppStyles.marginBottom]}>
              Bank Details
            </Text>
            <BankInput
              onSelect={(values: Bank[]) => onTextChange('bank', values[0].name)}
              value={selectedBankAccount.bank}
              isRequired
            />
            <CInput
              label="Bank Code - Override"
              fieldKey="BIC"
              fieldValue={selectedBankAccount.BIC}
              onTextChange={onTextChange}
            />
          </View>

          <View style={{ flex: 2 }}>
            <Text style={[AppStyles.textFormSectionHeader, AppStyles.marginBottom]}>
              Accounting
            </Text>
            <LedgerInput
              onSelect={(values: string[]) => onTextChange('ledger', values?.[0])}
              value={selectedBankAccount.ledger}
            />

            <View style={[AppStyles.flexRowCenter, AppStyles.alignFlexEnd]}>
              {ledgerDetails &&
                [...Array(10)].map((_, i) => {
                  const fieldName = `segment${i + 1}`;
                  const segment = ledgerDetails[fieldName] as LedgerSegment;
                  if (
                    segment?.type === LedgerSegmentType['Bank Account'] ||
                    segment?.type === LedgerSegmentType.Wildcard ||
                    segment?.type === LedgerSegmentType['Custom List']
                  ) {
                    return (
                      <CInput
                        isRequired
                        width={100}
                        style={AppStyles.marginRight}
                        label={segment?.name}
                        fieldKey={`account${i + 1}`}
                        fieldValue={selectedBankAccount?.glCodes?.[fieldName]}
                        onTextChange={(_, value) => {
                          setSelectedBankAccount((existingValue) => {
                            return {
                              ...existingValue,
                              glCodes: {
                                ...existingValue.glCodes,
                                [fieldName]: value,
                              },
                            } as BankAccount;
                          });
                        }}
                        key={i}
                      />
                    );
                  }
                  return null;
                })}
            </View>
            {ledgerDetails && (
              <Code alignSelf={'flex-start'} mt={2}>
                {glAccount}
              </Code>
            )}
          </View>
        </View>
      </View>
      {selectedBankAccount.id && (
        <ActivityHistory recordKey={selectedBankAccount.id} recordType={LogType.BankAccount} />
      )}
      <ButtonGroup
        mode={mode}
        onSave={onSave}
        isSaving={isSaving}
        canApprove={canApprove}
        setShowApprovalModal={setShowApprovalModal}
        setIsVisible={setIsVisible}
        onNext={onNext}
      />
    </>
  );
};
export default BankAccountDetails;
