import React, { FC, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';

import { FormControl, FormLabel } from '@chakra-ui/react';
import { Bank, StaticDataStore } from '../commonTypes';
import AppStyles from '../constants/Styles';
import { Text, View } from './Themed';

export type BankInputProps = {
  onSelect: (values: Bank[]) => void;
  value?: string;
  isRequired?: boolean;
};
const BankInput: FC<BankInputProps> = ({ onSelect, value, isRequired }) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const bankDetails = useMemo(() => {
    return staticData.ourBanks?.find((bank) => bank.name === value);
  }, [value, staticData.ourBanks]);

  return (
    <FormControl isRequired={isRequired} width={300}>
      <FormLabel fontSize={14} mb={1}>
        Bank
      </FormLabel>
      <Select
        onChange={(selected) =>
          onSelect([staticData.ourBanks?.find((bank) => bank.name === selected.value)])
        }
        value={value ? [{ value, label: value }] : []}
        name="Bank"
        options={staticData.ourBanks?.map((bank) => {
          return { value: bank.name, label: bank.name };
        })}
        placeholder="Select Bank"
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
      <View style={[AppStyles.flexRowCenterSpaceBetween, { marginTop: 5, marginHorizontal: 5 }]}>
        <Text style={AppStyles.textTiny}>{bankDetails?.externalName || ' '}</Text>
        <Text style={AppStyles.textTiny}>{bankDetails?.BIC || ' '}</Text>
      </View>
    </FormControl>
  );
};

export default BankInput;
