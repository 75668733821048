import React, { FC } from 'react';

import {} from 'native-base';
import { ViewStyle } from 'react-native';
import omit from 'lodash/omit';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftElementProps,
  InputProps,
  InputRightElement,
  InputRightElementProps,
} from '@chakra-ui/react';
import CToolTip from './CToolTip';

export type CInputProps = {
  label: string;
  isRequired?: boolean;
  fieldKey: string;
  fieldValue?: string;
  onTextChange?: (field: any, value: string) => void;
  disabled?: boolean;
  style?: ViewStyle;
  rightElement?: React.ReactElement;
  onSubmitEditing?: () => void;
  width?: number;
  leftElement?: React.ReactElement;
  leftElementProps?: InputLeftElementProps;
  rightElementProps?: InputRightElementProps;
  multiline?: boolean;
  maxLength?: number;
  errors?: any;
  onFocus?: () => void;
  onClick?: () => void;
  setErrors?: (value: any) => void;
  inputProps?: InputProps;
  isSecured?: boolean;
  tooltip?: string;
  isLockIcon?: boolean;
  textPaddingLeft?: string;
} & FormControlProps;
const CInput: FC<CInputProps> = ({
  label,
  isRequired = false,
  fieldKey,
  fieldValue = '',
  onTextChange,
  disabled,
  style,
  rightElement,
  onSubmitEditing,
  width = 300,
  leftElement,
  leftElementProps,
  multiline,
  maxLength = 100,
  errors = {},
  setErrors,
  inputProps,
  onFocus,
  onClick,
  rightElementProps,
  isSecured,
  tooltip,
  isLockIcon,
  textPaddingLeft,
  ...props
}) => {
  return (
    <FormControl
      style={style}
      isRequired={isRequired}
      width={width}
      isInvalid={fieldKey in errors}
      {...props}
    >
      <FormLabel fontSize={14} mb={1}>
        {label}
        {tooltip && <CToolTip label={tooltip} isLockIcon={isLockIcon} />}
      </FormLabel>
      <InputGroup>
        {leftElement && <InputLeftElement {...leftElementProps}>{leftElement}</InputLeftElement>}
        <Input
          height={8}
          paddingLeft={textPaddingLeft}
          type={isSecured ? 'password' : 'text'}
          isRequired={isRequired}
          onFocus={() => {
            setErrors?.(omit(errors, fieldKey));
            onFocus?.();
          }}
          maxLength={maxLength}
          value={fieldValue}
          onChange={(e) => onTextChange?.(fieldKey, e.target.value)}
          isReadOnly={disabled}
          onSubmit={onSubmitEditing}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onSubmitEditing?.();
            }
          }}
          onClick={onClick}
          autoComplete="off"
          {...inputProps}
        />
        {rightElement && (
          <InputRightElement {...rightElementProps}>{rightElement}</InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  );
};

export default CInput;
