/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { doc, updateDoc } from 'firebase/firestore';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import {
  CloseButton,
  Code,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  useToast,
} from '@chakra-ui/react';
import { omit } from 'lodash';
import { AppContext } from '../App';
import { UPDATE_ORG_INFO } from '../actions/ActionConstatnts';
import { Ledger, LedgerSegment, LedgerSegmentType, StaticDataStore, Status } from '../commonTypes';
import Button from '../components/Button';
import { CAlertDialog } from '../components/CAlertDialog';
import CDropdown from '../components/CDropdown';
import { CFlatList } from '../components/CFlatList';
import CInput from '../components/CInput';
import CurrencyInput from '../components/CurrencyInput';
import { FilterBar } from '../components/FilterBar';
import StatusTag from '../components/StatusTag';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList, _textFieldIsInvalid } from '../utils/helper';

export default function LedgerScreen({ navigation }: RootTabScreenProps<'LedgerScreen'>) {
  const [ledgers, setLedgers] = useState<Ledger[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLedger, setSelectedLedger] = useState<Ledger>();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [editEnabled, setEditEnabled] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: '',
    content: '',
    actionText: '',
    colorScheme: '',
    onAction: () => null || undefined,
  });

  const [filteredLedgers, setFilteredLedgers] = useState<Ledger[] | undefined>();
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [isDeactivateAlertOpen, setIsDeactivateAlertOpen] = useState(false);

  useEffect(() => {
    if (staticData?.orgInfo?.Ledgers) {
      setLedgers(
        Object.entries(staticData?.orgInfo?.Ledgers).map(([key, value]) => {
          return { ...value };
        }),
      );

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [staticData?.orgInfo?.Ledgers]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredLedgers(_searchList(ledgers, searchValue));
      } else {
        setFilteredLedgers(undefined);
      }
    },
    [ledgers],
  );

  const coaStructure = useMemo(() => {
    let structure = '';
    if (!selectedLedger) {
      return structure;
    }
    for (let i = 1; i <= 10; i++) {
      const fieldName = ('segment' + i) as keyof Ledger;
      if (selectedLedger[fieldName]?.type) {
        if (selectedLedger[fieldName]?.type !== LedgerSegmentType.Constant) {
          structure += '[' + selectedLedger[fieldName]?.type + ']';
        }
        if (
          selectedLedger[fieldName]?.type === LedgerSegmentType.Constant &&
          selectedLedger[fieldName]?.value
        ) {
          structure += selectedLedger[fieldName]?.value;
        }
        if (selectedLedger[fieldName]?.suffix) {
          structure += selectedLedger[fieldName]?.suffix;
        }
      }
    }
    return structure;
  }, [selectedLedger]);

  const onSave = useCallback(
    (status?: Status) => {
      if (!selectedLedger) {
        return;
      }
      if (_textFieldIsInvalid(selectedLedger?.name)) {
        toast({
          title: 'Invalid Name for the Ledger',
          description: 'Please enter a valid name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (_textFieldIsInvalid(selectedLedger?.description)) {
        toast({
          title: 'Invalid Description',
          description: 'Please enter a valid description',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (_textFieldIsInvalid(selectedLedger?.baseCurrency)) {
        toast({
          title: 'Select a valid base currency',
          description: 'The base currency is required',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (_textFieldIsInvalid(selectedLedger?.segment1?.type)) {
        toast({
          title: 'Invalid Structure',
          description: 'Please define atleast one segment for the structure',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      let errorExists = false;
      [...Array(10)].forEach((_, i) => {
        const fieldName = ('segment' + (i + 1)) as keyof Ledger;
        if (
          selectedLedger[fieldName]?.type === LedgerSegmentType.Constant &&
          !selectedLedger[fieldName]?.value
        ) {
          toast({
            title: 'Invalid Structure',
            description: 'Define a value for the constant of Segment ' + (i + 1),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          errorExists = true;
        }
      });
      if (errorExists) {
        return;
      }

      setIsSaving(true);

      const ledgerDoc = {
        ...selectedLedger,
        status: status || selectedLedger?.status,
        completeStructure: coaStructure,
      } as Ledger;
      console.warn('xyz', ledgerDoc);

      updateDoc(doc(db, 'Organizations', profileData.orgId), {
        [`Ledgers.${ledgerDoc.name}`]: ledgerDoc,
      })
        .then(() => {
          if (status) {
            toast({
              title: `Ledger ${ledgerDoc.name} ${status}`,
              status: status === Status.DEACTIVATED ? 'error' : 'success',
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: `Ledger ${ledgerDoc.name} ${
                selectedIndex !== undefined ? 'Updated' : 'Created'
              }`,
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          }

          dispatch({
            payload: {
              ...staticData?.orgInfo,
              Ledger: {
                ...staticData?.orgInfo?.Ledgers,
                [ledgerDoc.name]: ledgerDoc,
              },
            },
            type: UPDATE_ORG_INFO,
          });
          setLedgers((existingValue) => {
            if (selectedIndex !== undefined) {
              const updatedLedgers = [...existingValue];
              updatedLedgers[selectedIndex] = ledgerDoc;
              return updatedLedgers;
            } else {
              return [ledgerDoc, ...existingValue];
            }
          });
          if (status) {
            setSelectedLedger((existingValue) => {
              return { ...existingValue, status } as Ledger;
            });
          }
          setIsSaving(false);
          setEditEnabled(false);
          return;
        })
        .catch((e) => {
          console.warn(e);
          toast({
            title: `Something went wrong. Please try again later`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });

          setIsSaving(false);
        });
    },
    [
      ledgers,
      db,
      filteredLedgers,
      profileData.orgId,
      profileData.uid,
      selectedLedger,
      selectedIndex,
      toast,
      coaStructure,
    ],
  );

  const onTextChange = useCallback((field: keyof Ledger, value: string) => {
    setSelectedLedger((existingValue) => {
      return { ...existingValue, [field]: value } as Ledger;
    });
  }, []);

  const onSegmentTypeChanged = useCallback((index: number, value: LedgerSegmentType) => {
    setSelectedLedger((existingValue) => {
      const fieldName = ('segment' + (index + 1)) as keyof Ledger;
      let segment = existingValue?.[fieldName] as LedgerSegment;
      if (segment) {
        segment.type = value;
      } else {
        segment = {
          type: value,
        };
      }
      return { ...existingValue, [fieldName]: segment } as Ledger;
    });
  }, []);

  const onSegmentValueChanged = useCallback(
    (index: number, value: string | number, property?: string) => {
      setSelectedLedger((existingValue) => {
        const fieldName = ('segment' + (index + 1)) as keyof Ledger;
        let segment = existingValue?.[fieldName] as LedgerSegment;
        const propertyName = property ? property : 'value';
        if (segment) {
          segment[propertyName] = value;
        } else {
          segment = {
            type: LedgerSegmentType.Constant,
            [propertyName]: value,
          };
        }
        return { ...existingValue, [fieldName]: segment } as Ledger;
      });
    },
    [],
  );

  return (
    <View style={AppStyles.container}>
      <CAlertDialog
        open={isDeactivateAlertOpen}
        setOpen={setIsDeactivateAlertOpen}
        title={alertContent.title}
        content={alertContent.content}
        actionText={alertContent.actionText}
        colorScheme={alertContent.colorScheme}
        onAction={alertContent.onAction}
      />
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {ledgers && ledgers.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}
        <Button
          label="Create Ledger"
          variant={'Create'}
          onPress={() => {
            setSelectedLedger({
              name: '',
              description: '',
              status: Status.ACTIVE,
              baseCurrency: '',
              completeStructure: '',
            } as Ledger);
            setSelectedIndex(undefined);
            setEditEnabled(true);
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
        }}
      >
        <View style={{ width: Layout.window.width * 0.3 }}>
          <CFlatList
            emptyMessage={filteredLedgers === undefined ? 'No Ledgers yet' : 'No results found'}
            data={filteredLedgers || ledgers}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: Ledger; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedLedger(item);
                  setSelectedIndex(index);
                  setEditEnabled(false);
                }}
                key={item.name}
              >
                <Card
                  style={[
                    styles.bankAccountCard,
                    selectedLedger?.name === item.name && { borderColor: Colors.primary },
                  ]}
                >
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                    </View>
                    <StatusTag isReadOnly label={item.status} />
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        {selectedLedger && (
          <View style={AppStyles.width60}>
            <View style={[AppStyles.flexRowCenterSpaceBetween, { marginHorizontal: 10 }]}>
              <Text style={[AppStyles.textRowTitle, { marginBottom: 10 }]}>
                {selectedLedger.name || 'Ledger'}
              </Text>
            </View>
            <ScrollView
              style={{
                maxHeight: Layout.window.height - 50,
                marginRight: 10,
                borderWidth: 2,
                borderColor: Colors.primary,
                borderRadius: 10,
              }}
            >
              <View style={{ flex: 1, padding: 10, paddingBottom: 100 }}>
                <>
                  <View style={[AppStyles.flexRowCenterSpaceBetween]}>
                    <CInput
                      label="Name"
                      fieldValue={selectedLedger.name}
                      fieldKey="name"
                      onTextChange={onTextChange}
                      isReadOnly={!editEnabled}
                      isRequired
                    />
                    <CurrencyInput
                      onSelect={(values: string[]) => onTextChange('baseCurrency', values[0])}
                      value={selectedLedger.baseCurrency}
                      isReadOnly={!editEnabled}
                      isRequired
                    />
                    <StatusTag
                      isReadOnly
                      label={selectedLedger.status}
                      style={{ alignSelf: 'flex-start' }}
                    />
                  </View>
                  <CInput
                    label="Description"
                    fieldValue={selectedLedger.description}
                    fieldKey="description"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                    isRequired
                  />
                  <View style={{ marginTop: 10 }}>
                    <FormLabel mb={0}>GL Account Structure</FormLabel>

                    <View>
                      <Code mt={0} alignSelf={'flex-start'} pl={2} pr={2}>
                        {selectedLedger?.segment1?.type ? coaStructure : 'Not Defined'}
                      </Code>
                    </View>
                    {[...Array(10)].map((e, i) => {
                      const fieldName = ('segment' + (i + 1)) as keyof Ledger;
                      if (i > 0 && !selectedLedger[('segment' + i) as keyof Ledger]?.type) {
                        return null;
                      }
                      if (!editEnabled && !selectedLedger[fieldName]?.type) {
                        return null;
                      }
                      return (
                        <View style={[AppStyles.flexRowCenter, AppStyles.marginTop]}>
                          <CInput
                            style={AppStyles.marginRight}
                            width={180}
                            label="Name"
                            fieldValue={selectedLedger[fieldName]?.name}
                            fieldKey="segment1.name"
                            onTextChange={(field, value) => onSegmentValueChanged(i, value, 'name')}
                            isReadOnly={!editEnabled}
                            isRequired
                          />
                          <CDropdown
                            placeholder="Select Task Type"
                            name="Task Type"
                            value={selectedLedger[fieldName]?.type}
                            title={`Segment ${i + 1}`}
                            onChange={(value) => onSegmentTypeChanged(i, value)}
                            isRequired={i === 0}
                            width={180}
                            options={Object.keys(LedgerSegmentType).map((key) => {
                              return { value: key, label: key };
                            })}
                            isReadOnly={!editEnabled}
                          />
                          {selectedLedger[fieldName]?.type === LedgerSegmentType.Constant && (
                            <CInput
                              style={{ marginLeft: 10 }}
                              width={180}
                              label="Value"
                              fieldValue={selectedLedger[fieldName]?.value}
                              fieldKey="segment1.value"
                              onTextChange={(field, value) => onSegmentValueChanged(i, value)}
                              isReadOnly={!editEnabled}
                              isRequired
                            />
                          )}

                          {selectedLedger[fieldName]?.type === LedgerSegmentType.Wildcard && (
                            <>
                              <View style={AppStyles.marginLeft}>
                                <FormLabel mb={0}>Min Length</FormLabel>

                                <NumberInput
                                  min={0}
                                  max={30}
                                  mt={2}
                                  w={90}
                                  alignSelf={'flex-end'}
                                  value={selectedLedger[fieldName]?.minLength}
                                  onChange={(_, number) =>
                                    onSegmentValueChanged(i, number, 'minLength')
                                  }
                                  isReadOnly={!editEnabled}
                                >
                                  <NumberInputField height={8} />
                                  {editEnabled && (
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  )}
                                </NumberInput>
                              </View>
                              <View style={AppStyles.marginLeft}>
                                <FormLabel mb={0}>Max Length</FormLabel>

                                <NumberInput
                                  min={1}
                                  max={30}
                                  mt={2}
                                  w={90}
                                  alignSelf={'flex-end'}
                                  value={selectedLedger[fieldName]?.maxLength}
                                  onChange={(_, number) =>
                                    onSegmentValueChanged(i, number, 'maxLength')
                                  }
                                  isReadOnly={!editEnabled}
                                >
                                  <NumberInputField height={8} />
                                  {editEnabled && (
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  )}
                                </NumberInput>
                              </View>
                            </>
                          )}
                          <CInput
                            style={{ marginLeft: 10 }}
                            width={100}
                            label="Suffix"
                            fieldValue={selectedLedger[fieldName]?.suffix}
                            fieldKey="segment1.length"
                            onTextChange={(field, value) =>
                              onSegmentValueChanged(i, value, 'suffix')
                            }
                            isReadOnly={!editEnabled}
                          />
                          {i > 0 &&
                            !selectedLedger[('segment' + (i + 2)) as keyof Ledger]?.type &&
                            selectedLedger[fieldName]?.type &&
                            editEnabled && (
                              <CloseButton
                                onClick={() => {
                                  setSelectedLedger((existingValue) => {
                                    return omit(
                                      existingValue,
                                      ('segment' + (i + 1)) as keyof Ledger,
                                    );
                                  });
                                }}
                                ml={2}
                                alignSelf={'flex-end'}
                              />
                            )}
                        </View>
                      );
                    })}
                  </View>
                </>
              </View>
            </ScrollView>

            <View style={[AppStyles.flexRowCenter, AppStyles.floatingBottonRight]}>
              <Button
                label="Cancel"
                onPress={() => {
                  setEditEnabled(false);
                  if (selectedIndex === undefined) {
                    setSelectedLedger(undefined);
                  }
                }}
                isDisabled={isLoading}
                hidden={!editEnabled}
                variant="Cancel"
              />
              <Button
                label="Activate"
                variant={'Create'}
                onPress={() => {
                  setAlertContent({
                    title: 'Activate Ledger',
                    content: 'This Ledger will be enabled for use',
                    actionText: 'Activate',
                    colorScheme: 'lime',
                    onAction: () => onSave(Status.ACTIVE),
                  });
                  setIsDeactivateAlertOpen(true);
                }}
                hidden={!editEnabled || selectedLedger?.status === Status.ACTIVE}
                isLoading={isSaving}
              />
              <Button
                label="Deactivate"
                variant={'Delete'}
                onPress={() => {
                  {
                    setAlertContent({
                      title: 'Deactivate Ledger',
                      content:
                        'This Ledger will not be available to use. Are you sure you want to deactivate it?',
                      actionText: 'Deactivate',
                      colorScheme: 'danger',
                      onAction: () => onSave(Status.DEACTIVATED),
                    });
                    setIsDeactivateAlertOpen(true);
                  }
                }}
                hidden={
                  !editEnabled ||
                  selectedLedger?.status !== Status.ACTIVE ||
                  !selectedLedger?.name ||
                  !selectedIndex
                }
                isLoading={isSaving}
              />
              <Button
                label="Edit"
                variant={'Edit'}
                onPress={() => {
                  setEditEnabled(true);
                }}
                hidden={editEnabled}
              />
              <Button
                label={selectedLedger?.name ? 'Save Changes' : 'Create Ledger'}
                hidden={!editEnabled}
                onPress={() => onSave()}
                isLoading={isSaving}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
