/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { FlatList, FormControl, Input } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { AppContext } from '../App';
import { StaticDataStore, TransactionCategory } from '../commonTypes';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { FlyOut } from '../components/FlyOut';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList } from '../utils/helper';
import { UPDATE_CATEGORIES } from '../actions/ActionConstatnts';

export default function CategoriesScreen({ navigation }: RootTabScreenProps<'CategoriesScreen'>) {
  const [categories, setCategories] = useState<TransactionCategory[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<TransactionCategory>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [open, setOpen] = useState(false);

  const [filteredCategories, setFilteredCategories] = useState<TransactionCategory[] | undefined>();
  const { db } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();
  const { profileData } = useSelector((store: StaticDataStore) => store);

  const fetchCategories = useCallback(async () => {
    console.log('Fetching Categories');
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'Categories'),
      orderBy('name'),
    );
    const querySnapshot = await getDocs(q);
    const _categories: TransactionCategory[] = [];
    querySnapshot.forEach((doc) => {
      _categories.push({ ...doc.data(), id: doc.id } as TransactionCategory);
    });
    setCategories(_categories);
    setIsLoading(false);
  }, [db, profileData.orgId]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredCategories(_searchList(categories, searchValue));
      } else {
        setFilteredCategories(undefined);
      }
    },
    [categories],
  );

  const onSave = useCallback(async () => {
    if (!selectedCategory?.name) {
      return toast({
        title: 'Invalid Name',
        description: 'Please enter a valida name for the category',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (selectedIndex !== undefined && selectedCategory?.id) {
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'Categories', selectedCategory.id),
        { ...selectedCategory, lastUpdatedBy: profileData?.uid },
      )
        .catch((e) => {
          console.warn(e);
          toast({
            title: 'Something went wrong',
            description: 'Error updating category',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        })
        .then(() => {
          const _categories = filteredCategories || categories;
          _categories[selectedIndex] = selectedCategory;
          const updatedCategories = categories;
          updatedCategories[selectedIndex] = selectedCategory;
          dispatch({
            payload: updatedCategories,
            type: UPDATE_CATEGORIES,
          });
          if (filteredCategories) {
            setFilteredCategories(_categories);
            const _AllAccounts = categories;
            _AllAccounts[_AllAccounts.findIndex((bankAcc) => bankAcc.id === selectedCategory.id)] =
              selectedCategory;
            setCategories(_AllAccounts);
          } else {
            setCategories(_categories);
          }
          setOpen(false);
        });
    } else {
      const newCategory = await addDoc(
        collection(db, 'Organizations', profileData.orgId, 'Categories'),
        {
          ...selectedCategory,
          createdBy: profileData.uid,
          createdDtTm: serverTimestamp(),
        } as TransactionCategory,
      );
      dispatch({
        payload: [...categories, { ...selectedCategory, id: newCategory.id }],
        type: UPDATE_CATEGORIES,
      });
      setCategories((currentValue) => [
        { ...selectedCategory, id: newCategory.id },
        ...currentValue,
      ]);
      if (filteredCategories) {
        setFilteredCategories((currentValue) => [
          { ...selectedCategory, id: newCategory.id },
          ...(currentValue || []),
        ]);
      }

      setOpen(false);
    }

    toast({
      title: `Category ${selectedCategory?.id ? 'Updated' : 'Created'}`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  }, [
    categories,
    db,
    filteredCategories,
    profileData.orgId,
    selectedCategory,
    selectedIndex,
    toast,
  ]);

  const onTextChange = useCallback((field: keyof TransactionCategory, value: string) => {
    setSelectedCategory((existingValue) => {
      return { ...existingValue, [field]: value } as TransactionCategory;
    });
  }, []);

  return (
    <View style={AppStyles.container}>
      <FlyOut
        onSave={onSave}
        open={open}
        setOpen={(value) => setOpen(value)}
        title={selectedCategory?.name || 'New Category'}
      >
        {selectedCategory ? (
          <>
            <FormControl>
              <FormControl.Label>Category Name</FormControl.Label>
              <Input
                value={selectedCategory.name}
                onChangeText={(value) => onTextChange('name', value)}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label>Category Description</FormControl.Label>
              <Input
                value={selectedCategory.description}
                onChangeText={(value) => onTextChange('description', value)}
              />
            </FormControl>
            {/* <View>
              {Colors.categoryColors.map((color) => {
                return (
                  <View key={color} style={{ width: 20, height: 20, backgroundColor: color }} />
                );
              })}
            </View> */}
            <FormControl>
              <FormControl.Label>Category Color</FormControl.Label>
              <FlatList
                numColumns={5}
                data={Colors.categoryColors}
                renderItem={({ item }) => {
                  return (
                    <TouchableOpacity
                      onPress={() => onTextChange('color', item)}
                      key={item}
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 10,
                        backgroundColor: item,
                        marginRight: 10,
                        marginTop: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {selectedCategory.color === item ? (
                        <FontAwesome5 name={'check'} size={20} color={Colors.white} />
                      ) : null}
                    </TouchableOpacity>
                  );
                }}
              />
            </FormControl>
          </>
        ) : (
          <></>
        )}
      </FlyOut>
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {categories && categories.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}
        <Button
          label="Create Category"
          variant="Create"
          onPress={() => {
            setSelectedCategory({
              name: '',
              description: '',
              color: undefined,
              allowedTransactionTypes: ['Payments', 'Statements'],
            } as TransactionCategory);
            setOpen(true);
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width * 0.6 }}>
          <CFlatList
            emptyMessage={
              filteredCategories === undefined ? 'No Categories yet' : 'No results found'
            }
            data={filteredCategories || categories}
            isLoading={isLoading}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedCategory(item);
                  setSelectedIndex(index);
                  setOpen(true);
                }}
                key={item.id}
              >
                <Card style={styles.bankAccountCard}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                    </View>
                    <View style={AppStyles.alignFlexEnd}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          paddingHorizontal: 10,
                          borderRadius: 10,
                          marginBottom: 5,
                          height: 10,
                          width: 30,
                          backgroundColor: item.color || Colors.grey,
                        }}
                      />
                    </View>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
