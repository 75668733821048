import React, { FC, useState } from 'react';

import { useNavigation } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import { TouchableWithoutFeedback } from 'react-native';
import { Searchbar } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CloseButton } from '@chakra-ui/react';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import Lottie from 'lottie-react';
import { Skeleton } from 'native-base';
import { SHOW_AI_SCREEN } from '../actions/ActionConstatnts';
import AiAnimation from '../assets/animation/ai_animation.json';
import { StaticDataStore } from '../commonTypes';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { View } from './Themed';

const AIScreen: FC = () => {
  const dispatch = useDispatch();
  const { userInterface, staticData } = useSelector((store: StaticDataStore) => store);
  const functions = getFunctions();
  const [searchQuery, setSearchQuery] = useState('');

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [resultHTML, setResultHTMML] = useState<string>();

  const navigation = useNavigation();

  const onChange = (value: string) => {
    setSearchText(value);
    setSearchQuery(value);
    // delayedQuery(value);
  };

  if (!userInterface.aiScreen) {
    return null;
  }
  return (
    <BlurView
      intensity={50}
      tint="dark"
      style={{
        flex: 1,
        position: 'absolute',
        width: Layout.window.width,
        height: Layout.window.height,
        zIndex: 999,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CloseButton
        alignSelf={'flex-end'}
        mt={10}
        mr={5}
        size={'lg'}
        color={Colors.white}
        onClick={() => {
          dispatch({
            payload: false,
            type: SHOW_AI_SCREEN,
          });
        }}
      ></CloseButton>
      <TouchableWithoutFeedback>
        <View style={{ width: Layout.window.width, height: Layout.window.height, padding: 100 }}>
          <Searchbar
            value={searchText}
            placeholder={'I am your AI assistant! How can I help you?'}
            placeholderTextColor={Colors.transBlack}
            onChangeText={onChange}
            autoFocus
            onSubmitEditing={() => {
              if (__DEV__) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
              }
              setIsLoading(true);
              const processQuery = httpsCallable(functions, 'processQuery');
              processQuery({ query: searchQuery }).then((result) => {
                console.warn('AIQUERY', result);
                const executeStateFunction = new Function(
                  `return (${result.data.stateFunction})`,
                )();
                let resultHTML = '';
                try {
                  resultHTML = executeStateFunction({ staticData });
                } catch (e) {
                  resultHTML = '<p>Sorry! I am unable to answer this yet.</p>';
                }
                setResultHTMML(resultHTML);
                setIsLoading(false);
              });
            }}
          />

          {(resultHTML || isLoading) && (
            <Card p={5} mt={10} width={'70%'} alignSelf={'center'}>
              {isLoading ? (
                <View style={AppStyles.flexRowCenter}>
                  <View>
                    <Lottie animationData={AiAnimation} loop style={{ width: 100, height: 100 }} />
                  </View>
                  <Skeleton.Text px={1} lines={2} width={600} />
                </View>
              ) : (
                <View style={{ marginTop: 10 }}>
                  <div dangerouslySetInnerHTML={{ __html: resultHTML }} />
                  <p style={{ marginTop: 10, color: Colors.transBlack }}>
                    *Responses are AI generated. Please verify critical information.
                  </p>
                </View>
              )}
            </Card>
          )}
        </View>
      </TouchableWithoutFeedback>
    </BlurView>
  );
};

export default AIScreen;
