import React, { useEffect } from 'react';

import { View } from '../components/Themed';
import BalanceByCurrencyWidget from '../components/Widgets/BalanceByCurrencyWidget';
import BankAccountsWidget from '../components/Widgets/BankAccountsWidget';
import BankStatementWidget from '../components/Widgets/BankStatementWidget';
import BanksWidget from '../components/Widgets/BanksWidget';
import RatesWidget from '../components/Widgets/RatesWidget';
import AppStyles from '../constants/Styles';
import GridLayout from 'react-grid-layout';
import { CheckCircleIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, IconButton, useToast } from '@chakra-ui/react';
import PendingApprovalsWidget from '../components/Widgets/PendingApprovalsWidget';
import BalanceByBankWidget from '../components/Widgets/BalanceByBankWidget';
import CashTrendWidget from '../components/Widgets/CashTrendWidget';
import PaymentsByStatusWidget from '../components/Widgets/PaymentsByStatusWidget';
import { useSelector } from 'react-redux';
import { StaticDataStore } from '../commonTypes';
import { doc, updateDoc } from 'firebase/firestore';
import { AppContext } from '../App';
import { useContext } from 'react';

export default function HomeScreen() {
  const defaultLayout = [
    {
      h: 2,
      moved: false,
      minW: 2,
      x: 6,
      y: 0,
      minH: 2,
      w: 2,
      i: 'BankAccountsWidget',
      static: false,
    },
    {
      minW: 1,
      i: 'BanksWidget',
      y: 0,
      h: 2,
      moved: false,
      minH: 2,
      w: 1,
      x: 5,
      static: false,
    },
    {
      moved: false,
      static: false,
      h: 2,
      x: 3,
      minW: 2,
      y: 0,
      minH: 2,
      w: 2,
      i: 'RatesWidget',
    },
    {
      x: 0,
      y: 2,
      moved: false,
      w: 4,
      i: 'BalanceByCurrencyWidget',
      minW: 2,
      static: false,
      h: 5,
      minH: 3,
    },
    {
      w: 4,
      minH: 3,
      x: 4,
      y: 2,
      static: false,
      h: 5,
      minW: 2,
      moved: false,
      i: 'BalanceByBankWidget',
    },
    {
      static: false,
      y: 7,
      h: 7,
      x: 0,
      w: 12,
      minH: 3,
      i: 'CashTrendWidget',
      minW: 2,
      moved: false,
    },
    {
      h: 2,
      minH: 2,
      static: false,
      y: 0,
      minW: 1,
      moved: false,
      w: 1,
      i: 'PendingApprovalsWidget',
      x: 2,
    },
    {
      h: 2,
      y: 0,
      moved: false,
      w: 2,
      i: 'BankStatementWidget',
      minH: 2,
      x: 0,
      static: false,
      minW: 2,
    },
    {
      static: false,
      y: 0,
      i: 'PaymentsByStatusWidget',
      minH: 3,
      h: 7,
      w: 4,
      minW: 2,
      moved: false,
      x: 8,
    },
  ];

  const [layout, setLayout] = React.useState(defaultLayout);
  const [editLayout, setEditLayout] = React.useState(false);
  const toast = useToast();
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const { db } = useContext(AppContext);

  // Load saved layout when component mounts
  useEffect(() => {
    if (profileData?.homeScreenLayout) {
      try {
        setLayout(profileData.homeScreenLayout);
      } catch (error) {
        console.error('Error loading layout:', error);
        setLayout(defaultLayout);
      }
    }
  }, [profileData?.homeScreenLayout]);

  const saveLayout = async () => {
    if (!profileData?.uid || !db) return;
    console.warn(layout);
    try {
      const userRef = doc(db, 'Organizations', profileData.orgId, 'Users', profileData.uid);

      // Clean the layout data by removing undefined fields
      const cleanLayout = layout.map((item) => {
        const cleanItem = {} as any;
        Object.entries(item).forEach(([key, value]) => {
          if (value !== undefined) {
            cleanItem[key] = value;
          }
        });
        return cleanItem;
      });

      await updateDoc(userRef, {
        homeScreenLayout: cleanLayout,
      });

      toast({
        title: 'Layout Saved',
        description: 'Your dashboard layout has been saved successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving layout:', error);
      toast({
        title: 'Error Saving Layout',
        description: 'There was an error saving your layout. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleLayoutChange = (newLayout: any) => {
    setLayout(newLayout);
  };

  return (
    <View style={[AppStyles.container, { justifyContent: 'flex-start', padding: 10 }]}>
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        <View></View>
        <IconButton
          colorScheme="blue"
          aria-label="Edit Layout"
          icon={editLayout ? <CheckCircleIcon /> : <EditIcon />}
          onClick={() => {
            if (editLayout) {
              saveLayout();
            } else {
              toast({
                title: 'Edit Layout',
                description: 'Reaarrange the widgets by dragging and resizing them.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
              });
            }
            setEditLayout(!editLayout);
          }}
        />
      </View>

      <GridLayout
        className="layout"
        cols={12}
        rowHeight={30}
        width={1200}
        layout={layout}
        isDraggable={editLayout}
        isResizable={editLayout}
        autoSize={true}
        compactType={'horizontal'}
        onLayoutChange={(layout) => handleLayoutChange(layout)}
      >
        <div key="BankAccountsWidget">
          <BankAccountsWidget readonly={editLayout} />
        </div>
        <div key="BanksWidget">
          <BanksWidget readonly={editLayout} />
        </div>
        <div key="RatesWidget">
          <RatesWidget readonly={editLayout} />
        </div>
        <div key="BalanceByCurrencyWidget">
          <Flex style={{ alignSelf: 'flex-start', flex: 1, height: '100%' }}>
            <BalanceByCurrencyWidget />
          </Flex>
        </div>
        <div key="BalanceByBankWidget">
          <Flex style={{ alignSelf: 'flex-start', flex: 1, height: '100%' }}>
            <BalanceByBankWidget />
          </Flex>
        </div>
        <div key="CashTrendWidget">
          <Flex style={{ alignSelf: 'flex-start', flex: 1, height: '100%' }}>
            <CashTrendWidget />
          </Flex>
        </div>
        <div key="PendingApprovalsWidget">
          <PendingApprovalsWidget readonly={editLayout} />
        </div>
        <div key="BankStatementWidget">
          <BankStatementWidget readonly={editLayout} />
        </div>
        <div key="PaymentsByStatusWidget">
          <Flex style={{ alignSelf: 'flex-start', flex: 1, height: '100%' }}>
            <PaymentsByStatusWidget />
          </Flex>
        </div>
      </GridLayout>
    </View>
  );
}
