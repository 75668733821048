/* eslint-disable react/jsx-max-depth */
import React, { Dispatch, FC, SetStateAction } from 'react';

import { Input } from '@chakra-ui/react';
import { ApprovalWorkflow, ApprovalWorkflowStep, Operator } from '../../commonTypes';
import Colors from '../../constants/Colors';
import AppStyles from '../../constants/Styles';
import AccountGroupInput from '../BankAccounts/AccountGroupInput';
import CDropdown from '../CDropdown';
import { Text, View } from '../Themed';
import { TouchableOpacity } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import CToolTip from '../CToolTip';
import CategoryInput from '../CategoryInput';

export type StepCriteriaProps = {
  step: ApprovalWorkflowStep;
  index: number;
  fieldProperties: any;
  setSelectedWorkflow: Dispatch<SetStateAction<ApprovalWorkflow | undefined>>;
};
const StepCriteria: FC<StepCriteriaProps> = ({
  step,
  index,
  fieldProperties,
  setSelectedWorkflow,
}) => {
  const isFallback = index === 0;

  const renderCriteriaValueInput = (criteriaField: string, criteriaIndex: number) => {
    switch (fieldProperties[criteriaField]?.valueFiled) {
      case 'Account Groups':
        return (
          <AccountGroupInput
            hideLabel
            isMultiSelect
            values={step.criteria[criteriaIndex]?.values}
            onSelect={(values) => {
              setSelectedWorkflow((currentValue) => {
                const steps = currentValue.steps || [];
                steps[index].criteria[criteriaIndex].values = values?.map((value) => value) || [];
                return { ...currentValue, steps: steps };
              });
            }}
          />
        );
      case 'Category':
        return (
          <CategoryInput
            hideLabel
            isMultiSelect
            values={step.criteria[criteriaIndex]?.values}
            onSelect={(values) => {
              setSelectedWorkflow((currentValue) => {
                const steps = currentValue.steps || [];
                steps[index].criteria[criteriaIndex].values = values?.map((value) => value) || [];
                return { ...currentValue, steps: steps };
              });
            }}
          />
        );
      case 'Amount':
        if (step.criteria[criteriaIndex]?.operator === Operator.BETWEEN) {
          return (
            <View>
              <Input
                isRequired={true}
                maxLength={15}
                value={step.criteria[criteriaIndex]?.values?.[0]?.toString() || ''}
                onChange={(e) => {
                  setSelectedWorkflow((currentValue) => {
                    const steps = currentValue.steps || [];
                    steps[index].criteria[criteriaIndex].values[0] = e.target.value;
                    return { ...currentValue, steps: steps };
                  });
                }}
                style={{ borderColor: Colors.light_grey }}
                autoComplete="off"
                width={'90%'}
              />
              <Text
                style={[
                  AppStyles.marginTop,
                  AppStyles.marginBottom,
                  { textAlign: 'center', color: Colors.grey, fontWeight: 'bold' },
                ]}
              >
                And
              </Text>
              <Input
                isRequired={true}
                maxLength={15}
                value={step.criteria[criteriaIndex]?.values?.[1]?.toString() || ''}
                onChange={(e) => {
                  setSelectedWorkflow((currentValue) => {
                    const steps = currentValue.steps || [];
                    steps[index].criteria[criteriaIndex].values[1] = e.target.value;
                    return { ...currentValue, steps: steps };
                  });
                }}
                style={{ borderColor: Colors.light_grey }}
                autoComplete="off"
                width={'90%'}
              />
            </View>
          );
        }
        return (
          <Input
            isRequired={true}
            maxLength={15}
            value={step.criteria[criteriaIndex]?.values?.[0]?.toString()}
            onChange={(e) => {
              setSelectedWorkflow((currentValue) => {
                const steps = currentValue.steps || [];
                steps[index].criteria[criteriaIndex].values = [e.target.value];
                return { ...currentValue, steps: steps };
              });
            }}
            style={{ borderColor: Colors.light_grey }}
            autoComplete="off"
            width={'90%'}
          />
        );
      case 'Boolean':
        return (
          <CDropdown
            value={step.criteria[criteriaIndex]?.values?.[0]?.toString()}
            options={[
              { value: 'true', label: 'True' },
              { value: 'false', label: 'False' },
            ]}
            placeholder="Select Value"
            name="Value"
            onChange={(value) => {
              setSelectedWorkflow((currentValue) => {
                const steps = currentValue.steps || [];
                steps[index].criteria[criteriaIndex].values = [value];
                return { ...currentValue, steps: steps };
              });
            }}
          />
        );
      case 'Dropdown':
        return (
          <CDropdown
            value={step.criteria[criteriaIndex]?.values?.[0]?.toString()}
            options={fieldProperties[criteriaField]?.options}
            placeholder="Select Value"
            name="Value"
            onChange={(value) => {
              setSelectedWorkflow((currentValue) => {
                const steps = currentValue.steps || [];
                steps[index].criteria[criteriaIndex].values = [value];
                return { ...currentValue, steps: steps };
              });
            }}
          />
        );
    }
  };

  return (
    <View
      style={[
        AppStyles.padding20,
        AppStyles.borderRadius,
        { flex: 4, backgroundColor: Colors.lightest_grey },
      ]}
    >
      <Text style={[AppStyles.textSubTitle, { color: Colors.black, fontWeight: 'bold' }]}>
        Conditions
        <CToolTip
          label={
            isFallback
              ? 'This rule cannot have conditions since it is the fallback rule'
              : 'All the conditions should match for this rule to be triggered'
          }
        />
      </Text>
      {isFallback ? (
        <View style={AppStyles.flexCenter}>
          <Text>Applies if none of the above rules match the criteria</Text>
        </View>
      ) : (
        <View
          style={[
            AppStyles.flex1,
            AppStyles.marginTop,

            AppStyles.borderRadius5,
            AppStyles.padding,
            { backgroundColor: Colors.white },
          ]}
        >
          <View
            style={[AppStyles.flexRow, { borderBottomWidth: 2, borderColor: Colors.light_grey }]}
          >
            <View style={[AppStyles.padding, AppStyles.alignCenter, { flex: 2 }]}>
              <Text style={AppStyles.textSubTitle}>Field</Text>
            </View>
            <View style={[AppStyles.padding, AppStyles.alignCenter, { flex: 1 }]}>
              <Text style={AppStyles.textSubTitle}>Condition</Text>
            </View>
            <View style={[AppStyles.padding, AppStyles.alignCenter, { flex: 2 }]}>
              <Text style={AppStyles.textSubTitle}>Value</Text>
            </View>
            <View style={[AppStyles.padding, AppStyles.alignCenter, { width: 25 }]}></View>
          </View>
          {step.criteria?.map((criteria, criteriaIndex) => (
            <View
              style={[
                AppStyles.flexRowCenter,
                { borderBottomWidth: 1, borderColor: Colors.light_grey },
              ]}
            >
              <View style={[AppStyles.padding, { flex: 1 }]}>
                <CDropdown
                  width={'100%'}
                  value={criteria.field}
                  options={Object.keys(fieldProperties)
                    .filter((field) => {
                      return !step.criteria.find(
                        (c, findIndex) => c.field === field && findIndex < criteriaIndex,
                      );
                    })
                    .map((value) => {
                      return { value, label: fieldProperties[value].label };
                    })}
                  placeholder="Select Field"
                  name="CriteriaField"
                  onChange={(value) => {
                    setSelectedWorkflow((currentValue) => {
                      const steps = currentValue.steps || [];
                      steps[index].criteria[criteriaIndex].field = value;
                      steps[index].criteria[criteriaIndex].operator =
                        fieldProperties[value]?.condition?.[0];
                      return { ...currentValue, steps: steps };
                    });
                  }}
                />
              </View>
              <View
                style={[
                  AppStyles.padding,
                  AppStyles.alignCenter,
                  {
                    flex: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderColor: Colors.light_grey,
                    height: '100%',
                    justifyContent: 'center',
                  },
                ]}
              >
                {criteria.field && (
                  <CDropdown
                    width={'100%'}
                    value={criteria.operator || fieldProperties[criteria.field]?.condition?.[0]}
                    options={fieldProperties[criteria.field]?.condition?.map((condition) => ({
                      value: condition,
                      label: condition,
                    }))}
                    onChange={(value) => {
                      setSelectedWorkflow((currentValue) => {
                        const steps = currentValue.steps || [];
                        steps[index].criteria[criteriaIndex].operator = value;
                        return { ...currentValue, steps: steps };
                      });
                    }}
                    placeholder="Select Operator"
                    name="operator"
                  />
                )}
              </View>
              <View style={[AppStyles.padding, { flex: 2 }]}>
                {criteria.field &&
                  criteria.operator &&
                  renderCriteriaValueInput(criteria.field, criteriaIndex)}
              </View>
              <View
                style={[
                  AppStyles.padding,
                  AppStyles.alignCenter,
                  { width: 25, justifyContent: 'center' },
                ]}
              >
                <TouchableOpacity
                  onPress={() => {
                    setSelectedWorkflow((currentValue) => {
                      const steps = currentValue.steps || [];
                      steps[index].criteria.splice(criteriaIndex, 1);
                      return { ...currentValue, steps: steps };
                    });
                  }}
                >
                  <FontAwesome5 name="trash" size={18} color={Colors.light_grey} />
                </TouchableOpacity>
              </View>
            </View>
          ))}
          <TouchableOpacity
            style={AppStyles.padding}
            onPress={() => {
              setSelectedWorkflow((currentValue) => {
                const steps = currentValue.steps || [];
                steps[index].criteria.push({
                  field: '',
                  operator: Operator.IN,
                  values: [],
                });
                return { ...currentValue, steps: steps };
              });
            }}
          >
            {!step.criteria ||
              (step.criteria?.length === 0 && (
                <Text style={{ color: Colors.light_grey, textAlign: 'center' }}>
                  Applies to all payments if no criteria is added
                </Text>
              ))}
            {Object.keys(fieldProperties).length > step.criteria?.length && (
              <Text style={{ fontWeight: 'bold' }}>+ Add Criteria</Text>
            )}
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default StepCriteria;
