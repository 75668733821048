/* eslint-disable prettier/prettier */
export enum CXPermissions {
  //Also Add to All Permissions
  ENTITY = 'ENTITY',
  BANK = 'BANK',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  ACCOUNT_GROUP = 'ACCOUNT_GROUP',
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
  PAYMENT = 'PAYMENT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PAYMENT_TEMPLATE = 'PAYMENT_TEMPLATE',
  BANK_STATEMENT = 'BANK_STATEMENT',
  REVIEW_AND_EXPORT_ACCOUNTING = 'REVIEW_AND_EXPORT_ACCOUNTING',
  IMPORT_BANK_STATEMENTS = 'IMPORT_BANK_STATEMENT',
  IMPORT_DATA = 'IMPORT_DATA',
  CASH_POSITION = 'CASH_POSITION',
  CURRENCIES = 'CURRENCIES',
  COUNTRIES = 'COUNTRIES',
  HOLIDAYS = 'HOLIDAYS',
  BANK_TRANSACTION_RULES = 'BANK_TRANSACTION_RULES',
  APPLY_BANK_TRANSACTION_RULES = 'APPLY_BANK_TRANSACTION_RULES',
  CATEGORIES = 'CATEGORIES',
  APPROVAL_WORKFLOW = 'APPROVAL_WORKFLOW',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  PAYMENT_PROCESSING = 'PAYMENT_PROCESSING',
  JOBS = 'JOBS',
  LOGS = 'LOGS',
  FILE_IMPORT = 'FILE_IMPORT',
  SFTP_CONFIGURATION = 'SFTP_CONFIGURATION',
  LEDGER = 'LEDGER',
  REPORTING = 'REPORTING',
}
export enum UserGroupTabs {
  USERS = 'USERS',
  STATIC_DATA = 'STATIC_DATA',
  TRANSACTION = 'TRANSACTION',
  CONFIGURATION = 'CONFIGURATION',
  ACCESS_CONTROL = 'ACCESS_CONTROL',
  PROCESSING = 'PROCESSING',
  ACCOUNT_GROUP = 'ACCOUNT_GROUP',
}

export enum MenuItems {
  CASH_MANAGEMENT = 'Cash Management',
  STATIC_DATA = 'Static Data',
  CONFIGURATION = 'Configuration',
  ACCESS_CONTROL = 'Access Control',
  PROCESSING = 'Processing',
  ADMIN = 'Admin',
}

export enum EditCashPositionTabs {
  ACCOUNT_GROUPS = 'ACCOUNT_GROUPS',
  CONFIGURATION = 'CONFIGURATION',
}

export const AllPermissions = [
  { name: CXPermissions.ENTITY, display: 'Entity', tab: UserGroupTabs.STATIC_DATA },
  { name: CXPermissions.BANK, display: 'Bank', tab: UserGroupTabs.STATIC_DATA },
  { name: CXPermissions.BANK_ACCOUNT, display: 'Bank Account', tab: UserGroupTabs.STATIC_DATA },
  { name: CXPermissions.ACCOUNT_GROUP, display: 'Account Group', tab: UserGroupTabs.STATIC_DATA },

  {
    name: CXPermissions.CURRENCIES,
    display: 'Currencies',
    tab: UserGroupTabs.CONFIGURATION,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.COUNTRIES,
    display: 'Countries',
    tab: UserGroupTabs.CONFIGURATION,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.CATEGORIES,
    display: 'Categories',
    tab: UserGroupTabs.CONFIGURATION,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.HOLIDAYS,
    display: 'Holidays',
    tab: UserGroupTabs.CONFIGURATION,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.APPROVAL_WORKFLOW,
    display: 'Approval Workflow',
    tab: UserGroupTabs.CONFIGURATION,
  },
  {
    name: CXPermissions.SFTP_CONFIGURATION,
    display: 'SFTP Configuration',
    tab: UserGroupTabs.CONFIGURATION,
  },
  {
    name: CXPermissions.PAYMENT_METHODS,
    display: 'Payment Methods',
    tab: UserGroupTabs.CONFIGURATION,
  },

  { name: CXPermissions.PAYMENT, display: 'Payments', tab: UserGroupTabs.TRANSACTION },
  {
    name: CXPermissions.PAYMENT_TEMPLATE,
    display: 'Payment Templates',
    tab: UserGroupTabs.TRANSACTION,
  },
  {
    name: CXPermissions.BANK_STATEMENT,
    display: 'Bank Statement',
    tab: UserGroupTabs.TRANSACTION,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.CASH_POSITION,
    display: 'Cash Position',
    tab: UserGroupTabs.TRANSACTION,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.BANK_TRANSACTION_RULES,
    display: 'Bank Transaction Rules',
    tab: UserGroupTabs.TRANSACTION,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.IMPORT_BANK_STATEMENTS,
    display: 'Statement Import',
    tab: UserGroupTabs.PROCESSING,
  },
  {
    name: CXPermissions.IMPORT_DATA,
    display: 'Data Import',
    tab: UserGroupTabs.PROCESSING,
  },
  {
    name: CXPermissions.PENDING_APPROVAL,
    display: 'Pending Approvals',
    tab: UserGroupTabs.PROCESSING,
  },
  {
    name: CXPermissions.APPLY_BANK_TRANSACTION_RULES,
    display: 'Apply Bank Transaction Rules',
    tab: UserGroupTabs.PROCESSING,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.PAYMENT_PROCESSING,
    display: 'Payments',
    tab: UserGroupTabs.PROCESSING,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.JOBS,
    display: 'Jobs',
    tab: UserGroupTabs.PROCESSING,
    exclude: ['approve'],
  },
  {
    name: CXPermissions.REPORTING,
    display: 'Reporting',
    tab: UserGroupTabs.PROCESSING,
    exclude: ['approve'],
  },

  { name: CXPermissions.USER, display: 'Users', tab: UserGroupTabs.ACCESS_CONTROL },
  { name: CXPermissions.USER_GROUP, display: 'User Groups', tab: UserGroupTabs.ACCESS_CONTROL },
  {
    name: CXPermissions.APPROVAL_WORKFLOW,
    display: 'Approval Workflow',
    tab: UserGroupTabs.ACCESS_CONTROL,
  },
];
