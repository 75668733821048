import { useSelector } from 'react-redux';
import { StaticDataStore } from '../commonTypes';
import { useCallback, useMemo } from 'react';

export default function useCurrencies() {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const _convertToReportingCurrency = useCallback(
    (amount: number, currency: string) => {
      if (amount && amount !== 0 && currency) {
        console.warn(
          'conversion',
          `${amount} ${currency} to ${amount / (staticData.currencyRates?.[currency] || 1)}`,
        );
        return amount / (staticData.currencyRates?.[currency] || 1);
      }
      return amount;
    },
    [staticData.currencyRates],
  );
  console.warn('staticData.currencyRates', staticData.currencyRates);

  return { _convertToReportingCurrency };
}
