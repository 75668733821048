/* eslint-disable react-native/no-inline-styles */
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Button, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { omit, pick } from 'lodash';
import moment from 'moment';
import { Radio } from 'native-base';
import { ScrollView, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import {
  ApprovalWorkflowSource,
  LogType,
  Payment,
  PaymentStatus,
  PaymentType,
  Status,
} from '../commonTypes';
import ActivityHistory from '../components/ActivityHistory';
import AddressForm from '../components/AddressForm';
import AmountInput from '../components/AmountInput';
import ReviewAndApproveModal from '../components/ApprovalWorkflow/ReviewAndApproveModal';
import BankAccountInput from '../components/BankAccountInput';
import { CAlertDialog } from '../components/CAlertDialog';
import CInput from '../components/CInput';
import CategoryInput from '../components/CategoryInput';
import { DateInput } from '../components/DateInput';
import ExpandableView from '../components/ExpandableView';

import { AppContext } from '../App';
import { StaticDataStore } from '../commonTypes';
import CurrencyInput from '../components/CurrencyInput';
import DateNameInput from '../components/DateNameInput';
import PaymentMethodInput from '../components/Payments/PaymentMethodInput';
import PaymentTemplatePickerModal from '../components/Payments/PaymentTemplatePickerModal';
import PaymentTimeline from '../components/Payments/PaymentTimeline';
import { CTAFilled, View } from '../components/Themed';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _textFieldIsInvalid } from '../utils/helper';
import CommentSection from '../components/Comments/CommentSection';

type CreatePaymentsScreenProps = RootTabScreenProps<'CreatePaymentScreen'>;
const CreatePaymentScreen: FC<CreatePaymentsScreenProps> = ({ navigation, route }) => {
  const isTemplate = route?.params?.isTemplate;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showPaymentTemplatePickerModal, setShowPaymentTemplatePickerModal] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState<Payment>({
    ...(!isTemplate && { valueDate: moment().startOf('day').toDate() }),
    status: PaymentStatus.DRAFT,
    ...route?.params?.payment,
  } as Payment);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [debitDetailsExpanded, setDebitDetailsExpanded] = useState(true);
  const [creditDetailsExpanded, setCreditDetailsExpanded] = useState(false);
  const [intermediaryDetailsExpanded, setIntermediaryDetailsExpanded] = useState(false);
  const [intermediaryDetailsVisible, setIntermediaryDetailsVisible] = useState(false);
  const [additionalDetailsExpanded, setAdditionalDetailsExpanded] = useState(false);
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [editEnabled, setEditEnabled] = useState(!route?.params?.payment);
  const [originalPayment, setOriginalPayment] = useState({} as Payment);
  const [errors, setErrors] = React.useState({} as Partial<Payment>);
  const functions = getFunctions();
  const [alertContent, setAlertContent] = useState({
    title: '',
    content: '',
    actionText: '',
    colorScheme: '',
    onAction: () => null,
  });

  useEffect(() => {
    console.warn('xxxxx', route?.params?.payment?.id);
    setPaymentDetails({
      status: PaymentStatus.DRAFT,
      ...route?.params?.payment,
      valueDate: moment(route?.params?.payment?.valueDate).startOf('day').toDate(),
    } as Payment);
  }, [route?.params?.payment?.id]);

  useEffect(() => {
    if (route.params?.id) {
      setIsLoadingDetails(true);
      getDoc(doc(db, 'Organizations', profileData.orgId, 'Payments', route.params?.id)).then(
        (doc) => {
          if (doc.exists()) {
            const data = {
              ...doc.data(),
              id: doc.id,
              valueDate: isTemplate ? undefined : doc.data().valueDate?.toDate(),
            } as Payment;
            setPaymentDetails(data);
            setSelectedIndex(0);
            route.params.id = undefined;
            setCommentsExpanded(true);
            setIsLoadingDetails(false);
          }
        },
      );
    }
  }, [route.params?.id]);

  const scrollRef = useRef();

  const { db, defaultDb } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [isCancelAlertOpen, setIsCancelAlertOpen] = useState(false);

  const isInternalPayment = paymentDetails?.type === PaymentType.Internal;

  const onCancel = useCallback(() => {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    const cancelPayment = httpsCallable(functions, 'cancelPayment');
    cancelPayment({
      orgId: profileData.orgId,
      paymentId: paymentDetails.id,
    }).then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      console.log(result.data);
      setIsSaving(false);
      if (result?.data?.state) {
        // onUserUpdated?.({ ...selectedUser, uid: result?.data?.uid });
        // setIsVisible(false);
        toast({
          title: `Payment Cancelled`,
          description: 'Payment #' + paymentDetails?.sequenceNumber + ' Cancelled Successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // scrollRef?.current?.scrollTo({ x: 0, y: 0, animated: true })
        navigation.goBack();
      } else {
        toast({
          title: 'Unable to cancel payment',
          description: result.data?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setIsSaving(false);
      setIsLoading(false);
    });
  }, [db, profileData.orgId, paymentDetails, toast]);

  const onCopy = useCallback(() => {
    setPaymentDetails((currentValue) => {
      return {
        ...pick(currentValue, [
          'fromAccountCurrency',
          'fromAccountDetails',
          'fromEntityDetails',
          'fromBankDetails',
          'paymentCurrency',
          'paymentMethod',
          'type',
          'category',
          'template',
          'toAccountNumber',
          'toAccountDetails',
          'InternalBeneficiaryAccountDetails',
          'toIBAN',
          'toName',
          'toBankName',
          'toBankAddress',
          'toBankBIC',
          'toBankRouteCode',
          'toBankRouteType',
          'toAddress',
          'intermediaryBankName',
          'intermediaryBankAddress',
          'intermediaryBankBIC',
          'intermediaryBankRouteCode',
          'intermediaryBankRouteType',
          'chargeBearer',
          'regulatoryReporting',
          'paymentPurpose',
          'paymentAdvice',
        ]),
        valueDate: moment().startOf('day').toDate(),
        status: PaymentStatus.DRAFT,
      } as Payment;
    });
    setEditEnabled(true);
  }, []);

  const onSave = useCallback(async () => {
    setIsLoading(true);
    setIsSaving(true);
    const mandatoryFields = [
      'fromAccountDetails',
      'type',
      'toAccountNumber',
      'toName',
      'toBankName',
      'paymentCurrency',
      'category',
    ];
    if (isTemplate) {
      mandatoryFields.push('template');
    } else {
      mandatoryFields.push('paymentAmount');
    }

    const errors: any = {};
    let hasErrors = false;

    mandatoryFields.forEach((field) => {
      if (_textFieldIsInvalid(paymentDetails[field])) {
        console.warn('Missing ' + field);
        errors[field] = 'Mandatory';
        hasErrors = true;
      }
    });
    if (!paymentDetails?.valueDate && !isTemplate) {
      errors.valueDate = 'Mandatory';
      hasErrors = true;
    }
    if (!paymentDetails?.toAddress?.country) {
      errors.toAddress = { country: 'Mandatory' };
      hasErrors = true;
    }
    if (!paymentDetails?.toBankAddress?.country) {
      errors.toBankAddress = { country: 'Mandatory' };
      hasErrors = true;
    }

    setErrors(errors);

    if (hasErrors) {
      setIsSaving(false);
      setIsLoading(false);
      toast({
        title: 'Unable to create payment' + (isTemplate ? ' template' : ''),
        description: 'Please fill all the mandatory fields',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (paymentDetails.fromAccountDetails?.accountNumber === paymentDetails.toAccountNumber) {
      errors.toAccountNumber = 'From and to account cannot be same';
      hasErrors = true;
    }
    if (hasErrors) {
      setIsSaving(false);
      setIsLoading(false);
      toast({
        title: 'Unable to create payment' + (isTemplate ? ' template' : ''),
        description: 'From and to account cannot be same',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    connectFunctionsEmulator(functions, 'localhost', 5001);
    const createPayment = httpsCallable(functions, 'createPayment');
    createPayment({
      orgId: profileData.orgId,
      paymentDetails: {
        ...paymentDetails,

        // This is needed for proper dates between the BE and FE irrespective of the timezone
        valueDate: new Date(
          Date.UTC(
            paymentDetails.valueDate.getFullYear(),
            paymentDetails.valueDate.getMonth(),
            paymentDetails.valueDate.getDate(),
          ),
        ),
      },
      dateString: moment(paymentDetails.valueDate).format('YYYYMMDD'),
      isTemplate: !!isTemplate,
    }).then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      console.log(result.data);
      setIsSaving(false);
      if (result?.data?.state) {
        // onUserUpdated?.({ ...selectedUser, uid: result?.data?.uid });
        // setIsVisible(false);
        const action = paymentDetails?.id ? 'Updated' : 'Created';
        toast({
          title: `Payment${isTemplate ? ' Template' : ''} ${action}`,
          description: `Payment ${
            isTemplate ? 'Template ' + paymentDetails.template : '#' + result?.data?.sequenceNumber
          } ${action} Successfully`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // scrollRef?.current?.scrollTo({ x: 0, y: 0, animated: true });
        setPaymentDetails((currentValue) => {
          return {
            ...currentValue,
            id: result?.data?.paymentId,
            sequenceNumber: result?.data?.sequenceNumber,
            status: result?.data?.status,
            createdBy: profileData?.uid,
            createdDtTm: Date.now(),
          } as unknown as Payment;
        });
        setEditEnabled(false);
        navigation.goBack();
      } else {
        toast({
          title: 'Unable to create payment' + (isTemplate ? ' template' : ''),
          description: result.data?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      setIsSaving(false);
      setIsLoading(false);
    });
  }, [db, profileData.orgId, profileData.uid, paymentDetails, selectedIndex, toast]);

  const onTextChange = useCallback((field: keyof Payment, value: any) => {
    setPaymentDetails((existingValue) => {
      return { ...existingValue, [field]: value } as Payment;
    });
  }, []);

  const searchBIC = useCallback(
    async (BIC?: string, isIntermediary?: boolean) => {
      if (BIC) {
        const q = query(
          collection(defaultDb, 'DefaultConfig', 'BankData', 'BIC'),
          where('swift', 'in', [BIC, BIC.substring(0, 8)]),
        );

        const querySnapshot = await getDocs(q);
        console.warn(querySnapshot.docs.length);
        const defaultBank = querySnapshot.docs?.[0]?.data();
        if (defaultBank) {
          if (isIntermediary) {
            setPaymentDetails((currentValue) => {
              return {
                ...currentValue,
                intermediaryBankName: defaultBank.name,
                intermediaryBankBIC: defaultBank.swift,
                intermediaryBankAddress: {
                  city: defaultBank.city,
                  country: defaultBank.country_code,
                  addressLine1: defaultBank.address,
                },
              };
            });
          } else {
            setPaymentDetails((currentValue) => {
              return {
                ...currentValue,
                toBankName: defaultBank.name,
                toBankBIC: defaultBank.swift,
                toBankAddress: {
                  city: defaultBank.city,
                  country: defaultBank.country_code,
                  addressLine1: defaultBank.address,
                },
              };
            });
          }
        }
      }
    },
    [defaultDb, paymentDetails?.toBankBIC],
  );

  const BackButton = useCallback(
    (isGoBack?: boolean) => {
      return (
        <Button mr={2} variant={'outline'} disabled={isLoading} onClick={() => navigation.goBack()}>
          {isGoBack ? 'Go Back' : 'Cancel'}
        </Button>
      );
    },
    [isLoading],
  );

  const renderCTA = useCallback(() => {
    if (paymentDetails.status === PaymentStatus.DRAFT) {
      return (
        <View
          style={[
            AppStyles.flexRowCenterSpaceAround,
            { position: 'absolute', bottom: 10, right: 10, alignSelf: 'flex-end' },
          ]}
        >
          {BackButton()}
          <Button isLoading={isLoading} onClick={onSave} colorScheme="blue">
            {'Create Payment' + (isTemplate ? ' Template' : '')}
          </Button>
        </View>
      );
    }

    return (
      <View
        style={[
          AppStyles.flexRowCenterSpaceAround,
          { position: 'absolute', bottom: 10, right: 10, alignSelf: 'flex-end' },
        ]}
      >
        {!route?.params?.isApprovalOnly && BackButton(true)}

        {!isTemplate &&
          ![
            PaymentStatus.SENT,
            PaymentStatus.RELEASED,
            PaymentStatus.REJECTED,
            PaymentStatus.RECEIVED,
            PaymentStatus.COMPLETED,
            PaymentStatus.CANCELLED,
          ].includes(paymentDetails.status as PaymentStatus) && (
            <Button
              mr={2}
              isLoading={isLoading}
              onClick={() => {
                setAlertContent({
                  title: 'Cancel Payment',
                  content: 'Are you sure you want to cancel this payment?',
                  actionText: 'Cancel Payment',
                  colorScheme: 'red',
                  onAction: () => {
                    setIsCancelAlertOpen(false);
                    onCancel();
                  },
                });
                setIsCancelAlertOpen(true);
              }}
              colorScheme="red"
            >
              {'Cancel Payment'}
            </Button>
          )}
        <Button mr={2} isLoading={isLoading} onClick={onCopy} colorScheme="blue">
          {'Copy Payment' + (isTemplate ? ' Template' : '')}
        </Button>
        {!editEnabled &&
          !route?.params?.isApprovalOnly &&
          [
            PaymentStatus.DRAFT,
            PaymentStatus.PENDING_APPROVAL,
            PaymentStatus.APPROVED,
            Status.ACTIVE,
          ].includes(paymentDetails.status) && (
            <Button
              isLoading={isLoading}
              onClick={() => {
                setEditEnabled(true);
                setOriginalPayment(paymentDetails);
              }}
              colorScheme={'orange'}
            >
              {'Edit Payment' + (isTemplate ? ' Template' : '')}
            </Button>
          )}
        {!editEnabled &&
          canApprove &&
          (paymentDetails.status === PaymentStatus.PENDING_APPROVAL ||
            (isTemplate && paymentDetails.updateRequest)) && (
            <View style={[AppStyles.flexRowCenter, { alignSelf: 'flex-end', marginLeft: 10 }]}>
              <Button
                onClick={() => setShowApprovalModal(true)}
                isLoading={isLoading}
                mr={2}
                colorScheme={'red'}
              >
                Reject
              </Button>
              <Button
                isLoading={isLoading}
                colorScheme={'green'}
                onClick={() => setShowApprovalModal(true)}
                isDisabled={isLoading}
                ml={2}
              >
                Approve
              </Button>
              <Button
                isLoading={isLoading}
                isDisabled={isLoading}
                onClick={route?.params?.onNext}
                colorScheme="blue"
                ml={2}
                hidden={!route?.params?.onNext}
              >
                Next
              </Button>
            </View>
          )}
        {editEnabled &&
          !route?.params?.isApprovalOnly &&
          [
            PaymentStatus.DRAFT,
            PaymentStatus.PENDING_APPROVAL,
            PaymentStatus.APPROVED,
            Status.ACTIVE,
          ].includes(paymentDetails.status) && (
            <Button
              isLoading={isLoading}
              onClick={() => {
                onSave();
              }}
              colorScheme={'blue'}
            >
              Save Changes
            </Button>
          )}
      </View>
    );
  }, [editEnabled, isSaving, onSave, canApprove, paymentDetails, isLoading, isTemplate]);

  return (
    <View style={[AppStyles.container]}>
      {paymentDetails?.id && (
        <ReviewAndApproveModal
          source={
            isTemplate ? ApprovalWorkflowSource.PAYMENT_TEMPLATES : ApprovalWorkflowSource.PAYMENTS
          }
          pendingApprovalItem={paymentDetails}
          id={paymentDetails.id}
          show={showApprovalModal}
          onApprovalDataLoaded={(approvalData) => setCanApprove(approvalData.canApprove)}
          onClose={() => {
            setShowApprovalModal(false);
          }}
          onSomeActionDone={route?.params?.onSomeActionDone}
          onApprovalOrRejectonCompleted={(approved) => {
            if (isTemplate) {
              return navigation.goBack();
            }
            setPaymentDetails((currentValue) => {
              return {
                ...currentValue,
                status: approved ? PaymentStatus.APPROVED : PaymentStatus.REJECTED,
              };
            });
          }}
        />
      )}
      <PaymentTemplatePickerModal
        show={showPaymentTemplatePickerModal}
        onClose={() => setShowPaymentTemplatePickerModal(false)}
        onSelected={(selectedTemplate) => {
          console.warn('selectedTemplate', selectedTemplate.paymentCurrency);
          setPaymentDetails((currentValue) => {
            return {
              ...currentValue,
              ...selectedTemplate,
              paymentCurrency: selectedTemplate.paymentCurrency,
            };
          });
        }}
      />
      <ScrollView style={AppStyles.paddingBottom} ref={scrollRef}>
        <CAlertDialog
          cancelText="Not Now"
          open={isCancelAlertOpen}
          setOpen={setIsCancelAlertOpen}
          title={alertContent.title}
          content={alertContent.content}
          actionText={alertContent.actionText}
          colorScheme={alertContent.colorScheme}
          onAction={alertContent.onAction}
        />
        <PaymentTimeline
          paymentDetails={paymentDetails}
          onApprovalStepClicked={() => setShowApprovalModal(true)}
          isTemplate={isTemplate}
        />
        <ExpandableView
          isExpanded={debitDetailsExpanded}
          setExpanded={setDebitDetailsExpanded}
          title="Payment Details"
          subTitle={
            paymentDetails?.sequenceNumber ? `#${paymentDetails?.sequenceNumber}` : undefined
          }
        >
          <View>
            <CInput
              errors={errors}
              setErrors={setErrors}
              label="Template Name"
              fieldKey="template"
              fieldValue={paymentDetails?.template}
              onTextChange={onTextChange}
              isReadOnly={!isTemplate || !editEnabled}
              isRequired={isTemplate}
              style={AppStyles.marginBottom}
              inputProps={{
                placeholder: isTemplate ? 'Enter a template name' : 'No Template Selected',
              }}
              onClick={() => !isTemplate && editEnabled && setShowPaymentTemplatePickerModal(true)}
              rightElement={
                !isTemplate && editEnabled ? (
                  <TouchableOpacity
                    style={{ paddingHorizontal: 5 }}
                    onPress={() => {
                      paymentDetails.template
                        ? setPaymentDetails((currentValue) => {
                            return {
                              ...currentValue,
                              template: undefined,
                            };
                          })
                        : setShowPaymentTemplatePickerModal(true);
                    }}
                  >
                    <>
                      {editEnabled && paymentDetails.template && (
                        <AntDesign
                          name={'closecircle'}
                          size={Constants.TextSize.large}
                          color="black"
                        />
                      )}
                      {editEnabled && !paymentDetails.template && (
                        <FontAwesome5
                          name={'search'}
                          size={Constants.TextSize.regular}
                          color="black"
                        />
                      )}
                    </>
                  </TouchableOpacity>
                ) : undefined
              }
            />
            <View style={[AppStyles.flexRow, AppStyles.flexSpaceBetween]}>
              <View
                pointerEvents={
                  !editEnabled || (!!paymentDetails.template && !isTemplate) ? 'none' : 'auto'
                }
              >
                <FormControl isRequired isInvalid={!!errors?.type}>
                  <FormLabel fontSize={14} mb={1}>
                    Payment Type
                  </FormLabel>
                  <Radio.Group
                    isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                    style={AppStyles.marginRight}
                    name="myRadioGroup"
                    value={paymentDetails?.type}
                    onChange={(selectedValue) => {
                      setErrors((currentValue) => {
                        omit(currentValue, 'type');
                      });
                      setPaymentDetails((currentValue: Payment) => {
                        return {
                          ...currentValue,
                          type: selectedValue,
                          toName: '',
                          toAccountNumber: '',
                          toAccountDetails: undefined,
                          toBankName: '',
                          toAddress: undefined,
                          toBankAddress: undefined,
                          toBankBIC: undefined,
                          toBankRouteCode: undefined,
                          toBankRouteType: undefined,
                          toIBAN: '',
                        } as Payment;
                      });
                    }}
                  >
                    <Radio value={PaymentType.Internal} my="0.5">
                      {PaymentType.Internal}
                    </Radio>
                    <Radio value={PaymentType.External} my="0.5">
                      {PaymentType.External}
                    </Radio>
                  </Radio.Group>
                </FormControl>
                <CategoryInput
                  onSelect={(value) => onTextChange('category', value[0])}
                  value={paymentDetails?.category}
                  isReadOnly={!editEnabled}
                  isRequired
                  style={AppStyles.marginTop}
                  width={300}
                />
              </View>
              <View>
                <BankAccountInput
                  title="From Account"
                  hasError={!!errors?.fromAccountDetails}
                  isRequired
                  isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                  onSelect={(value) => {
                    setPaymentDetails((currentValue) => {
                      const account = staticData.bankAccounts?.find(
                        (account) => account.id === value[0],
                      );
                      return {
                        ...currentValue,
                        fromAccountNumber: account?.accountNumber,
                        fromAccountDetails: account,
                        fromAccountCurrency: account?.currency,
                        paymentCurrency: account?.currency,
                        fromEntityDetails: staticData.entities?.find(
                          (entity) => entity.name === account?.entity,
                        ),
                        fromBankDetails: staticData.ourBanks?.find(
                          (bank) => bank.name === account?.bank,
                        ),
                      };
                    });
                  }}
                  value={paymentDetails?.fromAccountDetails?.id}
                  width={350}
                />
                <PaymentMethodInput
                  onSelect={(value) => onTextChange('paymentMethod', value[0])}
                  value={paymentDetails?.paymentMethod}
                  options={paymentDetails.fromAccountDetails?.paymentMethods || []}
                  isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                />
              </View>
              <View>
                {isTemplate ? (
                  <CurrencyInput
                    onSelect={(value) => onTextChange('paymentCurrency', value)}
                    isReadOnly={!editEnabled}
                    value={paymentDetails?.paymentCurrency}
                    isRequired
                  />
                ) : (
                  <AmountInput
                    isReadOnly={!editEnabled}
                    isRequired
                    title="Amount"
                    fieldKey="paymentAmount"
                    currencyValue={paymentDetails?.paymentCurrency}
                    onCurrencyChange={(value) => onTextChange('paymentCurrency', value)}
                    amountValue={paymentDetails?.paymentAmount}
                    onAmountChange={(value) => onTextChange('paymentAmount', value)}
                    errors={errors}
                    setErrors={setErrors}
                  />
                )}
                {isTemplate ? (
                  <View style={AppStyles.marginTop}>
                    <DateNameInput
                      isReadOnly={!editEnabled}
                      title="Value Date"
                      value={paymentDetails?.defaultDate}
                      onSelect={(value) => onTextChange('defaultDate', value)}
                    />
                  </View>
                ) : (
                  <DateInput
                    isReadOnly={!editEnabled}
                    isRequired
                    title="Value Date"
                    value={moment(paymentDetails?.valueDate)}
                    onSelect={(value) => {
                      onTextChange('valueDate', value?.toDate());
                    }}
                    isNotOnModal
                    style={AppStyles.marginTop}
                  />
                )}
              </View>
            </View>
          </View>
        </ExpandableView>

        <ExpandableView
          isExpanded={creditDetailsExpanded}
          setExpanded={setCreditDetailsExpanded}
          title="Beneficiary"
          additionalMessage={
            paymentDetails?.type ? undefined : '(Select a payment type to proceed)'
          }
          disableExpand={!paymentDetails?.type}
        >
          <View>
            <View style={AppStyles.flexRowCenterSpaceBetween}>
              <View>
                <View style={[AppStyles.flexRow, AppStyles.flexSpaceBetween]}>
                  {isInternalPayment ? (
                    <View style={AppStyles.flexRowCenterSpaceBetween}>
                      <BankAccountInput
                        isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                        isRequired
                        width={350}
                        title="Beneficiary Account Number"
                        onSelect={(value) => {
                          const account = staticData.bankAccounts?.find(
                            (account) => account.id === value[0],
                          );
                          onTextChange('toAccountDetails', account);
                          setPaymentDetails((currentValue) => {
                            const bank = staticData.ourBanks?.find(
                              (bank) => bank.name === account?.bank,
                            );
                            return {
                              ...currentValue,
                              toAccountNumber: account?.accountNumber,
                              toName: account?.accountName,
                              toAddress: staticData.entities?.find(
                                (entity) => entity.name === account?.entity,
                              )?.address,
                              toAccountDetails: account,
                              toBankName: bank?.externalName,
                              toBankBIC: bank?.BIC,
                              toBankAddress: bank?.address,
                              toBankRouteCode: bank?.sortCode,
                            };
                          });
                        }}
                        value={paymentDetails?.toAccountDetails?.id}
                        style={{ marginTop: -3 }}
                      />
                    </View>
                  ) : (
                    <CInput
                      errors={errors}
                      setErrors={setErrors}
                      label="Beneficiary Account Number"
                      fieldKey="toAccountNumber"
                      fieldValue={paymentDetails?.toAccountNumber}
                      onTextChange={onTextChange}
                      isRequired
                      style={{ marginBottom: 5 }}
                      isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                    />
                  )}
                  <CInput
                    errors={errors}
                    setErrors={setErrors}
                    label="Beneficiary Name"
                    fieldKey="toName"
                    fieldValue={paymentDetails?.toName}
                    onTextChange={onTextChange}
                    isRequired
                    isReadOnly={
                      isInternalPayment ||
                      !editEnabled ||
                      (!!paymentDetails.template && !isTemplate)
                    }
                  />
                </View>
                <View style={AppStyles.flexRowCenterSpaceBetween}>
                  <CInput
                    errors={errors}
                    setErrors={setErrors}
                    label="Beneficiary IBAN"
                    fieldKey="toIBAN"
                    fieldValue={paymentDetails?.toIBAN}
                    onTextChange={onTextChange}
                    isReadOnly={
                      isInternalPayment ||
                      !editEnabled ||
                      (!!paymentDetails.template && !isTemplate)
                    }
                  />
                </View>
                <AddressForm
                  adddressValue={paymentDetails?.toAddress}
                  onAddressChange={onTextChange}
                  label={'Beneficiary Address'}
                  labelStyle={AppStyles.textFormSectionHeader}
                  disabled={
                    isInternalPayment || !editEnabled || (!!paymentDetails.template && !isTemplate)
                  }
                  isCountryRequired
                  errors={errors}
                  setErrors={setErrors}
                  groupKey="toAddress"
                />
              </View>
              <View>
                <View style={AppStyles.flexRowCenterSpaceBetween}>
                  <CInput
                    errors={errors}
                    setErrors={setErrors}
                    label="SWIFT BIC"
                    fieldKey="toBankBIC"
                    fieldValue={paymentDetails?.toBankBIC}
                    onTextChange={onTextChange}
                    isReadOnly={
                      isInternalPayment ||
                      !editEnabled ||
                      (!!paymentDetails.template && !isTemplate)
                    }
                    onSubmitEditing={() => searchBIC(paymentDetails?.toBankBIC)}
                    rightElement={
                      isInternalPayment ||
                      !editEnabled ||
                      (!!paymentDetails.template && !isTemplate) ? undefined : (
                        <TouchableOpacity
                          style={{ paddingHorizontal: 5 }}
                          onPress={() => searchBIC(paymentDetails?.toBankBIC)}
                        >
                          <FontAwesome5
                            name="search"
                            size={Constants.TextSize.regular}
                            color="black"
                          />
                        </TouchableOpacity>
                      )
                    }
                  />
                  <CInput
                    errors={errors}
                    setErrors={setErrors}
                    label="Bank Name"
                    fieldKey="toBankName"
                    fieldValue={paymentDetails?.toBankName}
                    onTextChange={onTextChange}
                    isRequired
                    isReadOnly={
                      isInternalPayment ||
                      !editEnabled ||
                      (!!paymentDetails.template && !isTemplate)
                    }
                  />
                </View>
                <View style={AppStyles.flexRowCenterSpaceBetween}>
                  <CInput
                    errors={errors}
                    setErrors={setErrors}
                    label="Local Route Code"
                    fieldKey="toBankRouteCode"
                    fieldValue={paymentDetails?.toBankRouteCode}
                    onTextChange={onTextChange}
                    isReadOnly={
                      isInternalPayment ||
                      !editEnabled ||
                      (!!paymentDetails.template && !isTemplate)
                    }
                  />
                </View>
                <AddressForm
                  adddressValue={paymentDetails?.toBankAddress}
                  onAddressChange={onTextChange}
                  label={'Beneficiary Bank Address'}
                  labelStyle={AppStyles.textFormSectionHeader}
                  disabled={
                    isInternalPayment || !editEnabled || (!!paymentDetails.template && !isTemplate)
                  }
                  isCountryRequired
                  errors={errors}
                  setErrors={setErrors}
                  groupKey="toBankAddress"
                />
              </View>
            </View>

            {!intermediaryDetailsVisible && editEnabled && !paymentDetails.template && (
              <CTAFilled
                style={AppStyles.marginTop25}
                icon={'plus'}
                label="Add Intermediary"
                onPress={() => {
                  setIntermediaryDetailsVisible(true);
                  setIntermediaryDetailsExpanded(true);
                }}
              />
            )}
          </View>
        </ExpandableView>

        {intermediaryDetailsVisible && (
          <ExpandableView
            isExpanded={intermediaryDetailsExpanded}
            setExpanded={setIntermediaryDetailsExpanded}
            title="Intermediary Details"
          >
            <View>
              <View style={[AppStyles.flexRow, AppStyles.flexSpaceBetween]}>
                <View>
                  <CInput
                    errors={errors}
                    setErrors={setErrors}
                    label="Intermediary Name"
                    fieldKey="intermediaryName"
                    fieldValue={paymentDetails?.intermediaryName}
                    onTextChange={onTextChange}
                    isRequired
                    isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                  />
                  <CInput
                    errors={errors}
                    setErrors={setErrors}
                    label="Intermediary Account Number"
                    fieldKey="intermediaryBankAccountNumber"
                    fieldValue={paymentDetails?.intermediaryBankAccountNumber}
                    onTextChange={onTextChange}
                    isRequired
                    isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                  />
                </View>
                <View>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <CInput
                      errors={errors}
                      setErrors={setErrors}
                      label="Intermediary BIC"
                      fieldKey="intermediaryBankBIC"
                      fieldValue={paymentDetails?.intermediaryBankBIC}
                      onTextChange={onTextChange}
                      onSubmitEditing={() => searchBIC(paymentDetails?.intermediaryBankBIC, true)}
                      isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                      rightElement={
                        isInternalPayment ||
                        !editEnabled ||
                        (!!paymentDetails.template && !isTemplate) ? undefined : (
                          <TouchableOpacity
                            style={{ paddingHorizontal: 5 }}
                            onPress={() => searchBIC(paymentDetails?.intermediaryBankBIC, true)}
                          >
                            <FontAwesome5
                              name="search"
                              size={Constants.TextSize.regular}
                              color="black"
                            />
                          </TouchableOpacity>
                        )
                      }
                    />
                    <CInput
                      errors={errors}
                      setErrors={setErrors}
                      label="Intermediary Bank Name"
                      fieldKey="intermediaryBankName"
                      fieldValue={paymentDetails?.intermediaryBankName}
                      onTextChange={onTextChange}
                      isRequired
                      isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                    />
                  </View>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <CInput
                      errors={errors}
                      setErrors={setErrors}
                      label="Intermediary Route Code"
                      fieldKey="intermediaryBankRouteCode"
                      fieldValue={paymentDetails?.intermediaryBankRouteCode}
                      onTextChange={onTextChange}
                      isRequired
                      isReadOnly={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                    />
                  </View>
                  <AddressForm
                    adddressValue={paymentDetails?.intermediaryBankAddress}
                    onAddressChange={onTextChange}
                    label={'Intermediary Bank Address'}
                    labelStyle={AppStyles.textFormSectionHeader}
                    errors={errors}
                    setErrors={setErrors}
                    groupKey="intermediaryBankAddress"
                    disabled={!editEnabled || (!!paymentDetails.template && !isTemplate)}
                  />
                </View>
              </View>
            </View>
          </ExpandableView>
        )}
        <ExpandableView
          isExpanded={additionalDetailsExpanded}
          setExpanded={setAdditionalDetailsExpanded}
          title="Additional Information"
        >
          <View>
            <View style={[AppStyles.flexRow, AppStyles.flexSpaceBetween]}>
              <View>
                <CInput
                  errors={errors}
                  setErrors={setErrors}
                  label="Message to Beneficiary"
                  fieldKey="paymentAdvice"
                  fieldValue={paymentDetails?.paymentAdvice}
                  onTextChange={onTextChange}
                  isReadOnly={!editEnabled}
                />
                <CInput
                  errors={errors}
                  setErrors={setErrors}
                  label="Regulatory Reporting"
                  fieldKey="regulatoryReporting"
                  fieldValue={paymentDetails?.regulatoryReporting}
                  onTextChange={onTextChange}
                  isReadOnly={!editEnabled}
                />
              </View>
              <View>
                <CInput
                  errors={errors}
                  setErrors={setErrors}
                  label="Payment Purpose"
                  fieldKey="paymentPurpose"
                  fieldValue={paymentDetails?.paymentPurpose}
                  onTextChange={onTextChange}
                  isReadOnly={!editEnabled}
                />
                <CInput
                  errors={errors}
                  setErrors={setErrors}
                  label="Internal Description"
                  fieldKey="internalDescription"
                  fieldValue={paymentDetails?.internalDescription}
                  onTextChange={onTextChange}
                  maxLength={1000}
                  multiline
                  isReadOnly={!editEnabled}
                />
              </View>
            </View>
          </View>
        </ExpandableView>

        {paymentDetails.id && (
          <ExpandableView
            isExpanded={commentsExpanded}
            setExpanded={setCommentsExpanded}
            title="Comments"
          >
            {
              <CommentSection
                collection={collection(db, 'Organizations', profileData.orgId, 'Payments')}
                documentId={paymentDetails.id}
                targetScreen="CreatePaymentScreen"
                hideTitle
              />
            }
          </ExpandableView>
        )}
        {isTemplate && paymentDetails.id && (
          <View style={[AppStyles.marginLeft, AppStyles.marginTop]}>
            <ActivityHistory recordKey={paymentDetails.id} recordType={LogType.PaymentTemplates} />
          </View>
        )}
      </ScrollView>

      {renderCTA()}
    </View>
  );
};

export default CreatePaymentScreen;
