import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import omit from 'lodash/omit';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import ActivityHistory from '../ActivityHistory';
import { Badge, useToast } from '@chakra-ui/react';
import { AppContext } from '../../App';
import { UPDATE_ENTITIES } from '../../actions/ActionConstatnts';
import {
  Address,
  ApprovalWorkflowSource,
  Entity,
  InputMode,
  LogType,
  StaticDataStore,
  Status,
} from '../../commonTypes';
import AppStyles from '../../constants/Styles';
import { _getChangedFields, _textFieldIsInvalid } from '../../utils/helper';
import AddressForm from '../AddressForm';
import CInput from '../CInput';
import { View } from '../Themed';
import StatusTag from '../StatusTag';
import ReviewAndApproveModal from '../ApprovalWorkflow/ReviewAndApproveModal';
import ButtonGroup from '../ButtonGroup';
import { TouchableOpacity } from 'react-native';

export type EntityDetailsProps = {
  entity: Entity;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onEntityUpdated?: (updatedEntity: Entity) => any;
  mode?: InputMode;
  onNext?: () => void;
  onItemActioned?: () => void;
};

export default function EntityDetails({
  entity,
  setIsVisible,
  onEntityUpdated,
  mode = InputMode.CREATE,
  onNext,
  onItemActioned,
}: EntityDetailsProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState<Entity>(entity);
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedEntity(entity);
  }, [entity]);

  const { db } = useContext(AppContext);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.EntityScreen?.edit || false;

  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.ENTITIES]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;

  const onSave = useCallback(async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);
    if (_textFieldIsInvalid(selectedEntity?.name)) {
      setIsSaving(false);
      return toast({
        title: 'Invalid Entity Name',
        description: 'Please enter a valid entity name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }

    if (_textFieldIsInvalid(selectedEntity?.code)) {
      setIsSaving(false);
      return toast({
        title: 'Invalid Entity code',
        description: 'Please enter a valid entity code',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    let entityDoc = {
      ...selectedEntity,
      status: Status.ACTIVE,
      createdBy: profileData.uid,
      lastUpdatedBy: profileData.uid,
    };
    if (mode === InputMode.CREATE) {
      //Create new Entity
      const docSnap = await getDoc(
        doc(db, 'Organizations', profileData.orgId, 'Entities', selectedEntity?.name || ''),
      );
      if (docSnap.exists()) {
        setIsSaving(false);

        return toast({
          title: 'Already exists',
          description: 'The entity already exists. Please enter a different name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (isWorkflowEnabled) {
        entityDoc = {
          ...entityDoc,
          status: Status.PENDING_APPROVAL,
        };
      }
      await setDoc(
        doc(db, 'Organizations', profileData.orgId, 'Entities', selectedEntity?.name || ''),
        {
          ...entityDoc,
          createdDtTm: serverTimestamp(),
        } as Entity,
      );
      setIsSaving(false);
      setIsVisible?.(false);
      onEntityUpdated?.(entityDoc as Entity);
      const _entities = staticData.entities || [];
      _entities.push(entityDoc);
      dispatch({
        payload: _entities,
        type: UPDATE_ENTITIES,
      });

      return toast({
        title: 'Entity Created',
        description: isWorkflowEnabled
          ? 'Entity creation request has been submitted and is pending approval'
          : 'Entity Created Successfully',
        status: isWorkflowEnabled ? 'warning' : 'success',
        duration: 5000,
        isClosable: true,
      });
    } else if (selectedEntity) {
      const updateRequest = {
        ..._getChangedFields(entity, selectedEntity),
        requestedBy: profileData.uid,
        requestId: uuidv4(),
        lastUpdatedAt: serverTimestamp(),
      };
      if (isWorkflowEnabled) {
        entityDoc = {
          ...entity,
          updateRequest: updateRequest,
          lastUpdatedBy: profileData.uid,
        };
      }
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'Entities', selectedEntity.name),
        isWorkflowEnabled
          ? { updateRequest: updateRequest }
          : {
              ...omit(entityDoc, ['createdBy', 'createdDtTm']),
            },
      )
        .then(() => {
          toast({
            title: isWorkflowEnabled
              ? 'Entity update requested. Pending approval'
              : 'Entity Updated',
            status: isWorkflowEnabled ? 'warning' : 'success',
            description: 'Entity updated successfully',
            duration: 5000,
            isClosable: true,
          });
          if (isWorkflowEnabled) {
            entityDoc = { ...entity, updateRequest };
          }
          const _entities = staticData.entities || [];
          _entities[_entities.findIndex((eEntity) => eEntity.name === entityDoc.name)] = entityDoc;
          dispatch({
            payload: _entities,
            type: UPDATE_ENTITIES,
          });
          onEntityUpdated?.(entity as Entity);
          setIsVisible?.(false);
        })
        .catch((e) => {
          console.warn(e);

          toast({
            title: 'Something went wrong',
            description: 'Please try again later',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      setIsSaving(false);
    } else {
      setIsSaving(false);
      return toast({
        title: 'Something went wrong',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    db,
    dispatch,
    isSaving,
    mode,
    onEntityUpdated,
    profileData.orgId,
    profileData.uid,
    selectedEntity,
    setIsVisible,
    staticData.entities,
    toast,
    isWorkflowEnabled,
  ]);

  const onTextChange = useCallback((field: keyof Entity, value: string | Address) => {
    setSelectedEntity((existingValue) => {
      return { ...existingValue, [field]: value } as Entity;
    });
  }, []);

  if (!selectedEntity) {
    return null;
  }

  return (
    <>
      {isWorkflowEnabled && (
        <ReviewAndApproveModal
          onClose={() => {
            setShowApprovalModal(false);
          }}
          pendingApprovalItem={selectedEntity}
          source={ApprovalWorkflowSource.ENTITIES}
          id={entity?.name}
          onApprovalDataLoaded={(approvalData) => {
            setCanApprove(approvalData.canApprove);
          }}
          onApprovalOrRejectonCompleted={() => {
            setIsVisible?.(false);
          }}
          onSomeActionDone={onItemActioned}
          show={showApprovalModal}
        />
      )}
      <View pointerEvents={canEdit ? 'auto' : 'none'}>
        <View style={AppStyles.flexRow}>
          <View style={AppStyles.flex1}>
            <View style={AppStyles.flexRow}>
              <CInput
                label="Entity Name"
                fieldKey="name"
                fieldValue={selectedEntity.name}
                onTextChange={onTextChange}
                isRequired
                disabled={mode === InputMode.EDIT}
              />
              <CInput
                label="Entity Code"
                fieldKey="code"
                fieldValue={selectedEntity.code}
                onTextChange={onTextChange}
                isRequired
                style={AppStyles.marginLeft}
              />
              <View style={[AppStyles.flex1, { alignItems: 'flex-end' }]} pointerEvents={'auto'}>
                <StatusTag
                  label={selectedEntity.status}
                  onPendingApprovalPress={() => setShowApprovalModal(true)}
                />
                {selectedEntity.updateRequest && (
                  <TouchableOpacity
                    onPress={() => {
                      setShowApprovalModal(true);
                    }}
                  >
                    <Badge variant="solid" colorScheme="orange">
                      Pending Updates
                    </Badge>
                  </TouchableOpacity>
                )}
              </View>
            </View>
            <View style={AppStyles.flexRow}>
              <CInput
                label="Short Name"
                fieldKey="shortName"
                fieldValue={selectedEntity.shortName}
                onTextChange={onTextChange}
              />
              <CInput
                label="GL Code"
                fieldKey="glCode"
                fieldValue={selectedEntity.glCode}
                style={AppStyles.marginLeft}
                onTextChange={onTextChange}
              />
            </View>
          </View>
        </View>

        <AddressForm
          adddressValue={selectedEntity.address}
          onAddressChange={onTextChange}
          label={'Address'}
        />
      </View>
      {mode !== InputMode.CREATE && (
        <ActivityHistory recordKey={selectedEntity.name} recordType={LogType.Entity} />
      )}
      <ButtonGroup
        mode={mode}
        onSave={onSave}
        isSaving={isSaving}
        canApprove={canApprove}
        setShowApprovalModal={setShowApprovalModal}
        setIsVisible={setIsVisible}
        onNext={onNext}
      />
    </>
  );
}
