/* eslint-disable react-native/no-inline-styles */
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import { useSelector } from 'react-redux';

import { Button, Divider, useToast } from '@chakra-ui/react';
import { AppContext } from '../../App';
import { GlExportFilters, InputMode, StaticDataStore, Visibility } from '../../commonTypes';
import AppStyles from '../../constants/Styles';
import { _textFieldIsInvalid } from '../../utils/helper';
import AccessType from '../AccessType';
import CategoryInput from '../CategoryInput';
import CCheckBox from '../CCheckBox';
import CInput from '../CInput';
import { MultiTextInput } from '../MultiTextInput';
import { Text, View } from '../Themed';
import AccountGroupSelectionList from '../Users/AccountGroupSelectionList';

export type EditGLExportFiltersProps = {
  filterConfig: GlExportFilters;
  setfilterConfig: Dispatch<SetStateAction<GlExportFilters>>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  onFilterUpdated?: (updatedUser: GlExportFilters) => any;
  mode?: InputMode;
};

const EditGLExportFilters: FC<EditGLExportFiltersProps> = ({
  filterConfig,
  setfilterConfig,
  isVisible,
  onFilterUpdated,
  setIsVisible,
  mode = InputMode.CREATE,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [temporaryfilterConfig, setTemporaryfilterConfig] = useState(
    mode !== InputMode.CREATE ? filterConfig : ({} as GlExportFilters),
  );
  const [originalfilterConfig, setOriginalfilterConfig] = useState(filterConfig);
  const { profileData } = useSelector((store: StaticDataStore) => store);

  const { db } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    if (mode !== InputMode.CREATE) {
      console.warn('Reseting Temp View Config');
      setTemporaryfilterConfig(filterConfig);
    }
    console.warn('Setting Original View Config', filterConfig);
    setOriginalfilterConfig(filterConfig);
  }, [mode, filterConfig]);

  const onCancel = useCallback(() => {
    setIsVisible(false);
    if (originalfilterConfig) {
      setTemporaryfilterConfig(originalfilterConfig);
      setfilterConfig(originalfilterConfig);
    }
  }, [originalfilterConfig, setIsVisible, setfilterConfig]);

  const onSave = useCallback(
    async (isApplyOnly: boolean) => {
      if (isSaving || !profileData?.orgId) {
        return;
      }
      console.warn('saving');
      setIsSaving(true);
      if (
        !isApplyOnly &&
        (_textFieldIsInvalid(temporaryfilterConfig?.name) ||
          _textFieldIsInvalid(temporaryfilterConfig?.description))
      ) {
        setIsSaving(false);
        toast({
          title: 'Enter all mandatory fields',
          description: 'Filter name and description are mandatory',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!isApplyOnly && !temporaryfilterConfig?.visibility) {
        setIsSaving(false);
        return toast({
          title: 'Select Visibility',
          description: 'Please select the visibility of the view',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      const cashPositionfilterConfigDoc = {
        ...temporaryfilterConfig,
        type: 'GLExportFilters',
      } as GlExportFilters;
      if (isApplyOnly) {
        console.warn(cashPositionfilterConfigDoc);
        setfilterConfig(cashPositionfilterConfigDoc);
        setIsSaving(false);
        return;
      }

      if (temporaryfilterConfig.id) {
        //Update the view
        await setDoc(
          doc(db, 'Organizations', profileData.orgId, 'Filters', temporaryfilterConfig.id),
          cashPositionfilterConfigDoc,
        );

        setIsVisible(false);
        setfilterConfig(temporaryfilterConfig);
        setIsSaving(false);
        return toast({
          title: 'Preset Updated',
          description: 'The preset has been updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      //Create new view

      const docSnap = await getDocs(
        query(
          collection(db, 'Organizations', profileData?.orgId, 'Filters'),
          where('name', '==', temporaryfilterConfig.name),
          where('type', '==', 'GLExportFilters'),
        ),
      );

      if (docSnap.docs.length > 0) {
        setIsSaving(false);

        return toast({
          title: 'Preset already exists',
          description:
            'A Preset with the name already exists. If you dont see it in your list of presets, it is possible that you dont have access to it. Please try a different name.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      const newViewDoc = await addDoc(
        collection(db, 'Organizations', profileData.orgId, 'Filters'),
        {
          ...cashPositionfilterConfigDoc,
          createdBy: profileData.uid,
          createdDtTm: serverTimestamp(),
        },
      );

      setIsVisible(false);
      setfilterConfig({
        ...temporaryfilterConfig,
        id: newViewDoc.id,
        createdBy: profileData?.uid,
        createdDtTm: Date.now(),
      });
      setIsSaving(false);

      return toast({
        title: 'Preset Created',
        description: 'Preset created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    [
      db,
      isSaving,
      profileData.orgId,
      profileData.uid,
      setIsVisible,
      setfilterConfig,
      temporaryfilterConfig,
      toast,
    ],
  );

  const onTextChange = useCallback((field: keyof GlExportFilters, value: string | string[]) => {
    setTemporaryfilterConfig((existingValue) => {
      return { ...existingValue, [field]: value } as GlExportFilters;
    });
  }, []);

  const renderAccountGroup = useCallback(() => {
    return (
      <AccountGroupSelectionList
        record={temporaryfilterConfig}
        setRecord={setTemporaryfilterConfig}
      />
    );
  }, [temporaryfilterConfig]);

  if (!isVisible) {
    return null;
  }

  return (
    <View>
      <Text style={AppStyles.textTitle}>{temporaryfilterConfig.name || 'Preset'}</Text>
      <View style={AppStyles.flexRow}>
        <View style={AppStyles.flex1}>
          <CInput
            label="Filter Name"
            fieldKey="name"
            fieldValue={temporaryfilterConfig.name}
            onTextChange={onTextChange}
            isRequired
            // disabled={mode === InputMode.EDIT}
          />
          <CInput
            label="Description"
            fieldKey="description"
            fieldValue={temporaryfilterConfig.description}
            onTextChange={onTextChange}
            isRequired
          />
        </View>
        <View style={AppStyles.flex1}>
          <AccessType
            label="Visibility"
            options={[Visibility.Private, Visibility.Public, Visibility.UserGroup]}
            value={temporaryfilterConfig?.visibility}
            onChange={(v) => onTextChange('visibility', v)}
            userGroups={temporaryfilterConfig?.userGroups}
            onUserGroupChange={(v) => onTextChange('userGroups', v)}
          />
        </View>
        <View style={AppStyles.flex1}>
          <CCheckBox
            label="Exclude System Suspense"
            value={temporaryfilterConfig.excludeSystemSuspense}
            onChange={(value) =>
              setTemporaryfilterConfig((existingValue) => {
                return { ...existingValue, excludeSystemSuspense: value } as GlExportFilters;
              })
            }
          />
          <CCheckBox
            style={AppStyles.marginTop}
            label="Exclude Zero Amounts"
            value={temporaryfilterConfig.excludeZeroAmount}
            onChange={(value) =>
              setTemporaryfilterConfig((existingValue) => {
                return { ...existingValue, excludeZeroAmount: value } as GlExportFilters;
              })
            }
          />
        </View>
        <Button title={'Cancel'} onClick={onCancel} isDisabled={isSaving} variant={'outline'}>
          Cancel
        </Button>
        <Button
          ml={2}
          colorScheme="blue"
          title={'Apply'}
          onClick={() => {
            onSave(true);
          }}
          isLoading={isSaving}
        >
          Apply
        </Button>
        <Button
          ml={2}
          title={'Save View'}
          onClick={() => onSave(false)}
          isLoading={isSaving}
          colorScheme={'green'}
        >
          Save Preset
        </Button>
      </View>
      <View style={[AppStyles.flexRowCenter, AppStyles.marginTop]}>
        <CategoryInput
          onSelect={(values) => {
            setTemporaryfilterConfig((existingValue) => {
              return { ...existingValue, excludedCategories: values } as GlExportFilters;
            });
          }}
          values={temporaryfilterConfig.excludedCategories}
          isMultiSelect
          includeAll={false}
          label="Exclude Categories"
          style={{ width: 300 }}
        />
        <MultiTextInput
          title={'Exclude Transaction Codes'}
          selectedValues={temporaryfilterConfig.excludedTransactionCodes || []}
          onSelect={(values) =>
            setTemporaryfilterConfig((existingValue) => {
              return { ...existingValue, excludedTransactionCodes: values } as GlExportFilters;
            })
          }
          style={{ marginTop: 0, marginLeft: 20 }}
        />
      </View>
      <View style={[AppStyles.flexRow, AppStyles.marginTop]}>
        <MultiTextInput
          title={'Exclude GL Accounts'}
          selectedValues={temporaryfilterConfig.excludedGlAccounts || []}
          onSelect={(values) =>
            setTemporaryfilterConfig((existingValue) => {
              return { ...existingValue, excludedGlAccounts: values } as GlExportFilters;
            })
          }
          addNextLine
          style={{ marginTop: 0 }}
        />
        <MultiTextInput
          title={'Consolidate GL Accounts'}
          selectedValues={temporaryfilterConfig.glAccountsToConsolidate || []}
          onSelect={(values) =>
            setTemporaryfilterConfig((existingValue) => {
              return { ...existingValue, glAccountsToConsolidate: values } as GlExportFilters;
            })
          }
          addNextLine
          style={{ marginTop: 0 }}
        />
      </View>

      <Divider mt={2} colorScheme={'gray'} />
    </View>
  );
};
export default EditGLExportFilters;
