/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from 'expo-linear-gradient';
import { FlatList, Image, Pressable, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { SHOW_SMART_MENU, UPDATE_ACCESSIBLE_SCREENS } from '../actions/ActionConstatnts';
import { StaticDataStore } from '../commonTypes';
import { MenuItemComponent, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Layout from '../constants/Layout';
import { MenuItem } from '../types';

export default function NavMenu() {
  const [expanded, setExpanded] = useState(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const navigation = useNavigation();
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const dispatch = useDispatch();
  useEffect(() => {
    let accesibleMenuItems = [];
    if (staticData.isAdmin) {
      accesibleMenuItems = Constants.menu;
    } else {
      accesibleMenuItems = Constants.menu
        .filter(
          (item) =>
            item.subMenu.filter(
              (subItem) => staticData.permissions[subItem.permission]?.view === true,
            ).length > 0,
        )
        .map((item) => {
          return {
            ...item,
            subMenu: item.subMenu.filter(
              (subItem) => staticData.permissions[subItem.permission]?.view === true,
            ),
          };
        });
    }
    setMenuItems(accesibleMenuItems);
    const accessibleScreens: any = {};
    accesibleMenuItems.map((item) => {
      item.subMenu.map((subItem) => {
        if (!accessibleScreens[subItem.target]) {
          accessibleScreens[subItem.target] = subItem;
        }
        accessibleScreens[subItem.target] = {
          ...subItem,
          ...staticData.permissions[subItem.permission],
          menuItem: item,
          ...(staticData.isAdmin && {
            create: true,
            edit: true,
            delete: true,
            approve: true,
            view: true,
          }),
        };
      });
    });
    console.warn('accessibleScreens', accessibleScreens);
    dispatch({
      payload: accessibleScreens,
      type: UPDATE_ACCESSIBLE_SCREENS,
    });
  }, [dispatch, staticData.isAdmin, staticData.permissions]);

  const renderMenu = useCallback(() => {
    return (
      <View style={[{ marginTop: 25 }, expanded && { width: 300 }]}>
        <FlatList
          data={[{ title: 'Search', icon: 'search' }, ...menuItems]}
          renderItem={({ item, index }) => {
            return (
              <MenuItemComponent
                menuItem={item}
                index={index}
                expanded={expanded}
                onPress={() => {
                  if (index === 0) {
                    setExpanded(false);
                    dispatch({
                      payload: true,
                      type: SHOW_SMART_MENU,
                    });
                    return;
                  }
                  setExpanded(true);
                  setMenuItems((v) => {
                    return v.map((menuItem, ind) => {
                      if (ind + 1 === index) {
                        return {
                          ...menuItem,
                          expanded: !expanded || !menuItem.expanded,
                        };
                      }
                      return { ...menuItem, expanded: false };
                    });
                  });
                }}
                onSubItemPress={(target) => {
                  navigation.navigate(target);
                  setExpanded(false);
                }}
              />
            );
          }}
        />

        <TouchableOpacity
          style={{
            flex: 1,
            alignItems: 'flex-end',
            paddingRight: 10,
            marginLeft: 10,
          }}
          onPress={() => setExpanded(!expanded)}
        >
          <FontAwesome5
            name={expanded ? 'angle-left' : 'angle-right'}
            color={Colors.white}
            size={20}
          />
        </TouchableOpacity>
      </View>
    );
  }, [dispatch, expanded, menuItems, navigation]);

  return (
    <Pressable
      style={{
        position: 'absolute',
        width: expanded ? Layout.window.width : undefined,
        alignItems: 'flex-start',
        backgroundColor: Colors.transBlack,
        height: '100%',
      }}
      onPress={() => {
        setExpanded(false);
      }}
    >
      <LinearGradient
        colors={[Colors.secondary, Colors.secondary, Colors.secondary, Colors.secondary]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={{
          height: '100%',
          backgroundColor: '#1167b1',
          padding: 10,
          minWidth: 50,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('HomeScreen');
            setExpanded(false);
          }}
          style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}
        >
          <Image
            source={require('../assets/images/favicon.png')}
            style={{ width: 30, height: 30 }}
          />
          {expanded && (
            <Text style={{ marginLeft: 10, fontSize: Constants.TextSize.xxl, color: Colors.white }}>
              Cash Xact
            </Text>
          )}
        </TouchableOpacity>
        {renderMenu()}
      </LinearGradient>
    </Pressable>
  );
}
