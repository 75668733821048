import { useSelector } from 'react-redux';
import { StaticDataStore } from '../commonTypes';

export default function useCategory() {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const _getCategoryName = (categoryId?: string) => {
    return (
      staticData?.categories?.find((category) => category.id === categoryId)?.name || 'Unmapped'
    );
  };

  return { _getCategoryName };
}
