/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { doc, updateDoc } from 'firebase/firestore';
import { ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { AppContext } from '../App';
import { UPDATE_ORG_INFO } from '../actions/ActionConstatnts';
import { PaymentMethod, StaticDataStore, Status } from '../commonTypes';
import Button from '../components/Button';
import { CAlertDialog } from '../components/CAlertDialog';
import { CFlatList } from '../components/CFlatList';
import CInput from '../components/CInput';
import { FilterBar } from '../components/FilterBar';
import KeyValuePairInput from '../components/KeyValuePairInput';
import StatusTag from '../components/StatusTag';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList, _textFieldIsInvalid } from '../utils/helper';

export default function PaymentMethodsScreen({
  navigation,
}: RootTabScreenProps<'PaymentMethodsScreen'>) {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [editEnabled, setEditEnabled] = useState(false);
  const [alertContent, setAlertContent] = useState({
    title: '',
    content: '',
    actionText: '',
    colorScheme: '',
    onAction: () => null || undefined,
  });

  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<
    PaymentMethod[] | undefined
  >();
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [isDeactivateAlertOpen, setIsDeactivateAlertOpen] = useState(false);

  useEffect(() => {
    if (staticData?.orgInfo?.PaymentMethods) {
      setPaymentMethods(
        Object.entries(staticData?.orgInfo?.PaymentMethods).map(([key, value]) => {
          return { ...value, id: key };
        }),
      );

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [staticData?.orgInfo?.PaymentMethods]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredPaymentMethods(_searchList(paymentMethods, searchValue));
      } else {
        setFilteredPaymentMethods(undefined);
      }
    },
    [paymentMethods],
  );

  const onSave = useCallback(
    (status?: Status) => {
      if (_textFieldIsInvalid(selectedPaymentMethod?.name)) {
        toast({
          title: 'Invalid Name for the Payment Method',
          description: 'Please enter a valid name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (_textFieldIsInvalid(selectedPaymentMethod?.description)) {
        toast({
          title: 'Invalid Description',
          description: 'Please enter a valid description',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (_textFieldIsInvalid(selectedPaymentMethod?.fileFormat)) {
        toast({
          title: 'Select a valid file format',
          description: 'The file format is mandatory and should be one of the avaliable formats',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      if (_textFieldIsInvalid(selectedPaymentMethod?.fileName)) {
        toast({
          title: 'Invalid File Name',
          description: 'Please enter a valid number for priority',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      setIsSaving(true);
      const params = selectedPaymentMethod?.params;
      if (params) {
        Object.keys(params).forEach((key) => {
          if (key === '' || params[key] === '') {
            delete params[key];
          }
        });
      }

      const paymentMethodDoc = {
        ...selectedPaymentMethod,
        status: status || selectedPaymentMethod?.status,
        params: params,
      } as PaymentMethod;
      console.warn('xyz', paymentMethodDoc);
      updateDoc(doc(db, 'Organizations', profileData.orgId), {
        [`PaymentMethods.${paymentMethodDoc.name}`]: paymentMethodDoc,
      })
        .then(() => {
          if (status) {
            toast({
              title: `Payment Method ${paymentMethodDoc.name} ${status}`,
              status: status === Status.DEACTIVATED ? 'error' : 'success',
              duration: 5000,
              isClosable: true,
            });
          } else {
            toast({
              title: `Payment Method ${paymentMethodDoc.name} ${
                selectedIndex !== undefined ? 'Updated' : 'Created'
              }`,
              status: 'success',
              duration: 5000,
              isClosable: true,
            });
          }

          dispatch({
            payload: {
              ...staticData?.orgInfo,
              PaymentMethod: {
                ...staticData?.orgInfo?.PaymentMethods,
                [paymentMethodDoc.name]: paymentMethodDoc,
              },
            },
            type: UPDATE_ORG_INFO,
          });
          setPaymentMethods((existingValue) => {
            if (selectedIndex !== undefined) {
              const updatedPaymentMethods = [...existingValue];
              updatedPaymentMethods[selectedIndex] = paymentMethodDoc;
              return updatedPaymentMethods;
            } else {
              return [paymentMethodDoc, ...existingValue];
            }
          });
          if (status) {
            setSelectedPaymentMethod((existingValue) => {
              return { ...existingValue, status } as PaymentMethod;
            });
          }
          setIsSaving(false);
          setEditEnabled(false);
          return;
        })
        .catch((e) => {
          console.warn(e);
          toast({
            title: `Something went wrong. Please try again later`,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });

          setIsSaving(false);
        });
    },
    [
      paymentMethods,
      db,
      filteredPaymentMethods,
      profileData.orgId,
      profileData.uid,
      selectedPaymentMethod,
      selectedIndex,
      toast,
    ],
  );

  const onTextChange = useCallback((field: keyof PaymentMethod, value: string) => {
    setSelectedPaymentMethod((existingValue) => {
      return { ...existingValue, [field]: value } as PaymentMethod;
    });
  }, []);

  return (
    <View style={AppStyles.container}>
      <CAlertDialog
        open={isDeactivateAlertOpen}
        setOpen={setIsDeactivateAlertOpen}
        title={alertContent.title}
        content={alertContent.content}
        actionText={alertContent.actionText}
        colorScheme={alertContent.colorScheme}
        onAction={alertContent.onAction}
      />
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {paymentMethods && paymentMethods.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}
        <Button
          label="Create Payment Method"
          variant={'Create'}
          onPress={() => {
            setSelectedPaymentMethod({
              name: '',
              description: '',
              status: Status.ACTIVE,
              fileName: '',
              fileFormat: '',
              params: {},
            } as PaymentMethod);
            setSelectedIndex(undefined);
            setEditEnabled(true);
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
        }}
      >
        <View style={{ width: Layout.window.width * 0.3 }}>
          <CFlatList
            emptyMessage={
              filteredPaymentMethods === undefined ? 'No Payment Methods yet' : 'No results found'
            }
            data={filteredPaymentMethods || paymentMethods}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: PaymentMethod; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedPaymentMethod(item);
                  setSelectedIndex(index);
                  setEditEnabled(false);
                }}
                key={item.id}
              >
                <Card
                  style={[
                    styles.bankAccountCard,
                    selectedPaymentMethod?.id === item.id && { borderColor: Colors.primary },
                  ]}
                >
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                    </View>
                    <StatusTag isReadOnly label={item.status} />
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        {selectedPaymentMethod && (
          <View style={AppStyles.width60}>
            <View style={[AppStyles.flexRowCenterSpaceBetween, { marginHorizontal: 10 }]}>
              <Text style={[AppStyles.textRowTitle, { marginBottom: 10 }]}>
                {selectedPaymentMethod.name || 'Payment Method'}
              </Text>
            </View>
            <ScrollView
              style={{
                maxHeight: Layout.window.height - 50,
                marginRight: 10,
                borderWidth: 2,
                borderColor: Colors.primary,
                borderRadius: 10,
              }}
            >
              <View style={{ flex: 1, padding: 10, paddingBottom: 100 }}>
                <>
                  <View style={[AppStyles.flexRowCenterSpaceBetween]}>
                    <CInput
                      label="Name"
                      fieldValue={selectedPaymentMethod.name}
                      fieldKey="name"
                      onTextChange={onTextChange}
                      isReadOnly={!editEnabled}
                      isRequired
                    />
                    <StatusTag isReadOnly label={selectedPaymentMethod.status} />
                  </View>
                  <CInput
                    label="Description"
                    fieldValue={selectedPaymentMethod.description}
                    fieldKey="description"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                    isRequired
                  />

                  <CInput
                    label="File Format Name"
                    fieldValue={selectedPaymentMethod.fileFormat}
                    fieldKey="fileFormat"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                    isRequired
                    tooltip="PAIN001_XML_V1 is the base XML format"
                  />
                  <CInput
                    label="External File Name"
                    fieldValue={selectedPaymentMethod.fileName}
                    fieldKey="fileName"
                    onTextChange={onTextChange}
                    isReadOnly={!editEnabled}
                    isRequired
                    tooltip="Name of the file that will be generated. Use [YYYYMMDDhhmmss] to include the current date in the file name. E.g: bic_method_[YYYYMMDDhhmmss].xml"
                  />
                  <KeyValuePairInput
                    title="Parameters"
                    value={selectedPaymentMethod.params}
                    onChange={(value) => {
                      console.warn(value);
                      onTextChange('params', value);
                    }}
                    isRequired={false}
                    isReadOnly={!editEnabled}
                  />
                </>
              </View>
            </ScrollView>

            <View style={[AppStyles.flexRowCenter, AppStyles.floatingBottonRight]}>
              <Button
                label="Cancel"
                onPress={() => {
                  setEditEnabled(false);
                }}
                isDisabled={isLoading}
                hidden={!editEnabled}
                variant="Cancel"
              />
              <Button
                label="Activate"
                variant={'Create'}
                onPress={() => {
                  setAlertContent({
                    title: 'Activate Payment Method',
                    content:
                      'This payment method will be allowed to be used going forward for all accounts for which it is enabled.',
                    actionText: 'Activate',
                    colorScheme: 'lime',
                    onAction: () => onSave(Status.ACTIVE),
                  });
                  setIsDeactivateAlertOpen(true);
                }}
                hidden={!editEnabled || selectedPaymentMethod?.status === Status.ACTIVE}
                isLoading={isSaving}
              />
              <Button
                label="Deactivate"
                variant={'Delete'}
                onPress={() => {
                  {
                    setAlertContent({
                      title: 'Deactivate Payment Method',
                      content:
                        'This payment method will not be available to use even if it is tagged to existing banks or accounts.',
                      actionText: 'Deactivate',
                      colorScheme: 'danger',
                      onAction: () => onSave(Status.DEACTIVATED),
                    });
                    setIsDeactivateAlertOpen(true);
                  }
                }}
                hidden={
                  !editEnabled ||
                  selectedPaymentMethod?.status !== Status.ACTIVE ||
                  !selectedPaymentMethod?.id
                }
                isLoading={isSaving}
              />
              <Button
                label="Edit"
                variant={'Edit'}
                onPress={() => {
                  setEditEnabled(true);
                }}
                hidden={editEnabled}
              />
              <Button
                label={selectedPaymentMethod?.id ? 'Save Changes' : 'Create Payment Method'}
                hidden={!editEnabled}
                onPress={() => onSave()}
                isLoading={isSaving}
              />
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
