import React, { FC, ReactElement, useMemo } from 'react';

import { Text, View } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';

export type IconTextProps = {
  icon?: ReactElement;
  value?: string;
  showEmpty?: boolean;
  textColor?: string;
  marginTop?: number;
  fontSize?: number;
  textAlignment?: 'left' | 'center' | 'right';
};
const IconText: FC<IconTextProps> = ({
  icon,
  value,
  showEmpty,
  textColor,
  marginTop,
  fontSize,
  textAlignment,
}) => {
  if (!value && !showEmpty) {
    return <View />;
  }
  return (
    <View style={[{ marginTop: marginTop || 0 }, AppStyles.flexRowCenter]}>
      {icon}
      <Text
        style={{
          textAlign: textAlignment,
          fontSize: fontSize || Constants.TextSize.large,
          marginLeft: icon ? 5 : 0,
          color: textColor || Colors.black,
        }}
      >
        {value}
      </Text>
    </View>
  );
};

export default IconText;
