import React, { FC, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';

import { Avatar, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { Text, View } from './Themed';
import { StaticDataStore } from '../commonTypes';
import AppStyles from '../constants/Styles';

export type BankAccountInputProps = {
  onSelect: (values: string[]) => void;
  value?: string;
  values?: string[];
  isRequired?: boolean;
  isMultiSelect?: boolean;
  isReadOnly?: boolean;
  includeAll?: boolean;
  width?: number | string;
  hideLabel?: boolean;
  title?: string;
  hasError?: boolean;
  style?: React.CSSProperties;
};
const BankAccountInput: FC<BankAccountInputProps> = ({
  onSelect,
  value,
  isRequired,
  isMultiSelect,
  values = [],
  isReadOnly,
  includeAll = isMultiSelect,
  width,
  hideLabel,
  title,
  hasError,
  style,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const bankAccountDetails = useMemo(() => {
    return staticData.bankAccounts?.find((acc) => acc.id === value);
  }, [value, staticData.bankAccounts]);

  // Custom option renderer
  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <Flex
        ref={innerRef}
        {...innerProps}
        align="center"
        p={2}
        cursor="pointer"
        _hover={{ bg: 'gray.100' }}
        justifyContent={'space-between'}
      >
        <View>
          <Text>{data.label}</Text>
          <Text>{data.number}</Text>
        </View>
        <Text style={{ marginLeft: 5 }}>{data.currency}</Text>
      </Flex>
    );
  };

  return (
    <FormControl isRequired={isRequired} width={width} style={style}>
      <FormLabel fontSize={14} mb={1} hidden={hideLabel}>
        {title || 'Bank Account'}
      </FormLabel>
      <View style={{ maxWidth: '90%', alignSelf: 'flex-start' }}>
        <Select
          isReadOnly={isReadOnly}
          isInvalid={hasError}
          isClearable={!isReadOnly}
          isMulti={isMultiSelect}
          onChange={(selected) => {
            if (isMultiSelect) {
              const all = selected?.findIndex((val) => val.value === 'All');
              if ((selected?.length > 1 && all > 0) || (selected?.length === 1 && all === 0)) {
                return onSelect(['All']);
              }
              return onSelect(
                selected?.map((val) => val.value)?.filter((val) => val !== 'All') || [],
              );
            }
            return onSelect([selected?.value]);
          }}
          closeMenuOnSelect={!isMultiSelect}
          components={{
            Option: CustomOption,
            ...(isReadOnly && { MultiValueRemove: () => null }), // Conditionally disable remove button
          }}
          value={
            isMultiSelect
              ? values?.map((val) => {
                  return {
                    value: val,
                    label:
                      staticData.bankAccounts?.find((ag) => ag.id === val)?.accountNumber || val,
                  };
                })
              : value
              ? [
                  {
                    value,
                    label:
                      staticData.bankAccounts?.find((ag) => ag.id === value)?.accountNumber ||
                      value,
                  },
                ]
              : []
          }
          name="Bank Accounts"
          options={[
            ...(includeAll ? [{ value: 'All', label: 'All' }] : []),
            ...(staticData.bankAccounts?.map((ag) => {
              return {
                value: ag.id,
                label: ag.accountName,
                currency: ag.currency,
                number: ag.accountNumber,
              };
            }) || []),
          ]}
          placeholder="Select Account"
          size="sm"
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          chakraStyles={{
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          menuPortalTarget={document.body}
        />
        <View style={[AppStyles.flexRowCenterSpaceBetween, { marginTop: 5, marginHorizontal: 5 }]}>
          <Text style={AppStyles.textTiny}>{bankAccountDetails?.accountName}</Text>
          <Text style={AppStyles.textTiny}>{bankAccountDetails?.bank}</Text>
        </View>
      </View>
    </FormControl>
  );
};

export default BankAccountInput;
