import React, { FC, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';
import AppStyles from '../constants/Styles';
import { Entity, StaticDataStore, Status } from '../commonTypes';
import { Text, View } from './Themed';
import { FormControl, FormLabel } from '@chakra-ui/react';

export type EntityInputProps = {
  onSelect: (values: Entity[]) => void;
  value?: string;
  isRequired?: boolean;
};
const EntityInput: FC<EntityInputProps> = ({ onSelect, value, isRequired }) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const entityDetails = useMemo(() => {
    return staticData.entities?.find((entity) => entity.name === value);
  }, [value, staticData.entities]);

  return (
    <View style={AppStyles.defaultWidthSmall}>
      <FormControl isRequired={isRequired}>
        <FormLabel fontSize={14} mb={1}>
          Entity
        </FormLabel>
        <Select
          onChange={(selected) =>
            onSelect([staticData.entities?.find((entity) => entity.name === selected.value)])
          }
          value={value ? [{ value, label: value }] : []}
          name="Entity"
          options={staticData.entities
            ?.filter((entity) => entity.status === Status.ACTIVE)
            .map((entity) => {
              return { value: entity.name, label: entity.code + ' - ' + entity.name };
            })}
          placeholder="Select Entity"
          size="sm"
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          chakraStyles={{
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          menuPortalTarget={document.body}
        />
        <View style={[AppStyles.flexRowCenterSpaceBetween, { marginTop: 5, marginHorizontal: 5 }]}>
          <Text style={AppStyles.textTiny}>
            {(entityDetails?.address?.country ? entityDetails?.address.country + ' - ' : '') +
              (entityDetails?.shortName || ' ')}
          </Text>
          <Text style={AppStyles.textTiny}>{entityDetails?.code || ' '}</Text>
        </View>
      </FormControl>
    </View>
  );
};

export default EntityInput;
