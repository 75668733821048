import { useToast } from '@chakra-ui/react';
import { arrayUnion, CollectionReference, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppContext } from '../../App';
import { Comment, StaticDataStore } from '../../commonTypes';
import CommentCard from './CommentCard';
import CommentInput from './CommentInput';
import { Text, View } from '../Themed';
import AppStyles from '../../constants/Styles';
import useNotifications from '../../hooks/useNotifications';

const CommentSection: React.FC<{
  collection: CollectionReference;
  documentId?: string;
  targetScreen: string;
  hideTitle?: boolean;
}> = ({ collection, documentId, targetScreen, hideTitle }) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const { db } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const { _addToNotification } = useNotifications();

  const parseMentions = (text: string) => {
    return text.replace(/@\[(.*?)\]\((.*?)\)/g, '@$1');
  };

  useEffect(() => {
    if (!documentId) return;
    const unsubscribe = onSnapshot(doc(collection, documentId), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        if (data?.comments) {
          setComments(data.comments);
        }
      }
    });

    return () => unsubscribe();
  });
  const addComment = (comment: string, mentions: string[]) => {
    const userTimezone = moment.tz.guess(); // Guess the user's timezone
    const commentData = {
      id: `${Date.now()}-${profileData?.uid}`,
      user: profileData?.uid,
      text: comment,
      taggedUsers: mentions,
      resolved: false,
      parentId: null,
      createdAt: {
        isoString: moment().tz(userTimezone).format(), // ISO 8601 string in the user's timezone
        timezone: moment.tz.guess(), // Store the user's timezone
      },
      replies: [],
    } as Comment;
    updateDoc(doc(collection, documentId), {
      comments: arrayUnion(commentData),
    }).then(() => {
      setComments([...comments, commentData]);

      // Add notification for mentioned users
      if (mentions.length > 0 && profileData?.orgId) {
        _addToNotification(mentions, {
          title: 'New Comment Mention',
          message: `${profileData.firstName} mentioned you in a comment: ${parseMentions(comment)}`,
          type: 'info',
          read: false,
          hide: false,
          targetScreen: targetScreen,
          key: documentId,
        });
      }

      toast({
        title: 'Comment added!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    });
  };

  if (!documentId) return null;

  return (
    <View>
      {!hideTitle && <Text style={[AppStyles.textRowTitle, AppStyles.marginTop25]}>Comments</Text>}
      <CommentInput onSubmit={addComment} style={AppStyles.marginTop} />
      {comments.reverse().map((comment, index) => (
        <CommentCard
          key={index}
          comment={comment}
          onReply={() => null}
          onResolve={() => null}
          onDelete={() => null}
        />
      ))}
    </View>
  );
};

export default CommentSection;
