import React, { FC } from 'react';

import { LockIcon, QuestionIcon, WarningIcon } from '@chakra-ui/icons';
import { Spinner, Tooltip } from '@chakra-ui/react';
import Colors from '../constants/Colors';

export type CToolTipProps = {
  label?: string;
  iconColor?: string;
  isLockIcon?: boolean;
  isWarningIcon?: boolean;
  fetch?: () => void;
};
const CToolTip: FC<CToolTipProps> = ({ label, iconColor, isLockIcon, isWarningIcon, fetch }) => {
  if (!fetch && !label) {
    return null;
  }
  return (
    <Tooltip
      textAlign={'center'}
      label={label || <Spinner size="sm" mt={1} />}
      fontSize="small"
      placement="top"
      onOpen={!label ? fetch : undefined}
    >
      {isLockIcon ? (
        <LockIcon ml={1} mb={1} fontSize={12} color={Colors.darkOrange} />
      ) : isWarningIcon ? (
        <WarningIcon ml={1} mb={1} fontSize={12} color={Colors.darkOrange} />
      ) : (
        <QuestionIcon ml={1} mb={1} fontSize={12} color={iconColor || Colors.secondary} />
      )}
    </Tooltip>
  );
};

export default CToolTip;
