import { useSelector } from 'react-redux';
import { Notification, StaticDataStore } from '../commonTypes';
import { useContext } from 'react';
import { AppContext } from '../App';
import { collection, doc, serverTimestamp, writeBatch } from 'firebase/firestore';

export default function useNotifications() {
  const { profileData } = useSelector((store: StaticDataStore) => store);

  const { db } = useContext(AppContext);

  const _addToNotification = (users: string[], content: Partial<Notification>, priority: number = 2)=> {
    const batch = writeBatch(db);
    users.forEach((user) => {
        batch.set(doc(collection(db, 'Organizations', profileData.orgId, 'Notifications')), {
            priority: priority, 
            ...content,
            user, 
            timestamp: serverTimestamp(),
        } as Notification);
    });
    try {
         batch.commit();
        console.warn('Batch committed');
    } catch (error) {
        console.error(error);
    }
}

  return { _addToNotification };
}
