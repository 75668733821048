import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { StaticDataStore } from '../commonTypes';
import CInput from './CInput';

export type AmountInputProps = {
  title?: string;
  onCurrencyChange: (values: string) => void;
  onAmountChange?: (value: number | null) => void;
  currencyValue?: string;
  amountValue?: number;
  isReadOnly?: boolean;
  isRequired?: boolean;
  errors?: any;
  fieldKey?: string;
  setErrors?: (value: any) => void;
};

const AmountInput: FC<AmountInputProps> = ({
  onCurrencyChange,
  currencyValue,
  onAmountChange,
  amountValue,
  isRequired,
  isReadOnly = false,
  title,
  errors = {},
  setErrors,
  fieldKey,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [displayValue, setDisplayValue] = useState<string>(amountValue?.toString() || '');

  const formatAmount = useCallback((value: string) => {
    // Remove any existing commas first
    const cleanValue = value.replaceAll(',', '');
    
    // If it's just a decimal point, allow it
    if (cleanValue === '.') {
      setDisplayValue('.');
      return onAmountChange?.(null);
    }

    // Split into integer and decimal parts
    const parts = cleanValue.split('.');
    const integerPart = parts[0];
    let decimalPart = parts[1] || '';

    // Limit decimal places to 2
    if (decimalPart.length > 2) {
      decimalPart = decimalPart.slice(0, 2);
    }

    // Format integer part with commas
    const formattedInteger = integerPart ? parseInt(integerPart).toLocaleString('en-US') : '0';
    
    // Combine parts
    const formattedValue = decimalPart 
      ? `${formattedInteger}.${decimalPart}`
      : parts.length > 1 
        ? `${formattedInteger}.` // Keep trailing decimal point while typing
        : formattedInteger;

    // Update display value with formatting
    setDisplayValue(formattedValue);
    
    // Update actual value (parsed number)
    const parsedValue = parseFloat(`${integerPart}.${decimalPart}`);
    if (!isNaN(parsedValue)) {
      onAmountChange?.(parsedValue);
    }
  }, [onAmountChange]);

  const delayedQuery = useCallback(
    debounce((rawValue: string) => {
      formatAmount(
        rawValue
          .toLocaleLowerCase()
          .replace('t', '000')
          .replace('k', '000')
          .replace('m', '000000')
          .replace('b', '000000000'),
      );
    }, 100),
    [],
  );

  return (
    <CInput
      label="Amount"
      fieldKey={fieldKey || 'amount'}
      fieldValue={displayValue}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      errors={errors}
      textPaddingLeft="5.5em"
      setErrors={setErrors}
      onTextChange={(field, value) => {
        // Allow digits, commas, and single decimal point
        if (value === '') {
          setDisplayValue('');
          return onAmountChange?.(null);
        }
        
        // Allow digits, commas, and at most one decimal point
        if (/^[0-9,]*\.?[0-9]*$/.test(value)) {
          return formatAmount?.(value);
        }
        
        // Handle special suffixes (k, m, b, t)
        if (/^[0-9,.]+[kmbt]$/i.test(value)) {
          return delayedQuery(value);
        }
      }}
      leftElementProps={{ width: '6em', fontSize: 14, height: 8 }}
      leftElement={
        <Select
          variant={'filled'}
          height={8}
          isReadOnly={isReadOnly}
          isDisabled={isReadOnly}
          onChange={(e) => {
            onCurrencyChange?.(e.target.value);
          }}
          value={currencyValue}
          name="Currency"
          placeholder="CCY"
        >
          {staticData.currencies?.map((currency) => {
            return (
              <option key={currency.iso.toString()} value={currency.iso.toString()}>
                {currency.iso}
              </option>
            );
          })}
        </Select>
      }
    />
  );
};

export default AmountInput;
