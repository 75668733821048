import React, { useCallback, useContext, useState } from 'react';

import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { CAlertDialog } from '../components/CAlertDialog';

import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import {
  ApprovalWorkflowSource,
  BankAccount,
  Payment,
  PaymentStatus,
  StaticDataStore,
} from '../commonTypes';
import ReviewAndApproveModal from '../components/ApprovalWorkflow/ReviewAndApproveModal';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList } from '../utils/helper';

import { useToast } from '@chakra-ui/react';
import { useFocusEffect } from '@react-navigation/native';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';

import { AppContext } from '../App';
import Button from '../components/Button';
import PaymentListItem from '../components/Payments/PaymentListItem';

export default function PaymentsReleaseScreen({
  navigation,
}: RootTabScreenProps<'PaymentsReleaseScreen'>) {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemForApproval, setSelectedItemForApproval] = useState<BankAccount>();
  const [filteredPayments, setFilteredPayments] = useState<Payment[] | undefined>();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const canEdit = staticData.accessibleScreens?.PaymentsReleaseScreen?.edit || false;

  const { db } = useContext(AppContext);

  const fetchPayments = useCallback(async () => {
    console.log('Fetching Payments');
    if (!profileData?.orgId) {
      return;
    }
    setIsLoading(true);
    setPayments([]);
    setLastVisible(null);
    const q = query(
      collection(db, 'Organizations', profileData?.orgId, 'Payments'),
      where('status', '==', PaymentStatus.APPROVED),
      orderBy('sequenceNumber', 'desc'),
      limit(5),
    );
    const querySnapshot = await getDocs(q);
    const _payments: Payment[] = [];
    querySnapshot.forEach((doc) => {
      _payments.push({ ...doc.data(), id: doc.id } as Payment);
    });
    console.warn(_payments);
    setPayments(_payments);
    if (querySnapshot.empty) {
      setLastVisible(null);
    } else {
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    }
    setIsLoading(false);
  }, [db, profileData?.orgId]);

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchPayments();
    }, [fetchPayments]),
  );

  // useEffect(() => {
  //   fetchPayments();
  //   setIsLoading(false);
  // }, [fetchPayments]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredPayments(_searchList(payments, searchValue));
      } else {
        setFilteredPayments(undefined);
      }
    },
    [payments],
  );

  const onReleasePayments = useCallback(() => {
    setIsProcessing(true);
    const functions = getFunctions();
    if (__DEV__) {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }
    const releasePayments = httpsCallable(functions, 'releasePayments');
    // const releasePayments = httpsCallable(functions, 'processAck');
    releasePayments({
      orgId: profileData.orgId,
    }).then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      console.log(result.data);
      fetchPayments();
      setIsProcessing(false);
      // if (result?.data?.state) {
      // onUserUpdated?.({ ...selectedUser, uid: result?.data?.uid });
      // setIsVisible(false);
      result?.data?.messages?.forEach((message: string) => {
        toast({
          title: message,
          description: '',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      });
      result?.data?.errors?.forEach((message: string) => {
        toast({
          title: message,
          description: '',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });

      setIsProcessing(false);
      setIsLoading(false);
    });
  }, [profileData.uid, profileData.orgId, fetchPayments, toast]);

  const onProcessAck = useCallback(() => {
    setIsProcessing(true);
    const functions = getFunctions();
    if (__DEV__) {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }
    // const releasePayments = httpsCallable(functions, 'releasePayments');
    const releasePayments = httpsCallable(functions, 'processAck');
    releasePayments({
      orgId: profileData.orgId,
    }).then((result) => {
      // Read result of the Cloud Function.
      /** @type {any} */
      console.log(result.data);
      fetchPayments();
      setIsProcessing(false);
      // if (result?.data?.state) {
      // onUserUpdated?.({ ...selectedUser, uid: result?.data?.uid });
      // setIsVisible(false);
      result?.data?.messages?.forEach((message: string) => {
        toast({
          title: message,
          description: '',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      });
      result?.data?.errors?.forEach((message: string) => {
        toast({
          title: message,
          description: '',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
      // } else {
      //   toast({
      //     title: 'Unable to release payment',
      //     description: 'Something went wrong. Please try again',
      //     status: 'error',
      //     duration: 5000,
      //     isClosable: true,
      //   });
      // }
      setIsProcessing(false);
      setIsLoading(false);
    });
  }, [profileData.uid, profileData.orgId, fetchPayments, toast]);

  const handleReleaseClick = useCallback(() => {
    setIsConfirmDialogOpen(true);
  }, []);

  return (
    <View style={AppStyles.container}>
      <ReviewAndApproveModal
        onClose={() => setSelectedItemForApproval(undefined)}
        pendingApprovalItem={selectedItemForApproval}
        source={ApprovalWorkflowSource.PAYMENTS}
      />
      <CAlertDialog
        open={isConfirmDialogOpen}
        setOpen={setIsConfirmDialogOpen}
        title="Confirm Payment Release"
        content="Are you sure you want to release these payments? This action is irreversible and the payments will be sent to the bank for further processing."
        actionText="Release Payments"
        cancelText="Cancel"
        colorScheme="blue"
        onAction={onReleasePayments}
      />
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {payments && payments.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}
        {staticData.accessibleScreens?.PaymentsReleaseScreen?.create &&
        payments &&
        payments.length > 0 ? (
          <View style={AppStyles.flexRowCenter}>
            <Button
              label="Release payments"
              variant="Send"
              isLoading={isProcessing}
              onPress={handleReleaseClick}
            />
            {/* <Button
              label="Process Acks"
              variant="Edit"
              isLoading={isProcessing}
              onPress={onProcessAck}
            /> */}
          </View>
        ) : (
          <View />
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width - 60 }}>
          <CFlatList
            emptyMessage={filteredPayments === undefined ? 'No Payments to release' : 'No results'}
            // subAction={
            //   <Text style={AppStyles.textSubTitle}>
            //     Importing bank statements will automatically create bank accounts if they do not
            //     exists
            //   </Text>
            // }
            data={filteredPayments || payments}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            loadMore={async () => {
              if (lastVisible) {
                setIsLoadingMore(true);
                const q = query(
                  collection(db, 'Organizations', profileData?.orgId, 'Payments'),
                  where('status', '==', PaymentStatus.APPROVED),
                  orderBy('sequenceNumber', 'desc'),
                  startAfter(lastVisible),
                  limit(5),
                );
                const querySnapshot = await getDocs(q);
                const _payments: Payment[] = [];
                querySnapshot.forEach((doc) => {
                  _payments.push(doc.data() as Payment);
                });
                if (querySnapshot.empty) {
                  setLastVisible(null);
                } else {
                  setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
                }
                setPayments([...payments, ..._payments]);
                setIsLoadingMore(false);
              }
            }}
            renderItem={({ item }: { item: Payment }) => <PaymentListItem payment={item} />}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
    borderRadius: 5,
    padding: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});
