/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';

import debounce from 'lodash/debounce';
import moment, { Moment } from 'moment';
import DatePicker from 'react-datepicker';
import { ViewStyle } from 'react-native';

import { View } from './Themed';

import 'react-datepicker/dist/react-datepicker.css';
import Colors from '../constants/Colors';
import { FormControl, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react';

import { MaterialIcons } from '@expo/vector-icons';

export type DateInputProps = {
  title?: string;
  onSelect: (value: Moment | undefined) => void;
  label?: string;
  renderItem?: any;
  isSingleSelect?: boolean;
  isReadOnly?: boolean;
  value?: Moment;
  width?: number;
  style?: ViewStyle;
  isRequired?: boolean;
  isNotOnModal?: boolean;
  placeholder?: string;
};
export function DateInput({
  title,
  onSelect,
  value,
  isRequired,
  style,
  width,
  isReadOnly,
  isNotOnModal,
  placeholder,
}: DateInputProps) {
  const onChange = (val: any) => {
    if (val) {
      onSelect?.(moment(val).startOf('day'));
    } else {
      onSelect?.(undefined);
    }
  };

  const delayedQuery = useCallback(
    debounce((rawValue) => {
      // if (!rawValue) {
      //   return onSelect(moment().startOf('day'));
      // }
      if ('today'.includes(rawValue?.toLowerCase())) {
        return onSelect(moment().startOf('day'));
      }
      if ('tomorrow'.includes(rawValue?.toLowerCase())) {
        return onSelect(moment().add(1, 'days').startOf('day'));
      }
      if ('yesterday'.includes(rawValue?.toLowerCase())) {
        return onSelect(moment().add(-1, 'days').startOf('day'));
      }
    }, 1000),
    [],
  );

  const handleChangeRaw = (rawValue: string) => {
    delayedQuery(rawValue);
  };

  return (
    <View style={[{ zIndex: 900 }, style]}>
      <FormControl isRequired={isRequired} width={width}>
        {title && (
          <FormLabel fontSize={14} mb={1}>
            {title}
          </FormLabel>
        )}
        <DatePicker
          customInput={
            <InputGroup>
              <Input
                isReadOnly={isReadOnly}
                placeholder={placeholder || 'Select Date'}
                value={value?.format('DD MMM yyyy')}
                _placeholder={{ fontSize: 14 }}
              />
              <InputRightElement
                children={<MaterialIcons name="date-range" size={24} color={Colors.primary} />}
              />
            </InputGroup>
          }
          disabled={isReadOnly}
          portalId={isNotOnModal ? 'root-portal' : undefined}
          selected={value?.toDate()}
          onChange={(date) => onChange(date)}
          onChangeRaw={(event) => {
            if (event.target.value) {
              handleChangeRaw(event.target.value);
            }
          }}
          placeholderText={placeholder || 'Select Date'}
          dateFormat={'dd MMM yyyy'}
        />
      </FormControl>
    </View>
  );
}
