import { background } from '@chakra-ui/react';

const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const primary = '#1AA9D0';
const secondary = '#062E60';
const midBlue = '#145a92';
const textBlue = '#1E3A8A';

export default {
  primary: primary,
  secondary: secondary,
  midBlue,
  textBlue,
  green: '#43C59E',
  grey: 'grey',
  midGrey: '#666666',
  orange: 'orange',
  darkOrange: '#cc7000',
  light_grey: '#c0c0c0',
  lightest_grey: '#EAE9E8',
  excel_green: '#008000',
  red: '#E24C4B',
  white: 'white',
  black: 'black',
  transBlack: '#00000080',
  warning: '#c69035',
  info: '#89CFF0',
  background: '#f1f8fb',
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    primary,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    secondary,
  },
  chartColors: ['#075264', '#0A758F', '#0D98BA', '#10BBE5', '#32CBF1', '#5DD6F4', '#042F39'],
  pieChartColors: [
    '#6FA3E6', // Soft Blue
    '#5D9CEC', // Sky Blue
    '#4A89DC', // Medium Blue
    '#357ABD', // Deeper Blue
    '#2A6F97', // Teal Blue
    '#3FAEAA', // Muted Aqua
    '#50C4B7', // Aqua Green
    '#46B5A7', // Ocean Green
    '#5EBF86', // Soft Green
    '#6DCC89', // Bright Mint
    '#55A68B', // Sage Green
    '#3E8C76', // Forest Teal
    '#88C090', // Pastel Jungle Green
    '#4EAB9D', // Seafoam Green
    '#8ABEB7', // Dusty Turquoise
    '#3D9970', // Emerald Pastel
    '#4FAF8D', // Medium Mint
    '#629FBC', // Washed Denim
    '#3A92C4', // Steel Blue
    '#4D8CC6', // Cool Blue
  ],
  categoryColors: [
    '#d73964',
    '#d23440',
    '#db643a',
    '#e88334',
    '#e2a71e',
    '#e25241',
    '#d0da59',
    '#4053ae',
    '#70b949',
    '#73564a',
    '#67ab5a',
    '#8f36aa',
    '#f6c244',
    '#52b9d0',
    '#4595ec',
    '#009688',
    '#5abeA7',
    '#59bccd',
    '#4a97e4',
    '#2d68cd',
    '#9946c7',
    '#d9639e',
    '#6d6f74',
    '#939287',
    '#868ea3',
  ],
};
