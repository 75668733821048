/* eslint-disable react-native/no-inline-styles */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import debounce from 'lodash/debounce';
import differenceBy from 'lodash/differenceBy';
import ActivityHistory from '../ActivityHistory';

import { useToast } from '@chakra-ui/react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { isEmpty, omit } from 'lodash';
import { AppContext } from '../../App';
import {
  AccountGroup,
  ApprovalWorkflowSource,
  BankAccount,
  InputMode,
  LogType,
  StaticDataStore,
  Status,
} from '../../commonTypes';
import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import AppStyles from '../../constants/Styles';
import { _getChangedFields, _textFieldIsInvalid } from '../../utils/helper';
import ReviewAndApproveModal from '../ApprovalWorkflow/ReviewAndApproveModal';
import ButtonGroup from '../ButtonGroup';
import { CFlatList } from '../CFlatList';
import CInput from '../CInput';
import FieldDisplay from '../FieldDisplay';
import { Card, Text, View } from '../Themed';

export type AccountGroupDetailsProps = {
  accountGroup: AccountGroup;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onAccountGroupUpdated?: (updatedAccount: AccountGroup) => any;
  mode?: InputMode;
  onNext?: () => void;
  onItemActioned?: () => void;
};

export default function AccountGroupDetails({
  accountGroup,
  setIsVisible,
  onAccountGroupUpdated,
  mode = InputMode.CREATE,
  onNext,
  onItemActioned,
}: AccountGroupDetailsProps) {
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.AccountGroupsScreen?.edit || false;
  const [accountGroups, setAccountGroups] = useState<AccountGroup[]>(
    staticData.accountGroups || [],
  );
  const isReadOnly = [InputMode.VIEW, InputMode.APPROVE].includes(mode);
  const [allAccounts, setAllAccounts] = useState<BankAccount[]>([]);
  const [storeAccounts, setStoreAccounts] = useState<BankAccount[]>([]);

  const [isSaving, setIsSaving] = useState(false);
  const [isAccountsLoading, setIsAccountsLoading] = useState(false);
  const [selectedAccountGroup, setSelectedAccountGroup] = useState<AccountGroup>(accountGroup);

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [canApprove, setCanApprove] = useState(false);

  const { db } = useContext(AppContext);
  const toast = useToast();

  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.ACCOUNT_GROUPS]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.SECURITY]?.status;

  const fetchAllAccounts = useCallback(() => {
    console.log('Fetching All Accounts');

    const _accounts: BankAccount[] = storeAccounts || [];

    setAllAccounts(
      _accounts.map((account) => {
        return account.accountGroups?.includes(selectedAccountGroup?.id || '')
          ? { ...account, selected: true }
          : account;
      }),
    );

    setIsAccountsLoading(false);
  }, [selectedAccountGroup?.id, storeAccounts]);

  useEffect(() => {
    setSelectedAccountGroup(accountGroup);
    fetchAllAccounts();
  }, [accountGroup, fetchAllAccounts]);

  const groupAccounts = useMemo(() => {
    return (
      storeAccounts?.filter(
        (acc) => selectedAccountGroup?.id && acc.accountGroups?.includes(selectedAccountGroup?.id),
      ) || []
    );
  }, [selectedAccountGroup?.id, storeAccounts]);

  const delayedStoreAccountUpdate = useCallback(
    debounce((_bankAccounts) => {
      setStoreAccounts(_bankAccounts);
      setIsAccountsLoading(false);
    }, 3000),
    [],
  );

  useEffect(() => {
    if (staticData?.bankAccounts) {
      setIsAccountsLoading(true);
      delayedStoreAccountUpdate(
        staticData?.bankAccounts?.filter((acc) => acc.status === Status.ACTIVE),
      );
    }
  }, [delayedStoreAccountUpdate, staticData?.bankAccounts]);

  const onSave = useCallback(async () => {
    if (_textFieldIsInvalid(selectedAccountGroup?.name)) {
      return toast({
        title: 'Invalid group name',
        description: 'Please enter a valid name for the account group.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSaving(true);
    let accountGroupDoc = {
      ...selectedAccountGroup,
    };

    if (mode === InputMode.CREATE) {
      if (isWorkflowEnabled) {
        accountGroupDoc = {
          ...accountGroupDoc,
          status: Status.PENDING_APPROVAL,
        };
      }
      const newAccountGroup = await addDoc(
        collection(db, 'Organizations', profileData.orgId, 'AccountGroups'),
        {
          ...accountGroupDoc,
          createdBy: profileData.uid,
          createdDtTm: serverTimestamp(),
        } as AccountGroup,
      );
      onAccountGroupUpdated?.({ ...accountGroupDoc, id: newAccountGroup.id } as AccountGroup);
      setIsVisible?.(false);
    } else {
      // Update

      const changedFields = _getChangedFields(accountGroup, selectedAccountGroup);
      if (isEmpty(changedFields)) {
        return;
      }
      const updateRequest = {
        ...changedFields,
        requestedBy: profileData.uid,
        requestId: uuidv4(),
        lastUpdatedAt: serverTimestamp(),
      };
      if (isWorkflowEnabled) {
        accountGroupDoc = {
          ...accountGroup,
          updateRequest: updateRequest,
          lastUpdatedBy: profileData.uid,
        };
      }
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'AccountGroups', selectedAccountGroup.id),
        isWorkflowEnabled
          ? { updateRequest: updateRequest }
          : {
              ...omit(accountGroupDoc, ['createdBy', 'createdDtTm']),
            },
      ).catch((e) => {
        toast({
          title: 'Something went wrong while updating the Account group',
          description: 'This could be a permission issue',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
      onAccountGroupUpdated?.(accountGroupDoc);
      const selectedccountsForUpd = allAccounts.filter((account) => account.selected);

      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(db));
      let operationCounter = 0;
      let batchIndex = 0;
      differenceBy(selectedccountsForUpd, groupAccounts, 'id').map((account) => {
        console.warn(account.accountNumber);

        if (account.id && !account.accountGroups?.includes(selectedAccountGroup.id)) {
          const updateRequest = {
            accountGroups: [...(account.accountGroups || []), selectedAccountGroup.id],
            requestedBy: profileData.uid,
            requestId: uuidv4(),
            lastUpdatedAt: serverTimestamp(),
          };
          batchArray[batchIndex].update(
            doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id),
            isWorkflowEnabled
              ? { updateRequest: updateRequest }
              : {
                  accountGroups: arrayUnion(selectedAccountGroup.id),
                  lastUpdatedBy: profileData.uid,
                },
          );
          operationCounter++;
          // await updateDoc(doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id), {
          //   accountGroups: arrayUnion(selectedAccountGroup.id),
          // });
          if (operationCounter === 499) {
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
          }
        }
      });

      differenceBy(groupAccounts, selectedccountsForUpd, 'id').map((account) => {
        if (account.id && account.accountGroups?.includes(selectedAccountGroup.id)) {
          const updateRequest = {
            accountGroups: account.accountGroups?.map((ag) => ag !== selectedAccountGroup.id) || [],
            requestedBy: profileData.uid,
            requestId: uuidv4(),
            lastUpdatedAt: serverTimestamp(),
          };
          batchArray[batchIndex].update(
            doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id),
            isWorkflowEnabled
              ? { updateRequest: updateRequest }
              : {
                  accountGroups: arrayRemove(selectedAccountGroup.id),
                  lastUpdatedBy: profileData.uid,
                },
          );
          operationCounter++;
          // await updateDoc(doc(db, 'Organizations', profileData.orgId, 'BankAccounts', account.id), {
          //   accountGroups: arrayRemove(selectedAccountGroup.id),
          // });
          if (operationCounter === 499) {
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
          }
        }
      });
      const promises = batchArray.map(
        async (batch) =>
          // eslint-disable-next-line no-return-await
          await batch.commit().catch((e) => {
            // console.log(e);
            //errorsExist = true;
            console.warn('error encountered');
            console.warn(e);
            toast({
              title: 'Unable to update the bank accounts related to this group',
              description: 'This could be a permission issue',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }),
      );
      await Promise.all(promises)
        .then(() => {
          console.warn('Completed Updating Accounts');
        })
        .catch((error) => {
          console.warn('Something went wrong');
          console.warn(error);
        });

      // setAccounts(selectedccountsForUpd);
      setIsVisible?.(false);
    }

    toast({
      title: `Account Group ${selectedAccountGroup?.id ? 'Updated' : 'Created'}`,
      description: isWorkflowEnabled
        ? 'Accounts added or removed are pending for approval'
        : 'Accounts related to the group have been updated successfully',
      status: isWorkflowEnabled ? 'warning' : 'success',
      duration: 5000,
      isClosable: true,
    });

    setIsSaving(false);
  }, [
    accountGroups,
    groupAccounts,
    allAccounts,
    db,
    profileData.orgId,
    profileData.uid,
    selectedAccountGroup,
    toast,
  ]);

  const onTextChange = useCallback((field: keyof AccountGroup, value: string) => {
    setSelectedAccountGroup((existingValue) => {
      return { ...existingValue, [field]: value } as AccountGroup;
    });
  }, []);

  return (
    <>
      {isWorkflowEnabled && (
        <ReviewAndApproveModal
          onClose={() => {
            setShowApprovalModal(false);
          }}
          pendingApprovalItem={selectedAccountGroup}
          source={ApprovalWorkflowSource.ACCOUNT_GROUPS}
          id={selectedAccountGroup?.id}
          onApprovalDataLoaded={(approvalData) => {
            setCanApprove(approvalData.canApprove);
          }}
          onApprovalOrRejectonCompleted={(approved) => {
            onAccountGroupUpdated?.(
              omit(
                {
                  ...selectedAccountGroup,
                  status: approved
                    ? Status.ACTIVE
                    : selectedAccountGroup?.updateRequest
                    ? selectedAccountGroup.status
                    : Status.DEACTIVATED,
                },
                ['updateRequest'],
              ) as AccountGroup,
            );
            setIsVisible?.(false);
          }}
          onSomeActionDone={onItemActioned}
          show={showApprovalModal}
        />
      )}
      <View pointerEvents={canEdit && !isReadOnly ? 'auto' : 'none'}>
        <View style={AppStyles.flexRowCenter}>
          <View style={AppStyles.flex1}>
            <CInput
              label="Group Name"
              fieldKey="name"
              fieldValue={selectedAccountGroup.name}
              onTextChange={onTextChange}
              isRequired
              disabled={isReadOnly}
            />
          </View>
          <View style={AppStyles.flex1}>
            <CInput
              label="Description"
              fieldKey="description"
              fieldValue={selectedAccountGroup.description}
              onTextChange={onTextChange}
              isRequired
              width={500}
              disabled={isReadOnly}
            />
          </View>
        </View>
        {mode !== InputMode.APPROVE && (
          <CFlatList
            style={{ maxHeight: Layout.window.height - 200 }}
            emptyMessage="No more accounts to add"
            data={allAccounts}
            isLoading={isAccountsLoading}
            loaderLines={2}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                style={{ marginTop: 5 }}
                onPress={() => {
                  setAllAccounts((currentValue) =>
                    currentValue.map((val, ind) =>
                      ind === index ? Object.assign(val, { selected: !val.selected }) : val,
                    ),
                  );
                }}
              >
                <Card key={item.id}>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <View style={{ flex: 2 }}>
                      <Text>{item.accountNumber}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
                    </View>
                    <View style={AppStyles.flex1}>
                      <FieldDisplay label={'Entity'} value={item.entity} />
                    </View>
                    <View style={AppStyles.flex1}>
                      {item.bank ? (
                        <FieldDisplay label={'Bank'} value={item.bank} />
                      ) : (
                        <FieldDisplay label={'Bank Code'} value={item.BIC} />
                      )}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Text style={{ marginRight: 10 }}>{item.currency}</Text>
                      {item.selected ? (
                        <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
                      ) : (
                        <View style={{ width: 12 }} />
                      )}
                    </View>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        )}
      </View>
      {mode !== InputMode.CREATE && (
        <ActivityHistory recordKey={selectedAccountGroup.name} recordType={LogType.AccountGroup} />
      )}
      <ButtonGroup
        mode={mode}
        onSave={onSave}
        isSaving={isSaving}
        canApprove={canApprove}
        setShowApprovalModal={setShowApprovalModal}
        setIsVisible={setIsVisible}
        onNext={onNext}
      />
    </>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
