/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FontAwesome5 } from '@expo/vector-icons';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../App';
import { UPDATE_CURRENCIES } from '../actions/ActionConstatnts';
import { Currency, EnabeldDisabled, StaticDataStore } from '../commonTypes';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import CInput from '../components/CInput';
import { FilterBar } from '../components/FilterBar';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList, _sort, _textFieldIsInvalid } from '../utils/helper';

export default function CurrenciesScreen({ navigation }: RootTabScreenProps<'CurrenciesScreen'>) {
  const [isLoading, setIsLoading] = useState(false);
  const [isImportCurrencies, setIsImportCurrencies] = useState(false);
  const [currenciesToImport, setCurrenciesToImport] = useState<Currency[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>();
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [isSaving, setIsSaving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filteredCurrencies, setFilteredCurrencies] = useState<Currency[] | undefined>();
  const { db, defaultDb } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredCurrencies(_searchList(staticData.currencies, searchValue));
      } else {
        setFilteredCurrencies(undefined);
      }
    },
    [staticData.currencies],
  );

  const onSave = useCallback(async () => {
    if (!profileData?.orgId) {
      return;
    }
    if (_textFieldIsInvalid(selectedCurrency?.name)) {
      return toast({
        title: 'Invalid Currency Name',
        description: 'Please enter a valid name',
        status: 'error',
      });
    }
    if (_textFieldIsInvalid(selectedCurrency?.iso)) {
      return toast({
        title: 'Invalid Currency Code',
        description: 'Please enter a valid code',
        status: 'error',
      });
    }

    if (selectedIndex !== undefined && selectedCurrency?.iso) {
      // Update existing currency
      if (!staticData.currencies) {
        return;
      }
      const index = staticData.currencies?.findIndex((curr) => curr.iso === selectedCurrency?.iso);
      const updatedCurrencyList = staticData.currencies;
      if (index < 0 || !updatedCurrencyList) {
        return;
      }
      setIsSaving(true);
      updatedCurrencyList[index] = selectedCurrency;

      await setDoc(
        doc(db, 'Organizations', profileData.orgId),
        {
          Currencies: updatedCurrencyList,
        },
        { merge: true },
      ).then(() => {
        dispatch({
          type: UPDATE_CURRENCIES,
          payload: updatedCurrencyList,
        });
      });
    } else if (staticData.currencies?.find((curr) => curr.iso === selectedCurrency?.iso)) {
      setIsSaving(false);
      return toast.show({
        title: 'Currency code already exists',
        variant: 'top-accent',
        description: 'Please enter a different Code or update the existing currency',
      });
    } else {
      // Create new currency
      const updatedCurrencyList = _sort(
        [...(staticData.currencies || []), selectedCurrency],
        'iso',
      );

      await setDoc(
        doc(db, 'Organizations', profileData.orgId),
        {
          Currencies: updatedCurrencyList,
        },
        { merge: true },
      ).then(() => {
        setIsSaving(false);
        dispatch({
          type: UPDATE_CURRENCIES,
          payload: updatedCurrencyList,
        });
      });
    }

    onClose();
    setIsSaving(false);

    toast({
      title: 'Currency Updated',
      status: 'success',
    });
  }, [
    profileData?.orgId,
    selectedCurrency,
    selectedIndex,
    staticData.currencies,
    toast,
    db,
    dispatch,
  ]);

  const onTextChange = useCallback((field: keyof Currency, value: string) => {
    setSelectedCurrency((existingValue) => {
      return { ...existingValue, [field]: value } as Currency;
    });
  }, []);

  const fetchDefaultConfigCurrencies = useCallback(() => {
    setIsLoading(true);
    getDoc(doc(defaultDb, 'DefaultConfig', 'Currencies')).then((currenciesDoc) => {
      if (currenciesDoc.exists()) {
        setCurrenciesToImport(
          currenciesDoc
            .data()
            ?.Currencies.filter(
              (curr: any) =>
                !staticData.currencies?.find((existingCurr) => existingCurr.iso === curr.ISO),
            ),
        );
        console.warn(currenciesDoc.data()?.Currencies);
      }
    });
    setIsLoading(false);
  }, [defaultDb, staticData.currencies]);

  const importSelectedCurrencies = useCallback(async () => {
    if (!profileData?.orgId) {
      return;
    }
    setIsLoading(true);
    const _newCurrencies = currenciesToImport
      .filter((curr) => curr.isSelected)
      .map((currency: any) => {
        return {
          iso: currency.ISO,
          name: currency.label,
          state: EnabeldDisabled.ENABLED,
        };
      });

    const updatedCurrencyList = _sort([...(staticData.currencies || []), ..._newCurrencies], 'iso');

    await setDoc(
      doc(db, 'Organizations', profileData.orgId),
      {
        Currencies: updatedCurrencyList,
      },
      { merge: true },
    ).then(() => {
      dispatch({
        type: UPDATE_CURRENCIES,
        payload: updatedCurrencyList,
      });
    });
    console.warn(updatedCurrencyList);

    setIsLoading(false);
    setIsImportCurrencies(false);
    onClose();
    toast({
      title: 'Currencies Imported',
      status: 'success',
    });
  }, [
    currenciesToImport,
    db,
    dispatch,
    profileData?.orgId,
    staticData.currencies,
    toast,
    isLoading,
  ]);

  const renderFlyOut = useCallback(() => {
    if (isImportCurrencies) {
      return (
        <View style={AppStyles.flex1}>
          <View style={{ height: Layout.window.height - 130 }}>
            <CFlatList
              isLoading={isLoading}
              data={currenciesToImport}
              style={AppStyles.marginBottom}
              keyField="ISO"
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity
                    key={item.ISO}
                    onPress={() => {
                      setCurrenciesToImport((currentValue) => {
                        return currentValue?.map((value) => {
                          if (value.ISO === item.ISO) {
                            return { ...value, isSelected: !value.isSelected };
                          }
                          return value;
                        });
                      });
                    }}
                  >
                    <Card>
                      <View style={AppStyles.flexRowCenterSpaceBetween}>
                        <View style={AppStyles.flexRowCenter}>
                          <FontAwesome5
                            name="check"
                            size={15}
                            color={item.isSelected ? Colors.primary : Colors.grey}
                            style={AppStyles.marginRight}
                          />
                          <Text>{item.ISO}</Text>
                        </View>
                        <Text>{item.label}</Text>
                      </View>
                    </Card>
                  </TouchableOpacity>
                );
              }}
            />
          </View>
          {/* <OldButton
            isFloating
            disabled={!(currenciesToImport?.filter((curr) => curr.isSelected).length > 0)}
            label="Import Currencies"
            onPress={importSelectedCurrencies}
            loading={isLoading}
          /> */}
          <View style={{ flex: 2 }}>
            <Button
              label="Import Currencies"
              onPress={importSelectedCurrencies}
              isLoading={isLoading}
            />
          </View>
        </View>
      );
    }
    if (selectedCurrency) {
      return (
        <>
          <CInput
            label="Currency Code"
            fieldValue={selectedCurrency.iso}
            fieldKey={'iso'}
            onTextChange={onTextChange}
            isRequired
            disabled={selectedIndex !== undefined}
          />
          <CInput
            label="Currency Name"
            fieldValue={selectedCurrency.name}
            fieldKey={'name'}
            onTextChange={onTextChange}
            isRequired
          />
        </>
      );
    }
    return <></>;
  }, [
    currenciesToImport,
    importSelectedCurrencies,
    isImportCurrencies,
    isLoading,
    onTextChange,
    selectedCurrency,
    selectedIndex,
  ]);

  return (
    <View style={AppStyles.container}>
      <Drawer isOpen={isOpen} placement="right" size={'sm'} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {selectedCurrency?.name || (isImportCurrencies ? 'Import Currencies' : 'New Currency')}
          </DrawerHeader>

          <DrawerBody>{renderFlyOut()}</DrawerBody>

          {!isImportCurrencies && (
            <DrawerFooter>
              <Button label="Cancel" variant="Cancel" onPress={onClose} isDisabled={isSaving} />
              <Button label="Save" onPress={onSave} isLoading={isSaving} />
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {staticData.currencies && staticData.currencies.length > 0 ? (
          <FilterBar onSearch={onSearchOrFilter} searchPlaceholder={'Search Name or Code'} />
        ) : (
          <View />
        )}
        <View style={AppStyles.flexRowCenter}>
          <Button
            mt={2}
            variant={'Create'}
            label="Import Currencies"
            onPress={() => {
              fetchDefaultConfigCurrencies();
              setIsImportCurrencies(true);
              onOpen();
            }}
          />
          {/* <MoreMenu>
            <Menu.Item
              onPress={() => {
                fetchDefaultConfigCurrencies();
                setIsImportCurrencies(true);
                onOpen();
              }}
            >
              Import Currencies
            </Menu.Item>
          </MoreMenu> */}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={AppStyles.width60}>
          <CFlatList
            emptyMessage={
              filteredCurrencies === undefined ? 'No Currencies yet' : 'No results found'
            }
            keyField="iso"
            data={_sort(filteredCurrencies || staticData.currencies, 'iso') as Currency[]}
            isLoading={isLoading}
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => {
                  setIsImportCurrencies(false);
                  setSelectedCurrency(item);
                  setSelectedIndex(index);
                  setOpen(true);
                }}
                key={item.iso}
              >
                <Card style={styles.bankAccountCard}>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <Text style={AppStyles.textRowTitle}>{item.iso}</Text>
                    <Text style={AppStyles.textSubTitle}>{item.name}</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
