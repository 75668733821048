import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { ViewStyle } from 'react-native';
import { StaticDataStore, TransactionCategory } from '../commonTypes';
import { View } from './Themed';

export type CategoryInputProps = {
  onSelect: (values: TransactionCategory[]) => void;
  value?: string;
  values?: string;
  isRequired?: boolean;
  isReadOnly?: boolean;
  style?: ViewStyle;
  hideLabel?: boolean;
  width?: number | string;
  isMultiSelect?: boolean;
  includeAll?: boolean;
  label?: string;
};
const CategoryInput: FC<CategoryInputProps> = ({
  onSelect,
  values,
  value,
  isRequired,
  isReadOnly,
  style,
  hideLabel,
  width,
  isMultiSelect,
  includeAll = isMultiSelect,
  label = 'Category',
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  return (
    <View style={style}>
      <FormControl isRequired={isRequired} width={width}>
        <FormLabel fontSize={14} mb={1} hidden={hideLabel}>
          {label}
        </FormLabel>
        <View style={{ alignSelf: 'flex-start' }}>
          <Select
            isReadOnly={isReadOnly}
            isMulti={isMultiSelect}
            onChange={(selected) => {
              if (isMultiSelect) {
                const all = selected?.findIndex((val) => val.value === 'All');
                if ((selected?.length > 1 && all > 0) || (selected?.length === 1 && all === 0)) {
                  return onSelect(['All']);
                }
                return onSelect(
                  selected?.map((val) => val.value)?.filter((val) => val !== 'All') || [],
                );
              }
              console.warn(selected?.value);
              return onSelect([selected?.value]);
            }}
            closeMenuOnSelect={!isMultiSelect}
            value={
              isMultiSelect
                ? values?.map((val) => {
                    return {
                      value: val,
                      label: staticData.categories?.find((ag) => ag.id === val)?.name || val,
                    };
                  })
                : value
                ? [
                    {
                      value,
                      label: staticData.categories?.find((ag) => ag.id === value)?.name || value,
                    },
                  ]
                : []
            }
            name="Categories"
            options={[
              ...(includeAll ? [{ value: 'All', label: 'All' }] : []),
              ...(staticData.categories?.map((ag) => {
                return { value: ag.id, label: ag.name };
              }) || []),
            ]}
            placeholder="Select Category"
            size="sm"
            menuPosition="fixed"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
            }}
            chakraStyles={{
              menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
            }}
            menuPortalTarget={document.body}
          />
        </View>
      </FormControl>
    </View>
  );
};

export default CategoryInput;
