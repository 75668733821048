import React, { FC, useMemo, useState } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import Colors from '../../constants/Colors';
import Layout from '../../constants/Layout';
import AppStyles from '../../constants/Styles';
import { BankAccount, StaticDataStore, Status } from '../../commonTypes';
import CardBadge from '../CardBadge';
import { CFlatList } from '../CFlatList';
import { CModal } from '../CModal';
import { Button, Text, View } from '../Themed';

const BankStatementWidget: FC<{ type?: 'small' | 'normal'; readonly?: boolean }> = ({
  type = 'normal',
  readonly = false,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [open, setOpen] = useState(false);
  const importedStatements = useMemo(() => {
    console.warn(
      staticData.bankAccountPreviousBusinessDays,
      'staticData.bankAccountPreviousBusinessDays',
    );
    return staticData.bankAccounts
      ?.filter((acc) => acc.status !== Status.DEACTIVATED)
      .map((account) =>
        account.id &&
        account.lastStatementValueDate &&
        moment(account.lastStatementValueDate?.toDate()).isSame(
          staticData.bankAccountPreviousBusinessDays?.[account.id],
          'day',
        )
          ? { ...account, isImported: true }
          : { ...account, isImported: false },
      );
  }, [staticData.bankAccountPreviousBusinessDays, staticData.bankAccounts]);

  return (
    <>
      <CModal
        title="Prior Day bank statement import status"
        open={open}
        setOpen={setOpen}
        hideButtons
        width={Layout.window.width * 0.5}
      >
        <CFlatList
          data={
            importedStatements
              ? [
                  ...importedStatements.filter((acc) => acc.isImported),
                  ...importedStatements.filter((acc) => !acc.isImported),
                ]
              : []
          }
          keyExtractor={(item: BankAccount) => item.id || item.accountNumber}
          renderItem={({ item }: { item: BankAccount }) => (
            <View
              style={[
                AppStyles.flexRowCenterSpaceBetween,
                AppStyles.marginTop,
                { width: Layout.window.width / 2.1 },
              ]}
            >
              <View>
                <Text style={AppStyles.textRowTitle}>{item.id}</Text>
                <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
              </View>
              <View style={AppStyles.flexRowCenter}>
                <Text>
                  {item.lastStatementValueDate
                    ? moment(item.lastStatementValueDate?.toDate()).format('DD MMM YYYY')
                    : 'No statements yet'}
                </Text>
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10,
                    marginLeft: 10,
                    marginRight: 25,
                    backgroundColor: item.isImported ? Colors.green : Colors.red,
                  }}
                />
              </View>
            </View>
          )}
        />
      </CModal>
      {type === 'small' ? (
        <Button
          label={`${
            importedStatements?.filter((acc) => acc.isImported)?.length.toString() || '0'
          }/${
            importedStatements?.filter((acc) => !acc.isImported)?.length.toString() || '0'
          } PD Statements`}
          onPress={() => setOpen(true)}
        />
      ) : (
        <CardBadge
          label="Prior Day Statements"
          value={importedStatements?.filter((acc) => acc.isImported)?.length.toString() || '0'}
          onPress={readonly ? undefined : () => setOpen(true)}
          additionalInfo={
            (importedStatements?.filter((acc) => !acc.isImported)?.length.toString() || '0') +
            ' not available yet'
          }
        />
      )}
    </>
  );
};

export default BankStatementWidget;
