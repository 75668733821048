import React, { FC, useContext, useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Card, Center } from '@chakra-ui/react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { Payment, PaymentStatus, StaticDataStore } from '../../commonTypes';
import { AppContext } from '../../App';
import { Text } from '../Themed';
import { TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';

// Register Chart.js plugins
Chart.register(...registerables, ChartDataLabels);

// Payment status colors mapping based on StatusTag colors
const STATUS_COLORS: Record<PaymentStatus, string> = {
  [PaymentStatus.DRAFT]: '#CBD5E0', // gray
  [PaymentStatus.PENDING_APPROVAL]: '#ED8936', // orange
  [PaymentStatus.REJECTED]: '#E53E3E', // red
  [PaymentStatus.APPROVED]: '#48BB78', // green
  [PaymentStatus.RELEASED]: '#ECC94B', // yellow
  [PaymentStatus.SENT]: '#319795', // teal
  [PaymentStatus.RECEIVED]: '#00B5D8', // cyan
  [PaymentStatus.COMPLETED]: '#3182CE', // blue
  [PaymentStatus.CANCELLED]: '#E53E3E', // red
};

const PaymentsByStatusWidget: FC = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const { db } = useContext(AppContext);
  const navigation = useNavigation();

  useEffect(() => {
    if (!profileData?.orgId || !db) return;

    // Set up real-time listener for payments
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'Payments'),
      where('valueDate', '>=', today),
      where('valueDate', '<', tomorrow),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const paymentsList: Payment[] = [];
      querySnapshot.forEach((doc) => {
        paymentsList.push({ ...doc.data(), id: doc.id } as Payment);
      });
      setPayments(paymentsList);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [db, profileData?.orgId]);

  // Process data for the pie chart
  const statusCounts = payments.reduce((acc, payment) => {
    const status = payment.status || PaymentStatus.DRAFT;
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Prepare data for chart
  const labels = Object.keys(statusCounts);
  const data = Object.values(statusCounts);
  const colors = labels.map((status) => STATUS_COLORS[status as PaymentStatus] || '#CBD5E0');

  return (
    <Card p={5} style={{ alignItems: 'center', flex: 1 }}>
      <TouchableOpacity onPress={() => navigation.navigate('PaymentsScreen')}>
        <Text style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }}>
          Payments by Status
        </Text>
      </TouchableOpacity>
      {payments.length === 0 ? (
        <Center flex={1}>
          <Text style={{ fontSize: 14, color: '#718096', textAlign: 'center', marginTop: 20 }}>
            No payments for today
          </Text>
        </Center>
      ) : (
        <Pie
          options={{
            plugins: {
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  padding: 10,
                  usePointStyle: true,
                },
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const label = context.label || '';
                    const value = context.raw as number;
                    const total = data.reduce((a, b) => a + b, 0);
                    const percentage = ((value / total) * 100).toFixed(1);
                    return `${label}: ${value} (${percentage}%)`;
                  },
                },
              },
              datalabels: {
                color: '#FFFFFF',
                font: {
                  weight: 'bold',
                  size: 12,
                },
                formatter: (value: number) => {
                  return value.toString();
                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
          }}
          data={{
            labels,
            datasets: [
              {
                data,
                backgroundColor: colors,
              },
            ],
          }}
        />
      )}
    </Card>
  );
};

export default PaymentsByStatusWidget;
