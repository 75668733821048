import React, { FC, useMemo } from 'react';

import { Chart, registerables } from 'chart.js';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumby';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { Card } from '@chakra-ui/react';
import { StaticDataStore, Status } from '../../commonTypes';
import Colors from '../../constants/Colors';
import { _getFormattedCurrency } from '../../utils/helper';
import { Text } from '../Themed';
import useCurrencies from '../../hooks/useCurrencies';

Chart.register(...registerables);

const BalanceByCurrencyWidget: FC = () => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const { _convertToReportingCurrency } = useCurrencies();
  const reportingCurrency = 'USD';
  const actualPieData = useMemo(() => {
    const currencyGroup = groupBy(
      staticData.bankAccounts?.filter((acc) => acc.status === Status.ACTIVE),
      'currency',
    );

    // console.warn(Object.entries(currencyGroup));
    const formattedData = Object.entries(currencyGroup)
      .sort()
      .map(([bankName, accounts]) => {
        return {
          currency: bankName,
          balance: sumBy(accounts, (account) =>
            _convertToReportingCurrency(account.balance || 0, account.currency),
          ),
          count: accounts?.length,
        };
      });
    // .sort(([, a], [, b]) => b - a)
    // .slice(0, 6)
    // .map((e, index) => ({ name: e[0], population: e[1], color: Colors.chartColors[index] }));

    return formattedData;
  }, [staticData.bankAccounts, _convertToReportingCurrency]);

  return (
    <Card p={5} style={{ alignItems: 'center', flex: 1 }}>
      <Text style={{ fontSize: 12, fontWeight: 'bold' }}>Balance by Currencies</Text>
      <Bar
        options={{
          plugins: {
            legend: { display: false },
            subtitle: {
              display: true,
              text: `K ${reportingCurrency}`,
            },
            datalabels: {
              color: Colors.white, // White text for contrast
              borderColor: Colors.grey,
              anchor: 'center',
              align: 'center',
              formatter: (value, context) => {
                return _getFormattedCurrency(value as number) + ' K';
              },
              font: {
                weight: 'bold',
                size: 12,
              },
            },
          },
          scales: {
            y: {
              ticks: {
                // Create scientific notation labels
                callback: function (value, index, values) {
                  return _getFormattedCurrency(value as number) + ' K';
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
          maintainAspectRatio: false,
          responsive: true,
        }}
        title="Balance by currency"
        data={{
          labels: [...actualPieData.map((e) => e.currency)],
          datasets: [
            {
              data: [...actualPieData.map((e) => e.balance / 1000)],
              backgroundColor: actualPieData.map(
                (_, index) => Colors.pieChartColors[index % Colors.pieChartColors.length],
              ),
            },
          ],
        }}
      />
    </Card>
  );
};

export default BalanceByCurrencyWidget;
