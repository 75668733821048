import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import {
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { Skeleton, VStack } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { AppContext } from '../App';
import { Action, ActivityHistory, LogType, StaticDataStore } from '../commonTypes';
import Colors from '../constants/Colors';
import { DateFormat } from '../constants/Constants';
import AppStyles from '../constants/Styles';
import useUsers from '../hooks/useUsers';
import ActivityHistoryChangesModal from './ActivityHistoryChangesModal';
import { CFlatList } from './CFlatList';
import { Text, View } from './Themed';
import { useNavigation } from '@react-navigation/native';
import { set } from 'lodash';

export type ActivityHistoryProps = {
  recordType: LogType;
  recordKey: string;
  createdAt?: Timestamp;
  createdBy?: string;
};
const CActivityHistory: FC<ActivityHistoryProps> = ({
  recordType,
  recordKey,
  createdAt,
  createdBy,
}) => {
  const { staticData, profileData } = useSelector((store: StaticDataStore) => store);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const [activityHistory, setActivityHistory] = useState<ActivityHistory[]>([]);
  const { _getUsersFromUid } = useUsers();
  const navigation = useNavigation();

  const [selectedItem, setSelectedItem] = useState<ActivityHistory>();
  const { db } = useContext(AppContext);
  const fetchActivityHistory = useCallback(
    async (
      _recordType: LogType,
      _recordKey: string,
      _lastVisible?: QueryDocumentSnapshot<DocumentData> | null,
    ) => {
      if (!profileData?.orgId) {
        return;
      }
      console.log('Fetching activity history');
      let q = query(
        collection(db, 'Organizations', profileData.orgId, 'Logs'),
        where('type', '==', _recordType),
        where('key', '==', _recordKey),
        orderBy('timestamp', 'desc'),
        limit(4),
      );
      q = _lastVisible ? query(q, startAfter(_lastVisible)) : q;
      const querySnapshot = await getDocs(q);
      const _activityHistory: ActivityHistory[] = [];
      querySnapshot.forEach((doc) => {
        _activityHistory.push({ ...doc.data(), id: doc.id } as ActivityHistory);
      });
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      if (_lastVisible) {
        setActivityHistory((cv) => [...cv, ..._activityHistory]);
      } else {
        setActivityHistory(_activityHistory);
      }

      setIsLoading(false);
    },
    [db, profileData?.orgId],
  );

  useEffect(() => {
    if (recordKey && recordType) {
      setIsLoading(true);
      setLastVisible(null);
      fetchActivityHistory(recordType, recordKey);
    } else {
      setLastVisible(null);
      setActivityHistory([]);
    }
  }, [fetchActivityHistory, recordKey, recordType]);

  const getColorFromAction = useCallback((action: Action) => {
    switch (action) {
      case Action.CREATE:
        return Colors.green;
      case Action.UPDATE:
        return Colors.orange;
      case Action.DELETE:
      case Action.REJECT:
        return Colors.red;
      case Action.APPROVE:
        return Colors.excel_green;
      default:
        return Colors.grey;
    }
  }, []);

  return (
    <View>
      {selectedItem && (
        <ActivityHistoryChangesModal
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
      <Text style={AppStyles.textFormSectionHeader}>Activity History</Text>
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        <CFlatList
          hideEmptyImage
          data={[
            ...activityHistory,
            ...(createdAt
              ? [
                  {
                    action: Action.CREATE,
                    timestamp: moment(createdAt?.seconds ? createdAt?.seconds * 1000 : createdAt),
                    user: createdBy,
                  },
                ]
              : []),
          ]}
          isLoadingMore={isLoadingMore}
          isLoading={isLoading}
          contentLoader={
            <VStack
              w="40%"
              marginTop={2}
              marginLeft={2}
              padding={2}
              borderWidth="1"
              overflow="hidden"
              rounded="md"
              _dark={{
                borderColor: 'coolGray.500',
              }}
              _light={{
                borderColor: 'coolGray.200',
              }}
            >
              <Skeleton.Text px="1" lines={1} />
            </VStack>
          }
          renderItem={({ item, index }: { item: ActivityHistory; index: number }) => (
            <View style={[AppStyles.flexRowCenter, AppStyles.marginLeft25]}>
              <View style={styles.verticalLine}>
                <Text style={AppStyles.textSecondary}>
                  {moment(item.timestamp.toDate()).format(DateFormat.DEFAULT)}
                </Text>
              </View>
              <View style={AppStyles.flexRowCenter}>
                <View
                  style={[
                    styles.roundIndicator,
                    { backgroundColor: getColorFromAction(item.action) },
                  ]}
                />
                <TouchableOpacity
                  onPress={() => {
                    setSelectedItem(item);
                  }}
                >
                  <Text>{`${
                    item.action.charAt(item.action.length - 1) === 'e'
                      ? item.action
                      : item.action + 'e'
                  }d by ${_getUsersFromUid(item.user)}`}</Text>
                  <Text style={AppStyles.textSecondary}>{JSON.stringify(item.comments)}</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
          emptyMessage="No activity history found"
          emptyStyle={{ alignItems: 'flex-start' }}
          ListFooterComponent={() => {
            if (activityHistory.length < 4 || !lastVisible) return null;
            return (
              <TouchableOpacity
                style={{
                  marginLeft: 150,
                  backgroundColor: Colors.secondary,
                  paddingHorizontal: 5,
                  borderRadius: 5,
                  alignSelf: 'flex-start',
                }}
                onPress={() => {
                  setIsLoadingMore(true);
                  fetchActivityHistory(recordType, recordKey, lastVisible);
                }}
              >
                <Text style={{ color: Colors.white }}>Load More</Text>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    </View>
  );
};

export default CActivityHistory;

const styles = StyleSheet.create({
  verticalLine: {
    width: 100,
    alignItems: 'flex-end',
    paddingRight: 10,
    borderRightWidth: 3,
    borderRightColor: Colors.lightest_grey,
    paddingVertical: 10,
  },
  roundIndicator: {
    width: 10,
    height: 10,
    borderRadius: 10,
    marginRight: 5,
    marginLeft: -7,
  },
});
