/* eslint-disable react-native/no-inline-styles */
/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  increment,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import moment, { Moment } from 'moment';
import { ScrollView } from 'native-base';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  BalSource,
  CashPositionSummary,
  CashPositionView,
  DateName,
  Holiday,
  InputMode,
  Payment,
  StatementTransaction,
  StaticDataStore,
  Visibility,
} from '../commonTypes';
import { AccountStatementTransactionItem } from '../components/AccountStatementTransactionItem';
import EditCashPosition from '../components/CashPosition/EditCashPosition';
import { CFlatList } from '../components/CFlatList';
import { CModal } from '../components/CModal';
import { CTable } from '../components/CTable';
import { DateInput } from '../components/DateInput';
import { FilterBar } from '../components/FilterBar';
import { SelectPresetInput } from '../components/SelectPresetInput';
import { Button, CTA, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import {
  _filterListByAccess,
  _filterToWhereClause,
  _getClosingAvailableBalance,
  _getCodeDescr,
  _getDateFromDateName,
  _getFormattedCurrency,
  _getOpeningLedgerBalance,
} from '../utils/helper';

// eslint-disable-next-line import/order
import { BellIcon } from '@chakra-ui/icons';
import { Checkbox, Tooltip } from '@chakra-ui/react';
import { CellClickedEvent, ColDef, RowClassParams } from 'ag-grid-community';
import { AppContext } from '../App';
import CategoryInput from '../components/CategoryInput';
import PaymentListItem from '../components/Payments/PaymentListItem';
import { RootTabScreenProps } from '../types';
import { StatementTransactionItem } from '../components/StatementTransactionItem';
import BankStatementDetails from '../components/BankStatements/BankStatementDetails';

export default function CashPositionScreen({
  navigation,
}: RootTabScreenProps<'CashPositionScreen'>) {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isReApplying, setIsReApplying] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [openingBalance, setOpeningBalance] = useState<any>({});
  const [showForecasts, setShowForecasts] = useState(false);
  const [showIntraDay, setShowIntraday] = useState(false);
  const [prevDayStmtClosingBalances, setPrevDayStmtClosingBalances] = useState<
    StatementTransaction[]
  >([]);

  const [currentDayStmtOpeningBalances, setCurrentDayStmtOpeningBalances] = useState<
    StatementTransaction[]
  >([]);
  const [openingBalances, setOpeningBalances] = useState<any[]>([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [isLoadingPayments, setIsLoadingPayments] = useState(false);
  const [categoryToReassign, setCategoryToReassign] = useState<string>();
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([{ field: 'category', filter: true }]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      cellStyle: { fontSize: '12px' },
    }),
    [],
  );
  const [viewConfigList, setViewConfigList] = useState<CashPositionView[]>([]);
  const [viewConfig, setViewConfig] = useState<CashPositionView>();
  const [selectedBankStatement, setSelectedBankStatement] = useState<StatementTransaction | null>(
    null,
  );
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  //   name: "Nandha's View",
  //   description: 'This is a demo view',
  //   id: '2IlJIY127WtuyOo9alYY',
  //   visibility: Visibility.Private,
  //   rowConfig: [
  //     {
  //       category: 'Account Receivables',
  //       categoryId: 'EwBkluBSUWzz2238p6mg',
  //     },
  //     {
  //       category: 'Wires & ACH',
  //       categoryId: 'jC2f5jMkwg2ehLNpiNae',
  //     },
  //     {
  //       category: 'ZBA Debits',
  //       categoryId: 'rRnSbMnavWJta3K8XtaL',
  //     },
  //     {
  //       category: 'Unmapped',
  //       categoryId: 'Unmapped',
  //     },
  //   ],
  // });

  const [transactions, setTransactions] = useState<StatementTransaction[]>();
  const [payments, setPayments] = useState<Payment[]>([]);
  const [bankBalances, setBankBalances] = useState<StatementTransaction[]>();

  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const [selectedDate, setSelectedDate] = useState<Moment | undefined>();
  // moment('20230710', 'YYYYMMDD'),
  // moment(),

  const [showAlert, setShowAlert] = useState(false);
  const [cashPositionSummaryList, setCashPositionSummaryList] = useState<CashPositionSummary[]>([]);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [createEnabled, setCreateEnabled] = useState(false);
  const [previousBusinessDays, setPreviousBusinessDays] = useState<{ [key: string]: Date }>();
  const [selectedKeys, setSelectedKeys] = useState<{
    category: string;
    categoryName: string;
    currency: string | null;
    accountId?: string | null;
  }>();

  const { db } = useContext(AppContext);
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.CashPositionScreen?.edit || false;

  const filteredAccounts = useMemo(() => {
    // Accounts as filtered by the account group config of the view
    if (staticData?.bankAccounts) {
      return (
        staticData.bankAccounts
          .filter((acc) => {
            console.warn(
              'CPS: Debug',
              viewConfig?.accountGroups && viewConfig?.accountGroups?.length > 0,
            );
            if (viewConfig?.accountGroups && viewConfig?.accountGroups?.length > 0) {
              return acc.accountGroups?.some((ag) => viewConfig?.accountGroups?.includes(ag));
            } else {
              return true;
            }
          })
          .map((acc) => acc.id) || []
      );
    }
    return [];
  }, [staticData.bankAccounts, viewConfig?.accountGroups]);

  useEffect(() => {
    // Get previous business Day for each account
    //  If current day, get it from the store since it is calculated on login
    // For other days, calculate based on Non business days and Holidays
    if (selectedDate && !moment().isSame(selectedDate, 'day')) {
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'Holidays'),
        where('date', '>=', moment(selectedDate).subtract(7, 'days').toDate()),
        where('date', '<=', moment(selectedDate).add(1, 'days').toDate()),
        orderBy('date'),
      );

      const _holidays: Holiday[] = [];
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((holidayDoc) => {
          _holidays.push({
            ...holidayDoc.data(),
            id: holidayDoc.id,
            date: holidayDoc.data().date.toDate(),
          } as Holiday);
        });
        const updatedAccounts = staticData.bankAccounts
          ?.filter((acc) => {
            if (viewConfig?.accountGroups && viewConfig?.accountGroups?.length > 0) {
              return acc.accountGroups?.some((ag) => viewConfig?.accountGroups?.includes(ag));
            } else {
              return true;
            }
          })
          .map((account) => {
            let previousBusinessDay = moment(selectedDate).subtract(1, 'days').toDate();
            const accountEntityCountry = staticData.entities?.find(
              (entity) => entity.name === account.entity,
            )?.address?.country;
            const countryNonBusinessDays = staticData.countries?.find(
              (country) => country.code === accountEntityCountry,
            )?.nonBusinessDays || [0, 6];

            while (
              countryNonBusinessDays?.includes(moment(previousBusinessDay).day()) ||
              _holidays.find(
                (holiday) =>
                  ((accountEntityCountry && holiday.countries?.includes(accountEntityCountry)) ||
                    holiday.currencies?.includes(account.currency) ||
                    (!holiday.countries && !holiday.currencies)) &&
                  moment(holiday.date).isSame(previousBusinessDay, 'day'),
              )
            ) {
              previousBusinessDay = moment(previousBusinessDay).subtract(1, 'days').toDate();
            }

            return { ...account, previousBusinessDay };
          });

        setPreviousBusinessDays(
          updatedAccounts?.reduce(
            (obj, item) => Object.assign(obj, { [item.id]: item.previousBusinessDay }),
            {},
          ),
        );
      });
    } else {
      setPreviousBusinessDays(staticData.bankAccountPreviousBusinessDays);
    }
  }, [
    db,
    profileData?.orgId,
    selectedDate,
    staticData.bankAccountPreviousBusinessDays,
    staticData.bankAccounts,
    staticData.countries,
    staticData.entities,
    viewConfig?.accountGroups,
  ]);

  const filteredCurrencies = useMemo(() => {
    // Currencies as filtered by the account group config of the view's accounts

    if (staticData?.bankAccounts) {
      return uniq(
        staticData.bankAccounts
          .filter((acc) => {
            if (viewConfig?.accountGroups && viewConfig?.accountGroups?.length > 0) {
              return acc.accountGroups?.some((ag) => viewConfig?.accountGroups?.includes(ag));
            } else {
              return true;
            }
          })
          .flatMap((acc) => [
            acc.currency,
            ...(showForecasts ? [`${acc.currency} [F]`] : []),
            ...(showIntraDay ? [`${acc.currency} [I]`] : []),
          ]),
      );
    }
    return [];
  }, [staticData.bankAccounts, viewConfig?.accountGroups, showForecasts, showIntraDay]);

  useEffect(() => {
    const viewQuery = query(collection(db, 'Organizations', profileData.orgId, 'CashPositionView'));
    getDocs(viewQuery).then((querySnapshot) => {
      if (querySnapshot.empty) {
        setViewConfig({
          name: 'Default View',
          description: 'This is a default view',
          accountGroups: staticData?.accountGroups?.map((ag) => ag.id),
          visibility: Visibility.Private,
          defaultDate: DateName.TODAY,
        });
      } else {
        const _views: CashPositionView[] = [];
        querySnapshot.forEach((doc) => {
          _views.push({
            ...doc.data(),
            id: doc.id,
          } as CashPositionView);
        });
        const filteredViews = _filterListByAccess(profileData.uid, profileData.userGroups, _views);
        setViewConfigList(filteredViews);
        try {
          AsyncStorage.getItem('defaultView').then((value) => {
            if (value !== null) {
              const defaultView = _views.find((v) => v.id === value);
              return setViewConfig(defaultView);
            }
            setViewConfig(_views[0]);
          });
        } catch (e) {
          // error reading value
          setViewConfig(_views[0]);
        }
      }
    });
  }, [db, profileData.orgId, profileData.uid, profileData.userGroups, staticData?.accountGroups]);

  useEffect(() => {
    if (viewConfig?.defaultDate) {
      setSelectedDate(moment('20230710', 'YYYYMMDD'));
      setSelectedDate(_getDateFromDateName(viewConfig?.defaultDate));
      // setSelectedDate(moment('20240123', 'YYYYMMDD'));
    }
    setShowForecasts(viewConfig?.showForecasts || false);
    setShowIntraday(viewConfig?.showIntraday || false);
  }, [viewConfig]);

  useEffect(() => {
    // Get Previous Day closing balances from Statements
    if (!selectedDate) {
      return;
    }

    const valueDate = selectedDate.toDate();
    console.warn('CPS: Fetching Balance : ', valueDate);
    const prevDayQuery = query(
      collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
      where('isBalance', '==', true),
      where('valueDate', '<=', valueDate),
      where('valueDate', '>=', moment(valueDate).add(-4, 'days').toDate()),
    );

    const unsubscribe = onSnapshot(prevDayQuery, (querySnapshot) => {
      if (querySnapshot.empty) {
        setPrevDayStmtClosingBalances([]);
      }
      let _bankStatements: StatementTransaction[] = [];
      console.warn('CPS: querySnapshot.size', querySnapshot.docs.length);
      console.warn('CPS: filteredAccounts ', filteredAccounts.length);
      querySnapshot.forEach((doc) => {
        if (filteredAccounts.includes(doc.data().accountId)) {
          _bankStatements.push({
            ...doc.data(),
            id: doc.id,
            isSelected: false,
          } as StatementTransaction & { isSelected: boolean });
        }
      });

      _bankStatements = _bankStatements.filter((item) => {
        return moment(item.valueDate.toDate()).isSame(
          previousBusinessDays?.[item.accountId],
          'day',
        );
      });

      console.warn('CPS: previousBusinessDays', previousBusinessDays);

      _bankStatements = uniqBy(_bankStatements, 'accountId');
      setPrevDayStmtClosingBalances(_bankStatements);
      // const _openingBalance: any = { category: 'Opening Balance' };
      // Object.entries(groupBy(_bankStatements, 'currency')).map(([key, value]: [string, any[]]) => {
      //   _openingBalance[key] = value.reduce(
      //     (partialSum, a) => partialSum + _getOpeningAvailableBalance(a.balances),
      //     0,
      //   );
      // });

      return () => unsubscribe();
    });
  }, [db, filteredAccounts, profileData.orgId, selectedDate, previousBusinessDays]);

  useEffect(() => {
    // Get Current Day opening balances from Statements
    if (!selectedDate) {
      return;
    }

    console.warn('CPC: Fetching Current day opening Balance');

    const valueDate = selectedDate.toDate();
    const prevDayQuery = query(
      collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
      where('isBalance', '==', true),
      where('valueDate', '>', valueDate),
      where('valueDate', '<', moment(valueDate).add(1, 'days').toDate()),
    );

    const unsubscribe = onSnapshot(prevDayQuery, (querySnapshot) => {
      if (querySnapshot.empty) {
        setCurrentDayStmtOpeningBalances([]);
      }
      let _bankStatements: StatementTransaction[] = [];
      querySnapshot.forEach((doc) => {
        if (filteredAccounts.includes(doc.data().accountId)) {
          _bankStatements.push({
            ...doc.data(),
            id: doc.id,
            isSelected: false,
            isCurrentDayBalance: true,
          } as StatementTransaction & { isSelected: boolean; isCurrentDayBalance: boolean });
        }
      });

      _bankStatements = uniqBy(_bankStatements, 'accountId');
      // console.warn('_bankStatements', _bankStatements);
      setCurrentDayStmtOpeningBalances(_bankStatements);

      return () => unsubscribe();
    });
  }, [db, filteredAccounts, profileData.orgId, selectedDate]);

  const fetchPayments = useCallback(
    async (category: string, currency: string | null, accountId?: string | null) => {
      // Get payments for the value date
      console.warn('fetching payments');
      if (isLoadingPayments) {
        return;
      }
      setIsLoadingPayments(true);

      const constraints = [];
      if (accountId || filter.accountId) {
        constraints.push(where('fromAccountDetails.id', '==', accountId || filter.accountId));
      }
      const orderByValues = [];
      orderByValues.push(orderBy('valueDate'));
      if (!filter.accountId && !accountId) {
        orderByValues.push(orderBy('fromAccountDetails.id'));
      }
      orderByValues.push(orderBy('paymentAmount'));
      if (currency) {
        constraints.push(where('paymentCurrency', '==', currency));
      }
      const valueDate = selectedDate.toDate();
      const paymentsQuery = query(
        collection(db, 'Organizations', profileData?.orgId, 'Payments'),
        where('valueDate', '>=', valueDate),
        where('valueDate', '<', moment(valueDate).add(1, 'days').toDate()),
        where('category', '==', category),
        ...constraints,
        ...orderByValues,
        limit(10),
      );
      getDocs(paymentsQuery).then((querySnapshot) => {
        console.warn('CPS: Payments', valueDate, querySnapshot.docs.length);
        if (querySnapshot.empty) {
          setPayments([]);
          setLastVisible(null);
        } else {
          const _payments: Payment[] = [];
          querySnapshot.docs.map((doc) => {
            _payments.push(doc.data() as Payment);
          });
          // console.warn('_cashPositionSummaryList', _cashPositionSummaryList);
          setPayments(_payments);
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setIsLoadingPayments(false);
      });
    },
    [db, profileData?.orgId, selectedDate, showForecasts, filter, isLoadingPayments],
  );

  useEffect(() => {
    if (!selectedDate) {
      return;
    }
    console.warn('CPS: prevDayStmtClosingBalances', prevDayStmtClosingBalances);
    const combinedBalances = prevDayStmtClosingBalances.concat(
      currentDayStmtOpeningBalances.filter((stmt) => {
        return !prevDayStmtClosingBalances.find(
          (prevStmt) => prevStmt.accountId === stmt.accountId,
        );
      }),
    );
    //console.warn('combinedBalances', combinedBalances);
    const _closingBalance: any = { category: 'Opening Balance', categoryId: 'OB' }; // Named Opening balance as it is prev day closing
    Object.entries(groupBy(combinedBalances, 'currency')).map(([key, value]: [string, any[]]) => {
      _closingBalance[key] = value.reduce(
        (partialSum, a) =>
          partialSum +
          (a.isCurrentDayBalance
            ? _getOpeningLedgerBalance(a.balances)
            : _getClosingAvailableBalance(a.balances)),
        0,
      );
    });
    const accountsWithPrevDayStatements: string[] = [];
    const _prevDayClosingBalances: any[] = combinedBalances.flatMap((stmt) => {
      accountsWithPrevDayStatements.push(stmt.accountId);
      const bal = stmt.isCurrentDayBalance
        ? _getOpeningLedgerBalance(stmt.balances)
        : _getClosingAvailableBalance(stmt.balances);
      return {
        category: 'Opening Balance',
        categoryId: 'OB',
        accountId: stmt.accountId,
        currency: stmt.currency,
        [stmt.currency]: bal,
        ...(showForecasts && { [`${stmt.currency} [F]`]: bal }),
        ...(showIntraDay && { [`${stmt.currency} [I]`]: bal }),
        balSource: stmt.isCurrentDayBalance ? BalSource.CDOLB : BalSource.PDCAB,
      };
    });
    _prevDayClosingBalances.push(
      ...filteredAccounts
        ?.filter((acc: string) => !accountsWithPrevDayStatements.includes(acc))
        .map((acc) => {
          console.warn('CPC: prevDayClosingBalances', 'Test');
          const curr = staticData.bankAccounts?.find((ba) => ba.id === acc)?.currency || '???';
          return {
            category: 'Opening Balance',
            categoryId: 'OB',
            accountId: acc,
            currency: curr,
            [curr]: 0,
            ...(showForecasts && { [`${curr} [F]`]: 0 }),
            ...(showIntraDay && { [`${curr} [I]`]: 0 }),
            balSource: BalSource.MSSNG,
          };
        }),
    );
    console.warn('CPC: prevDayClosingBalances', _prevDayClosingBalances);

    setOpeningBalance(_closingBalance); //Prev day closing balance
    setOpeningBalances(_prevDayClosingBalances);
    setBankBalances(combinedBalances);
  }, [
    db,
    profileData.orgId,
    selectedDate,
    prevDayStmtClosingBalances,
    currentDayStmtOpeningBalances,
    filteredAccounts,
    showForecasts,
    showIntraDay,
  ]);

  useEffect(() => {
    if (!selectedDate) {
      return;
    }
    const valueDate = selectedDate.toDate();
    console.warn('CPC: fetching Summary for', valueDate);
    const q = query(
      collection(db, 'Organizations', profileData.orgId, 'CashPositionSummary'),
      where('date', '>', valueDate),
      where('date', '<', moment(valueDate).add(1, 'days').toDate()),
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.warn('CPS: setCashPositionSummaryList1', querySnapshot.docs.length);
      if (querySnapshot.empty) {
        setCashPositionSummaryList([]);
      } else {
        const _cashPositionSummaryList: CashPositionSummary[] = [];
        querySnapshot.docs.map((doc) => {
          _cashPositionSummaryList.push(doc.data() as CashPositionSummary);
        });
        // console.warn('_cashPositionSummaryList', _cashPositionSummaryList);
        setCashPositionSummaryList(_cashPositionSummaryList);
      }
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, [db, profileData.orgId, selectedDate]);

  const cellClickedListener = useCallback(
    (event: CellClickedEvent<any>) => {
      if (event.value === undefined) {
        return;
      }

      console.warn(event);
      console.log(
        'cellClicked',
        event.colDef.field,
        ' ',
        event.data?.categoryId as string,
        event.value,
      );
      if (event.colDef.field === 'category' && !event.allCurrencies) {
        return;
      }

      //Closing balance is calculated and hence no Details needed
      if (event.node.key === 'Closing Balance') {
        return;
      }

      const isAllCurrencies = event.colDef.field === 'category' && event.allCurrencies;
      const currency =
        isAllCurrencies || event.colDef.field === 'accountId' ? null : event.colDef.field;
      // console.warn(categories);
      if (event.data) {
        setSelectedKeys({
          category: event.data?.categoryId as string,
          categoryName: event.data?.category as string,
          currency: currency || null,
          accountId: event.data?.accountId,
        });
      } else {
        setSelectedKeys({
          category:
            event.node.key === 'Opening Balance'
              ? 'OB'
              : event.node.key === 'Unmapped'
              ? 'Unmapped'
              : (staticData.categories?.find((ctg) => ctg.name === event.node.key)?.id as string),
          categoryName: event.node.key as string,
          currency: currency || null,
          accountId: null,
        });
      }

      setFilter({});
      setLastVisible(undefined);
      // fetchTransactions(tableData[event.rowIndex]?.categoryId, currency);
      setIsDetailOpen(true);
    },
    [staticData.categories],
  );

  const renderBalanceSource = useCallback((balSource: BalSource) => {
    if (balSource === BalSource.CDOLB) {
      return (
        <Tooltip
          textAlign={'center'}
          label={
            'Previous closing balance is not found. Falling back to the current day opening balance'
          }
          fontSize="small"
          placement="top"
        >
          <BellIcon ml={1} mb={1} fontSize={18} color={Colors.warning} />
        </Tooltip>
      );
    }

    if (balSource === BalSource.MSSNG) {
      return (
        <Tooltip
          textAlign={'center'}
          label={'Missing statement. Did not receive a statement from the bank for this account'}
          fontSize="small"
          placement="top"
        >
          <BellIcon ml={1} mb={1} fontSize={18} color={Colors.red} />
        </Tooltip>
      );
    }
    // if (balSource === BalSource.PDCAB) {
    //   return <Entypo name="info-with-circle" size={18} color={Colors.info} />;
    // }
    return null;
  }, []);

  useEffect(() => {
    // const currencyList = [
    //   ...new Set([
    //     ...uniqBy(cashPositionSummaryList, 'currency').map((data) => data.currency),
    //     ...Object.keys(openingBalance).slice(2),
    //   ]),
    const balanceCellRenderer = (props: any) => {
      // console.warn('xxx', props);
      if (props.node.data?.balSource && props.valueFormatted !== '') {
        return (
          <View style={[AppStyles.flexRowCenter, { justifyContent: 'flex-start' }]}>
            <span>{props.valueFormatted}</span>
            <View style={{ marginRight: 5 }}>
              {renderBalanceSource(props.node.data?.balSource)}
            </View>
          </View>
        );
      }
      // If one of the Childred do not have PDCAB, then show CDOLB warning icon
      if (props.node.group && props.node.key === 'Opening Balance') {
        // console.warn('yyy', props);
      }
      if (
        props.node.group &&
        props.node.key === 'Opening Balance' &&
        props.node.allLeafChildren.filter(
          (child: any) =>
            child?.data?.balSource !== BalSource.PDCAB &&
            child?.data?.currency === props.colDef.field,
        ).length > 0
      ) {
        return (
          <View style={[AppStyles.flexRowCenter]}>
            <View style={{ marginRight: 5 }}>{renderBalanceSource(BalSource.CDOLB)}</View>
            <span>{props.valueFormatted}</span>
          </View>
        );
      }
      return <span>{props.valueFormatted}</span>;
    };
    // ]

    const currencyList = filteredCurrencies.sort();
    // console.warn(currencyList);
    const _colDefs: ColDef[] = [
      {
        field: 'category',
        filter: true,
        pinned: 'left',
        rowGroup: true,
        hide: true,
        resizable: true,
      },
      {
        field: 'accountId',
        headerName: 'Account',
        filter: true,
      },
      ...new Set(
        ...[
          currencyList.map((currency) => {
            return {
              field: currency,
              filter: false,
              aggFunc: 'sum',
              headerName: currency,
              valueFormatter: (params) => _getFormattedCurrency(params.value),
              cellRenderer: balanceCellRenderer,
            } as ColDef;
          }),
        ],
      ),
    ];
    // console.warn('colDefs', _colDefs.length);
    setColumnDefs(_colDefs);
  }, [cashPositionSummaryList, filteredCurrencies, openingBalance, renderBalanceSource]);

  const orderTableDataByConfig = useCallback(
    (tableDataToSort: any[], rowConfig?: any[], openBal?: any[], closingBal: any[] = []) => {
      console.warn('CPS: orderTableDataByConfig');
      const _tableData = tableDataToSort;
      // console.warn('orderTableDataByConfig');
      console.warn(rowConfig);

      if ((rowConfig || []).length > 0) {
        const sortArray = rowConfig?.map((a) => a?.categoryId) || [];
        _tableData.sort(
          (a, b) => sortArray.indexOf(a?.categoryId) - sortArray.indexOf(b?.categoryId),
        );
        // console.warn(_tableData);
        return [...(openBal || []), ..._tableData, ...closingBal];
      }
      return [...(openBal || []), ..._tableData, ...closingBal];
    },
    [],
  );

  const flattenedData: any[] = useMemo(() => {
    // if (staticData.categories) {
    console.warn('CPS: cashPositionSummaryList', cashPositionSummaryList.length);
    const joinedData = cashPositionSummaryList.map((cps) => {
      return {
        ...cps,
        categoryName: staticData.categories?.find((c) => c.id === cps.category)?.name || 'Unmapped',
      };
    });

    console.warn('CPS: joinedData', joinedData);

    const _flattenedData: any[] = [];
    Object.entries(groupBy(joinedData, 'categoryName')).map(
      ([categoryName, cpSummaryList]: [string, CashPositionSummary[]]) => {
        cpSummaryList.map((cpSummary: CashPositionSummary) => {
          if (cpSummary.details) {
            Object.entries(cpSummary.details).map(([accountId, accountSummary]: [string, any]) => {
              if (
                filteredAccounts.includes(accountId) &&
                (accountSummary.statementAmount ||
                  accountSummary.paymentAmount ||
                  accountSummary.intradayStatementAmount) // Exclude 0 amounts
              ) {
                _flattenedData.push({
                  category: categoryName,
                  categoryId: cpSummary.category,
                  accountId,
                  currency: cpSummary.currency,
                  [cpSummary.currency]: accountSummary.statementAmount,
                  [`${cpSummary.currency} [F]`]: accountSummary.paymentAmount,
                  [`${cpSummary.currency} [I]`]: accountSummary.intradayStatementAmount,
                });
              }
            });
          } else {
            console.error(cpSummary);
          }
        });
      },
    );
    //console.warn(flattenedData);

    return _flattenedData;
    // }
    // return [];
  }, [cashPositionSummaryList, filteredAccounts, staticData.categories]);

  const tableData = useMemo(() => {
    // const accountList = [
    //   ...new Set(
    //     uniqBy([...openingBalances, ...flattenedData], 'accountId').map((acc) => {
    //       return { accountId: acc.accountId, currency: acc.currency };
    //     }),
    //   ),
    // ];

    const accountList = filteredAccounts.map((account) => {
      return {
        accountId: account,
        currency: staticData.bankAccounts?.find((ba) => ba.id === account)?.currency,
      };
    });

    const _closingBalances: any[] = accountList.map(({ accountId, currency }) => {
      console.warn(
        'CPS: Closing Balances',
        accountId,
        currency,
        openingBalances.find((ob) => ob.accountId === accountId)?.[currency],
        openingBalances,
      );
      return {
        category: 'Closing Balance',
        categoryId: 'CB',
        accountId,
        [currency]: flattenedData
          .filter((trans) => trans.accountId === accountId)
          .reduce(
            (partialSum, accountTrans) => partialSum + (accountTrans?.[currency] || 0),
            openingBalances.find((ob) => ob.accountId === accountId)?.[currency] || 0,
          ),
        [`${currency} [F]`]: flattenedData
          .filter((trans) => trans.accountId === accountId)
          .reduce(
            (partialSum, accountTrans) => partialSum + (accountTrans?.[`${currency} [F]`] || 0),
            openingBalances.find((ob) => ob.accountId === accountId)?.[`${currency} [F]`] || 0,
          ),
        [`${currency} [I]`]: flattenedData
          .filter((trans) => trans.accountId === accountId)
          .reduce(
            (partialSum, accountTrans) => partialSum + (accountTrans?.[`${currency} [I]`] || 0),
            openingBalances.find((ob) => ob.accountId === accountId)?.[`${currency} [I]`] || 0,
          ),
      };
    });
    // console.warn('flattenedData', flattenedData);
    // console.warn('_closingBalances', _closingBalances);
    return orderTableDataByConfig(
      flattenedData,
      viewConfig?.rowConfig,
      openingBalances,
      _closingBalances,
    );
    //TODO: use Closing Blances to get to the details
    //return formattedData;
  }, [
    flattenedData,
    openingBalances,
    showForecasts,
    showIntraDay,
    orderTableDataByConfig,
    viewConfig,
  ]);

  const gridRef = useRef<AgGridReact<any>>(); // Optional - for accessing Grid's API

  const fetchTransactions = useCallback(
    async (
      category: string,
      currency: string | null,
      accountId?: string | null,
      isIntraday?: boolean,
    ) => {
      //Remember to also update Load More
      console.warn('fetching transactions:', isIntraday);
      if (isLoadingTransactions) {
        return;
      }
      setIsLoadingTransactions(true);

      const constraints = _filterToWhereClause(filter);
      if (accountId) {
        constraints.push(where('accountId', '==', accountId));
      }
      const orderByValues = [];
      orderByValues.push(orderBy('valueDate'));
      if (!filter.accountId && !accountId) {
        orderByValues.push(orderBy('accountId'));
      }
      orderByValues.push(orderBy('amount'));
      if (currency) {
        constraints.push(where('currency', '==', currency));
      }
      const valueDate = selectedDate?.toDate();
      const q = query(
        collection(
          db,
          'Organizations',
          profileData.orgId,
          isIntraday ? 'IntraDayStatement' : 'BankStatement',
        ),
        where('isBalance', '==', false),
        where('category', '==', category),
        where('valueDate', '>', valueDate),
        where('valueDate', '<', moment(valueDate).add(1, 'days').toDate()),
        ...constraints,
        ...orderByValues,
        limit(10),
      );

      const querySnapshot = await getDocs(q);
      const _bankStatements: StatementTransaction[] = [];
      querySnapshot.forEach((doc) => {
        _bankStatements.push({
          ...doc.data(),
          id: doc.id,
          isSelected: false,
        } as StatementTransaction & { isSelected: boolean });
      });
      setTransactions(_bankStatements);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoadingTransactions(false);
    },
    [db, filter, isLoadingTransactions, profileData.orgId, selectedDate],
  );

  const loadMorefTransactions = useCallback(
    async (
      category?: string,
      currency?: string | null,
      accountId?: string | null,
      isIntraday?: boolean,
    ) => {
      if (
        !isLoadingTransactions &&
        lastVisible &&
        !isLoadingMore &&
        category &&
        currency !== undefined
      ) {
        console.warn('fetching transactions More:', isIntraday);
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filter);
        if (accountId) {
          constraints.push(where('accountId', '==', accountId));
        }
        const orderByValues = [];
        orderByValues.push(orderBy('valueDate'));
        if (!filter.accountId && !accountId) {
          orderByValues.push(orderBy('accountId'));
        }
        orderByValues.push(orderBy('amount'));
        if (currency) {
          constraints.push(where('currency', '==', currency));
        }
        const valueDate = selectedDate?.toDate();
        const q = query(
          collection(
            db,
            'Organizations',
            profileData.orgId,
            isIntraday ? 'IntraDayStatement' : 'BankStatement',
          ),
          where('isBalance', '==', false),
          where('category', '==', category),
          where('valueDate', '>', valueDate),
          where('valueDate', '<', moment(valueDate).add(1, 'days').toDate()),
          ...constraints,
          ...orderByValues,
          startAfter(lastVisible),
          limit(10),
        );
        const querySnapshot = await getDocs(q);
        const _bankStatements: StatementTransaction[] = [];
        querySnapshot.forEach((doc) => {
          _bankStatements.push({
            ...doc.data(),
            id: doc.id,
            isSelected: false,
          } as StatementTransaction & { isSelected: boolean });
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setTransactions((prevValue) => [...(prevValue || []), ..._bankStatements]);
        setIsLoadingMore(false);
      }
    },
    [
      db,
      filter,
      isLoadingMore,
      isLoadingTransactions,
      lastVisible,
      profileData.orgId,
      selectedDate,
    ],
  );

  useEffect(() => {
    // console.warn('selectedKeys', selectedKeys);
    if (selectedKeys && selectedKeys.category) {
      if (selectedKeys.currency?.endsWith(' [F]')) {
        fetchPayments(
          selectedKeys.category,
          selectedKeys.currency.replace(' [F]', ''),
          selectedKeys.accountId,
        );
      } else if (selectedKeys.currency?.endsWith(' [I]')) {
        fetchTransactions(
          selectedKeys.category,
          selectedKeys.currency.replace(' [I]', ''),
          selectedKeys.accountId,
          showIntraDay,
        );
      } else {
        fetchTransactions(selectedKeys.category, selectedKeys.currency, selectedKeys.accountId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, selectedKeys, showIntraDay]);

  const reassignTransactions = useCallback(async () => {
    setIsReApplying(true);
    const isIntraday = selectedKeys?.currency?.endsWith(' [I]');
    try {
      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(db));
      let operationCounter = 0;
      let batchIndex = 0;
      let summaryList: any = {};

      transactions
        ?.filter((trans) => trans.isSelected)
        .map((trans) => {
          batchArray[batchIndex].update(
            doc(
              db,
              'Organizations',
              profileData.orgId,
              isIntraday ? 'IntraDayStatement' : 'BankStatement',
              trans.id || '',
            ),
            { category: categoryToReassign, ruleId: 'Manual' },
          );
          operationCounter++;
          // For statements, update the category level summary totals
          const key = `${moment(trans.valueDate.toDate()).format('YYYYMMDD')}${trans.currency}${
            trans.category
          }`;
          const newKey = `${moment(trans.valueDate.toDate()).format('YYYYMMDD')}${
            trans.currency
          }${categoryToReassign}`;

          if (!summaryList[key]) {
            summaryList[key] = {
              statementAmount: 0,
              statementCount: 0,
              date: trans.valueDate,
              currency: trans.currency,
              category: trans.category,
              details: {},
            } as CashPositionSummary;
            operationCounter++; // Only the counter is increased here. Adding to the batch happens later
          }
          if (summaryList[key]) {
            if (!summaryList[key].details[trans.accountId]) {
              summaryList[key].details[trans.accountId] = { statementAmount: 0, statementCount: 0 };
            }
            if (trans.CdtDbtInd === 'C') {
              summaryList[key].statementAmount -= Math.abs(trans.amount);
              summaryList[key].details[trans.accountId].statementAmount -= Math.abs(trans.amount);
            } else {
              summaryList[key].statementAmount += Math.abs(trans.amount);
              summaryList[key].details[trans.accountId].statementAmount += Math.abs(trans.amount);
            }
            summaryList[key].statementCount--;
            summaryList[key].details[trans.accountId].statementCount--;
          }

          if (!summaryList[newKey]) {
            summaryList[newKey] = {
              statementAmount: 0,
              statementCount: 0,
              date: trans.valueDate,
              currency: trans.currency,
              category: categoryToReassign,
              details: {},
            } as CashPositionSummary;
            operationCounter++; // Only the counter is increased here. Adding to the batch happens later
          }
          if (summaryList[newKey]) {
            if (!summaryList[newKey].details[trans.accountId]) {
              summaryList[newKey].details[trans.accountId] = {
                statementAmount: 0,
                statementCount: 0,
              };
            }
            if (trans.CdtDbtInd === 'C') {
              summaryList[newKey].statementAmount += Math.abs(trans.amount);
              summaryList[newKey].details[trans.accountId].statementAmount += Math.abs(
                trans.amount,
              );
            } else {
              summaryList[newKey].statementAmount -= Math.abs(trans.amount);
              summaryList[newKey].details[trans.accountId].statementAmount -= Math.abs(
                trans.amount,
              );
            }
            summaryList[newKey].statementCount++;
            summaryList[newKey].details[trans.accountId].statementCount++;
          }
          if (operationCounter === 499) {
            // Also update in the scenario below if the counter is less than 499
            Object.entries(summaryList).map(([summaryKey, summaryValue]: [string, any]) => {
              batchArray[batchIndex].set(
                doc(db, 'Organizations', profileData.orgId, 'CashPositionSummary', summaryKey),
                {
                  category: summaryValue.category,
                  date: summaryValue.date,
                  currency: summaryValue.currency,
                  statementAmount: increment(summaryValue.statementAmount),
                  statementCount: increment(summaryValue.statementCount),
                  details: Object.fromEntries(
                    Object.entries(summaryValue.details).map(
                      ([accountId, accountSummary]: [string, any]) => [
                        accountId,
                        {
                          statementAmount: increment(accountSummary.statementAmount),
                          statementCount: increment(accountSummary.statementCount),
                        },
                      ],
                    ),
                  ),
                } as CashPositionSummary,
                { merge: true },
              );
            });
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
            summaryList = {};
          }
        });
      Object.entries(summaryList).map(([summaryKey, summaryValue]: [string, any]) => {
        batchArray[batchIndex].set(
          doc(db, 'Organizations', profileData.orgId, 'CashPositionSummary', summaryKey),
          {
            category: summaryValue.category,
            date: summaryValue.date,
            currency: summaryValue.currency,
            [isIntraday ? 'intradayStatementAmount' : 'statementAmount']: increment(
              summaryValue.statementAmount,
            ),
            [isIntraday ? 'intradaystatementCount' : 'statementCount']: increment(
              summaryValue.statementCount,
            ),
            details: Object.fromEntries(
              Object.entries(summaryValue.details).map(
                ([accountId, accountSummary]: [string, any]) => [
                  accountId,
                  {
                    [isIntraday ? 'intradayStatementAmount' : 'statementAmount']: increment(
                      accountSummary.statementAmount,
                    ),
                    [isIntraday ? 'intradaystatementCount' : 'statementCount']: increment(
                      accountSummary.statementCount,
                    ),
                  },
                ],
              ),
            ),
          } as CashPositionSummary,
          { merge: true },
        );
      });
      const promises = batchArray.map(
        async (batch) =>
          // eslint-disable-next-line no-return-await
          await batch.commit().catch((e) => {
            // console.log(e);
            //errorsExist = true;
            console.warn('error encountered');
            console.warn(e);
          }),
      );
      await Promise.all(promises)
        .then(() => {
          console.warn('Completed');
        })
        .catch((error) => {
          console.warn('Something went wrong');
          console.warn(error);
        });
    } catch (error) {
      console.warn('Something went wrong');
      console.warn(error);
    }
    setIsReApplying(false);
    setShowAlert(false);
    if (selectedKeys) {
      fetchTransactions(
        selectedKeys.category,
        isIntraday ? selectedKeys?.currency?.replace(' [I]', '') : selectedKeys?.currency,
        selectedKeys.accountId,
        isIntraday,
      );
    }
  }, [categoryToReassign, db, fetchTransactions, profileData.orgId, selectedKeys, transactions]);

  const categorySelectionModal = useMemo(() => {
    return (
      <View>
        <CategoryInput
          onSelect={(values) => {
            setCategoryToReassign(values?.[0]);
          }}
          label="Category to Reassign to"
          value={categoryToReassign}
          isRequired
          style={AppStyles.marginTop}
          width={300}
        />
        <View style={[AppStyles.flex1, { alignItems: 'flex-end' }]}>
          <Button
            label="Apply Changes"
            loading={isReApplying}
            disabled={!categoryToReassign}
            onPress={reassignTransactions}
            mt={5}
          />
        </View>
      </View>
    );
  }, [
    staticData.categories,
    categoryToReassign,
    isReApplying,
    reassignTransactions,
    selectedKeys?.category,
  ]);

  const renderPaymentDetails = useCallback(() => {
    return (
      <>
        <View style={[AppStyles.flexRowCenterSpaceBetween, { zIndex: 9999 }]}>
          <FilterBar
            isOnModal
            onSearch={() => null}
            searchPlaceholder={'Search loaded transactions'}
            hideSearch
            filterParams={[
              {
                title: 'Account',
                options: [],
                type: 'AccountSelect',
                field: 'accountId',
                hide: !!selectedKeys?.accountId,
              },
            ]}
            filter={filter}
            setFilter={setFilter}
          />
        </View>

        <CFlatList
          style={{ height: Layout.window.height * 0.6 }}
          isLoading={isLoadingPayments}
          data={payments}
          emptyMessage={'No forecasts found'}
          renderItem={({ item, index }) => (
            <PaymentListItem payment={item} key={index} isDisabled />
          )}
          // loadMore={() =>
          //   loadMorefTransactions(
          //     selectedKeys?.category,
          //     selectedKeys?.currency,
          //     selectedKeys?.accountId,
          //   )
          // }
          isLoadingMore={isLoadingMore}
        />
      </>
    );
  }, [filter, isLoadingPayments, payments, selectedKeys?.accountId, selectedKeys?.category]);

  const renderStatementDetails = useCallback(() => {
    let isBalance = false;
    if (selectedKeys?.category === 'OB') {
      isBalance = true;
    }
    return (
      <>
        {!isBalance && (
          <View style={[AppStyles.flexRowCenterSpaceBetween, { zIndex: 9999 }]}>
            <FilterBar
              isOnModal
              onSearch={() => null}
              searchPlaceholder={'Search loaded transactions'}
              hideSearch
              filterParams={[
                {
                  title: 'Sort by',
                  options: [
                    { label: 'Amount', value: 'amount' },
                    { label: 'Dr / Cr', value: 'CdtDbtInd' },
                  ],
                  type: 'sort',
                  field: 'orderBy',
                },
                {
                  title: 'Account',
                  options: [],
                  type: 'AccountSelect',
                  field: 'accountId',
                  hide: !!selectedKeys?.accountId,
                },
                {
                  title: 'Cr/Dr',
                  options: [
                    { label: 'Credits Only', value: 'C' },
                    { label: 'Debits Only', value: 'D' },
                  ],
                  type: 'select',
                  field: 'CdtDbtInd',
                  minWidth: 40,
                },
                {
                  title: 'Transaction Code',
                  options: [
                    { label: 'Credits Only', value: 'C' },
                    { label: 'Debits Only', value: 'D' },
                  ],
                  type: 'MultiTextInput',
                  field: 'combinedCode',
                },
              ]}
              filter={filter}
              setFilter={setFilter}
            />

            <Button
              label={'Re-assign Category'}
              onPress={() => {
                setShowAlert(true);
              }}
              disabled={transactions?.filter((trans) => trans.isSelected).length === 0}
            />
          </View>
        )}
        {isBalance ? (
          <CTable
            isLoading={false}
            columnDefs={[
              { field: 'accountNumber', filter: true, pinned: 'left' },
              { field: 'currency', filter: true },
              {
                field: 'openingBalance',
                filter: false,
                valueFormatter: (params) => _getFormattedCurrency(params.value),
              },
              {
                field: 'balSource',
                filter: true,
              },
            ]}
            tableData={(selectedKeys?.currency
              ? bankBalances?.filter((bankBal) => bankBal.currency === selectedKeys?.currency)
              : bankBalances
            )?.map((balanceTransaction) => {
              console.warn('balanceTransaction', balanceTransaction);
              return {
                accountNumber: balanceTransaction.accountNumber,
                currency: balanceTransaction.currency,
                balSource: balanceTransaction.isCurrentDayBalance
                  ? BalSource.CDOLB
                  : BalSource.PDCAB,
                openingBalance:
                  balanceTransaction.balances?.filter((bal) =>
                    Constants.closingAvailable.includes(bal.code),
                  )[0]?.amount ||
                  balanceTransaction.balances?.[0]?.amount ||
                  0,
              };
            })}
          />
        ) : (
          <CFlatList
            style={{ height: Layout.window.height * 0.6, paddingTop: 5 }}
            isLoading={isLoadingTransactions}
            data={transactions}
            emptyMessage={'No transctions found'}
            renderItem={({ item, index }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    setTransactions((currentValue) => {
                      return currentValue?.map((value) => {
                        if (value.id === item.id) {
                          return { ...value, isSelected: !value.isSelected };
                        }
                        return value;
                      });
                    });
                  }}
                  style={AppStyles.flex1}
                >
                  <StatementTransactionItem
                    transactionItem={item}
                    key={index}
                    showNarrative
                    showCheckBox
                    onViewDetails={() => {
                      setSelectedBankStatement(item);
                      setIsDetailVisible(true);
                    }}
                    onCheckBoxUpdated={(isChecked) => {
                      setTransactions((currentValue) => {
                        return currentValue?.map((value) => {
                          if (value.id === item.id) {
                            return { ...value, isSelected: !isChecked };
                          }
                          return value;
                        });
                      });
                    }}
                  />
                </TouchableOpacity>
              );
            }}
            loadMore={() => {
              if (selectedKeys?.currency?.endsWith(' [I]')) {
                loadMorefTransactions(
                  selectedKeys.category,
                  selectedKeys.currency.replace(' [I]', ''),
                  selectedKeys.accountId,
                  true,
                );
              } else {
                loadMorefTransactions(
                  selectedKeys?.category,
                  selectedKeys?.currency,
                  selectedKeys?.accountId,
                );
              }
            }}
            isLoadingMore={isLoadingMore}
          />
        )}
      </>
    );
  }, [
    bankBalances,
    filter,
    isLoadingMore,
    isLoadingTransactions,
    loadMorefTransactions,
    selectedKeys?.accountId,
    selectedKeys?.category,
    selectedKeys?.currency,
    transactions,
  ]);

  const enableEditing = useCallback(() => {
    setEditEnabled(true);
  }, []);

  const getRowStyle = (params: RowClassParams<any>) => {
    // console.warn(params.node);
    if (params.node.key && ['Opening Balance', 'Closing Balance'].includes(params.node.key)) {
      return { background: Colors.midBlue, color: Colors.white };
    }
    if (!params.node.group) {
      return { background: Colors.lightest_grey, color: Colors.black };
    }
  };

  const renderGrid = useCallback(() => {
    return (
      <>
        <AgGridReact
          ref={gridRef} // Ref for accessing Grid's API
          rowData={tableData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="single" // Options - allows click selection of rows
          onCellClicked={(event) => cellClickedListener(event)} // Optional - registering for Grid Event
          alwaysShowHorizontalScroll={true}
          rowDragManaged={true}
          getRowStyle={getRowStyle}
          suppressAggFuncInHeader={true}
          autoGroupColumnDef={{ headerName: 'Category', pinned: 'left' }}
          groupRowsSticky={true}
        />
      </>
    );
  }, [cellClickedListener, columnDefs, defaultColDef, tableData]);

  return (
    <ScrollView
      contentContainerStyle={[AppStyles.container, { padding: 10, backgroundColor: Colors.white }]}
    >
      <CModal
        title={
          selectedKeys?.categoryName +
          ' - ' +
          (selectedKeys?.currency ? selectedKeys?.currency + ' - ' : '') +
          (selectedKeys?.accountId ? selectedKeys?.accountId + ' - ' : '') +
          selectedDate?.format('DD MMM YYYY')
        }
        open={isDetailOpen}
        setOpen={setIsDetailOpen}
        hideButtons
      >
        {selectedKeys?.currency?.endsWith(' [F]')
          ? renderPaymentDetails()
          : renderStatementDetails()}
      </CModal>
      <CModal
        title={'Re-assign Category'}
        open={showAlert}
        setOpen={setShowAlert}
        hideButtons
        width={Layout.window.width * 0.4}
      >
        {categorySelectionModal}
      </CModal>
      {selectedBankStatement && (
        <CModal
          width={Layout.window.width * 0.8}
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={
            _getCodeDescr({
              code: selectedBankStatement.code,
              familyCode: selectedBankStatement.familyCode,
              subFamilyCode: selectedBankStatement.subFamilyCode,
              short: true,
            }) || 'Bank Statement'
          }
          hideButtons
        >
          <BankStatementDetails
            setIsVisible={setIsDetailVisible}
            statementTransaction={selectedBankStatement}
            onStatementTransactionUpdated={() => null}
            mode={InputMode.VIEW}
          />
        </CModal>
      )}

      <View style={AppStyles.flexRowCenterSpaceBetween}>
        <View style={AppStyles.flexRowCenter}>
          <SelectPresetInput
            data={viewConfigList}
            onSelect={async (v) => {
              setViewConfig(v);
              await AsyncStorage.setItem('defaultView', v.id);
            }}
            selectedValue={viewConfig}
            renderItem={(item: CashPositionView) => (
              <View>
                <Text style={AppStyles.textRowTitle}>{item?.name}</Text>
                <Text style={AppStyles.textSubTitle}>{item?.description}</Text>
              </View>
            )}
          />
          {viewConfig?.id && !editEnabled && !createEnabled && canEdit && (
            <CTA
              label="Edit"
              icon="pencil"
              buttonColor={Colors.orange}
              onPress={() => {
                enableEditing();
              }}
              style={AppStyles.marginLeft}
            />
          )}
        </View>

        {!editEnabled &&
          !createEnabled &&
          staticData.accessibleScreens?.CashPositionScreen?.create && (
            <Button
              label="Create View"
              color={'green'}
              icon={'plus-square'}
              onPress={() => {
                setCreateEnabled(true);
              }}
            />
          )}
      </View>
      <div
        className="ag-theme-alpine"
        style={{
          maxWidth: Layout.window.width * 0.9,
          height: Layout.window.height - 200,
          backgroundColor: 'white',
        }}
      >
        {editEnabled && (
          <EditCashPosition
            viewConfig={viewConfig}
            setViewConfig={setViewConfig}
            isVisible={editEnabled}
            setIsVisible={setEditEnabled}
            mode={InputMode.EDIT}
          />
        )}
        {createEnabled && (
          <EditCashPosition
            viewConfig={viewConfig}
            setViewConfig={setViewConfig}
            isVisible={createEnabled}
            setIsVisible={setCreateEnabled}
            mode={InputMode.CREATE}
          />
        )}
        <View style={[AppStyles.flexRowCenter, AppStyles.marginBottom, { zIndex: 999 }]}>
          <DateInput onSelect={setSelectedDate} value={selectedDate} />
          <Checkbox
            isChecked={showForecasts}
            onChange={(e) => setShowForecasts(e.target.checked)}
            style={AppStyles.marginLeft}
          >
            {'Show Forecasts [F]'}
          </Checkbox>

          <Checkbox
            isChecked={showIntraDay}
            onChange={(e) => setShowIntraday(e.target.checked)}
            style={AppStyles.marginLeft25}
          >
            {'Show Intraday [I]'}
          </Checkbox>
        </View>

        {renderGrid()}
        {/* {JSON.stringify(filteredAccounts)} */}
        {/* {staticData?.bankAccounts?.filincludes('3cahxWxVARNXreVG80Uk')} */}
      </div>
    </ScrollView>
  );
}
