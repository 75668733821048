/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

import { Ionicons } from '@expo/vector-icons';
import { Icon, Input } from 'native-base';
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

import { FormControl, FormLabel, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import Colors from '../constants/Colors';
import { _addAlpha } from '../utils/helper';
import { View } from './Themed';

export type MultiTextInputProps = {
  title: string;
  onSelect: Dispatch<SetStateAction<string[] | undefined>>;
  selectedValues?: string[];
  label?: string;
  isReadOnly?: boolean;
  isFilterComponent?: boolean;
  maxValues?: number;
  style?: React.CSSProperties;
  addNextLine?: boolean;
};
export function MultiTextInput({
  title,
  onSelect,
  isReadOnly,
  selectedValues = [],
  isFilterComponent,
  maxValues,
  style,
  addNextLine,
}: MultiTextInputProps) {
  const [newTag, setNewTag] = useState('');
  const refInput = useRef(null);

  const addNewTag = useCallback(() => {
    if (selectedValues.includes('*')) {
      onSelect([newTag]);
    } else if (!selectedValues.includes(newTag)) {
      onSelect([...selectedValues, newTag]);
    }

    setNewTag('');
  }, [newTag, onSelect, selectedValues]);

  const removeTag = useCallback(
    (value: string) => {
      onSelect(selectedValues.filter((v) => v !== value));
    },
    [onSelect, selectedValues],
  );

  return (
    <FormControl
      style={{
        marginLeft: isFilterComponent ? 20 : 0,
        marginTop: isFilterComponent ? 0 : 10,
        ...style,
      }}
    >
      {!isFilterComponent && (
        <FormLabel fontSize={14} mb={1}>
          {title}
        </FormLabel>
      )}
      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
        {selectedValues.map((value) => {
          return (
            <>
              <Tag mr={1} variant="solid" colorScheme="blue">
                <TagLabel>{value}</TagLabel>
                {!isReadOnly && <TagCloseButton onClick={() => removeTag(value)} />}
              </Tag>
              {addNextLine && <View style={{ width: '100%', marginTop: 5 }} />}
            </>
          );
        })}
        {!isReadOnly && (!maxValues || selectedValues?.length < maxValues) && (
          <Input
            value={newTag}
            onChangeText={setNewTag}
            ref={refInput}
            onSubmitEditing={() => {
              addNewTag();
            }}
            placeholder={title}
            InputRightElement={
              <TouchableOpacity disabled={!newTag} onPress={addNewTag}>
                <Icon
                  as={<Ionicons name="checkmark" />}
                  size={5}
                  ml="4"
                  color={newTag ? Colors.green : 'muted.400'}
                  style={{ marginRight: 10 }}
                />
              </TouchableOpacity>
            }
          />
        )}
      </View>
    </FormControl>
  );
}

const styles = StyleSheet.create({
  paramContainer: {
    marginRight: 20,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    alignItems: 'center',
    borderColor: _addAlpha(Colors.primary, 0.1),
    backgroundColor: _addAlpha(Colors.primary, 0.1),
  },
});
