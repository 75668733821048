/* eslint-disable react-native/no-inline-styles */
import React, { useCallback } from 'react';

import moment from 'moment';
import { ScrollView, TouchableOpacity, ViewStyle } from 'react-native';

import { StatementBalanceItem } from './StatementBalanceItem';
import { AmountText } from './StyledText';
import { Text, View } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { StatementTransaction } from '../commonTypes';
import {
  _getCamtRelatedPartyDetails,
  _getCodeDescr,
  _getColorFromCrDrIndicator,
} from '../utils/helper';
import { Card, Checkbox, IconButton } from '@chakra-ui/react';
import useCategory from '../hooks/useCategories';
import { Feather, FontAwesome } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';

export type StatementTransactionItemProps = {
  transactionItem: StatementTransaction;
  useDateString?: boolean;
  style?: React.CSSProperties;
  showNarrative?: boolean;
  showCheckBox?: boolean;
  onCheckBoxUpdated?: (isChecked: boolean) => void;
  onViewDetails?: () => void;
};
export function StatementTransactionItem({
  transactionItem,
  useDateString = false,
  style,
  showNarrative,
  showCheckBox,
  onCheckBoxUpdated,
  onViewDetails,
}: StatementTransactionItemProps) {
  const { _getCategoryName } = useCategory();

  const renderIcon = useCallback(() => {
    let Icon = <FontAwesome name="question" size={20} color={Colors.white} />;
    let starColor = Colors.black;
    let endColor = Colors.black;
    switch (transactionItem.CdtDbtInd) {
      case 'D':
        Icon = <Feather name="arrow-up-left" size={20} color={Colors.white} />;
        starColor = Colors.red;
        endColor = '#C738A0';
        break;
      case 'C':
        Icon = <Feather name="arrow-down-right" size={20} color={Colors.white} />;
        starColor = '#43C59E';
        endColor = '#2FAE6A';
        break;
    }

    return (
      <LinearGradient
        style={{
          marginRight: 10,
          width: 25,
          height: 25,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 8,
        }}
        colors={[starColor, endColor]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
      >
        {Icon}
      </LinearGradient>
    );
  }, [transactionItem.CdtDbtInd]);
  if (transactionItem.isBalance) {
    return (
      <Card
        ml={2}
        mr={2}
        mt={5}
        p={2}
        style={{
          marginBottom: 10,
          borderColor: Colors.primary,
          borderWidth: 2,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
          }}
        >
          <Text
            style={{
              fontSize: Constants.TextSize.max,
              color: Colors.primary,
            }}
          >
            {'Account Number: ' + transactionItem.accountNumber}
          </Text>
          {transactionItem.branchCode ? (
            <Text>{'Swift/Route Code: ' + transactionItem.branchCode}</Text>
          ) : null}
        </View>
        <ScrollView horizontal>
          {transactionItem.balances?.map((balance, index) => {
            return (
              <StatementBalanceItem
                balance={balance}
                currency={transactionItem.currency}
                key={index.toString()}
              />
            );
          })}
        </ScrollView>
      </Card>
    );
  }
  const relatedParty = _getCamtRelatedPartyDetails(transactionItem)?.name;
  return (
    <Card ml={2} mr={2} mt={0.2} p={3} style={{ borderRadius: 0, ...style }}>
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: 'transparent',
          justifyContent: 'space-between',
        }}
      >
        <View style={AppStyles.flexRow}>
          {showCheckBox && (
            <Checkbox
              mr={2}
              isChecked={transactionItem.isSelected}
              onChange={(e) => onCheckBoxUpdated?.(e.target.checked)}
            />
          )}
          {renderIcon()}
          <View>
            <Text style={AppStyles.textSecondary}>
              {_getCategoryName(transactionItem.category) +
                (relatedParty ? ' - ' + relatedParty : '')}
            </Text>
            <Text style={AppStyles.textSmall}>
              {useDateString
                ? moment(transactionItem.valueDateString, 'ddd MMM DD YYYY').format('DD MMM YYYY')
                : moment(transactionItem.valueDate?.toDate()).format('DD MMM YYYY')}
            </Text>
          </View>
        </View>
        <View style={AppStyles.alignFlexEnd}>
          <AmountText
            amount={transactionItem.amount}
            size={Constants.TextSize.large}
            currency={transactionItem.currency}
            code={transactionItem.subFamilyCode || transactionItem.code}
            color={_getColorFromCrDrIndicator(transactionItem.CdtDbtInd)}
          />
          <Text style={{ flex: 1 }}>
            {_getCodeDescr({
              code: transactionItem.code,
              familyCode: transactionItem.familyCode,
              subFamilyCode: transactionItem.subFamilyCode,
              short: true,
            })}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
        {showNarrative && (
          <Text style={{ flex: 1, marginTop: 10 }}>{transactionItem.narrative}</Text>
        )}
        {onViewDetails && (
          <TouchableOpacity
            style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}
            onPress={onViewDetails}
          >
            <Text style={{ color: Colors.midBlue }}>View Details</Text>
            <Text style={{ color: Colors.midBlue, marginLeft: 5 }}>›</Text>
          </TouchableOpacity>
        )}
      </View>
    </Card>
  );
}
