/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from 'react';

import { MaterialIcons } from '@expo/vector-icons';
import debounce from 'lodash/debounce';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { InputMode, StaticDataStore, Status, User, UserGroup } from '../commonTypes';
import { CFlatList } from '../components/CFlatList';
import { CModal } from '../components/CModal';
import { FilterBar } from '../components/FilterBar';
import StatusTag from '../components/StatusTag';
import { CTA, Card, SmallCta, Text, View } from '../components/Themed';
import UserGroupDetails from '../components/Users/UserGroupDetails';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _searchList } from '../utils/helper';

export default function UserGroupsScreen({ navigation }: RootTabScreenProps<'UserGroupsScreen'>) {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);

  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>({} as UserGroup);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const [inputMode, setInputMode] = useState(InputMode.EDIT);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [filteredUserGroups, setFilteredUserGroups] = useState<UserGroup[] | undefined>();

  const toast = useToast();

  useEffect(() => {
    console.warn('update user groups');
    console.warn(staticData.userGroups);
    setUserGroups(staticData.userGroups || []);
    if (selectedUserGroup?.id) {
      setSelectedUserGroup(
        staticData.userGroups?.find((eUserGroup) => eUserGroup.id === selectedUserGroup?.id) ||
          ({} as UserGroup),
      );
      setSelectedIndex(
        staticData.userGroups?.findIndex((eUserGroup) => eUserGroup.id === selectedUserGroup?.id),
      );
    }
  }, [selectedUserGroup?.id, staticData.userGroups]);

  useEffect(() => {
    console.warn('update selected users');
    setAllUsers(
      staticData?.users?.map((user) => {
        return user.userGroups?.includes(selectedUserGroup?.id || '')
          ? { ...user, selected: true }
          : user;
      }),
    );
  }, [selectedUserGroup?.id, staticData?.users]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredUserGroups(_searchList(userGroups, searchValue));
      } else {
        setFilteredUserGroups(undefined);
      }
    },
    [userGroups],
  );

  const canEdit = staticData.accessibleScreens?.UserGroupsScreen?.edit || false;

  const onUserGroupCreatedOrUpdated = useCallback(
    (updatedUserGroup: UserGroup) => {
      if (selectedIndex !== undefined && updatedUserGroup?.name) {
        //Updating existing account
        setSelectedUserGroup(updatedUserGroup);
        const _userGroups = filteredUserGroups || userGroups;
        _userGroups[selectedIndex] = updatedUserGroup;
        if (filteredUserGroups) {
          setFilteredUserGroups(_userGroups);
          const _AllUserGroups = userGroups;
          _AllUserGroups[
            _AllUserGroups.findIndex((userGroup) => userGroup.name === updatedUserGroup.name)
          ] = updatedUserGroup;
          setUserGroups(_AllUserGroups);
        } else {
          setUserGroups(_userGroups);
        }
      } else if (updatedUserGroup) {
        //Create new account
        setUserGroups((currentValue) => [
          {
            ...updatedUserGroup,
          },
          ...currentValue,
        ]);
        if (filteredUserGroups) {
          setFilteredUserGroups((currentValue) => [
            {
              ...updatedUserGroup,
            },
            ...(currentValue || []),
          ]);
        }
      } else {
        setIsDetailVisible(false);
        return toast({
          title: 'Something went worong',
          description: 'Please try again later',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [userGroups, filteredUserGroups, selectedIndex, toast],
  );

  return (
    <View style={AppStyles.container}>
      {selectedUserGroup && isDetailVisible && (
        <CModal
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={selectedUserGroup?.name || 'User Group'}
          hideButtons
        >
          <UserGroupDetails
            setIsVisible={setIsDetailVisible}
            userGroup={selectedUserGroup}
            onUserGroupUpdated={onUserGroupCreatedOrUpdated}
            mode={canEdit ? inputMode || InputMode.EDIT : InputMode.VIEW}
          />
        </CModal>
      )}
      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {userGroups && userGroups.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}
        <SmallCta
          label="Create User Group"
          buttonColor={Colors.green}
          icon={'plus-square'}
          visible={staticData.accessibleScreens?.UserGroupsScreen?.create || false}
          onPress={() => {
            setSelectedUserGroup({ name: '', description: '' } as UserGroup);
            setInputMode(InputMode.CREATE);
            setSelectedIndex(undefined);
            setIsDetailVisible(true);
          }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
        }}
      >
        <View style={{ width: Layout.window.width * 0.3 }}>
          <CFlatList
            data={filteredUserGroups || userGroups}
            isLoading={false}
            emptyMessage={
              filteredUserGroups === undefined ? 'No User groups yet' : 'No results found'
            }
            subAction={
              <Text style={[AppStyles.textSubTitle, { textAlign: 'center' }]}>
                Group users for Access provisioning and Security
              </Text>
            }
            renderItem={({ item, index }) => (
              <TouchableOpacity
                onPress={() => {
                  setSelectedUserGroup(item);
                  setSelectedIndex(index);
                }}
                key={item.id}
              >
                <Card
                  style={[
                    styles.bankAccountCard,
                    selectedUserGroup?.id === item.id && { borderColor: Colors.primary },
                  ]}
                >
                  <View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Text style={AppStyles.textRowTitle}>{item.name}</Text>
                      <View style={[AppStyles.flex1]}>
                        <StatusTag label={item.status || Status.ACTIVE} isReadOnly />
                        {item.updateRequest && <StatusTag label={'Pending Updates'} isReadOnly />}
                      </View>
                    </View>
                    <Text style={AppStyles.textSubTitle}>{item.description}</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        <View style={{ width: Layout.window.width * 0.3, marginRight: 10 }}>
          {selectedUserGroup && selectedIndex !== undefined && (
            <View
              style={{
                flexDirection: 'row',
                borderWidth: 2,
                borderColor: Colors.primary,
                height: Layout.window.height * 0.8,
                borderRadius: 10,
              }}
            >
              <View style={{ flex: 1, padding: 10, justifyContent: 'space-between' }}>
                <View style={{ flex: 1 }}>
                  <View style={AppStyles.flexRowCenterSpaceBetween}>
                    <Text style={[AppStyles.textRowTitle, { marginBottom: 10 }]}>
                      {selectedUserGroup.name}
                    </Text>
                    {canEdit && (
                      <CTA
                        label="Edit"
                        icon="pencil"
                        buttonColor={Colors.orange}
                        onPress={() => {
                          setInputMode(InputMode.EDIT);
                          setIsDetailVisible(true);
                        }}
                      />
                    )}
                    {!canEdit && (
                      <CTA
                        label="View Permissions"
                        icon={<MaterialIcons name="security" size={15} color={Colors.primary} />}
                        buttonColor={Colors.primary}
                        onPress={() => {
                          setInputMode(InputMode.VIEW);
                          setIsDetailVisible(true);
                        }}
                      />
                    )}
                  </View>

                  <CFlatList
                    emptyCTA="Add Users"
                    emptyAction={() => {
                      setInputMode(InputMode.EDIT);
                      setIsDetailVisible(true);
                    }}
                    loaderLines={2}
                    emptyMessage="No Users in this group yet"
                    data={allUsers.filter((user) => user.selected)}
                    isLoading={false}
                    renderItem={({ item }: { item: User }) => (
                      <Card key={item.uid}>
                        <View style={AppStyles.flexRowCenterSpaceBetween}>
                          <Text>{item.firstName}</Text>
                          <Text>{item.lastName}</Text>
                        </View>
                        <Text style={{ color: Colors.light_grey }}>{item.email}</Text>
                      </Card>
                    )}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
  },
});
