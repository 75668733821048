import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';
import { StaticDataStore } from '../../commonTypes';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { View } from '../Themed';

export type AccountGroupInputProps = {
  onSelect: (values: string[]) => void;
  value?: string;
  values?: string[];
  isRequired?: boolean;
  isMultiSelect?: boolean;
  isReadOnly?: boolean;
  includeAll?: boolean;
  width?: number | string;
  hideLabel?: boolean;
  style?: React.CSSProperties;
};
const AccountGroupInput: FC<AccountGroupInputProps> = ({
  onSelect,
  value,
  isRequired,
  isMultiSelect,
  values = [],
  isReadOnly,
  includeAll = isMultiSelect,
  width,
  hideLabel,
  style,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  return (
    <FormControl isRequired={isRequired} width={width} style={style}>
      <FormLabel fontSize={14} mb={1} hidden={hideLabel}>
        {'Account Groups'}
      </FormLabel>
      <View style={{ maxWidth: '90%', alignSelf: 'flex-start' }}>
        <Select
          isReadOnly={isReadOnly}
          isClearable={!isReadOnly}
          isMulti={isMultiSelect}
          onChange={(selected) => {
            if (isMultiSelect) {
              const all = selected?.findIndex((val) => val.value === 'All');
              if ((selected?.length > 1 && all > 0) || (selected?.length === 1 && all === 0)) {
                return onSelect(['All']);
              }
              return onSelect(
                selected?.map((val) => val.value)?.filter((val) => val !== 'All') || [],
              );
            }
            return onSelect([selected?.value]);
          }}
          closeMenuOnSelect={!isMultiSelect}
          components={isReadOnly ? { MultiValueRemove: () => null } : undefined}
          value={
            isMultiSelect
              ? values?.map((val) => {
                  return {
                    value: val,
                    label: staticData.accountGroups?.find((ag) => ag.id === val)?.name || val,
                  };
                })
              : value
              ? [
                  {
                    value,
                    label: staticData.accountGroups?.find((ag) => ag.id === value)?.name || value,
                  },
                ]
              : []
          }
          name="Account Groups"
          options={[
            ...(includeAll ? [{ value: 'All', label: 'All' }] : []),
            ...(staticData.accountGroups?.map((ag) => {
              return { value: ag.id, label: ag.name };
            }) || []),
          ]}
          placeholder="Select Account Groups"
          size="sm"
          menuPosition="fixed"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          chakraStyles={{
            menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
          }}
          menuPortalTarget={document.body}
        />
      </View>
    </FormControl>
  );
};

export default AccountGroupInput;
