/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-native/no-inline-styles */

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { doc, getDoc } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { useToast } from 'native-base';
import { FlatList, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { Button, Checkbox } from '@chakra-ui/react';
import { AppContext } from '../App';
import {
  BalanceRecord,
  CurrencyRate,
  StatementTransaction,
  StaticDataStore,
  UploadedFile,
} from '../commonTypes';
import CurrencyRateSummaryModal from '../components/CurrencyRateSummaryModal';
import { FlyOut } from '../components/FlyOut';
import { StatementBalanceItem } from '../components/StatementBalanceItem';
import { StatementTransactionItem } from '../components/StatementTransactionItem';
import { Card, Text, ToastAlert, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { _downloadExcel, _getCodeDescr, _getConvertedAmount, _pickDocument } from '../utils/helper';
import CToolTip from '../components/CToolTip';

export default function StatementImportScreen() {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [stickyHeaders, setStickyHeaders] = useState<number[]>([]);
  const [currencyRates, setCurrencyRates] = useState({});
  const [currencyRatesArray, setCurrencyRatesArray] = useState<CurrencyRate[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [errors, setErrors] = useState([]);
  const toast = useToast();
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState<string>();
  const [isIntraday, setIsIntraday] = useState(false);

  const [data, setData] = useState<StatementTransaction[]>([]);
  const { db, defaultDb } = useContext(AppContext);
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const fetchCurrencyRates = useCallback(() => {
    console.log('Fetching currency rates');
    getDoc(doc(defaultDb, 'DefaultConfig', 'CurrencyRates')).then((ratesDoc) => {
      if (ratesDoc?.data()?.rates) {
        const _currencyRates: any = {};
        const _currencyRatesArray: CurrencyRate[] = [];
        Object.entries(ratesDoc?.data()?.rates).map(([currencyCode, rate]) => {
          _currencyRates[currencyCode] = rate;
          _currencyRatesArray.push({
            from: 'USD',
            to: currencyCode,
            rate: Number(rate),
          });
        });
        setCurrencyRates(_currencyRates);
        console.warn(_currencyRatesArray);
        setCurrencyRatesArray(_currencyRatesArray);
      }
    });
  }, [defaultDb]);

  useEffect(() => {
    if (data?.length > 0) {
      fetchCurrencyRates();
    }
  }, [data?.length, fetchCurrencyRates]);

  const uploadAndProcessFile = useCallback(async () => {
    const file: UploadedFile | null = await _pickDocument(() => setIsLoading(true));
    if (file === null) {
      return setIsLoading(false);
    }
    const startTime = performance.now();
    const functions = getFunctions();
    if (__DEV__) {
      connectFunctionsEmulator(functions, 'localhost', 5001);
    }
    const processStatementFile = httpsCallable(functions, 'processStatementFile');
    processStatementFile({
      fileName: file.filepath,
      isIntraday: isIntraday,
      //Comment this out to test file without saving to DB
      org: profileData.orgId,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        console.warn(result.data);
        setData((data) => [...data, ...result.data.transactions]);
        setStickyHeaders((headers) => [...headers, ...result.data.balanceIndexes]);
        setErrors(result.data.errors);

        toast.show({
          render: ({ id }) => {
            return (
              <ToastAlert
                id={id}
                title="Statement Processing completed"
                variant="solid"
                status="success"
              />
            );
          },
        });
        setIsLoading(false);
        const endTime = performance.now();
        setTimer((currentValue) =>
          (parseFloat(currentValue || 0) + (endTime - startTime) / 1000).toFixed(2),
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [profileData.orgId, toast, isIntraday]);

  const downloadAsExcel = useCallback(() => {
    setIsDownloading(true);
    const dataForDownload = data.flatMap((trans) => {
      if (trans.isBalance) {
        return trans.balances?.map((bal) => {
          return {
            ...trans,
            amount: bal.amount,
            code: bal.code,
            familyCode: bal.familyCode || '',
            subFamilyCode: bal.subFamilyCode || '',
            codeDescr: _getCodeDescr({ code: bal.code, descrOnly: true }),
          };
        });
      }
      return {
        ...trans,
        codeDescr: _getCodeDescr({
          code: trans.code,
          familyCode: trans.familyCode,
          subFamilyCode: trans.subFamilyCode,
          descrOnly: true,
        }),
      };
    });
    const headers = {
      accountNumber: 'Account',
      valueDateString: 'Value Date',
      code: 'Code',
      familyCode: 'Family Code',
      subFamilyCode: 'Sub Family Code',
      codeDescr: 'Description',
      currency: 'Currency',
      amount: 'Amount',
      narrative: 'Narrative',
      EndToEndId: 'End To End',
    };
    _downloadExcel(dataForDownload, headers);
    setIsDownloading(false);
  }, [data]);

  const getSumOfRecordCodes = useCallback(
    (records: any[], codes: string[]) => {
      return records
        .filter((balance: { code: string }) => codes.includes(balance?.code))
        .reduce(
          (total: number, balance: { currency: string | undefined; amount: number }) =>
            total + _getConvertedAmount(balance?.currency, balance?.amount, currencyRates),
          0,
        );
    },
    [currencyRates],
  );

  const stats = useMemo(() => {
    const balanceRecords = data
      .filter((item) => item.isBalance)
      .map((item) => {
        return item.balances?.map((balance) => {
          return { ...balance, currency: item.currency };
        });
      })
      .flat(1);

    return {
      numberOfAccounts: stickyHeaders?.length,
      totalOpeningAvailable: {
        code: '040',
        amount: getSumOfRecordCodes(balanceRecords, ['040', 'OPAV']),
      } as BalanceRecord,
      totalOpeningLedger: {
        code: '010',
        amount: getSumOfRecordCodes(balanceRecords, ['010', 'OPBD', '60F']),
      } as BalanceRecord,
      totalClosingAvailable: {
        code: '045',
        amount: getSumOfRecordCodes(balanceRecords, ['045', 'CLAV', '62']),
      } as BalanceRecord,
      totalClosingLedger: {
        code: '015',
        amount: getSumOfRecordCodes(balanceRecords, ['015', 'CLBD', '64']),
      } as BalanceRecord,
    };
  }, [data, stickyHeaders.length, getSumOfRecordCodes]);

  const renderUploadSection = useCallback(() => {
    return (
      <Card
        style={{
          marginBottom: 10,
          marginHorizontal: 10,
          borderColor: Colors.primary,
          borderWidth: 2,
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            textAlign: 'center',
            fontSize: Constants.TextSize.xxl,
            color: Colors.primary,
          }}
        >
          {data?.length === 0
            ? 'Get started by uploading your bank statement file'
            : 'You can add more files to this data'}
        </Text>

        <Checkbox
          colorScheme="blue"
          size="lg"
          isChecked={!!isIntraday}
          onChange={(e) => setIsIntraday(e.target.checked)}
          style={{ marginTop: 10, alignItems: 'center', justifyContent: 'center' }}
        >
          <Text style={{ fontSize: Constants.TextSize.xl }}>Upload as Intraday</Text>
          <CToolTip label="CAMT 52 is automatically uploaded as Intraday even if this is not checked" />
        </Checkbox>
        <Button
          alignSelf={'center'}
          m={5}
          width={'fit-content'}
          onClick={() => uploadAndProcessFile()}
          rightIcon={<FontAwesome name="upload" color={'white'} size={20} />}
          isLoading={isLoading}
          colorScheme="green"
        >
          Upload Files
        </Button>
        <Text
          style={{
            textAlign: 'center',
            fontSize: Constants.TextSize.regular,
            color: Colors.grey,
          }}
        >
          Supported formats : BAI2, MT940, CAMT
        </Text>
        {errors?.length > 0 && (
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
            }}
            onPress={() => setOpen(true)}
          >
            <Ionicons name="alert-circle" color={Colors.red} size={Constants.TextSize.xl} />
            <Text
              style={{ color: Colors.red, fontSize: Constants.TextSize.xl, marginLeft: 10 }}
            >{`${errors?.length} errors while processing the statement`}</Text>
          </TouchableOpacity>
        )}
        {data.length > 0 && (
          <Button
            alignSelf={'center'}
            m={5}
            width={'fit-content'}
            onClick={() => downloadAsExcel()}
            rightIcon={<MaterialCommunityIcons name="file-excel" color={'white'} size={20} />}
            isLoading={isDownloading}
            colorScheme="green"
          >
            Download to Excel
          </Button>
        )}
      </Card>
    );
  }, [
    data.length,
    uploadAndProcessFile,
    isLoading,
    errors?.length,
    downloadAsExcel,
    isDownloading,
    isIntraday,
  ]);

  const renderErrorDetails = useCallback(() => {
    return (
      <FlyOut
        open={open}
        setOpen={(value) => setOpen(value)}
        title={'Statement import errors'}
        hideButtons
      >
        <FlatList
          data={errors}
          renderItem={({ item, index }) => {
            return <Text style={AppStyles.textError}>{(index + 1).toString() + '. ' + item}</Text>;
          }}
        />
      </FlyOut>
    );
  }, [errors, open]);

  if (data?.length > 0) {
    return (
      <View style={AppStyles.container}>
        {renderErrorDetails()}
        <CurrencyRateSummaryModal isVisible={modalVisible} setIsVisible={setModalVisible} />
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <View style={{ flex: 4 }}>
            <FlatList
              data={data}
              style={{ flex: 1 }}
              stickyHeaderIndices={stickyHeaders}
              renderItem={({ item }) => {
                return <StatementTransactionItem transactionItem={item} useDateString />;
              }}
            />
          </View>
          <View style={{ flex: 2 }}>
            {renderUploadSection()}
            <Card style={AppStyles.alignCenter}>
              <Text
                style={{
                  textAlign: 'center',
                  fontSize: Constants.TextSize.xxl,
                  color: Colors.primary,
                }}
              >
                Summary & Totals
              </Text>
              <Text style={{ textAlign: 'center' }}>{stats.numberOfAccounts + ' accounts '}</Text>
              <Text style={{ textAlign: 'center' }}>{data.length + ' transactions '}</Text>
              {timer && (
                <Text style={{ textAlign: 'center', color: Colors.grey }}>
                  {'In ' + timer + ' seconds '}
                </Text>
              )}
              <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <StatementBalanceItem balance={stats.totalOpeningLedger || 0} currency="USD" />
                <StatementBalanceItem balance={stats.totalClosingLedger || 0} currency="USD" />
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <StatementBalanceItem balance={stats.totalOpeningAvailable || 0} currency="USD" />
                <StatementBalanceItem balance={stats.totalClosingAvailable || 0} currency="USD" />
              </View>
              {currencyRatesArray?.length > 0 && (
                <TouchableOpacity onPress={() => setModalVisible(true)}>
                  <Text
                    style={{
                      textAlign: 'center',
                      textDecorationLine: 'underline',
                    }}
                  >
                    * Available exchange rates
                  </Text>
                </TouchableOpacity>
              )}
            </Card>
          </View>
        </View>
      </View>
    );
  }

  return (
    <View style={AppStyles.container}>
      {renderErrorDetails()}
      <View style={AppStyles.flexCenter}>{renderUploadSection()}</View>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.transBlack,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 10,
    minWidth: Layout.window.width * 0.3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
