import React, { FC, useState, useCallback } from 'react';
import { TextStyle, TouchableOpacity, ViewStyle, View } from 'react-native';
import { Text } from './Themed';
import AppStyles from '../constants/Styles';
import { Card } from '@chakra-ui/react';
import Constants from '../constants/Constants';
import Colors from '../constants/Colors';

export type CardBadgeProps = {
  label: string;
  value: string;
  additionalInfo?: string;
  onPress?: () => void;
  additionalInfoStyle?: TextStyle;
  style?: ViewStyle;
};

const CardBadge: FC<CardBadgeProps> = ({
  label,
  value,
  additionalInfo,
  onPress,
  additionalInfoStyle,
  style,
}) => {
  const [cardHeight, setcardHeight] = useState(0);

  const handleLayout = useCallback((event) => {
    const { height, width } = event.nativeEvent.layout;
    setcardHeight((height + height) / 2);
  }, []);

  // Calculate font size based on the card's widheightth
  const fontSize = cardHeight ? Math.min(cardHeight / 3, 80) : 16; // Scaling factor can be adjusted (6 in this case)

  return (
    <TouchableOpacity disabled={!onPress} onPress={onPress} style={[{ height: '100%' }, style]}>
      <Card p={2} flex={1} alignItems={'flex-start'} justifyContent={'space-between'}>
        {/* Wrap the entire Card content in View to capture layout */}
        <View
          onLayout={handleLayout}
          style={{ flex: 1, height: '100%', justifyContent: 'space-between' }}
        >
          <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{label}</Text>

          <Text
            style={{
              fontSize,
              fontWeight: 'bold',
              marginTop: 5,
              color: Colors.midBlue,
            }}
            adjustsFontSizeToFit
          >
            {value}
          </Text>

          {additionalInfo ? (
            <Text
              style={[
                { fontSize: Constants.TextSize.small, color: Colors.grey },
                additionalInfoStyle,
              ]}
            >
              {additionalInfo}
            </Text>
          ) : (
            <View></View>
          )}
        </View>
      </Card>
    </TouchableOpacity>
  );
};

export default CardBadge;
