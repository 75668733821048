/* eslint-disable react-native/no-inline-styles */
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, useToast } from '@chakra-ui/react';
import {
  collection,
  doc,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  updateDoc,
  where,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import { Skeleton, VStack } from 'native-base';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { omit } from 'lodash';
import moment from 'moment';
import { ScrollView } from 'react-native';
import { AppContext } from '../App';
import {
  BankAccount,
  GlExportFilters,
  InputMode,
  StatementTransaction,
  StaticDataStore,
  Visibility,
} from '../commonTypes';
import BankStatementDetails from '../components/BankStatements/BankStatementDetails';
import { CModal } from '../components/CModal';
import { FilterBar } from '../components/FilterBar';
import EditGLExportFilters from '../components/GL/EditGLExportFilters';
import { SelectPresetInput } from '../components/SelectPresetInput';
import { Button, CTA, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import { DateFormat } from '../constants/Constants';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import useCategory from '../hooks/useCategories';
import { RootTabScreenProps } from '../types';
import {
  _downloadStringAsFile,
  _filterListByAccess,
  _filterToWhereClause,
  _getCodeDescr,
  _getFileName,
  _getFormattedCurrency,
  _getTimeStampString,
  _searchList,
} from '../utils/helper';
import { CAlertDialog } from '../components/CAlertDialog';

export default function ReviewAndExportAccountingScreen({
  navigation,
}: RootTabScreenProps<'ReviewAndExportAccountingScreen'>) {
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [filterConfigList, setfilterConfigList] = useState<GlExportFilters[]>([]);
  const [filterConfig, setfilterConfig] = useState<GlExportFilters>();
  const [bankStatements, setbankStatements] = useState<StatementTransaction[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<any>({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const [selectedBankStatement, setSelectedBankStatement] = useState<StatementTransaction>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isExporting, setIsExporting] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [createEnabled, setCreateEnabled] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [alertContent, setAlertContent] = useState({
    title: '',
    content: '',
    actionText: '',
    colorScheme: '',
    onAction: () => null || undefined,
  });

  const [filteredbankStatements, setFilteredbankStatements] = useState<
    StatementTransaction[] | undefined
  >();
  const { db } = useContext(AppContext);
  const { _getCategoryName } = useCategory();
  const toast = useToast();

  useEffect(() => {
    const viewQuery = query(collection(db, 'Organizations', profileData.orgId, 'Filters'));
    getDocs(viewQuery).then((querySnapshot) => {
      if (querySnapshot.empty) {
        setfilterConfig({
          name: 'Default Preset',
          description: 'This is a default Preset',
          visibility: Visibility.Private,
        });
      } else {
        const _views: GlExportFilters[] = [];
        querySnapshot.forEach((doc) => {
          _views.push({
            ...doc.data(),
            id: doc.id,
          } as GlExportFilters);
        });
        const filteredViews = _filterListByAccess(profileData.uid, profileData.userGroups, _views);
        setfilterConfigList(filteredViews);
        try {
          AsyncStorage.getItem('defaultGlExportPreset').then((value) => {
            if (value !== null) {
              const defaultView = _views.find((v) => v.id === value);
              return setfilterConfig(defaultView);
            }
            setfilterConfig(_views[0]);
          });
        } catch (e) {
          // error reading value
          setfilterConfig(_views[0]);
        }
      }
    });
  }, [db, profileData.orgId, profileData.uid, profileData.userGroups, staticData?.accountGroups]);

  const applyPresetToData = useCallback(
    (data: StatementTransaction[]) => {
      if (filterConfig) {
        if (filterConfig.excludeSystemSuspense) {
          data = data.filter((item) => {
            return !item?.journals?.some((journal) => {
              return (
                journal.debit.account === 'System Suspense' ||
                journal.credit.account === 'System Suspense'
              );
            });
          });
        }
        if (filterConfig.excludeZeroAmount) {
          data = data.filter((item) => {
            return !item?.journals?.some((journal) => {
              return journal.debit.amount === 0 || journal.credit.amount === 0;
            });
          });
        }
        if (filterConfig.excludedCategories && filterConfig.excludedCategories.length > 0) {
          data = data.filter((item) => {
            return !filterConfig.excludedCategories?.includes(item.category);
          });
        }
        if (
          filterConfig.excludedTransactionCodes &&
          filterConfig.excludedTransactionCodes.length > 0
        ) {
          data = data.filter((item) => {
            return !filterConfig.excludedTransactionCodes?.includes(item.combinedCode);
          });
        }

        if (filterConfig.excludedGlAccounts && filterConfig.excludedGlAccounts.length > 0) {
          data = data.filter((item) => {
            return !item.journals?.some((journal) => {
              return (
                filterConfig.excludedGlAccounts?.includes(journal.debit.account) ||
                filterConfig.excludedGlAccounts?.includes(journal.credit.account)
              );
            });
          });
        }
        setbankStatements(data);
      } else {
        setbankStatements(data);
      }
    },
    [filterConfig],
  );

  const fetchBankStatements = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Journals');
      setbankStatements([]);
      const constraints = _filterToWhereClause(omit(filterValues, ['fromDate', 'toDate']));
      let q = query(
        collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
        where('isBalance', '==', false),
        where(
          'valueDate',
          '>=',
          moment(filterValues.fromDate as Date)
            .startOf('D')
            .toDate(),
        ),
        where(
          'valueDate',
          '<=',
          moment(filterValues.toDate as Date)
            .endOf('D')
            .toDate(),
        ),
        where('journals', '!=', null),
        ...constraints,
      );
      // const snapshot = await getCountFromServer(q);
      // alert(snapshot.data().count);
      q = query(
        q,
        orderBy('valueDate'), //limit(10)
      );

      const querySnapshot = await getDocs(q);
      const _bankStatements: StatementTransaction[] = [];
      querySnapshot.forEach((doc) => {
        _bankStatements.push({ ...doc.data(), id: doc.id } as StatementTransaction);
      });
      applyPresetToData(_bankStatements);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }

      setIsLoading(false);
    },
    [db, profileData?.orgId, applyPresetToData],
  );

  const loadMoreBankStatements = useCallback(
    async (account?: BankAccount, filterValues?: any) => {
      if (account && lastVisible && !isLoadingMore) {
        console.log('Fetching more statements');
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
          where('accountId', '==', account?.id),
          where('isBalance', '==', false),
          ...constraints,
          startAfter(lastVisible),
          limit(10),
        );
        const querySnapshot = await getDocs(q);
        const _bankStatements: StatementTransaction[] = [];
        querySnapshot.forEach((doc) => {
          _bankStatements.push({ ...doc.data(), id: doc.id } as StatementTransaction);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setbankStatements((prevValue) => [...prevValue, ..._bankStatements]);
        setIsLoadingMore(false);
      }
    },
    [db, isLoadingMore, lastVisible, profileData.orgId],
  );

  useEffect(() => {
    if (filter?.fromDate && filter?.toDate) {
      setIsLoading(true);
      setLastVisible(null);
      fetchBankStatements(filter);
    } else {
      setLastVisible(null);
      setbankStatements([]);
    }
  }, [fetchBankStatements, filter]);

  const ContentLoader = useCallback(() => {
    return (
      <VStack
        w="99%"
        height={100}
        marginTop={2}
        marginLeft={2}
        padding={5}
        borderWidth="1"
        space={8}
        overflow="hidden"
        rounded="md"
        _dark={{
          borderColor: 'coolGray.500',
        }}
        _light={{
          borderColor: 'coolGray.200',
        }}
      >
        <Skeleton.Text px="4" />
      </VStack>
    );
  }, []);

  const onSearch = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredbankStatements(_searchList(bankStatements, searchValue));
      } else {
        setFilteredbankStatements(undefined);
      }
    },
    [bankStatements],
  );

  const journalEntryRowCount = useMemo(() => {
    return {
      allRows: bankStatements.reduce((count, obj) => count + (obj.journals?.length || 0) * 2, 0),
      systemSuspenseRows: bankStatements.reduce(
        (count, obj) =>
          count +
          (obj.journals?.filter(
            (journal) =>
              journal.debit.account === 'System Suspense' ||
              journal.credit.account === 'System Suspense',
          ).length || 0),
        0,
      ),
      pendingRows: bankStatements.reduce(
        (count, obj) =>
          count + (obj.journals?.filter((journal) => journal.status === 'Pending').length || 0) * 2,
        0,
      ),
      readyToExportRows: bankStatements.reduce(
        (count, obj) =>
          count +
          (obj.journals?.filter(
            (journal) =>
              journal.status === 'Pending' &&
              journal.debit.account !== 'System Suspense' &&
              journal.credit.account !== 'System Suspense',
          ).length || 0) *
            2,
        0,
      ),
    };
  }, [bankStatements, isExporting]);

  const onExport = useCallback(
    async (data: StatementTransaction[]) => {
      console.warn('Exporting');
      setIsExporting(true);
      const exportTemplate = staticData?.orgInfo?.FileExportTemplate?.['Oracle Cloud'];
      // console.warn(exportTemplate);

      let exportData = '';
      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(db));

      let operationCounter = 0;
      let batchIndex = 0;
      data?.map((statement) => {
        let updatedJournals = statement.journals;
        statement.journals?.map((journal, journalIndex) => {
          if (journal.status === 'Pending') {
            if (updatedJournals) {
              updatedJournals[journalIndex].status = 'Exported';
            }
            let creditRow = '';
            let debitRow = '';
            exportTemplate?.fields?.map((field) => {
              if (creditRow !== '') {
                creditRow += exportTemplate.delimiter;
                debitRow += exportTemplate.delimiter;
              }
              switch (field.type) {
                case 'constant':
                  creditRow += field.value;
                  debitRow += field.value;
                  break;
                case 'field':
                  if (field.value) {
                    let value = field.value
                      .split('.')
                      .reduce((acc, key) => acc && acc[key], statement) as any;
                    if (!value) {
                      value = field.value.split('.').reduce((acc, key) => acc && acc[key], journal);
                    }
                    if (field.value === 'category') {
                      value = _getCategoryName(value);
                    }
                    if (field.formatType === 'date') {
                      value = _getTimeStampString(value, field.format);
                    } else if (field.formatType === 'number') {
                      if (field.format === '0.00') {
                        value = parseFloat(value).toFixed(2);
                      }
                    }
                    creditRow += value;
                    debitRow += value;
                  }
                  break;
                case 'function':
                  let value = '';
                  if (field.value === 'today') {
                    value = moment().format(field.format);
                  }
                  creditRow += value;
                  debitRow += value;
                  break;
                case 'expression':
                  console.warn('expression', field.name, field.value);
                  const evaluateExpression = new Function(
                    'account', // Explicitly pass 'debit' as a parameter
                    `return ${field.value};`,
                  );

                  const debit = evaluateExpression(journal.debit.account); // Pass only the 'debit' object
                  const credit = evaluateExpression(journal.credit.account); // Pass only the 'credit' object
                  creditRow += credit;
                  debitRow += debit;

                  break;
              }
            });
            exportData += creditRow + '\n' + debitRow + '\n';
          }
        });
        if (statement.id) {
          batchArray[batchIndex].update(
            doc(db, 'Organizations', profileData.orgId, 'BankStatement', statement.id),
            { journals: updatedJournals },
          );
          operationCounter++;
          if (operationCounter === 499) {
            batchArray.push(writeBatch(db));
            batchIndex++;
            operationCounter = 0;
          }
        }
      });
      if (exportTemplate?.fileName) {
        const fileName = _getFileName(exportTemplate.fileName);
        const promises = batchArray.map(
          async (batch) =>
            // eslint-disable-next-line no-return-await
            await batch.commit().catch((e) => {
              // console.log(e);
              //errorsExist = true;
              console.warn('error encountered');
              console.warn(e);
              toast({
                title: 'Unable to update the status of some of the journals',
                description: 'This could be a permission issue',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }),
        );
        await Promise.all(promises)
          .then(() => {
            console.warn('Completed Updating status of journals');
          })
          .catch((error) => {
            console.warn('Something went wrong');
            console.warn(error);
          });

        _downloadStringAsFile(fileName, exportData);
        setIsExporting(false);
        toast({
          title: `Exported ${fileName}`,
          description: 'The file has been exported',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [staticData],
  );

  return (
    <ScrollView style={AppStyles.container}>
      <CAlertDialog
        open={showAlert}
        setOpen={setShowAlert}
        title={alertContent.title}
        content={alertContent.content}
        actionText={alertContent.actionText}
        colorScheme={alertContent.colorScheme}
        onAction={alertContent.onAction}
      />
      {selectedBankStatement && (
        <CModal
          width={Layout.window.width * 0.8}
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={
            _getCodeDescr({
              code: selectedBankStatement.code,
              familyCode: selectedBankStatement.familyCode,
              subFamilyCode: selectedBankStatement.subFamilyCode,
            }) || 'Bank Statement'
          }
          hideButtons
        >
          <BankStatementDetails
            setIsVisible={setIsDetailVisible}
            statementTransaction={selectedBankStatement}
            onStatementTransactionUpdated={() => null}
            mode={InputMode.VIEW}
          />
        </CModal>
      )}
      <View
        style={{
          flexDirection: 'row',
          marginLeft: 10,
          marginBottom: 10,
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <View style={[AppStyles.marginTop, AppStyles.flex1, AppStyles.marginHorizontal]}>
          <FilterBar
            onSearch={onSearch}
            searchPlaceholder={'Search loaded GL'}
            filterParams={[
              // {
              //   title: 'Sort by',
              //   options: [
              //     { label: 'Amount', value: 'amount' },
              //     { label: 'Dr / Cr', value: 'CdtDbtInd' },
              //   ],
              //   type: 'sort',
              //   field: 'orderBy',
              // },
              {
                title: 'From Date',
                type: 'DateRange',
                field: 'fromDate',
              },
              {
                title: 'To Date',
                type: 'DateRange',
                field: 'toDate',
              },
              {
                title: 'Account',
                options: [],
                type: 'AccountSelect',
                field: 'accountId',
                hide: !filter.fromDate || !filter.toDate,
                style: { marginLeft: 20 },
              },
            ]}
            filter={filter}
            setFilter={setFilter}
          />

          {!editEnabled && !createEnabled && (
            <View style={AppStyles.flexRowCenterSpaceBetween}>
              <View style={AppStyles.flexRowCenter}>
                <SelectPresetInput
                  data={filterConfigList}
                  onSelect={async (v) => {
                    setfilterConfig(v);
                    await AsyncStorage.setItem('defaultGlExportPreset', v.id);
                  }}
                  selectedValue={filterConfig}
                  defaultName="Select Preset"
                  renderItem={(item: GlExportFilters) => (
                    <View>
                      <Text style={AppStyles.textRowTitle}>{item?.name}</Text>
                      <Text style={AppStyles.textSubTitle}>{item?.description}</Text>
                    </View>
                  )}
                />
                {filterConfig?.id && (
                  <CTA
                    label="Edit"
                    icon="pencil"
                    buttonColor={Colors.orange}
                    onPress={() => {
                      setEditEnabled(true);
                    }}
                    style={AppStyles.marginLeft}
                  />
                )}
              </View>
              <View style={AppStyles.flexRowCenter}>
                <Button
                  label="Create New Preset"
                  color={'green'}
                  icon={'plus-square'}
                  onPress={() => {
                    setCreateEnabled(true);
                  }}
                />
                {journalEntryRowCount.pendingRows > 0 && (
                  <Button
                    ml={2}
                    label="Export File"
                    color={'blue'}
                    icon={'plus-square'}
                    onPress={() => {
                      if (journalEntryRowCount.readyToExportRows > 0) {
                        setAlertContent({
                          title: `Export ${journalEntryRowCount.readyToExportRows} rows`,
                          content: `Are you sure you want to export the file? Journals with System suspense accounts will not be exported. The journals will be frozen once exported.`,
                          actionText: 'Export',
                          colorScheme: 'blue',
                          onAction: () =>
                            onExport(
                              bankStatements.filter((item) => {
                                return item.journals.some(
                                  (journal) =>
                                    journal.status === 'Pending' &&
                                    journal.debit.account !== 'System Suspense' &&
                                    journal.credit.account !== 'System Suspense',
                                );
                              }),
                            ),
                        });

                        setShowAlert(true);
                      } else {
                        toast({
                          title: 'No Journals to Export',
                          description:
                            'The pending journals have system suspense accounts and hence cannot be exported',
                          status: 'warning',
                          duration: 5000,
                          isClosable: true,
                        });
                      }
                    }}
                  />
                )}
              </View>
            </View>
          )}

          {editEnabled && (
            <EditGLExportFilters
              filterConfig={filterConfig}
              setfilterConfig={setfilterConfig}
              isVisible={editEnabled}
              setIsVisible={setEditEnabled}
              mode={InputMode.EDIT}
            />
          )}
          {createEnabled && (
            <EditGLExportFilters
              filterConfig={filterConfig}
              setfilterConfig={(fc) => {
                setfilterConfig(fc);
                fetchBankStatements(filter);
              }}
              isVisible={createEnabled}
              setIsVisible={setCreateEnabled}
              mode={InputMode.CREATE}
            />
          )}
        </View>
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 160,
          justifyContent: 'space-between',
        }}
      >
        {(!filter.fromDate || !filter.toDate) && (
          <View style={AppStyles.width60Center}>
            <Text style={AppStyles.textRowTitle}>Please select the date range</Text>
          </View>
        )}
        {filter.fromDate &&
          filter.toDate &&
          !isLoading &&
          bankStatements.length === 0 &&
          (!filteredbankStatements || filteredbankStatements.length === 0) && (
            <View
              style={{
                flex: 1,
                width: Layout.window.width * 0.6,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={AppStyles.textTitle}>No Journals found</Text>
            </View>
          )}
        {isLoading && <ContentLoader />}
        {(bankStatements.length > 0 || (filteredbankStatements?.length || 0) > 0) && (
          <ScrollView>
            <Text style={[{ alignSelf: 'flex-end', color: Colors.grey }, AppStyles.marginRight25]}>
              {`${journalEntryRowCount.allRows} Rows`}
              {journalEntryRowCount.pendingRows > 0
                ? ` | ${journalEntryRowCount.pendingRows} Pending Rows`
                : ''}
              {journalEntryRowCount.systemSuspenseRows > 0 && (
                <>
                  {' | '}
                  <Text
                    style={{ color: 'red' }}
                  >{`${journalEntryRowCount.systemSuspenseRows} System Suspense Rows`}</Text>
                </>
              )}
            </Text>
            <TableContainer>
              <Table size={'sm'}>
                <Thead>
                  <Tr>
                    <Th>Ledger</Th>
                    <Th>Date</Th>
                    <Th>Account</Th>
                    <Th>Currency</Th>
                    <Th>Direction</Th>
                    <Th>Amount</Th>
                    <Th>Category</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {(filteredbankStatements || bankStatements)?.map((item) => {
                    return item?.journals?.map((journal, index) => {
                      return (
                        <>
                          <Tr
                            key={index}
                            onClick={() => {
                              setSelectedBankStatement(item);
                              setSelectedIndex(index);
                              setIsDetailVisible(true);
                            }}
                          >
                            <Td>{journal.ledger}</Td>
                            <Td>{moment(journal.date, 'YYYYMMDD').format(DateFormat.DATE_ONLY)}</Td>
                            <Td
                              color={
                                journal.debit.account === 'System Suspense' ? 'red' : undefined
                              }
                            >
                              {journal.debit.account}
                            </Td>
                            <Td>{journal.debit.currency}</Td>
                            <Td>Dr</Td>
                            <Td>{_getFormattedCurrency(journal.debit.amount)}</Td>
                            <Td>{_getCategoryName(item.category)}</Td>
                            <Td>{journal.status}</Td>
                          </Tr>
                          <Tr
                            onClick={() => {
                              setSelectedBankStatement(item);
                              setSelectedIndex(index);
                              setIsDetailVisible(true);
                            }}
                          >
                            <Td>{journal.ledger}</Td>
                            <Td>{moment(journal.date, 'YYYYMMDD').format(DateFormat.DATE_ONLY)}</Td>
                            <Td
                              color={
                                journal.credit.account === 'System Suspense' ? 'red' : undefined
                              }
                            >
                              {journal.credit.account}
                            </Td>
                            <Td>{journal.credit.currency}</Td>
                            <Td>Cr</Td>
                            <Td>{_getFormattedCurrency(journal.debit.amount)}</Td>
                            <Td>{_getCategoryName(item.category)}</Td>
                            <Td>{journal.status}</Td>
                          </Tr>
                        </>
                      );
                    });
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ScrollView>
        )}
      </View>
    </ScrollView>
  );
}
