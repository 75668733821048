/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore';
import isEmpty from 'lodash/isEmpty';
import { Skeleton } from 'native-base';
import { FlatList, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import { Card } from '@chakra-ui/react';
import { AppContext } from '../App';
import { BankAccount, InputMode, StatementTransaction, StaticDataStore } from '../commonTypes';
import BankStatementDetails from '../components/BankStatements/BankStatementDetails';
import { CModal } from '../components/CModal';
import { DropDownSpecial } from '../components/DropDownSpecial';
import { FilterBar } from '../components/FilterBar';
import { StatementBalanceWidget } from '../components/StatementBalanceWidget';
import { StatementTransactionItem } from '../components/StatementTransactionItem';
import { AmountText } from '../components/StyledText';
import { Text, View } from '../components/Themed';
import BankStatementWidget from '../components/Widgets/BankStatementWidget';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _filterToWhereClause, _getCodeDescr, _isValidDate, _searchList } from '../utils/helper';

export default function BankStatementScreen({
  navigation,
  route,
}: RootTabScreenProps<'BankStatementScreen'>) {
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const [bankStatements, setbankStatements] = useState<StatementTransaction[]>([]);
  const [bankBalance, setBankBalance] = useState<StatementTransaction>();
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const [selectedBankStatement, setSelectedBankStatement] = useState<StatementTransaction>();
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [bankAccount, setBankAccount] = useState<BankAccount>();

  // /{ id: '000570303ZAR' }

  const [filteredbankStatements, setFilteredbankStatements] = useState<
    StatementTransaction[] | undefined
  >();
  const { db } = useContext(AppContext);

  useEffect(() => {
    if (route.params?.id) {
      getDoc(doc(db, 'Organizations', profileData.orgId, 'BankStatement', route.params?.id)).then(
        (doc) => {
          if (doc.exists()) {
            const data = { ...doc.data(), id: doc.id } as StatementTransaction;
            setSelectedBankStatement(data);
            setSelectedIndex(0);
            setIsDetailVisible(true);
            route.params.id = undefined;
          }
        },
      );
    }
  }, [route.params?.id]);

  const fetchBankStatements = useCallback(
    async (account: BankAccount, filterValues: any) => {
      console.log('Fetching bank statements');
      console.warn(filterValues.valueDate, _isValidDate(filterValues.valueDate));

      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
        where('accountId', '==', account?.id),
        where('isBalance', '==', false),
        ...constraints,
        orderBy('valueDate', 'desc'),
        limit(10),
      );

      const querySnapshot = await getDocs(q);
      const _bankStatements: StatementTransaction[] = [];
      querySnapshot.forEach((doc) => {
        _bankStatements.push({ ...doc.data(), id: doc.id } as StatementTransaction);
      });
      setbankStatements(_bankStatements);
      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }

      setIsLoading(false);
    },
    [db, profileData?.orgId],
  );

  const loadMoreBankStatements = useCallback(
    async (account?: BankAccount, filterValues?: any) => {
      if (account && lastVisible && !isLoadingMore) {
        console.log('Fetching more statements');
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
          where('accountId', '==', account?.id),
          where('isBalance', '==', false),
          ...constraints,
          orderBy('valueDate', 'desc'),
          startAfter(lastVisible),
          limit(20),
        );
        const querySnapshot = await getDocs(q);
        const _bankStatements: StatementTransaction[] = [];
        querySnapshot.forEach((doc) => {
          _bankStatements.push({ ...doc.data(), id: doc.id } as StatementTransaction);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setbankStatements((prevValue) => [...prevValue, ..._bankStatements]);
        setIsLoadingMore(false);
      }
    },
    [db, isLoadingMore, lastVisible, profileData.orgId],
  );

  const fetchBankBalance = useCallback(
    async (account: BankAccount) => {
      console.log('Fetching bank statements');
      const q = query(
        collection(db, 'Organizations', profileData.orgId, 'BankStatement'),
        where('accountId', '==', account?.id),
        where('isBalance', '==', true),
        orderBy('valueDate', 'desc'),
        limit(1),
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setBankBalance(undefined);
      } else {
        setBankBalance({
          ...querySnapshot.docs[0].data(),
          id: querySnapshot.docs[0].id,
        } as StatementTransaction);
      }
    },
    [db, profileData?.orgId],
  );

  useEffect(() => {
    if (bankAccount) {
      setIsLoading(true);
      setLastVisible(null);
      console.warn(filter);
      fetchBankStatements(bankAccount, filter);
      fetchBankBalance(bankAccount);
    } else {
      setLastVisible(null);
      setBankBalance(undefined);
      setbankStatements([]);
    }
  }, [bankAccount, fetchBankBalance, fetchBankStatements, filter]);

  const ContentLoader = useCallback(() => {
    return (
      <Card ml={2} mr={2} mt={0.2} p={2} style={{ borderRadius: 0 }}>
        <Skeleton.Text px="4" />
      </Card>
    );
  }, []);

  const onSearch = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredbankStatements(_searchList(bankStatements, searchValue));
      } else {
        setFilteredbankStatements(undefined);
      }
    },
    [bankStatements],
  );

  return (
    <View style={AppStyles.container}>
      {selectedBankStatement && (
        <CModal
          width={Layout.window.width * 0.8}
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={
            _getCodeDescr({
              code: selectedBankStatement.code,
              familyCode: selectedBankStatement.familyCode,
              subFamilyCode: selectedBankStatement.subFamilyCode,
              short: true,
            }) || 'Bank Statement'
          }
          hideButtons
        >
          <BankStatementDetails
            setIsVisible={setIsDetailVisible}
            statementTransaction={selectedBankStatement}
            onStatementTransactionUpdated={() => null}
            mode={InputMode.VIEW}
          />
        </CModal>
      )}
      <Card p={3} alignSelf={'stretch'}>
        <View style={[AppStyles.flexRowCenterSpaceBetween, { paddingHorizontal: 20 }]}>
          <View style={{ alignSelf: 'flex-start' }}>
            <DropDownSpecial
              data={staticData.bankAccounts || []}
              title={'Acccount'}
              onSelect={setBankAccount}
              selectedValue={bankAccount}
              label={'accountNumber'}
              style={{ marginTop: 0 }}
            />
          </View>
          <BankStatementWidget type="small" />
        </View>
      </Card>
      <View
        style={{
          flexDirection: 'row',
          marginLeft: 35,
          marginBottom: 10,
          alignItems: 'center',
          zIndex: 9999,
          justifyContent: 'space-between',
        }}
      >
        {(bankStatements && bankStatements.length > 0) || !isEmpty(filter) ? (
          <Card p={2} pt={2} mt={2} alignSelf={'flex-start'}>
            <FilterBar
              onSearch={onSearch}
              searchPlaceholder={'Search loaded transactions'}
              filterParams={[
                // {
                //   title: 'Sort by',
                //   options: [
                //     { label: 'Amount', value: 'amount' },
                //     { label: 'Dr / Cr', value: 'CdtDbtInd' },
                //   ],
                //   type: 'sort',
                //   field: 'orderBy',
                // },
                {
                  title: 'Cr/Dr',
                  options: [
                    { label: 'Credits Only', value: 'C' },
                    { label: 'Debits Only', value: 'D' },
                  ],
                  type: 'select',
                  field: 'CdtDbtInd',
                },
                {
                  title: 'Value Date',
                  type: 'DateRange',
                  field: 'valueDate',
                },
                {
                  title: 'Category',
                  type: 'select',
                  field: 'category',
                  options: staticData.categories?.map((cat) => ({
                    label: cat.name,
                    value: cat.id || cat.name,
                  })),
                },
              ]}
              filter={filter}
              setFilter={setFilter}
            />
          </Card>
        ) : (
          <View />
        )}
        {bankAccount && (
          <Card p={2} pt={2} m={2}>
            <Text style={{ color: Colors.black, fontWeight: 'bold', marginBottom: 5 }}>
              System Balance
            </Text>
            <AmountText
              amount={bankAccount?.balance || 0}
              currency={bankAccount.currency}
              size={Constants.TextSize.large}
              color={Colors.primary}
            />
          </Card>
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 220,
          paddingHorizontal: 25,
          justifyContent: 'space-between',
        }}
      >
        {!bankAccount && (
          <View style={AppStyles.width60Center}>
            <Text style={AppStyles.textRowTitle}>Please select a Bank account</Text>
          </View>
        )}
        {bankAccount && !isLoading && bankStatements.length === 0 && (
          <View
            style={{
              flex: 1,
              width: Layout.window.width * 0.6,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text style={AppStyles.textTitle}>No transactions found</Text>
          </View>
        )}
        {(isLoading || bankStatements.length > 0) && (
          <FlatList
            style={{ paddingBottom: 20 }}
            data={
              isLoading ? [{} as StatementTransaction] : filteredbankStatements || bankStatements
            }
            keyExtractor={(item) => item.id || ''}
            renderItem={({ item, index }) => {
              if (isLoading) {
                return ContentLoader();
              }
              return (
                <TouchableOpacity
                  onPress={() => {
                    // console.warn(item);
                    setSelectedBankStatement(item);
                    setSelectedIndex(index);
                    setIsDetailVisible(true);
                  }}
                >
                  <StatementTransactionItem
                    transactionItem={item}
                    style={index === 0 ? { borderTopLeftRadius: 10, borderTopRightRadius: 10 } : {}}
                  />
                </TouchableOpacity>
              );
            }}
            ListFooterComponent={() => {
              if (isLoadingMore) {
                return ContentLoader();
              }
              return null;
            }}
            onEndReached={() => loadMoreBankStatements(bankAccount, filter)}
            refreshing={isLoadingMore || isLoading}
          />
        )}
        <View>
          {bankBalance && (
            <StatementBalanceWidget transactionItem={bankBalance} account={bankAccount} />
          )}
        </View>
      </View>
    </View>
  );
}
