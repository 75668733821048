import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import { ApprovalTimelineItemStatus, StaticDataStore, Status } from '../../commonTypes';
import CardBadge from '../CardBadge';
import { AppContext } from '../../App';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

const PendingApprovalsWidget: FC<{ readonly?: boolean }> = ({ readonly = false }) => {
  const { profileData } = useSelector((store: StaticDataStore) => store);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingApprovalsCount, setPendingApprovalsCount] = useState(0);

  const navigation = useNavigation();
  const { db } = useContext(AppContext);

  useEffect(() => {
    console.log('Fetching Pending Approvals widget');
    if (!profileData?.orgId) {
      return;
    }
    setIsLoading(true);

    const q = query(
      collection(db, 'Organizations', profileData?.orgId, 'Approvals'),
      where('status', '==', ApprovalTimelineItemStatus.PENDING),
      where('approvers', 'array-contains', profileData?.uid),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setPendingApprovalsCount(querySnapshot.size);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [db, profileData]);

  return (
    <CardBadge
      label="Pending Approvals"
      value={pendingApprovalsCount.toString()}
      onPress={
        readonly
          ? undefined
          : () => {
              navigation.navigate('PendingApprovalsScreen');
            }
      }
    />
  );
};

export default PendingApprovalsWidget;
