import React, { FC, useCallback } from 'react';

import { TextStyle, ViewStyle } from 'react-native';

import CInput from './CInput';
import { View, Text } from './Themed';
import AppStyles from '../constants/Styles';
import { Address } from '../commonTypes';
import CountryInput from './CountryInput';
import { omit } from 'lodash';

export type AddressFormProps = {
  label?: string;
  adddressValue?: Address;
  onAddressChange: (field: any, value: Address) => void;
  disabled?: boolean;
  style?: ViewStyle;
  labelStyle?: TextStyle;
  isCountryRequired?: boolean;
  errors?: any;
  setErrors?: (value: any) => void;
  groupKey?: string;
};
const AddressForm: FC<AddressFormProps> = ({
  label,
  adddressValue = {} as Address,
  onAddressChange,
  disabled,
  style,
  labelStyle,
  isCountryRequired,
  errors = {},
  setErrors,
  groupKey = 'address',
}) => {
  const onTextChange = useCallback(
    (field: keyof Address, value: string) => {
      onAddressChange(groupKey, { ...adddressValue, [field]: value } as Address);
    },
    [adddressValue, onAddressChange],
  );

  const setAddressErrors = useCallback(
    (field: keyof Address) => {
      setErrors?.({ ...errors, [groupKey]: omit(errors[groupKey], field) });
    },
    [errors, setErrors],
  );
  return (
    <View style={[style]} pointerEvents={disabled ? 'none' : 'auto'}>
      {label && (
        <Text style={[AppStyles.textFormSectionHeader, AppStyles.marginBottom, labelStyle]}>
          {label}
        </Text>
      )}
      <View style={AppStyles.flexRow}>
        <CInput
          label="Address Line 1"
          fieldKey="addressLine1"
          fieldValue={adddressValue.addressLine1}
          onTextChange={onTextChange}
          disabled={disabled}
          errors={errors?.[groupKey] || {}}
          setErrors={setAddressErrors}
        />
        <CInput
          label="Address Line 2"
          fieldKey="addressLine2"
          fieldValue={adddressValue.addressLine2}
          onTextChange={onTextChange}
          disabled={disabled}
          ml={2}
          errors={errors?.[groupKey] || {}}
          setErrors={setAddressErrors}
        />
      </View>
      <View style={AppStyles.flexRow}>
        <CInput
          label="City"
          fieldKey="city"
          fieldValue={adddressValue.city}
          onTextChange={onTextChange}
          disabled={disabled}
          errors={errors?.[groupKey] || {}}
          setErrors={setAddressErrors}
        />
        <CInput
          setErrors={setAddressErrors}
          label="State"
          fieldKey="state"
          fieldValue={adddressValue.state}
          onTextChange={onTextChange}
          disabled={disabled}
          ml={2}
          errors={errors?.[groupKey] || {}}
        />
      </View>
      <View style={AppStyles.flexRowCenter}>
        <CInput
          label="Postal Code"
          fieldKey="zipCode"
          fieldValue={adddressValue.zipCode}
          onTextChange={onTextChange}
          disabled={disabled}
          errors={errors?.[groupKey] || {}}
          setErrors={setAddressErrors}
        />

        <CountryInput
          onSelect={(v) => {
            setAddressErrors('country');
            onTextChange('country', v?.[0]);
          }}
          value={adddressValue.country}
          style={AppStyles.marginLeft}
          isReadOnly={disabled}
          isRequired={isCountryRequired}
          hasError={!!errors?.[groupKey]?.country}
          formProps={{ ml: 2 }}
        />
      </View>
    </View>
  );
};

export default AddressForm;
