/* eslint-disable react-native/no-inline-styles */

import React, { Dispatch, SetStateAction } from 'react';

import omit from 'lodash/omit';
import { useSelector } from 'react-redux';

import { CFlatList } from './CFlatList';
import { CModal } from './CModal';
import { Text, View } from './Themed';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { StaticDataStore } from '../comonTypes';
import { Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

export type CurrencyRateSummaryModalProps = {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
};

export default function CurrencyRateSummaryModal({
  isVisible,
  setIsVisible,
}: CurrencyRateSummaryModalProps) {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  return (
    <CModal
      width={Layout.window.width * 0.4}
      hideSaveButton
      open={isVisible}
      setOpen={(value) => setIsVisible(value)}
      title={'Exchange Rates'}
    >
      <TableContainer>
        <Table variant="simple" size={'sm'}>
          <TableCaption>Exchange Rates</TableCaption>
          <Thead>
            <Tr>
              <Th>From Currency</Th>
              <Th>To Currency</Th>
              <Th isNumeric>Exchange Rate</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.keys(omit(staticData.currencyRates, 'date')).map((item) => {
              return (
                <Tr key={item}>
                  <Td>{'USD'}</Td>
                  <Td>{item}</Td>
                  <Td isNumeric>{staticData.currencyRates?.[item]}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </CModal>
  );
}
