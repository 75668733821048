/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { Icon, Input } from 'native-base';
import { StyleSheet, ViewStyle } from 'react-native';
import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';
import moment from 'moment';

import Colors from '../constants/Colors';
import AppStyles from '../constants/Styles';
import { DateInput } from './DateInput';
import { FilterContext } from './FilterContext';
import { MultiTextInput } from './MultiTextInput';
import { Text, View } from './Themed';
import { StaticDataStore } from '../commonTypes';
import { Flex } from '@chakra-ui/react';
export type FilterParam = {
  title: string;
  options?: { label: string; value: string }[];
  type: string;
  selectedValue?: string;
  field: string;
  hide?: boolean;
  minWidth?: number;
  maxWidth?: number;
  style?: ViewStyle;
};

export type FilterProps = {
  hideSearch?: boolean;
  searchPlaceholder?: string;
  filterParams?: FilterParam[];
  filter?: any;
  setFilter?: Dispatch<SetStateAction<object>>;
  updateFilter?: (filter: any) => void; // Incase if not dispatch - E.g. for Reporting
  onSearch: (searchValue: string) => void;
  isOnModal?: boolean;
};
export function FilterBar({
  hideSearch,
  filterParams,
  onSearch,
  searchPlaceholder,
  filter,
  setFilter,
  updateFilter,
  isOnModal,
}: FilterProps) {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const [searchText, setSearchText] = useState('');

  const delayedQuery = useCallback(
    debounce((q) => onSearch(q), 500),
    [onSearch],
  );

  const onChange = (value: string) => {
    setSearchText(value);
    delayedQuery(value);
  };

  const renderFilterParams = useCallback(() => {
    return filterParams?.map((filterParam) => {
      if (filterParam.hide) {
        return null;
      }
      switch (filterParam.type) {
        case 'select':
          return (
            <Select
              chakraStyles={{
                control: (provided) => ({
                  ...provided,
                  marginLeft: '10px',
                  minWidth: filterParam.minWidth || '250px', // Minimum width for the select input
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: '3px', // Reduce padding around arrow
                  fontSize: '12px', // Reduce arrow size
                }),
                menuList: (provided) => ({
                  ...provided,
                  width: 'auto', // Fit content
                  minWidth: '100%', // At least the width of the select input
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: '14px', // Change font size of the placeholder
                }),
              }}
              placeholder={filterParam.title}
              isClearable
              key={filterParam.field}
              {...filterParam}
              value={
                filter[filterParam.field] && {
                  value: filter[filterParam.field],
                  label: filterParam.options?.find(
                    (option) => option.value === filter[filterParam.field],
                  )?.label,
                }
              }
              onChange={(val) => {
                setFilter?.((currentValue: any) => {
                  if (val) {
                    return { ...currentValue, [filterParam.field]: val.value };
                  }
                  return omit(currentValue, [filterParam.field]);
                });
                if (val) {
                  updateFilter?.({ ...filter, [filterParam.field]: val.value });
                } else {
                  updateFilter?.(omit(filter, [filterParam.field]));
                }
              }}
            />
          );
        case 'AccountSelect':
          // Custom option renderer
          const CustomOption = (props) => {
            const { data, innerRef, innerProps } = props;
            return (
              <Flex
                ref={innerRef}
                {...innerProps}
                align="center"
                p={2}
                cursor="pointer"
                _hover={{ bg: 'gray.100' }}
                justifyContent={'space-between'}
              >
                <View>
                  <Text>{data.label}</Text>
                  <Text>{data.number}</Text>
                </View>
                <Text style={{ marginLeft: 5 }}>{data.currency}</Text>
              </Flex>
            );
          };
          return (
            <Select
              chakraStyles={{
                control: (provided) => ({
                  ...provided,
                  marginRight: '10px',
                  minWidth: '260px', // Minimum width for the select input
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: '3px', // Reduce padding around arrow
                  fontSize: '12px', // Reduce arrow size
                }),
                menuList: (provided) => ({
                  ...provided,
                  width: 'auto', // Fit content
                  minWidth: '100%', // At least the width of the select input
                  whiteSpace: 'nowrap', // Prevent text from wrapping
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: '14px', // Change font size of the placeholder
                }),
              }}
              placeholder={filterParam.title}
              isClearable
              key={filterParam.field}
              {...filterParam}
              options={staticData.bankAccounts?.map((ag) => {
                return {
                  value: ag.id,
                  label: ag.accountName,
                  currency: ag.currency,
                  number: ag.accountNumber,
                };
              })}
              value={
                filter[filterParam.field] && [
                  {
                    value: filter[filterParam.field],
                    label:
                      staticData.bankAccounts?.find((ag) => ag.id === filter[filterParam.field])
                        ?.accountNumber || filter[filterParam.field],
                  },
                ]
              }
              components={{
                Option: CustomOption,
              }}
              onChange={(val) => {
                setFilter?.((currentValue: any) => {
                  if (val) {
                    return { ...currentValue, [filterParam.field]: val.value };
                  }
                  return omit(currentValue, [filterParam.field]);
                });
                if (val) {
                  updateFilter?.({ ...filter, [filterParam.field]: val.value });
                } else {
                  updateFilter?.(omit(filter, [filterParam.field]));
                }
              }}
            />
          );
        case 'MultiTextInput':
          return (
            <MultiTextInput
              key={filterParam.field}
              title={filterParam.title || 'Transaction Codes'}
              selectedValues={filter[filterParam.field]}
              maxValues={3}
              isFilterComponent
              onSelect={
                (val) => {
                  setFilter?.((currentValue: any) => {
                    if (val && val.length > 0) {
                      return { ...currentValue, [filterParam.field]: val };
                    }
                    return omit(currentValue, [filterParam.field]);
                  });
                  if (val && val.length > 0) {
                    updateFilter?.({ ...filter, [filterParam.field]: val });
                  } else {
                    updateFilter?.(omit(filter, [filterParam.field]));
                  }
                }
                // setSelectedBankTransactionRule((currentVlaue) => {
                //   return { ...currentVlaue, code: values };
                // })
              }
            />
          );
        case 'DateRange':
          // return (
          //   <View style={AppStyles.marginLeft}>
          //     <DatePicker slotProps={{ textField: { size: 'small' } }} />
          //   </View>
          // );
          // console.warn('valx', filter[filterParam.field]);

          return (
            <>
              <DateInput
                onSelect={(val) => {
                  setFilter?.((currentValue: any) => {
                    console.warn(val);
                    if (val) {
                      return { ...currentValue, [filterParam.field]: val.toDate() };
                    }
                    return omit(currentValue, [filterParam.field]);
                  });
                  if (val) {
                    updateFilter?.({ ...filter, [filterParam.field]: val.toDate() });
                  } else {
                    updateFilter?.(omit(filter, [filterParam.field]));
                  }
                }}
                value={filter[filterParam.field] ? moment(filter[filterParam.field]) : undefined}
                placeholder={filterParam.title}
                width={155}
                style={[AppStyles.marginLeft25]}
                isNotOnModal
              />
            </>
          );
        case 'MultiSelect':
          return (
            <View
              style={[
                AppStyles.marginLeft25,
                { minWidth: filterParam.minWidth, maxWidth: filterParam.maxWidth || 450 },
              ]}
            >
              <Select
                isMulti
                chakraStyles={{
                  control: (provided) => ({
                    ...provided,
                    marginLeft: '10px',
                    minWidth: filterParam.minWidth || '250', // Minimum width for the select input
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: '3px', // Reduce padding around arrow
                    fontSize: '12px', // Reduce arrow size
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    width: 'auto', // Fit content
                    minWidth: '100%', // At least the width of the select input
                    whiteSpace: 'nowrap', // Prevent text from wrapping
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    fontSize: '14px', // Change font size of the placeholder
                  }),
                  menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
                }}
                placeholder={filterParam.title}
                isClearable
                key={filterParam.field}
                {...filterParam}
                value={
                  filter[filterParam.field] && {
                    value: filter[filterParam.field],
                    label: filterParam.options?.find(
                      (option) => option.value === filter[filterParam.field],
                    )?.label,
                  }
                }
                onChange={(val) => {
                  setFilter?.((currentValue: any) => {
                    if (val) {
                      return { ...currentValue, [filterParam.field]: val.value };
                    }
                    return omit(currentValue, [filterParam.field]);
                  });
                  if (val) {
                    updateFilter?.({ ...filter, [filterParam.field]: val.value });
                  } else {
                    updateFilter?.(omit(filter, [filterParam.field]));
                  }
                }}
                onChange={(val) => {
                  setFilter?.((currentValue: any) => {
                    if (val && val.length > 0) {
                      return { ...currentValue, [filterParam.field]: val.map((v) => v.value) };
                    }
                    return omit(currentValue, [filterParam.field]);
                  });
                }}
                value={
                  filter[filterParam.field]?.map((value) => {
                    return {
                      value,
                      label:
                        filterParam.options?.find((option) => option.value === value)?.label ||
                        value,
                    };
                  }) || []
                }
                name="Entity"
                options={filterParam.options || []}
                placeholder={filterParam.title}
                menuPosition="fixed"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
                }}
                menuPortalTarget={document.body}
              />
            </View>
          );
        default:
          return null;
      }
    });
  }, [filter, filterParams, setFilter, staticData.bankAccounts]);
  return (
    <FilterContext.Provider value={{ filterValue: undefined }}>
      <View style={styles.bar}>
        {!hideSearch && (
          <Input
            InputLeftElement={<Icon as={<MaterialIcons name="search" />} size={5} ml="2" />}
            InputRightElement={
              searchText ? (
                <Icon
                  as={<Ionicons name="close-circle" />}
                  size={5}
                  ml="4"
                  color="muted.400"
                  style={{ marginRight: 10 }}
                  onPress={() => onChange('')}
                />
              ) : undefined
            }
            backgroundColor={Colors.white}
            value={searchText}
            size="md"
            variant="rounded"
            autoComplete="off"
            key={'Search'}
            placeholder={searchPlaceholder || 'Search here'}
            w="s"
            onChangeText={onChange}
          />
        )}
        {renderFilterParams()}
      </View>
    </FilterContext.Provider>
  );
}

const styles = StyleSheet.create({
  bar: {
    marginHorizontal: 10,
    marginVertical: 10,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 9999,
  },
});
