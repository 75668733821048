/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import countBy from 'lodash/countBy';
import { useToast } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { PieChart } from 'react-native-chart-kit';
import { useSelector } from 'react-redux';

import { ApprovalWorkflowSource, BankAccount, InputMode, StaticDataStore } from '../commonTypes';
import ReviewAndApproveModal from '../components/ApprovalWorkflow/ReviewAndApproveModal';
import BankAccountDetails from '../components/BankAccounts/BankAccountDetails';
import Button from '../components/Button';
import { CFlatList } from '../components/CFlatList';
import { CModal } from '../components/CModal';
import FieldDisplay from '../components/FieldDisplay';
import { FilterBar } from '../components/FilterBar';
import StatusTag from '../components/StatusTag';
import { Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import { _getFormattedCurrency, _searchList } from '../utils/helper';

export default function BankAccountScreen({ navigation }: RootTabScreenProps<'BankAccountScreen'>) {
  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [inputMode, setInputMode] = useState<InputMode>(InputMode.CREATE);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [selectedItemForApproval, setSelectedItemForApproval] = useState<BankAccount>();
  const [searchValue, setSearchValue] = useState('');

  const [filteredBankAccounts, setFilteredBankAccounts] = useState<BankAccount[] | undefined>();

  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const canEdit = !!staticData.accessibleScreens?.BankAccountScreen?.edit;

  const actualPieData = useMemo(() => {
    const rawCount = countBy(filteredBankAccounts || bankAccounts, 'currency');
    const formattedData = Object.entries(rawCount)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 6)
      .map((e, index) => ({ name: e[0], population: e[1], color: Colors.chartColors[index] }));
    if (formattedData.length > 5) {
      formattedData.push({
        name: 'Others',
        population:
          (filteredBankAccounts || bankAccounts).length -
          formattedData.map((item) => item.population).reduce((a, b) => a + (b || 0), 0),
        color: Colors.chartColors[formattedData.length],
      });
    }
    return formattedData;
  }, [bankAccounts, filteredBankAccounts]);
  const chartConfig = {
    backgroundGradientFrom: '#1E2923',
    backgroundGradientFromOpacity: 0,
    backgroundGradientTo: '#08130D',
    backgroundGradientToOpacity: 0.5,
    color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    useShadowColorFromDataset: false, // optional
  };

  useEffect(() => {
    setBankAccounts(staticData.bankAccounts || []);
    setIsLoading(false);
  }, [staticData.bankAccounts]);

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      setSearchValue(searchValue);
    },
    [bankAccounts],
  );

  useEffect(() => {
    console.warn('refreshing filter list');
    if (searchValue && searchValue !== '') {
      setFilteredBankAccounts(_searchList(bankAccounts, searchValue));
    } else {
      setFilteredBankAccounts(undefined);
    }
  }, [bankAccounts, searchValue]);

  const onAccountCreatedOrUpdated = useCallback(
    (updatedAccount: BankAccount) => {
      if (selectedIndex !== undefined && updatedAccount?.id) {
        //Updating existing account
        const _bankAccounts = filteredBankAccounts || bankAccounts;
        _bankAccounts[selectedIndex] = updatedAccount;
        if (filteredBankAccounts) {
          setFilteredBankAccounts(_bankAccounts);
          const _AllAccounts = bankAccounts;
          _AllAccounts[_AllAccounts.findIndex((bankAcc) => bankAcc.id === updatedAccount.id)] =
            updatedAccount;
          setBankAccounts(_AllAccounts);
        } else {
          setBankAccounts(_bankAccounts);
        }
      } else if (updatedAccount) {
        //Create new account
        setBankAccounts((currentValue) => [
          {
            ...updatedAccount,
            id: updatedAccount?.accountNumber + updatedAccount?.currency,
          },
          ...currentValue,
        ]);
        if (filteredBankAccounts) {
          setFilteredBankAccounts((currentValue) => [
            {
              ...updatedAccount,
              id: updatedAccount?.accountNumber + selectedBankAccount?.currency,
            },
            ...(currentValue || []),
          ]);
        }
      } else {
        setIsDetailVisible(false);
        return toast.show({
          description: 'Something went wrong',
        });
      }
    },
    [bankAccounts, filteredBankAccounts, selectedBankAccount, selectedIndex, toast],
  );

  const onCreateNewAccount = useCallback(() => {
    setSelectedBankAccount({
      accountNumber: '',
      accountName: '',
      currency: '',
      bankCode: '',
      Bank: '',
      lastUpdatedBy: profileData.uid,
    } as BankAccount);
    setInputMode(InputMode.CREATE);
    setSelectedIndex(undefined);
    setIsDetailVisible(true);
  }, [profileData.uid]);

  return (
    <View style={AppStyles.container}>
      <ReviewAndApproveModal
        onClose={() => {
          setSelectedItemForApproval(undefined);
        }}
        pendingApprovalItem={selectedItemForApproval}
        source={ApprovalWorkflowSource.BANK_ACCOUNTS}
      />
      {selectedBankAccount && (
        <CModal
          open={isDetailVisible}
          setOpen={(value) => setIsDetailVisible(value)}
          title={selectedBankAccount?.accountNumber || 'Bank Account'}
          hideButtons
          width={Layout.window.width * 0.7}
        >
          <BankAccountDetails
            setIsVisible={setIsDetailVisible}
            bankAccount={selectedBankAccount}
            onAccountUpdated={onAccountCreatedOrUpdated}
            mode={canEdit ? inputMode || InputMode.EDIT : InputMode.VIEW}
          />
        </CModal>
      )}
      <View style={[AppStyles.flexRowCenterSpaceBetween, AppStyles.marginTop]}>
        {bankAccounts && bankAccounts.length > 0 ? (
          <FilterBar
            onSearch={onSearchOrFilter}
            searchPlaceholder={'Search by Name or Description'}
          />
        ) : (
          <View />
        )}
        {staticData.accessibleScreens?.BankAccountScreen?.create ? (
          <Button label="Create Bank Account" variant="Create" onPress={onCreateNewAccount} />
        ) : (
          <View />
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: Layout.window.width * 0.6 }}>
          <CFlatList
            emptyMessage={
              filteredBankAccounts === undefined
                ? 'No Bank Accounts yet or \n You may not have access to them'
                : 'No results found'
            }
            emptyCTA={'Create Bank Account'}
            emptyAction={onCreateNewAccount}
            subAction={
              <Text style={[AppStyles.textSubTitle, AppStyles.marginTop]}>
                Importing bank statements will automatically create bank accounts if they do not
                exists
              </Text>
            }
            data={filteredBankAccounts || bankAccounts}
            isLoading={isLoading}
            renderItem={({ item, index }: { item: BankAccount; index: number }) => (
              <TouchableOpacity
                onPress={() => {
                  setInputMode(InputMode.EDIT);
                  setSelectedBankAccount(item);
                  setSelectedIndex(index);
                  setIsDetailVisible(true);
                }}
              >
                <Card style={styles.bankAccountCard}>
                  <View
                    style={[
                      AppStyles.flexRow,
                      {
                        width: Layout.window.width * 0.6 - 40,
                      },
                    ]}
                  >
                    <View style={{ flex: 2 }}>
                      <Text style={AppStyles.textRowTitle}>{item.accountNumber}</Text>
                      <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
                      <Text style={AppStyles.textSubTitle}>
                        {_getFormattedCurrency(item.balance, item.currency)}
                      </Text>
                    </View>
                    {/* <View style={{ flex: 1 }}>
                      <FieldDisplay label={'Currency'} value={item.currency} />
                    </View> */}
                    <View style={AppStyles.flex1}>
                      <FieldDisplay label={'Entity'} value={item.entity} />
                    </View>
                    <View style={AppStyles.flex1}>
                      {item.bank ? (
                        <FieldDisplay label={'Bank'} value={item.bank} />
                      ) : (
                        <FieldDisplay label={'Bank Code'} value={item.BIC} />
                      )}
                    </View>

                    <View style={[AppStyles.flex1, AppStyles.paddingRight]}>
                      <StatusTag label={item.status} isReadOnly />
                      {item.updateRequest && <StatusTag label={'Pending Updates'} isReadOnly />}
                    </View>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          />
        </View>
        <View>
          {actualPieData && actualPieData.length > 0 && (
            <>
              <Text style={AppStyles.textSubTitle}>Accounts by Currency</Text>
              <PieChart
                data={actualPieData}
                width={Layout.window.width * 0.3}
                height={300}
                chartConfig={chartConfig}
                accessor={'population'}
                backgroundColor={Colors.background}
                paddingLeft={'15'}
                absolute
              />
            </>
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
  },
});
