import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { Entypo, FontAwesome5 } from '@expo/vector-icons';
import { collection, doc, getDoc } from 'firebase/firestore';
import moment from 'moment';
import { AppContext } from '../../App';
import { InputMode, StatementTransaction, StaticDataStore } from '../../commonTypes';
import Constants, { DateFormat } from '../../constants/Constants';
import AppStyles from '../../constants/Styles';
import {
  _getCamtRelatedPartyDetails,
  _getCodeDescr,
  _getColorFromCrDrIndicator,
  _getFormattedCurrency,
  _getTimeStampString,
} from '../../utils/helper';
import CommentSection from '../Comments/CommentSection';
import CToolTip from '../CToolTip';
import { AmountText } from '../StyledText';
import { Text, View } from '../Themed';
import Colors from '../../constants/Colors';

export type BankStatementDetailsProps = {
  statementTransaction: StatementTransaction;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onStatementTransactionUpdated?: (updatedAccount: StatementTransaction) => any;
  mode?: InputMode;
  onNext?: () => void;
  onItemActioned?: () => void;
};

const BankStatementDetails: FC<BankStatementDetailsProps> = ({
  statementTransaction,
  setIsVisible,
  onStatementTransactionUpdated,
  mode = InputMode.CREATE,
  onNext,
  onItemActioned,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedStatementTransaction, setSelectedStatementTransaction] =
    useState<StatementTransaction>(statementTransaction);

  const isReadOnly = [InputMode.VIEW, InputMode.APPROVE].includes(mode);
  const [ruleName, setRuleName] = useState<string>();
  useEffect(() => {
    setSelectedStatementTransaction(statementTransaction);
    console.warn(statementTransaction);
  }, [statementTransaction]);

  const { db } = useContext(AppContext);
  const toast = useToast();
  const dispatch = useDispatch();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);

  const getRuleName = useCallback(() => {
    if (selectedStatementTransaction?.ruleId) {
      getDoc(
        doc(
          db,
          'Organizations',
          profileData.orgId,
          'BankTransactionRules',
          selectedStatementTransaction.ruleId,
        ),
      ).then((doc) => {
        if (doc.exists()) {
          setRuleName('Applied by Rule ' + doc.data().name || '');
        } else {
          setRuleName('Updated Manually');
        }
      });
    }
  }, [selectedStatementTransaction?.ruleId, db, profileData.orgId]);

  const camtRltdParty = useMemo(() => {
    return _getCamtRelatedPartyDetails(statementTransaction);
  }, [statementTransaction, statementTransaction]);

  const accountDetails = useMemo(() => {
    return staticData?.bankAccounts?.find(
      (account) => selectedStatementTransaction.accountId === account.id,
    );
  }, [selectedStatementTransaction.accountId, staticData?.bankAccounts]);

  const copyToClipboard = (text: string, label: string) => {
    if (!text || text === '-') return;
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: `${label} copied`,
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    });
  };

  if (!selectedStatementTransaction) {
    return null;
  }

  return (
    <>
      <View style={AppStyles.paddingBottom}>
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
          }}
        >
          <View>
            <Text style={[{ fontSize: Constants.TextSize.large }, AppStyles.marginBottom]}>
              {_getCodeDescr({
                code: selectedStatementTransaction.code,
                familyCode: selectedStatementTransaction.familyCode,
                subFamilyCode: selectedStatementTransaction.subFamilyCode,
              })}
            </Text>
            <Text style={[AppStyles.textRowTitle, AppStyles.textBold]}>
              {staticData?.categories?.find(
                (category) => selectedStatementTransaction.category === category?.id,
              )?.name || 'Unmapped'}
              <CToolTip
                label={
                  selectedStatementTransaction.category === 'Unmapped'
                    ? 'No matching rule was found'
                    : ruleName
                }
                fetch={getRuleName}
              />
            </Text>
            <Flex flexDirection={'row'} mt={4} gap={4} alignItems={'center'} width="100%">
              <Box
                p={2}
                flex={1}
                height={'100%'}
                bg="gray.50"
                borderRadius="md"
                shadow="sm"
                minWidth="300px"
              >
                <Flex flexDirection={'column'} width="100%">
                  <Text style={[AppStyles.textSubTitle, AppStyles.marginBottom]}>Our Account</Text>
                  <Text style={AppStyles.textBold}>{accountDetails?.accountName}</Text>
                  <Text>{selectedStatementTransaction.accountNumber}</Text>
                  <Text>{accountDetails?.shortCode}</Text>
                </Flex>
              </Box>
              {statementTransaction?.sourceType?.includes('CAMT') && (
                <>
                  <Entypo
                    name={`arrow-${statementTransaction.CdtDbtInd === 'C' ? 'left' : 'right'}`}
                    size={24}
                    style={{ margin: 10 }}
                    color={_getColorFromCrDrIndicator(selectedStatementTransaction.CdtDbtInd)}
                  />
                  <Box
                    p={2}
                    flex={1}
                    height={'100%'}
                    bg="gray.50"
                    borderRadius="md"
                    shadow="sm"
                    minWidth="300px"
                  >
                    <Flex flexDirection={'column'} width="100%">
                      <Text style={[AppStyles.textSubTitle, AppStyles.marginBottom]}>
                        Other Account
                      </Text>

                      <Text style={AppStyles.textBold}>{camtRltdParty?.name}</Text>
                      <Text>{camtRltdParty?.iban}</Text>
                      <Text>{camtRltdParty?.bic ? 'Swift BIC: ' + camtRltdParty?.bic : ''}</Text>
                    </Flex>
                  </Box>
                </>
              )}
            </Flex>
          </View>
          <View style={AppStyles.alignFlexEnd}>
            <AmountText
              amount={selectedStatementTransaction.amount}
              currency={selectedStatementTransaction.currency}
              code={selectedStatementTransaction.subFamilyCode || selectedStatementTransaction.code}
              color={_getColorFromCrDrIndicator(selectedStatementTransaction.CdtDbtInd)}
            />

            <Text style={AppStyles.textSubTitle}>
              {_getTimeStampString(selectedStatementTransaction.valueDate, DateFormat.DATE_ONLY)}
            </Text>
          </View>
        </View>
        <Text style={[AppStyles.textRowTitle, AppStyles.marginTop25, AppStyles.marginBottom]}>
          Transaction Details
        </Text>

        <Box width="100%">
          <Flex direction="column" ml={2}>
            <Flex alignItems="center">
              <Box width="250px">
                <Text style={[AppStyles.textSubTitle]}>Account Servicer Reference</Text>
              </Box>
              <Box flex={1}>
                <Flex alignItems="center" gap={2}>
                  <Text>{statementTransaction.AcctSvcrRef || '-'}</Text>
                  {statementTransaction.AcctSvcrRef && (
                    <IconButton
                      aria-label="Copy reference"
                      icon={<FontAwesome5 name="copy" size={14} color={Colors.grey} />}
                      size="sm"
                      variant="ghost"
                      onClick={() =>
                        copyToClipboard(
                          statementTransaction.AcctSvcrRef,
                          'Account Servicer Reference',
                        )
                      }
                    />
                  )}
                </Flex>
              </Box>
            </Flex>

            <Flex alignItems="center">
              <Box width="250px">
                <Text style={[AppStyles.textSubTitle]}>Bank Reference</Text>
              </Box>
              <Box flex={1}>
                <Flex alignItems="center" gap={2}>
                  <Text>{statementTransaction.bankRef || statementTransaction.NtryRef || '-'}</Text>
                  {(statementTransaction.bankRef || statementTransaction.NtryRef) && (
                    <IconButton
                      aria-label="Copy reference"
                      icon={<FontAwesome5 name="copy" size={14} color={Colors.grey} />}
                      size="sm"
                      variant="ghost"
                      onClick={() =>
                        copyToClipboard(
                          statementTransaction.bankRef || statementTransaction.NtryRef,
                          'Bank Reference',
                        )
                      }
                    />
                  )}
                </Flex>
              </Box>
            </Flex>

            <Flex alignItems="center">
              <Box width="250px">
                <Text style={[AppStyles.textSubTitle]}>Narrative</Text>
              </Box>
              <Box flex={1}>
                <Flex alignItems="center" gap={2}>
                  <Text>{statementTransaction.narrative || 'Not reported in the statement'}</Text>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>

        {selectedStatementTransaction?.journals?.length && (
          <>
            <Text style={[AppStyles.textRowTitle, AppStyles.marginTop25]}>Accounting</Text>
            <TableContainer>
              <Table size={'sm'}>
                <Thead>
                  <Tr>
                    <Th>Ledger</Th>
                    <Th>Date</Th>
                    <Th>Account</Th>
                    <Th>Currency</Th>
                    <Th>Direction</Th>
                    <Th>Amount</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {selectedStatementTransaction?.journals?.map((journal, index) => {
                    return (
                      <>
                        <Tr key={index}>
                          <Td>{journal.ledger}</Td>
                          <Td>{moment(journal.date, 'YYYYMMDD').format(DateFormat.DATE_ONLY)}</Td>
                          <Td
                            color={journal.debit.account === 'System Suspense' ? 'red' : undefined}
                          >
                            {journal.debit.account}
                          </Td>
                          <Td>{journal.debit.currency}</Td>
                          <Td>Dr</Td>
                          <Td>{_getFormattedCurrency(journal.debit.amount)}</Td>
                          <Td>{journal.status}</Td>
                        </Tr>
                        <Tr>
                          <Td>{journal.ledger}</Td>
                          <Td>{moment(journal.date, 'YYYYMMDD').format(DateFormat.DATE_ONLY)}</Td>
                          <Td
                            color={journal.credit.account === 'System Suspense' ? 'red' : undefined}
                          >
                            {journal.credit.account}
                          </Td>
                          <Td>{journal.credit.currency}</Td>
                          <Td>Cr</Td>
                          <Td>{_getFormattedCurrency(journal.debit.amount)}</Td>
                          <Td>{journal.status}</Td>
                        </Tr>
                      </>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}

        <CommentSection
          collection={collection(db, 'Organizations', profileData.orgId, 'BankStatement')}
          documentId={selectedStatementTransaction.id}
          targetScreen="BankStatementScreen"
        />
      </View>
    </>
  );
};
export default BankStatementDetails;
