import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { FontAwesome5 } from '@expo/vector-icons';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import omit from 'lodash/omit';

import { TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { Badge, useToast } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../App';
import { UPDATE_OUR_BANKS } from '../../actions/ActionConstatnts';
import {
  Address,
  ApprovalWorkflowSource,
  Bank,
  InputMode,
  LogType,
  StaticDataStore,
  Status,
} from '../../commonTypes';
import Constants from '../../constants/Constants';
import AppStyles from '../../constants/Styles';
import { _getChangedFields, _textFieldIsInvalid } from '../../utils/helper';
import ActivityHistory from '../ActivityHistory';
import AddressForm from '../AddressForm';
import ReviewAndApproveModal from '../ApprovalWorkflow/ReviewAndApproveModal';
import ButtonGroup from '../ButtonGroup';
import CCheckBox from '../CCheckBox';
import CInput from '../CInput';
import StatusTag from '../StatusTag';
import { Text, View } from '../Themed';

export type BankDetailsProps = {
  bank?: Bank;
  setIsVisible?: Dispatch<SetStateAction<boolean>>;
  onBankUpdated?: (updatedAccount: Bank) => any;
  mode?: InputMode;
  startWithBic?: boolean;
  onNext?: () => void;
  onItemActioned?: () => void;
};

export default function BankDetails({
  bank,
  setIsVisible,
  onBankUpdated,
  mode = InputMode.CREATE,
  startWithBic = true,
  onNext,
  onItemActioned,
}: BankDetailsProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [isBicLookedUp, setIsBicLookedUp] = useState(false);
  const [selectedBank, setSelectedBank] = useState<Bank | undefined>(bank);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [canApprove, setCanApprove] = useState(false);
  const { db, defaultDb } = useContext(AppContext);
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const canEdit = staticData.accessibleScreens?.BankScreen?.edit || false;
  const dispatch = useDispatch();
  const isReadOnly = [InputMode.VIEW, InputMode.APPROVE].includes(mode);

  const isWorkflowEnabled =
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.BANKS]?.status ||
    staticData?.orgInfo?.Workflow?.[ApprovalWorkflowSource.STATIC_DATA]?.status;

  useEffect(() => {
    setIsBicLookedUp(!startWithBic);
  }, [bank, startWithBic]);

  useEffect(() => {
    setSelectedBank(bank);
  }, [bank]);

  const onSave = useCallback(async () => {
    if (isSaving || !profileData?.orgId) {
      return;
    }

    setIsSaving(true);
    if (_textFieldIsInvalid(selectedBank?.name)) {
      setIsSaving(false);
      return toast({
        title: 'Invalid bank name',
        description: 'Please enter a valid name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (_textFieldIsInvalid(selectedBank?.externalName)) {
      setIsSaving(false);
      return toast({
        title: 'Invalid external name',
        description: 'Please enter a valid external name',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    if (!selectedBank?.isOurBank && !selectedBank?.isOtherBank) {
      setIsSaving(false);
      return toast({
        title: 'Invalid relationship',
        description: 'Select atleast one relationship',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    let relationship = selectedBank?.isOurBank ? 'Ours' : 'Third Party';
    if (selectedBank?.isOurBank && selectedBank?.isOtherBank) {
      relationship = 'Both';
    }

    const updateRequest = {
      ..._getChangedFields(bank, selectedBank),
      requestedBy: profileData.uid,
      requestId: uuidv4(),
      lastUpdatedAt: serverTimestamp(),
    };
    let bankDoc = {
      ...selectedBank,
      status: Status.ACTIVE,
      relationship: relationship,
      lastUpdatedBy: profileData?.uid,
    };
    if (mode === InputMode.CREATE) {
      //Create new Bank
      const docSnap = await getDoc(
        doc(db, 'Organizations', profileData.orgId, 'Banks', selectedBank.name),
      );

      if (docSnap.exists()) {
        toast({
          title: 'Bank already exists',
          description: 'A Bank with the same name already exists. Please enter a different name.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsSaving(false);
        return;
      }
      bankDoc = {
        ...bankDoc,
        createdBy: profileData?.uid,
        lastUpdatedBy: profileData?.uid,
        status: isWorkflowEnabled ? Status.PENDING_APPROVAL : Status.ACTIVE,
      };
      await setDoc(doc(db, 'Organizations', profileData.orgId, 'Banks', selectedBank.name), {
        ...bankDoc,
        createdDtTm: serverTimestamp(),
      } as Bank);
      setIsSaving(false);
      setIsVisible?.(false);
      onBankUpdated?.(bankDoc as Bank);
      const _banks = staticData.ourBanks || [];
      _banks.push(bankDoc as Bank);
      dispatch({
        payload: _banks,
        type: UPDATE_OUR_BANKS,
      });

      toast({
        title: 'Bank Created',
        description: isWorkflowEnabled
          ? 'Bank creation request has been submitted and is pending approval'
          : 'Bank Created Successfully',
        status: isWorkflowEnabled ? 'warning' : 'success',
        duration: 5000,
        isClosable: true,
      });
    } else if (selectedBank) {
      if (isWorkflowEnabled) {
        bankDoc = {
          ...bank,
          updateRequest: updateRequest,
        };
      }
      await updateDoc(
        doc(db, 'Organizations', profileData.orgId, 'Banks', selectedBank.name),
        isWorkflowEnabled
          ? { updateRequest: updateRequest }
          : { ...omit(bankDoc, ['createdBy', 'createdDtTm']) },
      )
        .then(() => {
          toast({
            title: isWorkflowEnabled ? 'Bank update requested. Pending approval' : 'Bank Updated',
            status: isWorkflowEnabled ? 'warning' : 'success',
            description: 'Bank updated successfully',
            duration: 5000,
            isClosable: true,
          });
          if (isWorkflowEnabled) {
            bankDoc = { ...bank, updateRequest };
          }
          const _banks = staticData.ourBanks || [];
          _banks[_banks.findIndex((eBank) => eBank.name === bankDoc.name)] = bankDoc;
          dispatch({
            payload: _banks,
            type: UPDATE_OUR_BANKS,
          });
          onBankUpdated?.({
            ...bank,
            ...(isWorkflowEnabled ? { updateRequest: updateRequest } : {}),
          } as Bank);
          setIsBicLookedUp(false);
          setIsVisible?.(false);
        })
        .catch((e) => {
          return toast({
            title: 'Something went wrong',
            description: 'Please try again later',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
      setIsSaving(false);
    } else {
      setIsSaving(false);
      return toast({
        title: 'Something went wrong',
        description: 'Please try again later',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [
    db,
    dispatch,
    isSaving,
    mode,
    onBankUpdated,
    profileData.orgId,
    profileData?.uid,
    selectedBank,
    setIsVisible,
    staticData.ourBanks,
    toast,
  ]);

  const onTextChange = useCallback((field: keyof Bank, value: string | Address) => {
    setSelectedBank((existingValue) => {
      return { ...existingValue, [field]: value } as Bank;
    });
  }, []);

  const onIsOurBankSelected = useCallback((value: boolean) => {
    setSelectedBank((existingValue) => {
      return { ...existingValue, isOurBank: value } as Bank;
    });
  }, []);

  const onIsOtherBankSelected = useCallback((value: boolean) => {
    setSelectedBank((existingValue) => {
      return { ...existingValue, isOtherBank: value } as Bank;
    });
  }, []);
  const searchBIC = useCallback(async () => {
    if (selectedBank?.BIC) {
      setIsBicLookedUp(true);
      const q = query(
        collection(defaultDb, 'DefaultConfig', 'BankData', 'BIC'),
        where('swift', 'in', [selectedBank.BIC, selectedBank.BIC.substring(0, 8)]),
      );

      const querySnapshot = await getDocs(q);
      console.warn(querySnapshot.docs.length);
      const defaultBank = querySnapshot.docs?.[0]?.data();
      if (defaultBank) {
        setSelectedBank((existingValue) => {
          return {
            status: existingValue?.status,
            externalName: defaultBank.name,
            BIC: defaultBank.swift,
            address: {
              city: defaultBank.city,
              country: defaultBank.country_code,
              addressLine1: defaultBank.address,
            },
          } as Bank;
        });
      }
    }
  }, [db, selectedBank?.BIC]);

  if (!selectedBank) {
    return null;
  }

  return (
    <>
      {isWorkflowEnabled && (
        <ReviewAndApproveModal
          onClose={() => {
            setShowApprovalModal(false);
          }}
          pendingApprovalItem={selectedBank}
          source={ApprovalWorkflowSource.BANKS}
          id={bank?.name}
          onApprovalDataLoaded={(approvalData) => {
            setCanApprove(approvalData.canApprove);
            console.warn('approvalData2', approvalData);
          }}
          onApprovalOrRejectonCompleted={(approved) => {
            onBankUpdated?.(
              omit(
                {
                  ...selectedBank,
                  status: approved
                    ? Status.ACTIVE
                    : selectedBank?.updateRequest
                    ? selectedBank.status
                    : Status.DEACTIVATED,
                },
                ['updateRequest'],
              ),
            );
            setIsVisible?.(false);
          }}
          onSomeActionDone={onItemActioned}
          show={showApprovalModal}
        />
      )}
      {mode === InputMode.EDIT || isBicLookedUp ? (
        <View pointerEvents={canEdit && !isReadOnly ? 'auto' : 'none'}>
          <View style={AppStyles.flexRow}>
            <View>
              <View style={AppStyles.flexRow}>
                <CInput
                  label="Bank Name"
                  fieldKey="name"
                  fieldValue={selectedBank.name}
                  onTextChange={onTextChange}
                  isRequired
                  disabled={mode === InputMode.EDIT}
                />
                <CInput
                  label="External Name"
                  fieldKey="externalName"
                  fieldValue={selectedBank.externalName}
                  onTextChange={onTextChange}
                  isRequired
                  ml={2}
                />
              </View>
              <View style={AppStyles.flexRow}>
                <CInput
                  label="SWIFT BIC"
                  fieldKey="BIC"
                  fieldValue={selectedBank.BIC}
                  onTextChange={onTextChange}
                />
                <CInput
                  label="Local Route Code"
                  fieldKey="sortCode"
                  fieldValue={selectedBank.sortCode}
                  onTextChange={onTextChange}
                  ml={2}
                />
              </View>
            </View>
            <View style={AppStyles.marginLeft25}>
              <Text style={AppStyles.textFormSectionHeader}>Relationship</Text>
              <View style={[AppStyles.flexRow, AppStyles.marginTop]}>
                <CCheckBox
                  label="Our Bank"
                  value={selectedBank.isOurBank}
                  onChange={onIsOurBankSelected}
                />
                <View>
                  <CCheckBox
                    label="Other Bank"
                    value={selectedBank.isOtherBank}
                    onChange={onIsOtherBankSelected}
                    style={AppStyles.marginLeft25}
                  />
                </View>
              </View>
            </View>
            <View style={[AppStyles.flex1, { alignItems: 'flex-end' }]} pointerEvents={'auto'}>
              <StatusTag
                label={selectedBank.status}
                onPendingApprovalPress={() => setShowApprovalModal(true)}
              />
              {selectedBank.updateRequest && (
                <TouchableOpacity
                  onPress={() => {
                    setShowApprovalModal(true);
                  }}
                >
                  <Badge variant="solid" colorScheme="orange">
                    Pending Updates
                  </Badge>
                </TouchableOpacity>
              )}
            </View>
          </View>

          <AddressForm
            adddressValue={selectedBank.address}
            onAddressChange={onTextChange}
            label={'Address'}
            disabled={isReadOnly || !canEdit}
          />
        </View>
      ) : (
        <View>
          <CInput
            label="SWIFT BIC"
            fieldKey="BIC"
            fieldValue={selectedBank.BIC}
            onTextChange={onTextChange}
            onSubmitEditing={searchBIC}
            rightElement={
              <TouchableOpacity style={{ paddingHorizontal: 5 }} onPress={searchBIC}>
                <FontAwesome5 name="search" size={Constants.TextSize.regular} color="black" />
              </TouchableOpacity>
            }
          />
        </View>
      )}
      {mode !== InputMode.CREATE && (
        <ActivityHistory recordKey={selectedBank.name} recordType={LogType.Bank} />
      )}
      <ButtonGroup
        mode={mode}
        onSave={onSave}
        isSaving={isSaving}
        canApprove={canApprove}
        setShowApprovalModal={setShowApprovalModal}
        setIsVisible={setIsVisible}
        onNext={onNext}
      />
    </>
  );
}
