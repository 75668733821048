/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { FontAwesome5, Ionicons, MaterialIcons } from '@expo/vector-icons';
import debounce from 'lodash/debounce';
import { Icon, Input, Popover } from 'native-base';
import { InterfacePopoverProps } from 'native-base/lib/typescript/components/composites/Popover/types';
import { FlatList, TouchableOpacity, ViewStyle } from 'react-native';

import { Card, Text, View } from './Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { _searchList } from '../utils/helper';

export type SelectPresetInputProps<T> = {
  title?: string;
  data?: T[];
  dataKey?: string;
  onSelect: Dispatch<SetStateAction<T | undefined>>;
  selectedValue?: T;
  renderItem?: any;
  isReadOnly?: boolean;
  style?: ViewStyle;
  isRequired?: boolean;
  onAddNew?: () => void;
  defaultName?: string;
};
export function SelectPresetInput({
  data = [],
  dataKey,
  title,
  onSelect,
  selectedValue,
  renderItem,
  isReadOnly,
  style,
  isRequired,
  onAddNew,
  defaultName = 'Default',
}: SelectPresetInputProps<any>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState<(typeof data)[]>();

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredData(_searchList(data, searchValue));
      } else {
        setFilteredData(undefined);
      }
    },
    [data],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((q) => onSearchOrFilter(q), 500),
    [onSearchOrFilter],
  );
  const onChange = (value: string) => {
    setSearchText(value);
    delayedQuery(value);
  };

  const renderTrigger = useCallback(
    (triggerProps: InterfacePopoverProps) => {
      return (
        <TouchableOpacity
          style={style}
          {...triggerProps}
          disabled={isReadOnly}
          onPress={() => setIsOpen(true)}
        >
          <View style={AppStyles.flexRowCenter}>
            <Text style={AppStyles.textTitle}>
              {selectedValue ? selectedValue.name : defaultName}
            </Text>

            {!isReadOnly && (
              <FontAwesome5
                color={Colors.grey}
                name="caret-down"
                size={30}
                style={AppStyles.marginLeft}
              />
            )}
          </View>
        </TouchableOpacity>
      );
    },
    [isReadOnly, selectedValue, style],
  );

  return (
    <View>
      {title && (
        <Text style={[AppStyles.textInputLabel, { marginTop: 10 }]}>
          {title}
          {isRequired && <Text style={{ color: Colors.red }}>*</Text>}
        </Text>
      )}
      <Popover isOpen={isOpen} trigger={renderTrigger} onClose={() => setIsOpen(!isOpen)}>
        <Popover.Content w="l">
          <Popover.Arrow />
          <Popover.Header>
            <View style={AppStyles.flexRowCenter}>
              {filteredData || data ? (
                <Input
                  InputLeftElement={<Icon as={<MaterialIcons name="search" />} size={5} ml="2" />}
                  InputRightElement={
                    searchText ? (
                      <Icon
                        as={<Ionicons name="close-circle" />}
                        size={5}
                        ml="4"
                        color="muted.400"
                        style={{ marginRight: 10 }}
                        onPress={() => onChange('')}
                      />
                    ) : undefined
                  }
                  value={searchText}
                  size="md"
                  variant="rounded"
                  placeholder={'Search here'}
                  w="l"
                  onChangeText={onChange}
                />
              ) : (
                <Text>No Saved Presets</Text>
              )}
              {onAddNew && (
                <TouchableOpacity onPress={onAddNew} style={AppStyles.marginLeft}>
                  <FontAwesome5 name={'plus'} size={20} color={Colors.primary} />
                </TouchableOpacity>
              )}
            </View>
          </Popover.Header>
          <Popover.Body>
            {(filteredData || data)?.length > 0 ? (
              <FlatList
                style={{ maxHeight: Layout.window.height / 2 }}
                data={filteredData || data}
                renderItem={({ item }) => {
                  const selected = selectedValue[dataKey || 'id'] === item[dataKey || 'id'];
                  return (
                    <TouchableOpacity
                      onPress={() => {
                        onSelect(item);
                        return setIsOpen(false);
                      }}
                    >
                      <Card style={{ marginHorizontal: 0 }}>
                        {renderItem ? (
                          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            {renderItem(item)}
                            {selected ? (
                              <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
                            ) : (
                              <View style={{ width: 12 }} />
                            )}
                          </View>
                        ) : (
                          <>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                              <Text>{item.accountNumber}</Text>
                              <View style={AppStyles.flexRowCenter}>
                                <Text style={{ marginRight: 5 }}>{item.currency}</Text>
                                {selected ? (
                                  <FontAwesome5 name={'check'} size={12} color={Colors.primary} />
                                ) : (
                                  <View style={{ width: 12 }} />
                                )}
                              </View>
                            </View>
                            <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
                          </>
                        )}
                      </Card>
                    </TouchableOpacity>
                  );
                }}
              />
            ) : (
              <Text style={{ textAlign: 'center' }}>No Saved Presets found</Text>
            )}
          </Popover.Body>
        </Popover.Content>
      </Popover>
    </View>
  );
}
