/* eslint-disable react-native/no-inline-styles */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import debounce from 'lodash/debounce';
import { Icon, Input } from 'native-base';
import { FlatList, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { _addAlpha, _searchList } from '../utils/helper';
import { Card, Text, View } from './Themed';
import { LinearGradient } from 'expo-linear-gradient';

export type DropDownSpecialProps<T> = {
  title: string;
  data: T[];
  onSelect: Dispatch<SetStateAction<T | undefined>>;
  selectedValue?: T;
  label?: string;
  style?: StyleProp<ViewStyle>;
  isOnModal?: boolean;
};
export function DropDownSpecial({
  data,
  title,
  onSelect,
  selectedValue,
  label = 'label',
  style,
  isOnModal,
}: DropDownSpecialProps<any>) {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState<(typeof data)[]>();

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredData(_searchList(data, searchValue));
      } else {
        setFilteredData(undefined);
      }
    },
    [data],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce((q) => onSearchOrFilter(q), 500),
    [onSearchOrFilter],
  );
  const onChange = (value: string) => {
    setSearchText(value);
    delayedQuery(value);
    console.warn('onChange');
  };

  const content = (
    <PopoverContent w="l" zIndex={9999}>
      <PopoverArrow />
      <PopoverHeader>
        {data?.length > 0 ? (
          <Input
            InputLeftElement={<Icon as={<MaterialIcons name="search" />} size={5} ml="2" />}
            InputRightElement={
              searchText ? (
                <Icon
                  as={<Ionicons name="close-circle" />}
                  size={5}
                  ml="4"
                  color="muted.400"
                  style={{ marginRight: 10 }}
                  onPress={() => onChange('')}
                />
              ) : undefined
            }
            value={searchText}
            size="md"
            variant="rounded"
            placeholder={'Search here'}
            w="l"
            onChangeText={onChange}
          />
        ) : (
          <Text>No option available</Text>
        )}
      </PopoverHeader>
      <PopoverBody>
        <FlatList
          style={{ maxHeight: Layout.window.height / 2 }}
          data={filteredData || data}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  onSelect(item);
                  setIsOpen(false);
                }}
              >
                <Card style={{ marginHorizontal: 0 }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text>{item.accountNumber}</Text>
                    <Text>{item.currency}</Text>
                  </View>
                  <Text style={AppStyles.textSubTitle}>{item.accountName}</Text>
                </Card>
              </TouchableOpacity>
            );
          }}
        />
      </PopoverBody>
    </PopoverContent>
  );

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(!isOpen)} key={title}>
      <PopoverTrigger>
        {
          <TouchableOpacity onPress={() => setIsOpen(true)}>
            <LinearGradient
              // Background Linear Gradient
              colors={[Colors.secondary, Colors.primary]}
              start={[0.0, 0.0]}
              end={[1.0, 1.0]}
              // locations={[0.0, 0.2]}
              style={[
                {
                  marginTop: 20,
                  flexDirection: 'row',
                  borderRadius: 5,
                  padding: 5,
                  paddingLeft: 10,
                  height: 40,
                  alignItems: 'center',
                  elevation: 5,
                  shadowColor: '#171717',
                  shadowOpacity: 0.3,
                  shadowRadius: 10,
                },
                style,
              ]}
            >
              {selectedValue ? (
                <Text style={{ color: Colors.lightest_grey }}>
                  {title + ': '}
                  <Text style={[AppStyles.textSecondary, { color: Colors.white }]}>
                    {selectedValue[label]}
                  </Text>{' '}
                </Text>
              ) : (
                <Text style={{ fontSize: 16, color: Colors.white }}>{title}</Text>
              )}
              {selectedValue ? (
                <TouchableOpacity onPress={() => onSelect(null)}>
                  <Icon as={<MaterialIcons name="close" />} size={5} ml="2" color={Colors.white} />
                </TouchableOpacity>
              ) : (
                <Icon
                  as={<MaterialIcons name="arrow-drop-down" />}
                  size={5}
                  ml="2"
                  color={Colors.white}
                />
              )}
            </LinearGradient>
          </TouchableOpacity>
        }
      </PopoverTrigger>
      {isOnModal ? <View>{content}</View> : <Portal>{content}</Portal>}
    </Popover>
  );
}
