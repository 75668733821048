import { Report } from '@webdatarocks/webdatarocks/webdatarocks';
import { FieldValue, Timestamp } from 'firebase/firestore';
import { CXPermissions } from './components/Users/Constants';

export enum EnabeldDisabled {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum Status {
  NEW = 'New',
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
  PENDING = 'Pending',
  PENDING_APPROVAL = 'Pending Approval',
}

export enum InputMode {
  CREATE = 'Create',
  EDIT = 'Edit',
  VIEW = 'View',
  APPROVE = 'Approve',
}

type CreatedInfo = {
  createdBy?: string;
  createdDtTm?: Timestamp;
};
export type Address = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
};

export type Bank = {
  name: string;
  externalName: string;
  address?: Address;
  BIC?: string;
  sortCode?: string;
  status?: Status | 'New';
  relationship?: 'Ours' | 'Third Party' | 'Both'; //Combination of isOurBank and isOtherBank
  isOurBank?: boolean;
  isOtherBank?: boolean;
  lastUpdatedBy?: string;
  updateRequest?: any;
} & CreatedInfo;

export type Entity = {
  name: string;
  code: string;
  shortName?: string;
  address?: Address;
  status?: Status;
  glCode?: string;
  updateRequest?: any;
  lastUpdatedBy?: string;
} & CreatedInfo;

export type BankAccount = {
  id?: string;
  accountName: string;
  accountNumber: string;
  shortCode?: string;
  entity?: string;
  iban?: string;
  currency: string;
  BIC?: string;
  status?: Status;
  bank?: string;
  balance?: number;
  accountGroups?: string[];
  lastUpdatedBy: string;
  previousBusinessDay?: Date;
  lastStatementValueDate?: Timestamp;
  lastStatementImportDate?: Timestamp;
  updateRequest?: any;
  paymentMethods?: string[];
  ledger?: string;
  glAccount?: string;
  glCodes?: { [key: string]: string };
} & CreatedInfo;

export enum PaymentType {
  'Internal' = 'Internal',
  'External' = 'External',
}

export enum PaymentStatus {
  'DRAFT' = 'Draft',
  'PENDING_APPROVAL' = 'Pending Approval',
  'REJECTED' = 'Rejected',
  'APPROVED' = 'Approved',
  'RELEASED' = 'Released',
  'SENT' = 'Sent',
  'RECEIVED' = 'Received by Bank', // Level 1 Ack
  'COMPLETED' = 'Completed', // Level 2 Ack
  'CANCELLED' = 'Cancelled',
}

export enum PaymentTemplateStatus {
  'ACTIVE' = 'Active',
  'DEACTIVATED' = 'Deactivated',
  'PENDING_UPDATE' = 'Pending Update',
  'UPDATE_APPROVED' = 'Update Approved',
}

export type Payment = {
  id?: string;
  sequenceNumber?: number;
  type: PaymentType;
  template?: string;
  isRepetitive?: boolean;
  status: PaymentStatus | Status.ACTIVE;
  fromAccountDetails: Partial<BankAccount>;
  fromEntityDetails?: Entity;
  toAccountDetails?: Partial<BankAccount>;
  InternalBeneficiaryAccountDetails?: Partial<BankAccount>; // Only for internal
  fromAccountCurrency: string;
  paymentCurrency: string;
  fromBankDetails: Bank;
  paymentMethod: string;
  category: string;
  toAccountNumber: string;
  toIBAN?: string;
  toName: string;
  toAddress?: Address;
  toBankName: string;
  toBankAddress?: Address;
  toBankBIC?: string;
  toBankRouteCode?: string;
  toBankRouteType?: string;
  intermediaryBankName?: string;
  intermediaryBankAddress?: Address;
  intermediaryBankBIC?: string;
  intermediaryBankRouteCode?: string;
  intermediaryBankRouteType?: string;
  intermediaryBankAccountNumber?: string;
  intermediaryName: string;

  paymentAmount: number;
  chargeBearer: 'CRED' | 'DEBT' | 'SHAR' | 'SLEV';
  valueDate: Date | Timestamp;
  defaultDate?: DateName;
  internalDescription?: string;
  paymentAdvice?: string;
  furtherCredit?: string;
  settlementDate: Timestamp;

  bankReference?: string;
  regulatoryReporting?: string;
  paymentPurpose?: string;
  customerReference?: string;
  endToEndReference?: string;
  bankRejectReason?: string;
  approverComments?: string;
  approvedDtTm?: Timestamp;
  releasedAt?: Timestamp;
  releasedBy?: string;
  sentAt?: Timestamp;
  approvedBy?: string[];
  rejectedBy?: string;
  rejectedAt?: Timestamp; // Used for Cancelled payment only. Rejected payments will use ApprovedAt
  approvedAt?: Timestamp;
  receivedAckDtTm?: Timestamp;
  completedDtTm?: Timestamp;
  messageId?: string;
  fileAckCode?: string;
  fileAckFileName?: string;
  transactionAckCode?: string;
  transactionAckFileName?: string;
  fileName?: string;
  createdAt?: Timestamp;
  updateRequest?: any;
} & CreatedInfo;

export enum Action {
  CREATE = 'Create',
  UPDATE = 'Update',
  UPDATE_REQUEST = 'Update Request',
  DELETE = 'Delete',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  IMPORT = 'Import',
  RUN = 'Run',
}

export enum LogType {
  'BankAccount' = 'BankAccounts',
  'BankStatement' = 'BankStatement',
  'CashPosition' = 'CashPosition',
  'Category' = 'Category',
  'AccountGroup' = 'AccountGroup',
  'BankTransactionRule' = 'BankTransactionRule',
  'Currency' = 'Currency',
  'Bank' = 'Bank',
  'Entity' = 'Entity',
  'Country' = 'Country',
  'HolidayCalendar' = 'HolidayCalendar',
  'User' = 'User',
  'UserGroup' = 'UserGroup',
  'Payments' = 'Payments',
  'PaymentTemplates' = 'PaymentTemplates',
  'SFTPCredentials' = 'SFTPCredentials',
  'Jobs' = 'Jobs',
  'Reports' = 'Reports',
  'Ledger' = 'Ledger',
}

export type ActivityHistory = {
  key: string;
  action: Action;
  user: string;
  before?: any;
  after?: any;
  timestamp: Timestamp;
  recordUid: string;
  type: LogType;
  comments?: string;
  metaData?: any;
  id?: string;
  approvalId?: string;
};

export type AccessibleScreen = {
  [key: string]: {
    title: string;
    target: string;
    permission: CXPermissions;
    view: boolean;
    edit: boolean;
    create: boolean;
    delete: boolean;
    approve: boolean;
  };
};

export enum PaymentMethodParams {
  'SvcLvl' = 'Service level',
  'ChrgBr' = 'Charges',
  'OrgId' = 'Org Id',
}

export type PaymentMethod = {
  id?: string;
  name: string;
  description?: string;
  status?: Status;
  params?: { [key in keyof typeof PaymentMethodParams]: string };
  fileName: string;
  fileFormat?: string;
} & CreatedInfo;

export type OrgInfo = {
  Name: string;
  Industry: string;
  Countries: Country[];
  Currencies: Currency[];
  Workflow?: any;
  PaymentMethods?: { [key: string]: PaymentMethod };
  Ledgers?: { [key: string]: Ledger };
  FileExportTemplate?: { [key: string]: FileExportTemplate };
};

export type staticData = {
  bankAccounts?: BankAccount[];
  bankAccountPreviousBusinessDays?: { [key: string]: Date };
  accountGroups?: AccountGroup[];
  categories?: TransactionCategory[];
  orgInfo?: OrgInfo;
  currencies?: Currency[];
  countries?: Country[];
  ourBanks?: Bank[];
  entities?: Entity[];
  userGroups?: UserGroup[];
  users?: User[];
  currencyRates?: { [key: string]: number };
  permissions?: any;
  isAdmin: boolean;
  accessibleScreens?: AccessibleScreen[];
};

export type StaticDataStore = {
  profileData: { orgId: string } & User;
  staticData: staticData;
  userInterface: {
    smartMenu?: boolean;
    aiScreen?: boolean;
    recentMenu?: AccessibleScreen;
    notificationCount: number;
    highPrioNotificationCount: number;
  };
};

export type RootTabParamList = {
  TabOne: undefined;
  TabTwo: undefined;
  LoginScreen: undefined;
  StatementImportScreen: undefined;
  ImportDataScreen: undefined;
  BankAccountScreen: undefined;
  BankStatementScreen: undefined;
  CashPositionScreen: undefined;
  CategoriesScreen: undefined;
  AccountGroupsScreen: undefined;
  BankTransactionRulesScreen: undefined;
  TempUtilsScreen: undefined;
  CurrenciesScreen: undefined;
  BankScreen: undefined;
  EntityScreen: undefined;
  CountriesScreen: undefined;
  HolidayCalendarScreen: undefined;
  UsersScreen: undefined;
  UserGroupsScreen: undefined;
  ApprovalWorkflowScreen: undefined;
};

export type Currency = {
  iso: string;
  name: string;
  state?: EnabeldDisabled;
};
export type Country = {
  name: string;
  code: string;
  nonBusinessDays?: number[];
};

export type Holiday = {
  id?: string;
  name: string;
  date: Date;
  countries?: string[];
  currencies?: string[];
};

export type StatementFile = {
  fileName: string;
  senderId: string;
  receiverId: string;
  fileDate: Date | FieldValue;
  valueDate: Date | null;
  stmtId?: string[];
};

export type BalanceRecord = {
  code: string;
  familyCode?: string;
  subFamilyCode?: string;
  amount: number;
  CdtDbtInd?: string;
};

export type JournalEntryDetails = { account: string; amount: number; currency: string };

export type JournalEntry = {
  id?: string;
  type: string;
  source: string;
  ledger: string;
  status: string;
  date: string;
  description?: string;
  debit: JournalEntryDetails;
  credit: JournalEntryDetails;
};
export type StatementTransaction = {
  code: string;
  familyCode?: string;
  subFamilyCode?: string;
  combinedCode: string;
  valueDate: Timestamp;
  valueDateString: string;
  bookingDate?: Date;
  stmtId?: string;
  accountNumber: string;
  accountName?: string;
  accountId: string;
  amount: number;
  currency: string;
  branchCode?: string;
  narrative?: string;
  isBalance: boolean;
  balances?: BalanceRecord[];
  id?: string;
  CdtDbtInd?: string;
  category?: string;
  oldCategory?: string; // Used temporarily only and will not be available in the DB
  oldCdtDbtInd?: string; // Used temporarily only and will not be available in the DB
  sourceType?: string;
  originalCategory?: string; //When updating from 1 category to other
  ruleId?: string;
  journals?: JournalEntry[];
  journalChanged?: boolean; // Used temporarily only and will not be available in the DB
  somethingChanged?: boolean; // Used temporarily only and will not be available in the DB
  comments?: Comment[];
};

export type CashPositionSummary = {
  id?: string;
  statementAmount: number | FieldValue;
  statementCount: number | FieldValue;
  paymentAmount: number | FieldValue;
  paymentCount: number | FieldValue;
  date: Timestamp;
  currency: string;
  category: string;
  details?: any;
};

export type CurrencyRate = {
  from: string;
  to: string;
  rate: number;
  date?: Date;
};

export type TransactionCategory = {
  id?: string;
  name: string;
  description?: string;
  color?: string;
  icon?: string;
  updateRequest?: any;
  lastUpdatedBy?: string;
} & CreatedInfo;

export enum Visibility {
  Public = 'Public',
  Private = 'Private',
  UserGroup = 'User Group',
}

export type CashPositionView = {
  id?: string;
  name: string;
  description?: string;
  rowConfig?: any[];
  visibility: Visibility;
  userGroups?: string[];
  accountGroups?: string[];
  defaultDate?: DateName;
  isLocked?: boolean;
  showForecasts?: boolean;
  showIntraday?: boolean;
} & CreatedInfo;

export type GlExportFilters = {
  id?: string;
  name: string;
  description?: string;
  visibility: Visibility;
  userGroups?: string[];
  accountGroups?: string[];
  excludedCategories?: string[];
  excludedTransactionCodes?: string[];
  excludeSystemSuspense?: boolean;
  excludeZeroAmount?: boolean;
  excludedGlAccounts?: string[];
  glAccountsToConsolidate?: string[];
} & CreatedInfo;

export type AccountGroup = {
  id: string;
  name: string;
  description?: string;
  updateRequest?: any;
  status: Status;
  lastUpdatedBy?: string;
} & CreatedInfo;

export type UserGroup = {
  id?: string;
  name: string;
  description?: string;
  permissions?: any;
  accountGroups?: string[];
  updateRequest?: any;
  lastUpdatedBy?: string;
  status: Status;
} & CreatedInfo;

export type UserGroupPermissionAccess = {
  view: boolean;
  create: boolean;
  delete: boolean;
  edit: boolean;
  approve?: boolean;
};

export type UserGroupPermission = {
  name: string;
  description?: string;
  access: UserGroupPermissionAccess;
} & CreatedInfo;

export type BankTransactionRule = {
  id?: string;
  name: string;
  accounts?: string[];
  accountGroups?: string[];
  code?: string[];
  priority: number;
  status: string;
  description?: string;
  keyWords?: string[];
  category: string;
  ledger?: string;
  glAccount?: string;
  glCodes?: { [key: string]: string };
  creditDebitOverride?: 'C' | 'D';
} & CreatedInfo;

export type ProcessStatementFileResponse = {
  data: {
    transactions: StatementTransaction[];
    balanceIndexes: number[];
  };
};

export type UploadedFile = {
  downloadURL: string;
  filepath: string;
};

export type User = {
  uid: string;
  firstName: string;
  lastName: string;
  displayName?: string;
  displayImage?: string;
  email: string;
  userGroups: string[];
  status: Status;
  lastLogin: Timestamp;
  updateRequest?: any;
  lastUpdatedBy?: string;
  homeScreenLayout?: any[];
} & CreatedInfo;

export enum DateName {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  TOMORROW = 'Tomorrow',
  THIS_WEEK = 'This Week',
  LAST_WEEK = 'Last Week',
  NEXT_WEEK = 'Next Week',
  THIS_MONTH = 'This Month',
  LAST_MONTH = 'Last Month',
  NEXT_MONTH = 'Next Month',
  THIS_QUARTER = 'This Quarter',
  LAST_QUARTER = 'Last Quarter',
  NEXT_QUARTER = 'Next Quarter',
  THIS_YEAR = 'This Year',
  LAST_YEAR = 'Last Year',
  NEXT_YEAR = 'Next Year',
  NEXT_BUSINESS_DAY = 'Next Business Day',
}

export enum BalSource {
  'CDOLB' = 'Current Day Opening Ledger Balance',
  'CDCLB' = 'Current Day Closing Ledger Balance',
  'PDOLB' = 'Previous Day Opening Ledger Balance',
  'PDCLB' = 'Previous Day Closing Ledger Balance',
  'CDOAB' = 'Current Day Opening Available Balance',
  'CDCAB' = 'Current Day Closing Available Balance',
  'PDOAB' = 'Previous Day Opening Available Balance',
  'PDCAB' = 'Previous Day Closing Available Balance',
  'MSSNG' = 'Missing Statement',
}

export enum ApprovalWorkflowSource {
  STATIC_DATA = 'All Static Data',
  SECURITY = 'All Security',
  BANK_ACCOUNTS = 'Bank Accounts',
  BANKS = 'Banks',
  PAYMENTS = 'Payments',
  PAYMENT_TEMPLATES = 'Payment Templates',
  ENTITIES = 'Entities',
  USERS = 'Users',
  USER_GROUPS = 'User Groups',
  ACCOUNT_GROUPS = 'Account Groups',
  CONFIGURATION = 'Configuration',
}

// export enum ApprovalWorkflowSourceRuleMap {
//   'All Static Data' = 'StaticData',
//   'Bank Accounts' = 'BankAccounts',
//   'Banks' = 'Banks',
//   'Payments' = 'Payments',
// }

export enum Operator {
  EQUALS = '==',
  NOT_EQUALS = '!=',
  LESS_THAN = '<',
  LESS_THAN_EQUAL = '<=',
  GREATER_THAN = '>',
  GREATER_THAN_EQUAL = '>=',
  IN = 'in',
  NOT_IN = 'not-in',
  ARRAY_CONTAINS = 'array-contains',
  ARRAY_CONTAINS_ANY = 'array-contains-any',
  BETWEEN = 'between',
  EXISTS = 'exists',
}

export type ApprovalWorkflowStep = {
  level: number; //0 is Fallback
  allOrAny: 'All' | 'Any';
  approverUserGroups: string[];
  criteria: {
    field: string;
    operator: Operator;
    values: string[] | boolean[];
  }[];
};
export type ApprovalWorkflow = {
  source: ApprovalWorkflowSource;
  status: boolean;
  steps?: ApprovalWorkflowStep[];
  icon?: string;
};
export enum ApprovalTimelineItemStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  REJECTED = 'Rejected',
  IN_PROGRESS = 'In Progress',
}

export type ApprovalTimelineItem = ApprovalWorkflowStep & {
  id?: string;
  status: ApprovalTimelineItemStatus;
  title: string;
  approvers: {
    uid: string;
    name: string;
    state: 'Skipped' | 'Approved' | 'Pending' | 'Not Started' | 'Rejected' | 'Submitted';
    comments?: string;
    timestamp?: Timestamp | any;
  }[];
  timestamp?: Timestamp | any;
};

export type ApprovalWorkflowRequest = {
  id?: string;
  key: string;
  before?: any;
  after?: any;
  status: ApprovalTimelineItemStatus;
  type: ApprovalWorkflowSource;
  approvers: string[];
  user: string;
  timeline: ApprovalTimelineItem[];
  timestamp: Timestamp;
  action?: { label: string; color: string };
};

export type SFTPConfiguration = {
  id?: string;
  name: string;
  host: string;
  port: string;
  username: string;
  password: string;
  path: string;
  destinationPath?: string;
  description?: string;
  fileMask?: string;
  updateRequest?: any;
  lastUpdatedBy?: string;
} & CreatedInfo;

export type Notification = {
  id?: string;
  title: string;
  message: string;
  type: 'info' | 'warning' | 'error' | 'success';
  timestamp: Timestamp;
  read: boolean;
  hide: boolean;
  ctaText?: string;
  targetScreen?: string;
  key?: string;
  source?: LogType | ApprovalWorkflowSource;
  approvalId?: string;
  user: string;
  priority?: number; // 1 is highest
};

export enum RequestAction {
  Create = 'Create',
  Update = 'Update',
}

export enum JobStatus {
  Scheduled = 'Scheduled',
  Running = 'Running',
  Completed = 'Completed',
  Failed = 'Failed',
}

export enum TaskType {
  Import = 'Import',
  Export = 'Export',
  SFTP = 'SFTP',
  Archive = 'Archive',
}

export enum ActiveStatus {
  Active = 'Active',
  Deativated = 'Deactivated',
}

export type Task = {
  name: string;
  status: ActiveStatus;
  type: TaskType;
  description?: string;
  timestamp: Timestamp;
  createdBy: string;
  createdDtTm: Timestamp;
  updatedBy?: string;
  updatedDtTm?: Timestamp;
  parameters?: any;
  result?: any;
  sftpDirection?: 'Pull' | 'Push';
  sftpConfig?: string;
  sourceFolder?: string;
  destinationFolder?: string;
};

export type JobSchedule = {
  timeZone: string;
  frequency: 'Daily' | 'Weekly' | 'Monthly' | 'Once' | 'Adhoc';
  startDate: Date;
  endDate?: Date;
  daysOfWeek?: number[];
  onceTime?: string;
  occurrenceDailyType?: 'Every' | 'Once';
  occurrenceDailyEvery?: number;
  occurrenceDailyEveryUnit?: 'Hours' | 'Mins';
  occurrencyDailyFromTime?: string;
  occurrencyDailyToTime?: string;
  occurrenceOnceAtTime?: string;
};

export type Job = {
  id?: string;
  name: string;
  status: ActiveStatus;
  description?: string;
  updatedBy?: string;
  updatedDtTm?: Timestamp;
  parameters?: any;
  tasks?: Task[];
  lastRun: Timestamp | null;
  lastRunStatus: JobStatus | null;
  lastRunId: string | null;
  schedule?: JobSchedule;
  lastUpdatedBy?: string;
} & CreatedInfo;

export type JobRun = {
  runId: string;
  startTime: Timestamp;
  endTime?: Timestamp;
  status: JobStatus;
} & Job;

export type CReport = {
  id?: string;
  name: string;
  dataSource: string;
  description?: string;
  visibility: Visibility;
  userGroups?: string[];
  isLocked?: boolean;
  reportConfig?: Report;
  lastRun?: Timestamp;
  lastUpdatedBy?: string;
  lastUpdatedDtTm?: Timestamp;
  filterParams?: any[];
  filters?: any;
} & CreatedInfo;

export enum DbCollections {
  BankAccounts = 'BankAccounts',
  BankTransactionRules = 'BankTransactionRules',
  Banks = 'Banks',
  Categories = 'Categories',
  Entities = 'Entities',
  Jobs = 'Jobs',
  Payments = 'Payments',
  PaymentTemplates = 'PaymentTemplates',
  UserGroups = 'UserGroups',
  Users = 'Users',
}

export type DataType = {
  value: DbCollections;
  label: string;
  fields: any[];
};

export enum LedgerSegmentType {
  'Entity' = 'Entity',
  'Bank Account' = 'Bank Account',
  'Currency' = 'Currency',
  'Constant' = 'Constant',
  'Wildcard' = 'Wildcard',
  'Custom List' = 'Custom List',
}

export type LedgerSegment = {
  name: string;
  type: LedgerSegmentType;
  value?: string;
  suffix?: string;
  minLength?: number;
  maxLength?: number;
};

export type Ledger = {
  name: string;
  description?: string;
  baseCurrency: string;
  segment1: LedgerSegment;
  segment2?: LedgerSegment;
  segment3?: LedgerSegment;
  segment4?: LedgerSegment;
  segment5?: LedgerSegment;
  segment6?: LedgerSegment;
  segment7?: LedgerSegment;
  segment8?: LedgerSegment;
  segment9?: LedgerSegment;
  segment10?: LedgerSegment;
  completeStructure: string;
  status: Status;
  updateRequest?: any;
  lastUpdatedBy?: string;
} & CreatedInfo;

type FileExportTemplate = {
  name: string;
  description?: string;
  fileName: string;
  fileFormat: 'csv' | 'xlsx | pdf | json | xml | delimited';
  delimiter: string;
  includeHeader: boolean;
  dataSource: string;
  fields: {
    name: string;
    label: string;
    type: 'constant' | 'field' | 'expression' | 'function';
    formatType?: 'date' | 'number' | 'string';
    format?: string;
    value?: string;
  }[];
  visibility: Visibility;
  userGroups?: string[];
  lastUpdatedBy?: string;
} & CreatedInfo;

export type Comment = {
  id: string;
  user: string;
  text: string;
  taggedUsers: string[];
  parentId: string | null;
  createdAt: {
    isoString: string;
    timezone: string;
  };
  resolved: boolean;
  replies: Comment[];
};
