/* eslint-disable react-native/no-inline-styles */
import React from 'react';

import moment from 'moment';
import { FlatList } from 'native-base';

import { StatementBalanceItem } from './StatementBalanceItem';
import { Card, Text, View } from './Themed';
import Colors from '../constants/Colors';
import Constants from '../constants/Constants';
import AppStyles from '../constants/Styles';
import { BankAccount, StatementTransaction } from '../commonTypes';
import Layout from '../constants/Layout';

export type StatementBalanceWidgetProps = {
  transactionItem: StatementTransaction;
  account?: BankAccount;
};
export function StatementBalanceWidget({ transactionItem, account }: StatementBalanceWidgetProps) {
  if (transactionItem.isBalance) {
    return (
      <Card
        style={{
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <View
          style={{
            backgroundColor: 'transparent',
            marginLeft: 10,
          }}
        >
          {account?.accountName && (
            <Text
              style={{
                color: Colors.black,
                fontWeight: 'bold',
              }}
            >
              {account?.accountName}
            </Text>
          )}
          <Text
            style={{
              color: Colors.black,
            }}
          >
            {transactionItem.accountNumber}
          </Text>
          <Text style={AppStyles.textRegularGrey}>
            {moment(transactionItem.valueDate?.toDate()).format('ddd, DD MMM YYYY')}
          </Text>
        </View>
        <FlatList
          style={{ maxHeight: Layout.window.height / 2 }}
          data={transactionItem.balances}
          renderItem={({ item, index }) => {
            return (
              <StatementBalanceItem
                balance={item}
                currency={transactionItem.currency}
                balKey={index.toString()}
                style={{ marginLeft: 10 }}
              />
            );
          }}
        />
      </Card>
    );
  }
  return null;
}
