/* eslint-disable react-native/no-inline-styles */
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { ColDef, RowDragEndEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore';
import union from 'lodash/union';
import uniqBy from 'lodash/uniqBy';
import { useSelector } from 'react-redux';

import {
  Button,
  Checkbox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { AppContext } from '../../App';
import {
  CashPositionView,
  DateName,
  InputMode,
  StaticDataStore,
  Visibility,
} from '../../commonTypes';
import Layout from '../../constants/Layout';
import AppStyles from '../../constants/Styles';
import { _array_move, _textFieldIsInvalid } from '../../utils/helper';
import AccessType from '../AccessType';
import CInput from '../CInput';
import { MultiSelectInput } from '../MultiSelectInput';
import { Text, ToastAlert, View } from '../Themed';
import AccountGroupSelectionList from '../Users/AccountGroupSelectionList';
import { EditCashPositionTabs } from '../Users/Constants';

export type EditCashPositionProps = {
  viewConfig: CashPositionView;
  setViewConfig: Dispatch<SetStateAction<CashPositionView>>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  onViewUpdated?: (updatedUser: CashPositionView) => any;
  mode?: InputMode;
};

const EditCashPosition: FC<EditCashPositionProps> = ({
  viewConfig,
  setViewConfig,
  isVisible,
  onViewUpdated,
  setIsVisible,
  mode = InputMode.CREATE,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [temporaryViewConfig, setTemporaryViewConfig] = useState(
    mode !== InputMode.CREATE ? viewConfig : ({} as CashPositionView),
  );
  const [originalViewConfig, setOriginalViewConfig] = useState(viewConfig);
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const [editColDefs] = useState<ColDef[]>([
    {
      field: 'category',
      filter: false,
      pinned: 'left',
      rowDrag: true,
      headerName: 'Rearrange Categories',
      suppressSizeToFit: true,
      resizable: false,
      flex: 1,
      width: 300,
    },
  ]);
  const [rowOrderConfig, setRowOrderConfig] = useState<any>([]);
  const { db } = useContext(AppContext);
  const toast = useToast();

  useEffect(() => {
    if (mode !== InputMode.CREATE) {
      console.warn('Reseting Temp View Config');
      setTemporaryViewConfig(viewConfig);
    }
    console.warn('Setting Original View Config', viewConfig);
    setOriginalViewConfig(viewConfig);
  }, [mode, viewConfig]);

  const onCancel = useCallback(() => {
    setIsVisible(false);
    if (originalViewConfig) {
      setTemporaryViewConfig(originalViewConfig);
      setViewConfig(originalViewConfig);
    }
  }, [originalViewConfig, setIsVisible, setViewConfig]);

  const onSave = useCallback(
    async (isApplyOnly: boolean) => {
      if (isSaving || !profileData?.orgId) {
        return;
      }
      console.warn('saving');
      setIsSaving(true);
      if (
        _textFieldIsInvalid(temporaryViewConfig?.name) ||
        _textFieldIsInvalid(temporaryViewConfig?.description)
      ) {
        setIsSaving(false);
        toast({
          title: 'Enter all mandatory fields',
          description: 'View name and description are mandatory',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if ((temporaryViewConfig?.accountGroups?.length || 0) <= 0) {
        setIsSaving(false);
        return toast({
          title: 'Select atleast one account group',
          description: 'The view should have atleast one account group selected',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (!temporaryViewConfig?.visibility) {
        setIsSaving(false);
        return toast({
          title: 'Select Visibility',
          description: 'Please select the visibility of the view',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }

      if (!temporaryViewConfig?.defaultDate) {
        setIsSaving(false);
        return toast({
          title: 'Select Default Date',
          description: 'Please select the default date',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      const cashPositionViewConfigDoc = {
        ...temporaryViewConfig,
        rowConfig: rowOrderConfig,
      } as CashPositionView;
      if (isApplyOnly) {
        console.warn(cashPositionViewConfigDoc);
        setViewConfig(cashPositionViewConfigDoc);
        setIsSaving(false);
        return;
      }

      if (temporaryViewConfig.id) {
        //Update the view
        await setDoc(
          doc(db, 'Organizations', profileData.orgId, 'CashPositionView', temporaryViewConfig.id),
          cashPositionViewConfigDoc,
        );

        setIsVisible(false);
        setViewConfig(cashPositionViewConfigDoc);
        setIsSaving(false);
        return toast({
          title: 'View Updated',
          description: 'The view has been updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      //Create new view

      const docSnap = await getDocs(
        query(
          collection(db, 'Organizations', profileData?.orgId, 'CashPositionView'),
          where('name', '==', temporaryViewConfig.name),
        ),
      );

      if (docSnap.docs.length > 0) {
        setIsSaving(false);

        return toast({
          title: 'View already exists',
          description:
            'A View with the name already exists. If you dont see it in your list of views, it is possible that you dont have access to it. Please try a different name.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      const newViewDoc = await addDoc(
        collection(db, 'Organizations', profileData.orgId, 'CashPositionView'),
        {
          ...cashPositionViewConfigDoc,
          createdBy: profileData.uid,
          createdDtTm: serverTimestamp(),
        },
      );

      setIsVisible(false);
      setViewConfig({
        ...temporaryViewConfig,
        id: newViewDoc.id,
        createdBy: profileData?.uid,
        createdDtTm: Date.now(),
      });
      setIsSaving(false);

      return toast({
        title: 'View Created',
        description: 'The view has been created successfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    },
    [
      db,
      isSaving,
      profileData.orgId,
      profileData.uid,
      rowOrderConfig,
      setIsVisible,
      setViewConfig,
      temporaryViewConfig,
      rowOrderConfig,
      toast,
    ],
  );

  const onTextChange = useCallback((field: keyof CashPositionView, value: string | string[]) => {
    setTemporaryViewConfig((existingValue) => {
      return { ...existingValue, [field]: value } as CashPositionView;
    });
  }, []);

  const editTableData = useMemo(() => {
    return staticData.categories?.map((category) => {
      return {
        category: category.name,
        categoryId: category.id,
      };
    });
  }, [staticData.categories]);

  useEffect(() => {
    //Since new categories need to be at the end
    console.warn('test1', union(temporaryViewConfig?.rowConfig, editTableData));
    console.warn(temporaryViewConfig?.rowConfig);
    console.warn(editTableData);
    setRowOrderConfig(uniqBy(union(temporaryViewConfig?.rowConfig, editTableData), 'categoryId'));
  }, [editTableData, temporaryViewConfig?.rowConfig]);

  const onRowDragEnd = useCallback(
    (e: RowDragEndEvent) => {
      console.log('onRowDragEnd', e);
      const originalIndex = rowOrderConfig?.findIndex(
        (row: any) => row?.categoryId === e.node.data?.categoryId,
      );
      const newIndex = e.node.rowIndex;
      console.warn(originalIndex, '->', newIndex);
      if (originalIndex !== null && newIndex !== null && originalIndex !== newIndex) {
        const reOrdered = _array_move(rowOrderConfig, originalIndex, newIndex);
        console.warn(reOrdered);
        setRowOrderConfig(reOrdered);
        // setTemporaryViewConfig((existingValue) => {
        //   return { ...existingValue, rowConfig: reOrdered } as CashPositionView;
        // });
      }
    },
    [rowOrderConfig],
  );

  const renderEditGrid = useCallback(() => {
    return (
      <div
        className="ag-theme-alpine"
        style={{ maxWidth: 300, height: Layout.window.height - 400 }}
      >
        <AgGridReact
          //ref={gridRef} // Ref for accessing Grid's API
          rowData={rowOrderConfig} // Row Data for Rows
          columnDefs={editColDefs} // Column Defs for Columns
          defaultColDef={{
            sortable: false,
          }} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="single" // Options - allows click selection of rows
          // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
          alwaysShowHorizontalScroll={false}
          rowDragManaged={true}
          onRowDragEnd={onRowDragEnd}
        />
      </div>
    );
  }, [editColDefs, onRowDragEnd, rowOrderConfig]);

  const renderTab = useCallback(
    (tabName: EditCashPositionTabs) => {
      return (
        <View
          style={[
            AppStyles.flexRowCenterSpaceBetween,
            AppStyles.marginTop,
            { alignItems: 'flex-start' },
          ]}
        >
          <View>
            <AccessType
              label="Visibility"
              options={[Visibility.Private, Visibility.Public, Visibility.UserGroup]}
              value={temporaryViewConfig?.visibility}
              onChange={(v) => onTextChange('visibility', v)}
              userGroups={temporaryViewConfig?.userGroups}
              onUserGroupChange={(v) => onTextChange('userGroups', v)}
            />
          </View>
          <MultiSelectInput
            title="Default Date"
            data={[
              { label: DateName.TODAY, id: DateName.TODAY },
              { label: DateName.YESTERDAY, id: DateName.YESTERDAY },
              { label: DateName.TOMORROW, id: DateName.TOMORROW },
            ]}
            onSelect={(v) => {
              onTextChange('defaultDate', v?.[0].label);
            }}
            isSingleSelect
            selectedValues={
              temporaryViewConfig?.defaultDate
                ? [{ label: temporaryViewConfig.defaultDate, id: temporaryViewConfig.defaultDate }]
                : []
            }
            renderItem={(item) => <Text>{item.label}</Text>}
            style={AppStyles.defaultWidthSmall}
            isRequired={true}
          />
          <View>
            <Checkbox
              isChecked={!!temporaryViewConfig?.showForecasts}
              onChange={(e) =>
                setTemporaryViewConfig((cv) => {
                  return { ...cv, showForecasts: e.target.checked };
                })
              }
            >
              {'Show Forecasts [F]'}
            </Checkbox>

            <Checkbox
              isChecked={!!temporaryViewConfig?.showIntraday}
              onChange={(e) =>
                setTemporaryViewConfig((cv) => {
                  return { ...cv, showIntraday: e.target.checked };
                })
              }
              style={AppStyles.marginTop}
            >
              {'Show Intraday [I]'}
            </Checkbox>
          </View>
          <View style={AppStyles.defaultWidthSmall}>{renderEditGrid()}</View>
        </View>
      );
    },
    [
      onTextChange,
      renderEditGrid,
      temporaryViewConfig?.defaultDate,
      temporaryViewConfig?.userGroups,
      temporaryViewConfig?.visibility,
      temporaryViewConfig?.showForecasts,
      temporaryViewConfig?.showIntraday,
    ],
  );

  const renderAccountGroup = useCallback(() => {
    return (
      <AccountGroupSelectionList record={temporaryViewConfig} setRecord={setTemporaryViewConfig} />
    );
  }, [temporaryViewConfig]);

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    {
      key: EditCashPositionTabs.CONFIGURATION,
      title: 'Configuration',
    },
    {
      key: EditCashPositionTabs.ACCOUNT_GROUPS,
      title: 'Account Groups',
    },
  ]);

  if (!isVisible) {
    return null;
  }

  return (
    <View>
      <View style={AppStyles.flexRowCenter}>
        <View style={AppStyles.flex1}>
          <CInput
            label="View Name"
            fieldKey="name"
            fieldValue={temporaryViewConfig.name}
            onTextChange={onTextChange}
            isRequired
            // disabled={mode === InputMode.EDIT}
          />
        </View>
        <View style={AppStyles.flex1}>
          <CInput
            label="Description"
            fieldKey="description"
            fieldValue={temporaryViewConfig.description}
            onTextChange={onTextChange}
            isRequired
          />
        </View>
        <Button title={'Cancel'} onClick={onCancel} isDisabled={isSaving} variant={'outline'}>
          Cancel
        </Button>
        <Button
          ml={2}
          colorScheme="blue"
          title={'Apply'}
          onClick={() => {
            onSave(true);
          }}
          isLoading={isSaving}
        >
          Apply
        </Button>
        <Button
          ml={2}
          title={'Save View'}
          onClick={() => onSave(false)}
          isLoading={isSaving}
          colorScheme={'green'}
        >
          Save View
        </Button>
      </View>
      {/* <TabView
        navigationState={{
          index,
          routes,
        }}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        onIndexChange={setIndex}
        initialLayout={initialLayout}
        style={{
          marginTop: StatusBar.currentHeight,
        }}
      /> */}

      <Tabs variant={'enclosed'} index={index} onChange={setIndex} mt={5} mb={5}>
        <TabList>
          {routes.map((route) => (
            <Tab key={route.key}>{route.title}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {routes.map((route) => {
            switch (route.key) {
              case EditCashPositionTabs.CONFIGURATION:
                return <TabPanel key={route.key}>{renderTab(route.key)}</TabPanel>;
              case EditCashPositionTabs.ACCOUNT_GROUPS:
                return <TabPanel key={route.key}>{renderAccountGroup()}</TabPanel>;
              default:
                return null;
            }
          })}
        </TabPanels>
      </Tabs>
    </View>
  );
};
export default EditCashPosition;
