import React, { FC, useMemo, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumby';
import { Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Card, Center } from '@chakra-ui/react';
import { StaticDataStore, Status } from '../../commonTypes';
import Colors from '../../constants/Colors';
import { Text } from '../Themed';
import useCurrencies from '../../hooks/useCurrencies';

// Register Chart.js plugins
Chart.register(...registerables, ChartDataLabels);

const BalanceByBankWidget: FC = () => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  const { _convertToReportingCurrency } = useCurrencies();
  const reportingCurrency = 'USD';

  const actualPieData = useMemo(() => {
    const bankGroup = groupBy(
      staticData.bankAccounts?.filter((acc) => acc.status === Status.ACTIVE),
      (account) => account.bank || 'Unmapped',
    );

    console.warn('bankGroup', Object.entries(bankGroup));
    const formattedData = Object.entries(bankGroup)
      .sort()
      .map(([bankName, accounts]) => {
        return {
          currency: bankName,
          balance: sumBy(accounts, (account) =>
            _convertToReportingCurrency(account.balance || 0, account.currency),
          ),
          count: accounts.length,
        };
      });

    return formattedData;
  }, [staticData.bankAccounts, _convertToReportingCurrency]);

  return (
    <Card p={5} style={{ alignItems: 'center', flex: 1 }}>
      <Text
        style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'center' }}
      >{`Balance by Banks`}</Text>
      {staticData.bankAccounts?.length == 0 ? (
        <Center flex={1}>
          <Text style={{ fontSize: 14, color: '#718096', textAlign: 'center', marginTop: 20 }}>
            No data yet
          </Text>
        </Center>
      ) : (
        <Pie
          options={{
            plugins: {
              legend: { display: true, position: 'bottom' },
              subtitle: {
                position: 'left',
                display: true,
                text: `${reportingCurrency}`,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const label = context.label || '';
                    const value = (context.raw || 0).toFixed(2);
                    return `${label}: ${value}k ${reportingCurrency}`;
                  },
                },
              },
              datalabels: {
                color: Colors.transBlack, // Label color
                anchor: 'end', // Center the text inside the segment
                align: 'end',
                padding: -2,
                formatter: (value, context) => {
                  const dataset = context.chart.data.datasets[0];
                  const total = dataset.data.reduce((acc, val) => acc + val, 0);
                  const percentage = ((value / total) * 100).toFixed(1) + '%';

                  // Check if the segment size is large enough to fit the text
                  const meta = context.chart.getDatasetMeta(0);
                  const arc = meta.data[context.dataIndex];
                  const radius = arc.outerRadius; // Segment outer radius
                  const textWidth = context.chart.ctx.measureText(percentage).width;

                  // Show the label only if the segment is larger than the text width
                  return radius > textWidth + 10 ? percentage : '';
                },
                font: {
                  weight: 'bold',
                  size: 12,
                },
              },
            },
            maintainAspectRatio: false,
            responsive: true,
          }}
          data={{
            labels: [...actualPieData.map((e) => e.currency)],
            datasets: [
              {
                data: [...actualPieData.map((e) => e.balance / 1000)], // Convert to thousands
                backgroundColor: actualPieData.map(
                  (_, index) => Colors.pieChartColors[(index * 2) % Colors.pieChartColors.length],
                ),
              },
            ],
          }}
        />
      )}
    </Card>
  );
};

export default BalanceByBankWidget;
