import { useToast } from '@chakra-ui/react';
import { Ledger, LedgerSegment, LedgerSegmentType } from '../commonTypes';
import { _textFieldIsInvalid } from '../utils/helper';

export default function useAccounting() {
  const toast = useToast();

  const validateGLCodes = (ledgerDetails: Ledger, glCodes?: string[]) => {
    if (ledgerDetails) {
      let errorExists = false;
      [...Array(10)].map((_, i) => {
        const fieldName = `segment${i + 1}`;
        const segment = ledgerDetails[fieldName] as LedgerSegment;
        if (
          segment?.type === LedgerSegmentType['Bank Account'] ||
          segment?.type === LedgerSegmentType.Wildcard ||
          segment?.type === LedgerSegmentType['Custom List']
        ) {
          if (_textFieldIsInvalid(glCodes?.[fieldName])) {
            errorExists = true;
            return toast({
              title: 'Invalid ' + segment?.name,
              description: 'Since you have selected a Ledger, this is a mandatory field',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
          if (
            segment.type === LedgerSegmentType.Wildcard &&
            ((segment.maxLength && (glCodes?.[fieldName]?.length || 0) > segment.maxLength) ||
              (segment.minLength && (glCodes?.[fieldName]?.length || 0) < segment.minLength))
          ) {
            errorExists = true;
            let toastMessage = 'The value should be ';
            if ((segment.minLength === segment.maxLength) !== undefined) {
              toastMessage += 'exactly ' + segment.minLength;
            } else if (segment.minLength !== undefined && segment.maxLength !== undefined) {
              toastMessage += 'between ' + segment.minLength + ' and ' + segment.maxLength;
            } else if (segment.minLength) {
              toastMessage += 'more than ' + segment.minLength;
            } else if (segment.maxLength) {
              toastMessage += 'less than ' + segment.maxLength;
            }
            toastMessage += ' characters';
            return toast({
              title: 'Invalid ' + segment?.name,
              description: toastMessage,
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
          }
        }
      });
      return errorExists;
    }
  };

  return { validateGLCodes };
}
