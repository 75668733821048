import React from 'react';

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import Layout from '../constants/Layout';

export type CModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  title: string;
  children: React.ReactElement;
  onSave?: () => void;
  onClose?: () => void;
  hideSaveButton?: boolean;
  hideButtons?: boolean;
  hideCancelButton?: boolean;
  width?: number;
  height?: number;
  isSaving?: boolean;
  footerContent?: React.ReactElement;
  additionalButtons?: React.ReactElement;
};
export function CModal({
  open,
  setOpen,
  title,
  onSave,
  onClose,
  children,
  hideSaveButton,
  hideCancelButton,
  hideButtons,
  width,
  height,
  isSaving,
  footerContent,
  additionalButtons,
}: CModalProps) {
  return (
    <Modal
      isOpen={open}
      onClose={() => {
        onClose?.();
        setOpen(false);
      }}
      // scrollBehavior="inside"
    >
      <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="2px" />

      <ModalContent
        pt={0}
        maxWidth={width || Layout.window.width * 0.9}
        height={height}
        // minHeight={bodyStyle ? undefined : Layout.window.height * 0.5}
        // style={{
        //   marginLeft: 'auto',
        //   marginRight: 0,
        // }}
      >
        <ModalHeader pb={1}>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={0}>{children}</ModalBody>
        {hideButtons ? (
          footerContent
        ) : (
          <ModalFooter>
            {!hideCancelButton && (
              <Button
                variant="outline"
                mr={3}
                onClick={() => {
                  onClose?.();
                  setOpen(false);
                }}
                isDisabled={isSaving}
              >
                Cancel
              </Button>
            )}
            {additionalButtons}
            {!hideSaveButton && (
              <Button colorScheme="blue" onClick={onSave} isLoading={isSaving}>
                Save
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
