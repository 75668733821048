import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Select } from 'chakra-react-select';

import { FormControl, FormLabel } from '@chakra-ui/react';
import { StaticDataStore } from '../../commonTypes';

export type LedgerInputProps = {
  title?: string;
  onSelect: (values: string[]) => void;
  value?: string;
  options?: string[];
  isReadOnly?: boolean;
  isRequired?: boolean;
  values?: string[];
  isMultiSelect?: boolean;
};
const LedgerInput: FC<LedgerInputProps> = ({
  onSelect,
  value,
  isRequired,
  isReadOnly = false,
  values,
  isMultiSelect,
  title,
  options,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);
  // if (staticData?.orgInfo?.Ledgers?.length === 1) {
  //   onSelect(staticData?.orgInfo?.Ledgers[0].name);
  // }
  return (
    <FormControl isRequired={isRequired} width={300}>
      <FormLabel fontSize={14} mb={1}>
        {title || 'Ledger'}
      </FormLabel>
      <Select
        isReadOnly={isReadOnly}
        isMulti={isMultiSelect}
        onChange={(selected) => {
          isMultiSelect ? onSelect(selected?.map((val) => val.value)) : onSelect([selected?.value]);
        }}
        closeMenuOnSelect={!isMultiSelect}
        value={
          isMultiSelect
            ? values?.map((val) => {
                return { value: val, label: val };
              })
            : value
            ? [{ value, label: value }]
            : []
        }
        name="Ledger"
        options={
          Object.keys(staticData?.orgInfo?.Ledgers || {})
            ?.map((method) => {
              return { value: method, label: method };
            })
            .filter((method) => !options || options?.includes(method.value)) || []
        }
        placeholder="Select Ledger"
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default LedgerInput;
