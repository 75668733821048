/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useContext, useState } from 'react';

import { useToast } from 'native-base';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';

import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import {
  ApprovalTimelineItemStatus,
  ApprovalWorkflowRequest,
  ApprovalWorkflowSource,
  InputMode,
  RequestAction,
  StaticDataStore,
} from '../commonTypes';
import ReviewAndApproveModal from '../components/ApprovalWorkflow/ReviewAndApproveModal';
import { CFlatList } from '../components/CFlatList';
import { FilterBar } from '../components/FilterBar';
import { Button, Card, Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import AppStyles from '../constants/Styles';
import { RootTabScreenProps } from '../types';
import {
  _filterToWhereClause,
  _getApprovalWorkflowRequestAction,
  _getTimeStampString,
  _searchList,
} from '../utils/helper';

import {
  Badge,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FontAwesome } from '@expo/vector-icons';
import { useFocusEffect } from '@react-navigation/native';
import { isEmpty } from 'lodash';
import { Image } from 'react-native';
import { AppContext } from '../App';
import BankAccountDetails from '../components/BankAccounts/BankAccountDetails';
import IconText from '../components/IconText';
import useUsers from '../hooks/useUsers';
import CreatePaymentScreen from './CreatePaymentScreen';
import BankDetails from '../components/BankAccounts/BankDetails';
import EntityDetails from '../components/BankAccounts/EntityDetails';
import UserDetails from '../components/Users/UserDetails';
import UserGroupDetails from '../components/Users/UserGroupDetails';
import AccountGroupDetails from '../components/BankAccounts/AccountGroupDetails';

export default function PendingApprovalsScreen({
  navigation,
}: RootTabScreenProps<'PendingApprovalsScreen'>) {
  const [approvalRequestList, setApprovalRequestList] = useState<ApprovalWorkflowRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItemForApproval, setSelectedItemForApproval] = useState<ApprovalWorkflowRequest>();
  const [filteredApprovalRequestList, setFilteredApprovalRequestList] = useState<
    ApprovalWorkflowRequest[] | undefined
  >();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [filter, setFilter] = useState({});
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData> | null>();
  const toast = useToast();
  const { profileData, staticData } = useSelector((store: StaticDataStore) => store);
  const { _getUsersFromUid } = useUsers();
  const canEdit = staticData.accessibleScreens?.PendingApprovalsScreen?.edit || false;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [reviewAndApproveModalOpen, setReviewAndApproveModalOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);

  const { db } = useContext(AppContext);

  const fetchPendingApprovals = useCallback(
    async (filterValues: any) => {
      console.log('Fetching Pending Approvals');
      if (!profileData?.orgId) {
        return;
      }
      setIsLoading(true);
      setLastVisible(null);
      const constraints = _filterToWhereClause(filterValues);
      const q = query(
        collection(db, 'Organizations', profileData?.orgId, 'Approvals'),
        where('status', '==', ApprovalTimelineItemStatus.PENDING),
        where('approvers', 'array-contains', profileData?.uid),
        ...constraints,
        orderBy('timestamp', 'desc'),
        limit(5),
      );
      const querySnapshot = await getDocs(q);
      const _approvalRequestList: ApprovalWorkflowRequest[] = [];
      querySnapshot.forEach((doc) => {
        const item = doc.data() as ApprovalWorkflowRequest;
        const action = _getApprovalWorkflowRequestAction(item);
        _approvalRequestList.push({ ...item, id: doc.id, action } as ApprovalWorkflowRequest);
      });

      setApprovalRequestList(_approvalRequestList);
      console.warn(_approvalRequestList);

      if (querySnapshot.empty) {
        setLastVisible(null);
      } else {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setIsLoading(false);
    },
    [db, profileData, staticData],
  );

  const loadMoreApprovals = useCallback(
    async (filterValues: any) => {
      if (lastVisible) {
        setIsLoadingMore(true);
        const constraints = _filterToWhereClause(filterValues);
        const q = query(
          collection(db, 'Organizations', profileData?.orgId, 'Approvals'),
          where('status', '==', ApprovalTimelineItemStatus.PENDING),
          where('approvers', 'array-contains', profileData?.uid),
          ...constraints,
          orderBy('timestamp', 'desc'),
          startAfter(lastVisible),
          limit(5),
        );
        const querySnapshot = await getDocs(q);
        const _approvalRequestList: ApprovalWorkflowRequest[] = [];
        querySnapshot.forEach((doc) => {
          _approvalRequestList.push({ ...doc.data(), id: doc.id } as ApprovalWorkflowRequest);
        });
        if (querySnapshot.empty) {
          setLastVisible(null);
        } else {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setApprovalRequestList([...approvalRequestList, ..._approvalRequestList]);
        setIsLoadingMore(false);
      }
    },
    [db, lastVisible, approvalRequestList, profileData?.orgId],
  );

  useFocusEffect(
    useCallback(() => {
      setIsLoading(true);
      fetchPendingApprovals(filter);
    }, [fetchPendingApprovals, filter]),
  );

  const onSearchOrFilter = useCallback(
    (searchValue: string) => {
      if (searchValue && searchValue !== '') {
        setFilteredApprovalRequestList(_searchList(approvalRequestList, searchValue));
      } else {
        setFilteredApprovalRequestList(undefined);
      }
    },
    [approvalRequestList],
  );

  const onApprovalSelected = useCallback(
    (action: RequestAction, pendingApprovalItem: ApprovalWorkflowRequest) => {
      setSelectedItemForApproval(pendingApprovalItem);
      console.warn('pendingApprovalItem', pendingApprovalItem?.after);
      console.warn('onNext4', action);
      if (
        action === RequestAction.Update &&
        pendingApprovalItem?.type !== ApprovalWorkflowSource.USER_GROUPS
      ) {
        setReviewAndApproveModalOpen(true);
      } else {
        onOpen();
      }
    },
    [onOpen],
  );

  const RefreshButton = (
    <TouchableOpacity
      style={[AppStyles.marginRight, AppStyles.padding]}
      onPress={() => {
        setIsLoading(true);
        fetchPendingApprovals(filter);
      }}
    >
      <FontAwesome name="refresh" size={24} color={Colors.primary} />
    </TouchableOpacity>
  );
  const onNext = useCallback(() => {
    console.warn('onNext', itemIndex, approvalRequestList?.length);
    onClose();
    setReviewAndApproveModalOpen(false);

    if (
      (approvalRequestList?.length || 0) > 1 &&
      itemIndex + 1 < (approvalRequestList?.length || 0)
    ) {
      console.warn('onNext1');
      onApprovalSelected(
        approvalRequestList[itemIndex + 1]?.action?.label || RequestAction.Create,
        approvalRequestList[itemIndex + 1],
      );
      setItemIndex(itemIndex + 1);
    } else {
      console.warn('onNext2');
      setSelectedItemForApproval(undefined);
      setItemIndex(0);
    }
  }, [approvalRequestList, itemIndex, onApprovalSelected, onClose, setReviewAndApproveModalOpen]);

  const onNextPressed =
    (approvalRequestList?.length || 0) > 1 && itemIndex + 1 < (approvalRequestList?.length || 0)
      ? onNext
      : undefined;

  const onItemActioned = useCallback(() => {
    fetchPendingApprovals(filter);
    setSelectedItemForApproval(undefined);
    setReviewAndApproveModalOpen(false);
    if (onNextPressed) {
      onNext();
    }
  }, [approvalRequestList, itemIndex, fetchPendingApprovals, filter, onNext]);

  const renderDetails = useCallback(() => {
    switch (selectedItemForApproval?.type) {
      case ApprovalWorkflowSource.BANKS:
        return (
          <BankDetails
            bank={{
              ...(selectedItemForApproval?.after || {}),
              id: selectedItemForApproval?.key,
            }}
            mode={InputMode.APPROVE}
            onNext={onNextPressed}
            onItemActioned={onItemActioned}
            startWithBic={false}
          />
        );
      case ApprovalWorkflowSource.ENTITIES:
        return (
          <EntityDetails
            entity={{
              ...(selectedItemForApproval?.after || {}),
              id: selectedItemForApproval?.key,
            }}
            mode={InputMode.APPROVE}
            onNext={onNextPressed}
            onItemActioned={onItemActioned}
          />
        );
      case ApprovalWorkflowSource.USERS:
        return (
          <UserDetails
            user={{
              ...(selectedItemForApproval?.after || {}),
              id: selectedItemForApproval?.key,
            }}
            mode={InputMode.APPROVE}
            onNext={onNextPressed}
            onItemActioned={onItemActioned}
          />
        );
      case ApprovalWorkflowSource.USER_GROUPS:
        return (
          <UserGroupDetails
            userGroup={{
              ...(selectedItemForApproval?.after || {}),
              id: selectedItemForApproval?.key,
              ...(selectedItemForApproval?.action?.label === RequestAction.Update &&
                selectedItemForApproval?.after?.updateRequest),
            }}
            mode={InputMode.APPROVE}
            onNext={onNextPressed}
            onItemActioned={onItemActioned}
          />
        );
      case ApprovalWorkflowSource.ACCOUNT_GROUPS:
        return (
          <AccountGroupDetails
            accountGroup={{
              ...(selectedItemForApproval?.after || {}),
              id: selectedItemForApproval?.key,
            }}
            mode={InputMode.APPROVE}
            onNext={onNextPressed}
            onItemActioned={onItemActioned}
          />
        );
      case ApprovalWorkflowSource.PAYMENTS:
        return (
          <CreatePaymentScreen
            route={{
              params: {
                onNext: onNextPressed,
                onItemActioned,
                isApprovalOnly: true,
                payment: {
                  ...(selectedItemForApproval?.after || {}),
                  id: selectedItemForApproval?.key,
                  valueDate: selectedItemForApproval?.after?.valueDate?.toDate(),
                },
              },
            }}
          />
        );

      case ApprovalWorkflowSource.BANK_ACCOUNTS:
        return (
          <BankAccountDetails
            bankAccount={{
              ...(selectedItemForApproval?.after || {}),
              id: selectedItemForApproval?.key,
            }}
            mode={InputMode.APPROVE}
            onNext={onNextPressed}
            onItemActioned={onItemActioned}
          />
        );
      case ApprovalWorkflowSource.PAYMENT_TEMPLATES:
        return (
          <CreatePaymentScreen
            route={{
              params: {
                isApprovalOnly: true,
                isTemplate: true,
                payment: {
                  ...(selectedItemForApproval?.after || {}),
                  id: selectedItemForApproval?.key,
                  // valueDate: selectedItemForApproval?.after?.valueDate?.toDate(),
                },
              },
            }}
          />
        );
      default:
        return null;
    }
  }, [selectedItemForApproval, itemIndex, approvalRequestList, onNext, onItemActioned]);

  return (
    <View style={AppStyles.container}>
      {selectedItemForApproval && reviewAndApproveModalOpen && (
        <ReviewAndApproveModal
          isFullScreen
          show
          onClose={() => {
            setSelectedItemForApproval(undefined);
            setReviewAndApproveModalOpen(false);
          }}
          pendingApprovalItem={selectedItemForApproval.after}
          source={selectedItemForApproval?.type}
          approvalRequestData={selectedItemForApproval}
          onSomeActionDone={onItemActioned}
          onNext={onNextPressed}
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="2px" />
        <ModalContent maxWidth={'95%'}>
          <ModalCloseButton />
          <ModalHeader>
            {selectedItemForApproval?.type}
            <Text style={AppStyles.marginLeft}>
              {`Request by - ${_getUsersFromUid(
                selectedItemForApproval?.timeline[0].approvers[0].uid,
              )}`}
            </Text>
            <Badge
              ml={2}
              colorScheme={selectedItemForApproval?.action?.color}
              variant="solid"
              style={{ alignSelf: 'flex-end' }}
              fontSize={'0.6em'}
            >
              {selectedItemForApproval?.action?.label}
            </Badge>
          </ModalHeader>
          <ModalBody>{renderDetails()}</ModalBody>
        </ModalContent>
      </Modal>

      <View style={AppStyles.flexRowCenterSpaceBetween}>
        {(approvalRequestList && approvalRequestList.length > 0) || !isEmpty(filter) ? (
          <>
            <Card style={{ paddingVertical: 0, marginTop: 10, marginBottom: 10, borderRadius: 5 }}>
              <FilterBar
                onSearch={onSearchOrFilter}
                searchPlaceholder={'Search Pending Approvals...'}
                filter={filter}
                setFilter={setFilter}
                filterParams={[
                  {
                    title: 'Type',
                    type: 'MultiSelect',
                    field: 'type',
                    options: [
                      { label: ApprovalWorkflowSource.BANKS, value: ApprovalWorkflowSource.BANKS },
                      {
                        label: ApprovalWorkflowSource.BANK_ACCOUNTS,
                        value: ApprovalWorkflowSource.BANK_ACCOUNTS,
                      },
                      {
                        label: ApprovalWorkflowSource.PAYMENTS,
                        value: ApprovalWorkflowSource.PAYMENTS,
                      },
                      {
                        label: ApprovalWorkflowSource.PAYMENT_TEMPLATES,
                        value: ApprovalWorkflowSource.PAYMENT_TEMPLATES,
                      },
                      {
                        label: ApprovalWorkflowSource.ENTITIES,
                        value: ApprovalWorkflowSource.ENTITIES,
                      },
                      {
                        label: ApprovalWorkflowSource.USERS,
                        value: ApprovalWorkflowSource.USERS,
                      },
                      {
                        label: ApprovalWorkflowSource.USER_GROUPS,
                        value: ApprovalWorkflowSource.USER_GROUPS,
                      },
                      {
                        label: ApprovalWorkflowSource.ACCOUNT_GROUPS,
                        value: ApprovalWorkflowSource.ACCOUNT_GROUPS,
                      },
                      // {
                      //   label: ApprovalWorkflowSource.CONFIGURATION,
                      //   value: ApprovalWorkflowSource.CONFIGURATION,
                      // },
                    ],
                  },
                ]}
              />
            </Card>
            <View style={AppStyles.flexRowCenter}>
              <Button
                mr={2}
                label="Review and Approve"
                onPress={() => {
                  onApprovalSelected(
                    approvalRequestList[0]?.action?.label || RequestAction.Create,
                    approvalRequestList[0],
                  );
                  setItemIndex(0);
                }}
              />
              {RefreshButton}
            </View>
          </>
        ) : (
          <>
            <View />
            {RefreshButton}
          </>
        )}
      </View>

      <View
        style={{
          flexDirection: 'row',
          height: Layout.window.height - 150,
          justifyContent: 'space-between',
        }}
      >
        <View style={{ width: '40%' }}>
          <CFlatList
            isLoading={isLoading}
            data={filteredApprovalRequestList || approvalRequestList}
            emptyMessage={'No items pending for your approval!'}
            emptyImage={
              <Image
                source={require('../assets/images/completed.svg')}
                style={{ width: 300, height: 225 }}
              />
            }
            renderItem={({ item, index }: { item: ApprovalWorkflowRequest; index: number }) => {
              const before = item.before;
              const after = item.after;
              return (
                <Card>
                  <TouchableOpacity
                    onPress={() => {
                      onApprovalSelected(item.action?.label || RequestAction.Create, item);
                      setItemIndex(index);
                    }}
                  >
                    <View style={AppStyles.flexRowCenterSpaceBetween}>
                      <Text style={AppStyles.textSubTitle}>{item.type}</Text>
                      <Badge
                        colorScheme={item.action?.color}
                        variant="solid"
                        style={{ alignSelf: 'flex-end' }}
                        fontSize={'0.6em'}
                      >
                        {item.action?.label}
                      </Badge>
                    </View>

                    <Text>
                      {after?.sequenceNumber
                        ? '#' + after?.sequenceNumber
                        : before?.name ||
                          before?.accountName ||
                          before?.template ||
                          after?.template ||
                          after?.name ||
                          after?.firstName ||
                          item.key}
                    </Text>
                    <View style={AppStyles.flexRowCenterSpaceBetween}>
                      <IconText
                        icon={<Text style={AppStyles.textSubTitle}>Req By: </Text>}
                        value={_getUsersFromUid(item.user)}
                        showEmpty={false}
                        marginTop={10}
                      />
                      <Text style={AppStyles.textTiny}>{_getTimeStampString(item.timestamp)}</Text>
                    </View>
                  </TouchableOpacity>
                </Card>
              );
            }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  bankAccountCard: {
    marginBottom: 10,
    marginHorizontal: 10,
    borderWidth: 2,
    flex: 1,
    borderRadius: 5,
    padding: 0,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 20,
    borderRightWidth: 0,
    borderBottomWidth: 20,
    borderLeftWidth: 15,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.primary,
    position: 'absolute',
    left: 0,
    top: 35,
  },
});
