import React, { FC, useMemo } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import rdiff from 'recursive-diff';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Action, ActivityHistory, StaticDataStore } from '../commonTypes';
import { DateFormat } from '../constants/Constants';
import AppStyles from '../constants/Styles';
import useUsers from '../hooks/useUsers';
import { _getChanges, _getItemNameFromLogs } from '../utils/helper';
import ApprovalTimeline from './ApprovalWorkflow/ApprovalTimeline';
import CChangesSummaryItem from './CChangesSummaryItem';
import { CFlatList } from './CFlatList';
import { Text, View } from './Themed';

export type ActivityHistoryChangesModalProps = {
  selectedItem?: ActivityHistory;
  setSelectedItem: (item?: ActivityHistory) => void;
};
const ActivityHistoryChangesModal: FC<ActivityHistoryChangesModalProps> = ({
  selectedItem,
  setSelectedItem,
}) => {
  const { staticData } = useSelector((store: StaticDataStore) => store);

  const { _getUsersFromUid } = useUsers();

  const { changes, before } = useMemo(() => {
    if (selectedItem) {
      let _changes: rdiff.rdiffResult[];
      const excludeFields = [
        'lastUpdatedBy',
        'updateRequest',
        'requestedBy',
        'lastUpdatedAt',
        'requestId',
        'approvedAt',
        'approvedBy',
        'rejectedBy',
        'lastApprovalAction',
        'createdAt',
        'lastApprovalId',
        'lastApprovalComments',
        'lastStatementValueDate',
        'lastStatementImportDate',
      ];
      if (selectedItem.action === Action.UPDATE_REQUEST) {
        _changes = _getChanges(
          selectedItem.before,
          selectedItem.after?.updateRequest || [],
          excludeFields,
        ).filter((x) => selectedItem.after?.updateRequest[x.path[0]] !== undefined);
      } else {
        _changes = _getChanges(selectedItem.before, selectedItem.after, excludeFields);
      }

      const _before = selectedItem.before;

      return { changes: _changes, before: _before };
    }
    return { changes: null, before: null };
  }, [selectedItem, staticData?.users]);

  if (!selectedItem) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        setSelectedItem(undefined);
      }}
    >
      <ModalOverlay bg="none" backdropFilter="auto" backdropInvert="30%" backdropBlur="2px" />
      <ModalContent maxWidth="60%">
        <ModalCloseButton />
        <ModalHeader>{`${selectedItem.action} by - ${_getUsersFromUid(
          selectedItem.user,
        )} on ${moment(selectedItem.timestamp.toDate()).format(DateFormat.DEFAULT)}`}</ModalHeader>
        <ModalBody>
          <Text style={AppStyles.textRowTitle}>{_getItemNameFromLogs(selectedItem)}</Text>
          {(changes?.length || 0) > 0 && (
            <View style={AppStyles.padding}>
              <CFlatList
                isLoading={false}
                numColumns={3}
                data={changes || []}
                renderItem={({ item }) => {
                  return <CChangesSummaryItem item={item} before={before} />;
                }}
              />
            </View>
          )}
          {selectedItem && selectedItem.approvalId && (
            <ApprovalTimeline id={selectedItem.approvalId} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ActivityHistoryChangesModal;
