import React, { FC } from 'react';

import { Select } from 'chakra-react-select';
import { FormControl, FormLabel, SpaceProps } from '@chakra-ui/react';
import CToolTip from './CToolTip';
import Constants from '../constants/Constants';

export type DaysOfWeekPickerProps = {
  values?: string[];
  onSelect?: (value?: string) => void;
  isRequired?: boolean;
  title?: string;
  width?: number;
  ml?: SpaceProps['ml'];
  tooltip?: string;
};
const DaysOfWeekPicker: FC<DaysOfWeekPickerProps> = ({
  values,
  onSelect,
  isRequired,
  title,
  width,
  ml,
  tooltip,
}) => {
  return (
    <FormControl ml={ml} isRequired={isRequired} width={width || 300}>
      {title && (
        <FormLabel fontSize={14} mb={1}>
          {title}
          {tooltip && <CToolTip label={tooltip} />}
        </FormLabel>
      )}
      <Select
        isMulti
        onChange={(selected) => {
          onSelect(selected.map((val: any) => val.value));
        }}
        closeMenuOnSelect={false}
        value={values?.map((val) => {
          return { value: val, label: Constants.daysOfWeek.find((d) => d.id === val)?.label };
        })}
        name="User Groups"
        options={Constants.daysOfWeek?.map((ccy) => {
          return { value: ccy.id, label: ccy.label };
        })}
        placeholder="Select Days"
        size="sm"
        menuPosition="fixed"
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        chakraStyles={{
          menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
        }}
        menuPortalTarget={document.body}
      />
    </FormControl>
  );
};

export default DaysOfWeekPicker;
